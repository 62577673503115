import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef} from '@angular/core';
import { GLAccountsService } from '../../../services/glaccounts/glaccounts.service';
import { GLAccount, emptyGLAccount} from '../../../services/glaccounts/glaccounts.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { v4 as uuidv4 } from 'uuid'
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';

@Component({
  selector: 'app-gl-account-single',
  templateUrl: './gl-account-single.component.html',
  styleUrls: ['./gl-account-single.component.scss'],
})
export class GLAccountSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfGLAccount: number | null = null;
  @Input() glAccount: GLAccount = emptyGLAccount;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  
  glAccountForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin:boolean;

  constructor(
    private glAccountsService: GLAccountsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private notificationHelper: NotificationHelper
  ) {   
  }

  ngOnInit(): void {
    if (this.idOfGLAccount) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();  
    if (!this.isAdmin) {
      this.glAccountForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfGLAccount) {
        this.glAccountForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }
  buildForm() {
    this.glAccountForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      number: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      erpid: new FormControl({value: '', disabled: true}, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
    this.glAccountForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.glAccountForm.controls;
  }
  updateForm() {
    this.glAccountForm.patchValue({ ...this.glAccount });
    this.isFormChanged = false;
  }
    
  onClick_Save() {
    this.saveRecord();
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  async checkForExistingNumber() {
    var numberExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Number = this.glAccountForm.controls['number'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Number, Operand_Name, PageSize };
    const existingGLAccounts: GLAccount[] = await (
      await this.glAccountsService.getList(params)
    ).body;
    if (existingGLAccounts.length > 0) {
      if (this.glAccount.id) {
        for (const key in existingGLAccounts) {
          const currentGLAccount: GLAccount = existingGLAccounts[key];
          if (currentGLAccount.id !== this.glAccount.id) {
            numberExists = true;
          }
        }
      } else {
        numberExists = true;
      }
    }
    return numberExists;
  }

  async saveRecord() {
    this.glAccountForm.markAllAsTouched();
    this.submitted = true;
    if (this.glAccountForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.glAccount,
      ...this.glAccountForm.getRawValue(), // glAccountForm.value does not include disabled controls.
    };
    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.notificationHelper.showStatusOnDialog('ERP ID already exists!', 'error', this.container);
      return;
    }

    // Check to see if GLAccountNumber already exists.
    if ((await this.checkForExistingNumber()) === true) {
      this.notificationHelper.showStatusOnDialog('GL Account Number already exists!', 'error', this.container);
    } else {
      if (this.glAccount.id) {        
        try {
          const response: any = await this.glAccountsService.update(
            this.glAccount.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {
            const response: any = await this.glAccountsService.create(
                this.glAccountForm.getRawValue(), // glAccountForm.value does not include disabled controls.
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }   
  }


  async validation_CheckForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.glAccountForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingGLAccounts: GLAccount[] = await (
      await this.glAccountsService.getList(params)
    ).body;
    if (existingGLAccounts.length > 0) {
      if (this.glAccount.id) {
        for (const key in existingGLAccounts) {
          const currentGLAccount: GLAccount = existingGLAccounts[key];
          if (currentGLAccount.id != this.glAccount.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }
  async getGLAccountById() {
    if (this.idOfGLAccount) {
      return await this.glAccountsService.getById(this.idOfGLAccount);
    } else {
      return emptyGLAccount;
    }
  }  
  async loadData() {
    try {
      [this.glAccount] = await Promise.all([this.getGLAccountById()]);      
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }
}
