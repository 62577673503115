<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/SyncQueue.png" alt="Sync Queues"
       style="padding-right: 5px;">
  Sync Queues
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()" [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
              imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()"
              title="Delete Selected">
        Delete
      </button>
    </kendo-buttongroup>
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Reset.png" (click)="onClick_ResetQueues()" title="Reset Selected Queues" [disabled]="disableDeleteBtn">
      Reset Queues
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Execute_Multiple.png" (click)="onClick_RunNow()" title="Process All Pending Queues Now">
      Process Now
    </button>
  </ng-template>
  <kendo-grid-column field="entryTime" title="Entry Time" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.entryTime | date: 'MM/dd/yyyy HH:mm:ss zz' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span role="button">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="syncSet.name" title="Sync Set"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="queueType.name" title="Queue Type"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <sync-queue-queue-type-filter [filter]="filter"
                                [data]="queueTypeOptions"
                                textField="name"
                                valueField="name">
      </sync-queue-queue-type-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="entity.name" title="Entity"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="recordID" title="Record ID"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="sinceTime" title="Since Time" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.sinceTime | date: 'MM/dd/yyyy HH:mm:ss zz' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span role="button">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="status.name" title="Status"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <sync-queue-status-filter [filter]="filter"
                                  [data]="queueStatusOptions"
                                  textField="name"
                                  valueField="name">
      </sync-queue-status-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="completedTime" title="Completed Time" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.completedTime | date: 'MM/dd/yyyy HH:mm:ss zz' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span role="button">{{ dataItem.name }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="errorMessage" title="Error Message"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="testRecordCount" title="Test Record Count"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

