<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/UofM_Schedule.png" alt="Unit of Measure" style="padding-right: 5px;">
  Create / Update Unit of Measure Schedule
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
    imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/uofm-schedules']"
    routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Save_Close.png" (click)="onClick_SaveClose()" title="Save & Close">SAVE &
    CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Save_New.png" (click)="onClick_SaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Add.png" (click)="onClick_AddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
    [disabled]="!isAdmin || disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
    (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
</kendo-buttongroup>
<form [formGroup]="uofMScheduleForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Unit of Measure Schedule Name">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>

    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="erpid" text="ERP ID"></kendo-label>
        <kendo-textbox class="form-control" formControlName="erpid">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
</form>
<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-uofm-sublist [uofMScheduleID]="idOfUofMSchedule" mode="subgrid" #subgrid>
  </app-uofm-sublist>
</div>
