<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Pricing_Condition.png" alt="Pricing Condition" style="padding-right: 5px;">
  Create / Update Pricing Condition
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" (click)="onClickBack()"
          routerLinkActive="router-link-active" title="Go Back to List">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">
    SAVE & NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
          title="Delete Record">
    DELETE
  </button>
</kendo-buttongroup>

<form [formGroup]="pricingConditionForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Pricing Condition Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Pricing Condition Name"
                       maxlength="100">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col">
      <div>
        <kendo-formfield>
          <kendo-label for="conditionalPricingFilterID" text="Conditional Pricing Filter"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="conditionalPricingFilterID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="conditionalPricingFilterID"
                       [ngClass]="{'is-invalid': submitted && f.conditionalPricingFilterID.errors,'w-100': true}"
                       [searchable]="true" [notFoundText]="'Please start typing to search'"
                       (search)="search($event, filters.ConditionalPricingFilter)"
                       (change)="onSelectBoxChanged($event,'conditionalPricingFilterID',conditionalPricingFilters)"
                       placeholder="Select a Conditional Pricing Filter">
              <ng-option *ngFor="let item of conditionalPricingFilters" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_conditionalPricingFilter()" icon="search"></button>
          </span>
          <kendo-formerror>Conditional Pricing Filter is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col flex-nowrap">
    </div>
  </div>

  <div class="row">
    <div class="col">
      <kendo-formfield>
        <kendo-label for="conditionType" class="font-weight-bold" text="* Condition Type"></kendo-label>
        <ng-select id="conditionType" [multiple]="false" [selectOnTab]="true" [clearable]="false" formControlName="conditionTypeID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.conditionTypeID?.errors, 'w-100': true }"
                   placeholder="Select a Condition Type" [searchable]="true" appendTo="body"
                   (change)="onSelectBoxChanged($event, 'conditionTypeID', conditionOptions)">
          <ng-option *ngFor="let item of conditionTypeOptions" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-formerror>Condition Type is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col">
      <kendo-formfield>
        <kendo-label for="conditionOperand" class="font-weight-bold" text="* Condition Operand"></kendo-label>
        <ng-select id="conditionOperand" [multiple]="false" [selectOnTab]="true" [clearable]="false" formControlName="conditionOperandID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.conditionOperandID?.errors,'w-100': true}"
                   placeholder="Select a Condition Operand" [searchable]="true" appendTo="body"
                   (change)="onSelectBoxChanged($event, 'conditionOperandID', conditionOperands)">
          <ng-option *ngFor="let item of conditionOperands" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-formerror>Condition Operand is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col">
      <div>
        <kendo-formfield *ngIf="showConditionQuantity">
          <kendo-label for="conditionQuantity" class="font-weight-bold" text="* Quantity"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="conditionQuantity" [autoCorrect]="true" [decimals]="5" [min]="0" [max]="99999999999999"
                                [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.conditionQuantity.errors }"
                                placeholder="Enter a Quantity">
          </kendo-numerictextbox>
          <kendo-formerror>Quantity is required.</kendo-formerror>
        </kendo-formfield>
      </div>
      <div>
        <kendo-formfield *ngIf="showConditionAmount">
          <kendo-label for="conditionAmount" class="font-weight-bold" text="* Amount"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="conditionAmount" [autoCorrect]="true" [decimals]="5" [min]="-99999999999999" [max]="99999999999999"
                                [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.conditionAmount.errors }"
                                placeholder="Enter a Sales Condition Amount">
          </kendo-numerictextbox>
          <kendo-formerror>Amount is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </div>
  <div class="row" style="padding-top:15px;">
    <div class="col form-group d-flex align-items-center">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="convertToUofM" (change)="convertToUofMChange()" formControlName="convertToUofM"
               #convertToUofM kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="convertToUofM" text="Convert to Unit of Measure"></kendo-label>
      </div>
    </div>
    <div class="col form-group">
      <kendo-formfield *ngIf="showConvertToUofMName">
        <kendo-label for="convertToUofMName" class="font-weight-bold" text="* Unit of Measure Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="convertToUofMName" placeholder="Enter UofM Name"
                       maxlength="100">
        </kendo-textbox>
        <kendo-formerror>UofM Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col">
      <kendo-formfield>
        <kendo-label for="maximumAffectedQuantity" class="font-weight-bold" text="* Maximum Affected Quantity (0=unlimited)"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="maximumAffectedQuantity" [autoCorrect]="true" [decimals]="5" [min]="0" [max]="99999999999999"
                              [format]="'n5'" [ngClass]="{ 'is-invalid': submitted && f.maximumAffectedQuantity.errors }"
                              placeholder="Max Qty this Condition will Affect">
        </kendo-numerictextbox>
        <kendo-formerror>Maximum Affected Quantity is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row" style="padding-top:15px;">
    <div class="col">
      <kendo-formfield *ngIf="showHistoryDateOption">
        <kendo-label for="historyDateOption" class="font-weight-bold" text="* History Date Option"></kendo-label>
        <ng-select id="historyDateOptionOption" [multiple]="false" [selectOnTab]="true" [clearable]="false" formControlName="historyDateOptionID"
                   [notFoundText]="'Please start typing to search'"
                   [ngClass]="{'is-invalid': submitted && f.historyDateOptionID?.errors,'w-100': true}"
                   placeholder="Select a History Date Option" [searchable]="true" appendTo="body"
                   (change)="onSelectBoxChanged($event, 'historyDateOptionID', historyDateOptions)">
          <ng-option *ngFor="let item of historyDateOptions" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-formerror>History Date Option is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col">
      <kendo-formfield *ngIf="showHistoryDateValue">
        <kendo-label for="historyDateValue" class="font-weight-bold" text="* History Date Value"></kendo-label>
        <kendo-numerictextbox class="form-control" formControlName="historyDateValue" [maxlength]="4" [min]="1" [decimals]="0" [max]="9999"
                              [format]="'####'" [ngClass]="{ 'is-invalid': submitted && f.historyDateValue.errors }"
                              placeholder="Enter a Value for History Date">
        </kendo-numerictextbox>
        <kendo-formerror>Value is required.</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col">
      <kendo-formfield *ngIf="showHistoryDate">
        <kendo-label for="historyDate" class="font-weight-bold" text="* History Date">
        </kendo-label>
        <kendo-datepicker id="historyDate" class="form-control" formControlName="historyDate" [(value)]="historyDate" [min]="min">
        </kendo-datepicker>
        <kendo-formerror *ngIf="f.historyDate.errors && f.historyDate.errors.required">History Date is required</kendo-formerror>
        <kendo-formerror *ngIf="f.historyDate.errors && f.historyDate.errors.minError">History Date Must be Greater than Jan 1st, 2000</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group d-flex align-items-center">
    </div>
  </div>
</form>
