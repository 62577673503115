<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <!--<img src="../../../../assets/images/Customer_Attributes.png" alt="Sync Writer Columns"
    style="padding-right: 5px;">-->
  Sync Writer Columns
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton id="btnAdd" id="btnAdd" type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || !this.syncWriterID"
              imageUrl="../../../../assets/images/Add.png" (click)="onCreate()"
              title="Add New">
        Add
        New
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn"
              imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">
        Edit
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
              imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()"
              title="Delete Selected">
        Delete
      </button>
    </kendo-buttongroup>
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>

  <kendo-grid-column field="sequenceNumber" title="Sequence Number" width="175"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-lt-operator></kendo-filter-lt-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
        <kendo-filter-gt-operator></kendo-filter-gt-operator>
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="columnName" title="Column Name" width="175"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
