import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttribute, emptyItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  AttributeDataType = 1,
}
@Component({
  selector: 'app-item-attribute-single',
  templateUrl: './item-attribute-single.component.html',
  styleUrls: ['./item-attribute-single.component.scss'],
})
export class ItemAttributeSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfItemAttribute: number | null = null;
  @Input() itemAttribute: ItemAttribute = emptyItemAttribute;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  loading: boolean = true;
  itemAttributeForm: FormGroup;
  attributeDataTypes: Lookup[] = [];
  submitted = false;
  modalReference: any;
  isFormChanged = false;

  constructor(
    private itemAttributesService: ItemAttributesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfItemAttribute) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }
  buildForm() {
    this.itemAttributeForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', Validators.maxLength(200)),
      attributeDataTypeID: new FormControl(null, Validators.required),
    });
    this.getAttributeDataTypes();
    this.itemAttributeForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.itemAttributeForm.controls;
  }
  updateForm() {
    this.itemAttributeForm.patchValue({ ...this.itemAttribute });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.itemAttributeForm.markAllAsTouched();
    this.submitted = true;
    if (this.itemAttributeForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = { ...this.itemAttribute, ...this.itemAttributeForm.value };
    // Check to see if Item Attribute Name already exists.
    if ((await this.validation_CheckForExistingName()) === true) {
      this.notificationHelper.showStatusOnDialog('Item Attribute Name already exists!', 'error', this.container);
    } else {
      if (this.itemAttribute.id) {        
        try {
          const response: any = await this.itemAttributesService.update(
            this.itemAttribute.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {
          const response: any = await this.itemAttributesService.create(
            this.itemAttributeForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }
  }

  async getAttributeDataTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'AttributeDataType';
    const params = { Filter_OptionSet, PageSize };
    this.attributeDataTypes = await this.lookupService.getList(params);
    this.attributeDataTypes = this.attributeDataTypes.filter(
      x => x.name !== "Lookup" && x.name !== "Text" && x.name !== "Time");
    return this.attributeDataTypes;
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.itemAttributeForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingItemAttributes: ItemAttribute[] = await (
      await this.itemAttributesService.getList(params)
    ).body;
    if (existingItemAttributes.length > 0) {
      if (this.itemAttribute.id) {
        for (const key in existingItemAttributes) {
          const itemAttr: ItemAttribute = existingItemAttributes[key];
          if (itemAttr.id !== this.itemAttribute.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }
  async getitemAttributeById() {
    if (this.idOfItemAttribute) {
      return await this.itemAttributesService.getById(this.idOfItemAttribute);
    } else {
      return emptyItemAttribute;
    }
  }
  async loadData() {
    try {
      [this.itemAttribute, this.attributeDataTypes] = await Promise.all([
        this.getitemAttributeById(),
        this.getAttributeDataTypes(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  onSelectBoxChanged(id: number, key: keyof ItemAttribute, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.itemAttribute[key] = item;
  }

}
