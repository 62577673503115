import { Component, OnInit, ViewChild } from '@angular/core';
import { SyncQueuesService } from '../../../services/sync-queues/sync-queues.service';
import { SyncQueue, emptySyncQueue } from '../../../services/sync-queues/sync-queues.model';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemJobLog } from '../../../services/system-job-logs/system-job-logs.model';
import { SystemJobLogListComponent } from '../../system-job-logs/system-job-log-list/system-job-log-list.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sync-queue-single',
  templateUrl: './sync-queue-single.component.html',
  styleUrls: ['./sync-queue-single.component.scss'],
})
export class SyncQueueSingleComponent implements OnInit {
  @ViewChild('subgrid') SystemJobLogsSubGrid: SystemJobLogListComponent;

  idOfSyncQueue: number | null = null;
  syncQueue: SyncQueue = emptySyncQueue;
  loading: boolean = true;
  syncQueueForm: FormGroup;
  subgridmode = 'subgrid';
  systemJobLogs: SystemJobLog[] = [];
  paginationObject_SystemJobLogs: any = { systemJobLogs: mockedData };

  pagination_systemJobLogs: any = { systemJobLogs: mockedData };

  constructor(
    private syncQueuesService: SyncQueuesService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public fb: FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.syncQueue = emptySyncQueue;
        this.idOfSyncQueue = null;
        return;
      }
      else {
        this.idOfSyncQueue = params.id;
      }
    });
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadData();
  }

  buildForm() {
    this.syncQueueForm = new FormGroup({
      entryTime: new FormControl(''),
      syncSet: new FormControl(''),
      queueType: new FormControl(''),
      entity: new FormControl('')
    });
  }
  get f() {
    return this.syncQueueForm.controls;
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfSyncQueue) {
        this.syncQueue = await Promise.resolve(await this.syncQueuesService.getById(this.idOfSyncQueue));
      }
      if (this.syncQueue) {
        this.syncQueueForm.patchValue({
          entryTime: this.syncQueue.entryTime,
          syncSet: this.syncQueue.syncSet.name,
          queueType: this.syncQueue.queueType.name,
          entity: this.syncQueue.entity.name
        });
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      this.loading = false;
    }
  }

 
}
