export interface PriceSheet {
  id: number;
  name: string;
  priceBookID: number;
  priceBook: {
    name: string,
    id: number
  };
  rank: number;
  isActive: boolean;
  currencyID: number;
  currency: {
    name: string,
    id: number,
    erpid: string
  };
  pricingFilterID: number;
  pricingFilter: {
    name: string;
    id: number;
  };
  AttributeValue: string;
  effectiveBeginDate: Date;
  effectiveEndDate: Date;
  revisionDate: Date;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPriceSheet: PriceSheet = {
  id: null,
  name: '',
  priceBookID: null,
  priceBook: {
    name: null,
    id: null
  },
  rank: null,
  isActive: false,
  currencyID: null,
  currency: {
    name: '',
    id: null,
    erpid: ''
  },
  pricingFilterID: null,
  pricingFilter: {
    name: '',
    id: null
  },
  AttributeValue: '',
  effectiveBeginDate: null,
  effectiveEndDate: null,
  revisionDate: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
