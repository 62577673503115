import { Component, OnInit, Input, ViewEncapsulation, SimpleChanges, OnChanges } from '@angular/core';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { SyncReaderColumn } from '../../../services/sync-reader-columns/sync-reader-columns.model';
import { SyncReaderColumnsService } from '../../../services/sync-reader-columns/sync-reader-columns.service';
import { SyncWriterColumn } from '../../../services/sync-writer-columns/sync-writer-columns.model';
import { SyncWriterColumnsService } from '../../../services/sync-writer-columns/sync-writer-columns.service';

@Component({
  selector: 'app-sync-map-preview',
  templateUrl: './sync-map-preview.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SyncMapPreviewComponent implements OnInit, OnChanges {
  @Input() syncReaderID: number;
  @Input() syncWriterID: number;
  public gridViewReader: SyncReaderColumn[];
  public gridViewWriter: SyncWriterColumn[];
  constructor(
    private syncReaderColumnsService: SyncReaderColumnsService,
    private syncWriterColumnsService: SyncWriterColumnsService,
  ) {
  }
  syncReaderColumns: SyncReaderColumn[] = [];
  syncWriterColumns: SyncWriterColumn[] = [];

  loading: boolean = true;
  query = { PageSize: 50, SortTerm: '', PageNumber: 1, SearchTerm: '' };

  ngOnInit() {
    this.loading = true;
    this.loadListDataReader();
    this.loadListDataWriter();
    this.loading = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['syncReaderID']) {
      this.loadListDataReader();
    }
    if (changes['syncWriterID']) {
      this.loadListDataWriter();
    }
  }

  onRefresh() {
    this.ngOnInit();
  }

  public async loadListDataReader() {
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.syncReaderID) {
        const response: any = await this.syncReaderColumnsService.getList({
          Filter_SyncReaderID: this.syncReaderID,
          ...this.query,
        });
        this.syncReaderColumns = response.body;
        this.gridViewReader = this.syncReaderColumns;
      }
    } catch (e) {
    } 
  }

  public async loadListDataWriter() {
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.syncWriterID) {
        const response: any = await this.syncWriterColumnsService.getList({
          Filter_SyncWriterID: this.syncWriterID,
          ...this.query,
        });
        this.syncWriterColumns = response.body;
        this.gridViewWriter = this.syncWriterColumns;
      }
    } catch (e) {
    } 
  }

}
