<div>
  <ng-template #areYouSure let-modal>
    <div class="modal-header">Alert</div>
    <div class="modal-body">
      <p>Are you sure you want to delete this record?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
        Yes
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
        No
      </button>
    </div>
  </ng-template>

  <div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
    <img src="../../../../assets/images/Price_Sheet.png" alt="System Job Error" style="padding-right: 5px;">
    System Job Error
  </div>
  <kendo-buttongroup>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Back.png" (click)="onClick_Back()"
            routerLinkActive="router-link-active" title="Go Back to List">
      BACK
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            [disabled]="disableButtons" imageUrl="../../../../assets/images/Delete.png"
            (click)="showRemovePopUp(areYouSure)" title="Delete Record">
      DELETE
    </button>
  </kendo-buttongroup>

  <form [formGroup]="SystemJobErrorForm">
    <div class="row">

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="errorMessage" text="Error Message"></kendo-label>
          <kendo-textbox class="form-control" formControlName="errorMessage" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="errorDateTime" text="Error Date Time"></kendo-label>
          <kendo-textbox class="form-control" formControlName="errorDateTime" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="entityName" text="Entity Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="entityName" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="systemJobLogID" text="System Job Log ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="systemJobLogID" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="errorNumber" text="Error Number"></kendo-label>
          <kendo-textbox class="form-control" formControlName="errorNumber" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="errorSeverity" text="Error Severity"></kendo-label>
          <kendo-textbox class="form-control" formControlName="errorSeverity" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="errorState" text="Error State"></kendo-label>
          <kendo-textbox class="form-control" formControlName="errorState" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="recordReferenceID" text="Record Reference ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="recordReferenceID" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="jsonSnippet" text="JSON Snippet"></kendo-label>
          <kendo-textarea class="form-control" width="100%" formControlName="jsonSnippet" [readonly]="true" rows="10" style="width: 100%; overflow-y: auto;">
          </kendo-textarea>
        </kendo-formfield>
      </div>
    </div>


  </form>

</div>
