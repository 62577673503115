<div>
  <ng-template #areYouSure let-modal>
    <div class="modal-header">Alert</div>
    <div class="modal-body">
      <p>Are you sure you want to delete this record?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
        Yes
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
        No
      </button>
    </div>
  </ng-template>

  <div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
    <img src="../../../../assets/images/Catalog_Sheet.png" alt="Catalog Sheet" style="padding-right: 5px;">
    Create / Update Catalog Sheet
  </div>
  <kendo-buttongroup>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Back.png" (click)="onClick_Back()"
            routerLinkActive="router-link-active" title="Go Back to List">
      BACK
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">
      SAVE
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Save_Close.png" (click)="onClick_SaveClose()" title="Save & Close">
      SAVE &
      CLOSE
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Save_New.png" (click)="onClick_SaveNew()" title="Save & New">
      SAVE &
      NEW
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Add.png" (click)="onClick_AddNew()" title="Add New">
      ADD NEW
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            [disabled]="disableButtons" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
      COPY
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            [disabled]="disableButtons" imageUrl="../../../../assets/images/Delete.png"
            (click)="showRemovePopUp(areYouSure)" title="Delete Record">
      DELETE
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Price_Calculation.png" (click)="onClickCalculate()" title="Calculate Entire Sheet">CALCULATE</button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Actions.png" (click)="onClickBenchmark()" title="Benchmark Entire Sheet">BENCHMARK</button>
  </kendo-buttongroup>

  <form [formGroup]="catalogSheetForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Catalog Sheet Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Catalog Sheet Name">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="catalog" class="font-weight-bold" text="* Catalog"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="catalog" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       title="Every Catalog Sheet must be assigned to a Catalog."
                       formControlName="catalogID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'borderless': true, 'is-invalid': submitted && f.catalogID.errors,'w-100': true }"
                       placeholder="Select a Catalog" [searchable]="true"
                       (search)="search($event, filters.Catalog)"
                       (change)="onSelectBoxChanged($event,'catalogID',catalogs)">
              <ng-option *ngFor="let item of catalogs" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (focus)="onFocus($event)" (blur)="onBlur($event)" (click)="onLookup_catalog()" icon="search"></button>
          </span>
          <kendo-formerror>Catalog is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="description" text="Description"></kendo-label>
          <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Catalog Sheet Description"
                         title="Specify a detailed description to help differentiate between Catalog Sheets." >
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="wrapper">
      <kendo-expansionpanel title="Defaults" [attr.title]="'These values will be used on each Catalog Line unless the line specifies a value.'">

        <div class="row">
          <div class="col form-group">
            <kendo-formfield>
              <kendo-label for="customer" text="Customer"></kendo-label>
              <span class="k-textbox" style="width: 100%;">
                <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                           formControlName="customerID" [notFoundText]="'Please start typing to search'"
                           [ngClass]="{'is-invalid': submitted && f.customerID.errors,'w-100': true}" placeholder="Select a Customer"
                           [searchable]="true" (search)="search($event, filters.Customer)"
                           (change)="onSelectBoxChanged($event,'customerID',customers)">
                  <ng-option *ngFor="let item of customers" [value]="item.id"> {{ item?.number }} - {{ item?.name }} </ng-option>
                </ng-select>
                <kendo-textbox-separator></kendo-textbox-separator>
                <button kendoButton look="clear" (click)="onLookup_customer()" icon="search"></button>
              </span>
            </kendo-formfield>
          </div>

          <div class="col form-group">
            <kendo-formfield>
              <kendo-label for="item" text="Item"></kendo-label>
              <span class="k-textbox" style="width: 100%;">
                <ng-select id="itemID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                           formControlName="itemID" [notFoundText]="'Please start typing to search'"
                           [ngClass]="{'is-invalid': submitted && f.itemID.errors,'w-100': true}" placeholder="Select an Item"
                           [searchable]="true" (search)="search($event, filters.Item)"
                           (change)="onSelectBoxChanged($event,'itemID',items)">
                  <ng-option *ngFor="let item of items" [value]="item.id"> {{ item?.number }} - {{ item?.name }} </ng-option>
                </ng-select>
                <kendo-textbox-separator></kendo-textbox-separator>
                <button kendoButton look="clear" (click)="onLookup_item()" icon="search"></button>
              </span>
            </kendo-formfield>
          </div>

        </div>
        <div class="row">

          <div class="col form-group">
            <kendo-formfield>
              <kendo-label for="priceSchedule" text="Price Schedule"></kendo-label>
              <span class="k-textbox" style="width: 100%;">
                <ng-select id="priceScheduleID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                           formControlName="priceScheduleID" [notFoundText]="'Please start typing to search'"
                           [ngClass]="{'is-invalid': submitted && f.priceScheduleID.errors,'w-100': true}" placeholder="Select a Price Schedule"
                           [searchable]="true" (search)="search($event, filters.PriceSchedule)"
                           (change)="onSelectBoxChanged($event,'priceScheduleID',priceSchedules)">
                  <ng-option *ngFor="let item of priceSchedules" [value]="item.id"> {{ item.name }} </ng-option>
                </ng-select>
                <kendo-textbox-separator></kendo-textbox-separator>
                <button kendoButton look="clear" (click)="onLookup_priceSchedule()" icon="search"></button>
              </span>
            </kendo-formfield>
          </div>

          <div class="col form-group">
            <kendo-formfield *ngIf="singleCurrency">
              <kendo-label for="currencyLabelName" text="Currency"></kendo-label>
              <kendo-textbox class="form-control" formControlName="currencyLabelName" maxlength="100" disabled="true">
              </kendo-textbox>
            </kendo-formfield>
            <kendo-formfield *ngIf="!singleCurrency">
              <kendo-label for="currency" text="Currency"></kendo-label>
              <span class="k-textbox" style="width: 100%;">
                <ng-select id="currencyID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                           formControlName="currencyID" [notFoundText]="'Please start typing to search'"
                           [ngClass]="{'is-invalid': submitted && f.currencyID.errors,'w-100': true}" placeholder="Select a Currency"
                           [searchable]="true" (search)="search($event, filters.Currency)"
                           (change)="onSelectBoxChanged($event,'currencyID',currencies)">
                  <ng-option *ngFor="let item of currencies" [value]="item.id"> {{ item.name }} </ng-option>
                </ng-select>
                <kendo-textbox-separator></kendo-textbox-separator>
                <button kendoButton look="clear" (click)="onLookup_currency()" icon="search"></button>
              </span>
            </kendo-formfield>
          </div>
        </div>

        <div class="row">
          <div class="col form-group">
            <kendo-formfield>
              <kendo-label for="location" text="Location"></kendo-label>
              <span class="k-textbox" style="width: 100%;">
                <ng-select id="locationID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                           formControlName="locationID" [notFoundText]="'Please start typing to search'"
                           [ngClass]="{'is-invalid': submitted && f.locationID.errors,'w-100': true}" placeholder="Select a Location"
                           [searchable]="true" (search)="search($event, filters.Location)"
                           (change)="onSelectBoxChanged($event,'locationID',locations)">
                  <ng-option *ngFor="let item of locations" [value]="item.id"> {{ item.name }} </ng-option>
                </ng-select>
                <kendo-textbox-separator></kendo-textbox-separator>
                <button kendoButton look="clear" (click)="onLookup_location()" icon="search"></button>
              </span>
            </kendo-formfield>
          </div>

          <div class="col form-group">
            <kendo-formfield>
              <kendo-label for="effectiveDate" text="Effective Date"></kendo-label>
              <kendo-datepicker id="effectiveDate" class="form-control" formControlName="effectiveDate"
                                title="Uses this date at the time of calculation."
                                [(value)]="effectiveDate">
              </kendo-datepicker>
            </kendo-formfield>
          </div>

          <div class="col form-group d-flex align-items-center">
            <div class="wrap" style="padding-right: 10px;">
              <input type="checkbox" id="useToday" formControlName="useToday" title="Uses the current date at the time of calculation." />
              <kendo-label class="k-checkbox-label" for="useToday" text="Use Today" title="Uses the current date at the time of calculation."></kendo-label>
            </div>
          </div>

          <div class="col form-group d-flex align-items-center">
            <div class="wrap" style="padding-right: 10px;">
              <input type="checkbox" id="useBaseUofM" formControlName="useBaseUofM" />
              <kendo-label class="k-checkbox-label" for="useBaseUofM" text="Use Item Base UofM"></kendo-label>
            </div>
          </div>

        </div>

      </kendo-expansionpanel>
    </div>

  </form>

  <div class="card p-3" style="background-color:rgb(234, 234, 234); margin-top:20px;">
    <app-catalog-line-list #subgrid [catalogSheetID]='idOfCatalogSheet' mode='subgrid'
                            [pagination]="paginationObject_CatalogLines" [loading]="loading">
    </app-catalog-line-list>
  </div>
</div>
