export interface SystemJobError {
  id: number;
  systemJobLogID: number;
  systemJobLog: {
    name: string,
    id: number
  };
  errorDateTime: Date;
  errorMessage: string;
  errorNumber: number;
  errorSeverity: number;
  errorState: number;
  entityName: string;
  recordReferenceID: number;
  jsonSnippet: string;
}



export const emptySystemJobError: SystemJobError = {
  id: null,
  systemJobLogID: null,
  systemJobLog: {
    name: '',
    id: null
  },
  errorDateTime: null,
  errorMessage: '',
  errorNumber: null,
  errorSeverity: null,
  errorState: null,
  entityName: '',
  recordReferenceID: null,
  jsonSnippet: '',
};
