<ng-template #areYouSure let-modal>
  <div class="modal-header">
    Alert
  </div>
  <div class="modal-body">
    <p>
      Are you sure you want to delete that entity ?
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="remove(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Schedule.png" alt="Price Schedule Children" style="padding-right: 5px;">
  Price Schedule Children
</div>
<!--Note Sortable is False on this Grid-->
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="false" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="priceScheduleChildID"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick($event)"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" id="btnAdd" class="showTooltip" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="!this.priceScheduleID" imageUrl="../../../../assets/images/Add.png"
              (click)="onLookup_PriceScheduleChildren()" title="Save the record above to be able to start adding these records here.">
        Add New
      </button>
      <!--<button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="edit()"
              title="Edit Selected">
        Edit
      </button>-->
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
              (click)="onClick_Delete()" title="Remove Selected">
        Remove
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableRankBtn"
              imageUrl="../../../../assets/images/Up.png" (click)="ChangeRanks(-1)" title="Increase Rank">
        Increase Rank
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableRankBtn"
              imageUrl="../../../../assets/images/Down.png" (click)="ChangeRanks(1)" title="Decrease Rank">
        Decrease Rank
      </button>
    </kendo-buttongroup>
  </ng-template>

  <kendo-grid-column field="rank" title="Rank" width="100"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="priceScheduleChildName" title="Child Price Schedule Name" width="250"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
<!--Temp disable zooming to other price schedules becasue it doesn't work-->
    <!--<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'" [routerLink]="['/price/price-schedule/', dataItem.childPriceScheduleID]">{{ dataItem.priceScheduleChildName }}</a>
      <span role="button" *ngIf="mode == 'lookup'" (dblclick)="onSelectSingleItem(dataItem)">{{ dataItem.priceScheduleChildName }}</span>
    </ng-template>-->
  </kendo-grid-column>

</kendo-grid>
