import { HttpHeaders } from '@angular/common/http';
export interface Pagination {
  TotalCount: number;
  PageSize: number;
  CurrentPage: number;
  TotalPages: number;
  HasNext: boolean;
  HasPrevious: boolean;
}
export const mockedData: Pagination = {
  TotalCount: 100000,
  PageSize: 50,
  CurrentPage: 1,
  TotalPages: 2001,
  HasNext: true,
  HasPrevious: false,
};

export function getPaginationHeader(headers: HttpHeaders): Pagination {
  return (JSON.parse(headers.get('X-Pagination')) as Pagination) || mockedData;
}
