  <div class="modal-header">Unsaved Changes</div>
  <div class="modal-body">
    <p>Any unsaved changes will be discarded.</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-primary"
      (click)="goBackAndSave()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-outline-secondary"
      (click)="goNext()"
    >
      OK
    </button>
  </div>
