<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Pricing_Tree.png" alt="Sync Connectors" style="padding-right: 5px;">
  Sync Connectors
</div>
<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Add.png" (click)="onCreate()" title="Add New">Add New</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">Edit</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
      <!--<button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">Export</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()" title="Import from Excel">Import</button>-->
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>
  <kendo-grid-column field="name" title="Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      {{ dataItem.name }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description" title="Description" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>
  <kendo-grid-column field="authType.name" title="Auth Type" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <sync-connector-auth-type-filter [filter]="filter"
                                         [data]="authTypeOptions"
                                         textField="name"
                                         valueField="name">
      </sync-connector-auth-type-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="httpMethod.name" title="HTTP Method" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <sync-connector-http-method-filter [filter]="filter"
                                         [data]="httpMethodOptions"
                                         textField="name"
                                         valueField="name">
        </sync-connector-http-method-filter>
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="baseURL" title="Base URL" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>
  <kendo-grid-excel #exportToExcel fileName="SyncConnectors.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="description" title="Description" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="authType.name" title="AuthTypeName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="httpMethod.name" title="HTTPMethodName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="baseURL" title="BaseURL" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="username" title="Username" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="password" title="Password" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="server" title="Server" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="endpointName" title="EndpointName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="endpointVersion" title="EndpointVersion" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="clientID" title="ClientID" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="clientSecret" title="ClientSecret" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="tenantID" title="TenantID" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="pathname" title="Pathname" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
