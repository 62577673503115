<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Schedule.png" alt="Price Schedules" style="padding-right: 5px;">
  Price Schedules
</div>

<kendo-grid #grid="kendoGrid"
            [data]="gridView"
            [pageSize]="query.PageSize"
            [skip]="skip"
            [sort]="query.SortTerm"
            [reorderable]="true"
            [loading]="loading"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [filter]="filter"
            [filterable]="filterable"
            (filterChange)="gridFilterChange($event)"
            [resizable]="true"
            [sortable]="true"
            [sort]="sort"
            (sortChange)="sortChange($event)"
            [selectable]="{ mode: 'multiple' }"
            kendoGridSelectBy="id"
            [selectedKeys]="mySelection"
            (cellClick)="onCellClick($event)"
            (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" [routerLink]="['/price/price-schedule/new']" title="Add New">Add New</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">Edit</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">Export</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()" title="Import from Excel">Import</button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>
  <kendo-grid-column field="name" title="Name" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'"
         [routerLink]="['/price/price-schedule/', dataItem.id]">
        {{ dataItem.name }}
      </a>
      <span role="button"
            *ngIf="mode == 'lookup'"
            (dblclick)="onSelectSingleItem(dataItem)">
        {{ dataItem.name }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="isActive" title="Is Active" filter="boolean" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.isActive ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="priceSourceOption.name" title="Price Source Option" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter>
      <price-schedule-price-source-filter [filter]="filter" [data]="priceSchedulePriceSourceOptions" textField="name" valueField="name">
      </price-schedule-price-source-filter>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="pricingFilter.name" title="Pricing Filter" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <div *ngIf="dataItem.pricingFilter">
        <a *ngIf="mode == 'view'"
           [routerLink]="['/price/pricing-filter/', dataItem.pricingFilter.id]">
          {{ dataItem.pricingFilter.name }}
        </a>
        <span role="button"
              *ngIf="mode == 'lookup'"
              (dblclick)="onSelectSingleItem(dataItem)">
          {{ dataItem.pricingFilter.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="roundingRule.name" title="Rounding Rule" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.roundingRule">
        <a role="button" (click)="show_RoundingRule(dataItem.roundingRule)">
          {{ dataItem.roundingRule.name }}
        </a>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="priceBook.name" title="Price Book" width="150" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.priceBook">
        <a *ngIf="mode == 'view'"
           [routerLink]="['/price/price-book/', dataItem.priceBook.id]">
          {{ dataItem.priceBook.name }}
        </a>
        <span role="button"
              *ngIf="mode == 'lookup'"
              (dblclick)="onSelectSingleItem(dataItem)">
          {{ dataItem.priceBook.name }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="useInDynamicPricing" title="Use In Dynamic Pricing" filter="boolean" width="175" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.useInDynamicPricing ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="dynamicPricingRank" title="Dynamic Pricing Rank" width="175" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="erpid" title="ERP ID" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="PriceSchedules.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="useInDynamicPricing" title="UseInDynamicPricing" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="dynamicPricingRank" title="DynamicPricingRank" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="isHighestPrice" title="MaximumPrice" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="isLowestPrice" title="MinimumPrice" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceSourceOption.name" title="PriceSourceName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="pricingFilter.name" title="PricingFilterName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceBook.name" title="PriceBookName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="isActive" title="IsActive" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="roundingRule.name" title="RoundingRuleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="erpid" title="ERPID" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="calculateAfterPriceSchedule.name" title="CalculateAfterPriceScheduleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="calculations" title="Calculations" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="childPriceSchedules" title="ChildPriceSchedules" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
