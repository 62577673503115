<nav class="navbar navbar-expand-lg navbar-light fixed-left">  
  <!-- <a mat-button id="menu-toggle" (click)="toggleMenu($event)">
    <mat-icon>menu</mat-icon>
  </a> -->
  <a mat-button id="menu-toggle" href="#">
    <img src="../../../assets/images/RPM_Logo.png" alt="Logo" style="width: 60px;">
  </a>
  <div class=" navbar-collapse" id="navbarNav">
    <ul class="nav navbar-nav ml-auto">
      <li>
        <ng-select style="width: 300px;"
          id="companyOption"
          [multiple]="false"          
          [selectOnTab]="true"          
          placeholder="Company"
          [searchable]="true"  
          [clearable] = "false"
          [(ngModel)]="selectedCompanyID"        
          (change)="onSelectBoxChanged($event, companies)">
          <ng-option *ngFor="let company of companies; let companyID=index" [value]="company.id">
            {{ company.name }}
          </ng-option>
        </ng-select>
      </li>
      <li>
        <div class="dropdown">
          <button class="btn dropdown-toggle" [title]="this.userTooltip" type="button" id="profileMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="../../../assets/images/User.png">
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">            
            <button *ngIf="this.userID" (click)="editUserProfile($event)" class="list-group-item list-group-item-action bg-light">User Profile</button>            
            <button type="submit" *ngIf="!this.userID" class="list-group-item list-group-item-action bg-light" [routerLink]="['/auth/']" routerLinkActive="router-link-active">
              Log In
            </button>
            <button *ngIf="this.userID" class="list-group-item list-group-item-action bg-light" (click)="logout($event)">Log Out</button>
          </div>
        </div>
      </li>
      <li>
        <!-- <a mat-button href="https://rocktonsoftware.com/RPMHelp/index.htm" target="_blank"> 
          <mat-icon>help_outline</mat-icon>
        </a>  -->
        <button type="button" style="border-style: none;" class="bg-light" (click)="onHelp($event)">
          <img src="../../../assets/images/Help.png">
        </button> 
      </li>
    </ul>
  </div>
</nav>
