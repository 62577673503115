import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { DocumentAttribute, emptyDocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  AttributeDataType = 1,
}
@Component({
  selector: 'app-document-attribute-single',
  templateUrl: './document-attribute-single.component.html',
  styleUrls: ['./document-attribute-single.component.scss'],
})
export class DocumentAttributeSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfDocumentAttribute: number | null = null;
  @Input() documentAttribute: DocumentAttribute = emptyDocumentAttribute;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  documentAttributeForm: FormGroup;
  attributeDataTypes: Lookup[] = [];
  submitted = false;
  modalReference: any;
  isFormChanged = false;

  constructor(
    private documentAttributesService: DocumentAttributesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfDocumentAttribute) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  buildForm() {
    this.documentAttributeForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', Validators.maxLength(200)),
      attributeDataTypeID: new FormControl(null, Validators.required),
    });
    this.documentAttributeForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getAttributeDataTypes();
  }
  get f() {
    return this.documentAttributeForm.controls;
  }
  updateForm() {
    this.documentAttributeForm.patchValue({ ...this.documentAttribute });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = (modelRef) => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord(null);
  }

  async saveRecord(action) {
    this.documentAttributeForm.markAllAsTouched();
    this.submitted = true;
    if (this.documentAttributeForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.documentAttribute,
      ...this.documentAttributeForm.value,
    };
    // Check to see if Document Attribute Name already exists.
    if ((await this.validation_CheckForExistingName()) === true) {
      this.notificationHelper.showStatusOnDialog('Document Attribute Name already exists!', 'error', this.container);
    } else {
      if (this.documentAttribute.id) {        
        try {
          const response: any = await this.documentAttributesService.update(
            this.documentAttribute.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {
          const response: any = await this.documentAttributesService.create(
            this.documentAttributeForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }
  }

  async getAttributeDataTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'AttributeDataType';
    const params = { Filter_OptionSet, PageSize };
    this.attributeDataTypes = await this.lookupService.getList(params);
    this.attributeDataTypes = this.attributeDataTypes.filter(
      x => x.name !== "Lookup" && x.name !== "Text" && x.name !== "Time");
    return this.attributeDataTypes;
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.documentAttributeForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingDocumentAttributes: DocumentAttribute[] = await (
      await this.documentAttributesService.getList(params)
    ).body;
    if (existingDocumentAttributes.length > 0) {
      if (this.documentAttribute.id) {
        for (const key in existingDocumentAttributes) {
          const docAttr: DocumentAttribute = existingDocumentAttributes[key];
          if (docAttr.id != this.documentAttribute.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }
  async getDocumentAttributeById() {
    if (this.idOfDocumentAttribute) {
      return await this.documentAttributesService.getById(this.idOfDocumentAttribute);
    } else {
      return emptyDocumentAttribute;
    }
  }
  async loadData() {
    try {
      [this.documentAttribute, this.attributeDataTypes] = await Promise.all([
        this.getDocumentAttributeById(),
        this.getAttributeDataTypes(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  onSelectBoxChanged(id: number, key: keyof DocumentAttribute, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.documentAttribute[key] = item;
  }

}
