<kendo-dialog [minWidth]="450" (close)="onClick_Close('Cancel')" [width]="900" [height]="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Actions.png" alt="Sync Reader Preview" style="padding-right: 5px;">
      Sync Reader Preview
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <form [formGroup]="syncReaderPreviewForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="description" text="Result Data"></kendo-label>
          <textarea id="resultData" wrap="off"
                    style="height: 400px; overflow-y: scroll; overflow-x: scroll; background-color: #f0f0f0; cursor: text;
                      user-select: text; -webkit-user-select: text; -moz-user-select: text; -ms-user-select: text; outline: none;
                      box-shadow: none;" class="form-control" formControlName="resultData" readonly
                    placeholder="Unable to retrieve preview."></textarea>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')" primary="true"><span class="k-icon k-i-cancel"></span>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
