import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss'],
})
export class DeleteConfirmationComponent implements OnInit {
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter(false);
  @Output() closeModal: EventEmitter<any> = new EventEmitter(false);
  constructor() {}

  ngOnInit(): void { }

  close(status: string): void {
    if (status == "yes") {
      this.confirmDelete.emit();
    }
    else {
      this.closeModal.emit();
    }
  }
}
