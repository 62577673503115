import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ItemCurrenciesService } from '../../../services/item-currencies/item-currencies.service';
import { ItemCurrency, emptyItemCurrency } from '../../../services/item-currencies/item-currencies.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemsService } from '../../../services/items/items.service';
import { Item, emptyItem } from 'src/app/services/items/items.model';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofM } from 'src/app/services/uofms/uofms.model';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { Currency } from 'src/app/services/currencies/currencies.model';
import { UofMListComponent } from '../../uofms/uofm-list/uofm-list.component';
import { CurrencyListComponent } from '../../currencies/currency-list/currency-list.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { SettingsService } from '../../../services/settings/settings.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { UofMLookupComponent } from '../../uofms/uofm-lookup/uofm-lookup.component';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';

enum Filters {
  UofM = 1,
  Currency
}
@Component({
  selector: 'app-item-currency-single',
  templateUrl: './item-currency-single.component.html',
  styleUrls: ['./item-currency-single.component.scss'],
})
export class ItemCurrencySingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfItemCurrency: number | null = null;
  @Input() idOfItem: number | null = null;
  @Input() defaultItem: Item = emptyItem;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  itemCurrency: ItemCurrency = emptyItemCurrency;
  itemCurrencyForm: FormGroup;
  items: Item[] = [];
  uofMs: UofM[] = [];
  currencies: Currency[] = [];
  submitted = false;
  defaultCurrencyID: number;
  singleCurrency: boolean;
  isFormChanged = false;
  modalReference;
  public isAdmin: boolean;

  constructor(
    private itemCurrenciesService: ItemCurrenciesService,
    private itemsService: ItemsService,
    private uofMsService: UofMsService,
    private currenciesService: CurrenciesService,
    private modalService: NgbModal,
    private settingsService: SettingsService,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }
  ngOnInit(): void {
    this.loadData();
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.itemCurrencyForm.disable();
    }
  }
  buildForm() {
    this.itemCurrencyForm = new FormGroup({
      itemID: new FormControl('', Validators.required),
      uofMID: new FormControl('', Validators.required),
      currencyID: new FormControl('', Validators.required),
      costAmount: new FormControl('', Validators.required),
      listPriceAmount: new FormControl('', Validators.required),
    });
    this.itemCurrencyForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getUseSingleCurrencySettings();
    if (!this.idOfItemCurrency) {
      this.getDefaultCurrencySettings();
    }
  }
  get f() {
    return this.itemCurrencyForm.controls;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  updateForm() {
    this.itemCurrencyForm.patchValue({ ...this.itemCurrency });
    this.isFormChanged = false;
  }

  async checkIfItemCurrencyExists() {
    var itemCurrencyExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ItemID = this.idOfItem;
    const Filter_CurrencyID = this.itemCurrency.currency.id;
    const Filter_UnitOfMeasureID = this.itemCurrency.uofM.id;
    const params = { Filter_ItemID, Filter_CurrencyID, Filter_UnitOfMeasureID, PageSize };
    const existingItemCurrencies: ItemCurrency[] = await (
      await this.itemCurrenciesService.getList(params)
    ).body;
    if (existingItemCurrencies.length > 0) {
      if (this.itemCurrency.id) {
        for (const key in existingItemCurrencies) {
          const currentItemCurrency: ItemCurrency = existingItemCurrencies[key];
          if (currentItemCurrency.id != this.itemCurrency.id) {
            itemCurrencyExists = true;
          }
        }
      } else {
        itemCurrencyExists = true;
      }
    }
    return itemCurrencyExists;
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.itemCurrencyForm.markAllAsTouched();
    this.submitted = true;
    if (this.itemCurrencyForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.itemCurrency,
      ...this.itemCurrencyForm.value,
    };

    // TODO: Validate Cost and List Price Amounts are decimals.
    // Check to see if Document Attribute Name already exists.
    if ((await this.checkIfItemCurrencyExists()) === true) {
      this.notificationHelper.showStatusOnDialog('The Item, Unit of Measure and Currency combination entered already exists and must be unique.!', 'error', this.container);
      return;
    }

    if (this.itemCurrency.id) {
      try {
        const response: any = await this.itemCurrenciesService.update(
          this.itemCurrency.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.itemCurrenciesService.create(this.itemCurrencyForm.value);
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }

  async getItemCurrencyById(id) {
    return await this.itemCurrenciesService.getById(id);
  }
  async getUofMById(id) {
    return await this.uofMsService.getById(id);
  }
  async getCurrencyById(id) {
    return await this.currenciesService.getById(id);
  }

  async loadData() {
    try {
      if (this.idOfItemCurrency) {
        this.itemCurrency = await Promise.resolve(this.getItemCurrencyById(this.idOfItemCurrency));
        const currentUofM: UofM = await Promise.resolve(this.getUofMById(this.itemCurrency.uofMID));
        this.uofMs.push(currentUofM);
        const currentCurrency: Currency = await Promise.resolve(this.getCurrencyById(this.itemCurrency.currencyID));
        this.currencies.push(currentCurrency);
      }
      if (this.defaultItem) {
        this.itemCurrency.itemID = this.defaultItem.id;
        this.itemCurrency.item = this.defaultItem;
      }
      this.defaultItem.name = this.itemCurrency.item.name;
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }
  onSelectBoxChanged(id: number, key: keyof ItemCurrency, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.itemCurrency[key] = item;
  }

  async onLookup_UofM() {
    const modalRef = this.modalService.open(UofMLookupComponent, { });
    modalRef.componentInstance.uofMScheduleID = this.itemCurrency.item.uofMScheduleID;
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofM = receivedEntry;
      this.uofMs = [];
      this.uofMs.push(recordSelected);
      this.itemCurrencyForm.patchValue({ ['uofMID']: receivedEntry.id });
      this.itemCurrency.uofM = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_Currency() {
    const modalRef = this.modalService.open(CurrencyLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.itemCurrencyForm.patchValue({ ['currencyID']: receivedEntry.id });
      this.itemCurrency.currency = receivedEntry;
      modalRef.close('test');
    });
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_UofMScheduleID = this.itemCurrency.item.uofMScheduleID;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.UofM:
        this.uofMs = (
          await this.uofMsService.getList({ Filter_UofMScheduleID, ...params })
        ).body;
        break;
      case Filters.Currency:
        this.currencies = (await this.currenciesService.getList(params)).body;
        break;
      default:
        break;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  async getUseSingleCurrencySettings() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = 'UseSingleCurrency';
    const resp = await this.settingsService.getList({ Filter_Name, PageSize });
    // @ts-ignore
    const setting: Setting = resp.body;
    this.singleCurrency = setting[0].value == 'true';
  }

  async getDefaultCurrencySettings() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = 'DefaultCurrency';
    const resp = await this.settingsService.getList({ Filter_Name, PageSize });
    // @ts-ignore
    const setting: Setting = resp.body;
    if (setting && setting[0]) {
      this.defaultCurrencyID = setting[0].value;
      const resp = await this.currenciesService.getById(this.defaultCurrencyID);
      let defaultCurrency = resp;
      this.currencies = [];
      this.currencies.push(defaultCurrency);
      this.itemCurrencyForm.patchValue({ ['currencyID']: defaultCurrency.id });
      this.itemCurrency.currency = defaultCurrency;
      this.itemCurrency.currencyID = defaultCurrency.id;
      this.isFormChanged = false;
    }
  }

}
