<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/SyncSet.png" alt="Sync Set" style="padding-right: 5px;">
  Create / Update Sync Set
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/sync-sets']" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Save_Close.png" (click)="onClick_SaveClose()" title="Save & Close">SAVE & CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Save_New.png" (click)="onClick_SaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Add.png" (click)="onClick_AddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">COPY</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
</kendo-buttongroup>
<form [formGroup]="syncSetForm">
  <div class="row">
    <div class="col form-group col-5">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control"
                       formControlName="name"
                       placeholder="Enter Name"
                       maxlength="100" [clearButton]="true">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group col-7">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Description" maxlength="200" [clearButton]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group col-5">
      <kendo-formfield>
        <kendo-label for="readerSyncConnector" class="font-weight-bold" text="* Reader Sync Connector"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="readerSyncConnector" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="readerSyncConnectorID" [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'borderless': true, 'is-invalid': submitted && f.readerSyncConnectorID.errors,'w-100': true }"
                     placeholder="Select a Sync Connector" [searchable]="true" (search)="search($event, filters.readerSyncConnector)"
                     (change)="onSelectBoxChanged($event,'readerSyncConnectorID',readerSyncConnectors)">
            <ng-option *ngFor="let item of readerSyncConnectors" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_ReaderSyncConnector()" icon="search"></button>
        </span>
        <kendo-formerror>Sync Connector is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group col-5">
      <kendo-formfield>
        <kendo-label for="writerSyncConnector" class="font-weight-bold" text="* Writer Sync Connector"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="writerSyncConnector" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="writerSyncConnectorID" [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'borderless': true, 'is-invalid': submitted && f.writerSyncConnectorID.errors,'w-100': true }"
                     placeholder="Select a Sync Connector" [searchable]="true" (search)="search($event, filters.writerSyncConnector)"
                     (change)="onSelectBoxChanged($event,'writerSyncConnectorID',writerSyncConnectors)">
            <ng-option *ngFor="let item of writerSyncConnectors" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_WriterSyncConnector()" icon="search"></button>
        </span>
        <kendo-formerror>Sync Connector is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group col-2">
      <div style="display: flex; align-items: center; height: 100%; padding-right: 10px; padding-left: 18px;">
        <input class="form-check-input" type="checkbox" formControlName="isDefault" id="isDefault" />
        <kendo-label class="k-checkbox-label" for="isDefault" text="Default"></kendo-label>
      </div>
    </div>
  </div>
</form>
<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-sync-set-entity-sublist #subgrid
                               [syncSetID]='idOfSyncSet'>
  </app-sync-set-entity-sublist>
</div>
