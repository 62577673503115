import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportsService } from '../../../services/reports/reports.service';
import { Report, emptyReport } from '../../../services/reports/reports.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from 'src/app/utils/StorageHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent, } from '@progress/kendo-angular-grid';
import { UtilitiesService } from '../../../services/utilities/utilities.service';

@Component({
  selector: 'app-report-single',
  templateUrl: './report-single.component.html',
  styleUrls: ['./report-single.component.scss'],
})
export class ReportSingleComponent implements OnInit {
  idOfReport: number | null = null;
  report: Report = emptyReport;
  loading: boolean = true;
  reportForm: FormGroup;
  attributeDataTypes: Lookup[] = [];
  submitted = false;
  modalReference: any;
  paginationObject: any = { reportValues: mockedData };
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  public isAdmin: boolean;
  public isRocktonAdmin: boolean;
  public gridView: GridDataResult;
  query = { PageSize: 10, PageNumber: 1 };
  public skip = 0;

  constructor(
    private route: ActivatedRoute,
    private reportsService: ReportsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private notificationHelper: NotificationHelper,
    private utilitiesService: UtilitiesService,
    private router: Router,
    private copyService: CopyService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.report = emptyReport;
        this.idOfReport = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfReport = params.id;
      this.loadData();
    });
    this.buildForm();
  }


  ngOnInit(): void {
    //if (this.idOfReport) {
    //  this.loadData();
    //}
    this.buildForm();
    this.updateForm();

    this.isRocktonAdmin = StorageService.IsRocktonAdmin();
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.reportForm.disable();
    }
  }

  buildForm() {
    this.reportForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      ranOn: new FormControl(''),
      description: new FormControl(''),
      sqlScriptText: new FormControl(''),
      resultSet: new FormControl('')
    });
    this.reportForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.reportForm.controls;
  }

  updateForm() {
    this.reportForm.patchValue({ ...this.report });
    this.isFormChanged = false;
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.reportForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingReports: Report[] = await (
      await this.reportsService.getList(params)
    ).body;
    if (existingReports.length > 0) {
      if (this.report.id) {
        for (const key in existingReports) {
          const currentReport: Report = existingReports[key];
          if (currentReport.id != this.report.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMReport", this.report.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.report.id);
    await this.reportsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/reports']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.report && this.report.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/report/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/report/new']);
      });
    }
  }

  onClick_Save() {
    this.submit(null);
  }

  onClick_SaveClose() {
    this.submit("Close");
  }

  onClick_SaveNew() {
    this.submit("New");
  }

  async submit(action) {
    this.reportForm.markAllAsTouched();
    this.submitted = true;
    if (this.reportForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = { ...this.report, ...this.reportForm.value };
    if (await this.validation_CheckForExistingName() === true) {
      this.snack.openSnackBar(
        'Report Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.report.id) {
        try {
          const response: any = await this.reportsService.update(
            this.report.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/reports']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/report/new']);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.reportsService.create(
            this.reportForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.report = response.body;
            this.idOfReport = this.report.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/reports']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/report/new']);
              });
            }
            else {
              this.report = response.body;
              this.router.navigate(['/price/report/', this.report.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getReportById() {
    if (this.idOfReport) {
      return await this.reportsService.getById(this.idOfReport);
    } else {
      return emptyReport;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [this.report] = await Promise.all([this.getReportById()]);
      if (this.report.resultSet) {
        const jsonData = JSON.parse(this.report.resultSet);
        const recordCount = jsonData.length;
        const startIndex = (this.query.PageNumber - 1) * this.query.PageSize;
        const pageData = jsonData.slice(startIndex, startIndex + this.query.PageSize)
        this.gridView = {
          data: pageData,
          total: recordCount,
        }
      }
      else {
        this.gridView = {
          data: [],
          total: 0,
        };
      };
      this.updateForm();
    } catch (e) {
      this.notificationHelper.showStatus(e.error, "error");
    } finally {
      this.loading = false;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  onTabSelect(e) {
    if ((e.title === 'Results' || e.title === 'JSON Result Set') && this.isFormChanged) {
      e.preventDefault(); // Prevent tab change unless saved
      this.notificationHelper.showStatus('Please save changes to continue', "success");
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadData();
  }

  async onClickClearData() {
    this.reportForm.patchValue({ resultSet: '' });
    await this.submit(null);
    this.gridView = { data: [], total: 0 };
    await this.loadData();
  }

  async onClickCalculate() {
    if (!this.reportForm.get('sqlScriptText')?.value) {
      this.snack.openSnackBar('SQL Script is not defined.', '', true, 'Error', 'alert-danger');
      return;
    }
    try {
      try {
        const params = [
          {
            Name: "@ReportID",
            Value: this.idOfReport
          }
        ];
        const paramList = JSON.stringify(params);
        const response: any = await this.utilitiesService.callStoredProc('usp_RPMProcessReport', paramList);
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Report generated successfully.' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
    finally { }
    this.loadData();
  }

  async onClickExport(grid: GridComponent, excelComponent: ExcelComponent) {
    grid.saveAsExcel();
  }

}


