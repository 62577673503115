import { Component, OnInit} from '@angular/core';
import { SyncReaderPreviewService } from '../../../services/sync-reader-preview/sync-reader-preview.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sync-reader-preview',
  templateUrl: './sync-reader-preview.component.html',
  styleUrls: ['./sync-reader-preview.component.scss'],
})
export class SyncReaderPreviewComponent implements OnInit {
  idOfSyncReader: number | null = null;
  syncReaderPreviewForm: FormGroup;
  modalReference: any;

  constructor(
    private syncReaderPreviewService: SyncReaderPreviewService,
    public activeModal: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
    this.loadData();
    this.buildForm();
  }
  buildForm() {
    this.syncReaderPreviewForm = new FormGroup({
      resultData: new FormControl(''),
    });
  }
  get f() {
    return this.syncReaderPreviewForm.controls;
  }
 
  onClick_Close(message) {    
    this.activeModal.close(message);
  }

  async loadData() {
    if (this.idOfSyncReader) {

      // Call the service to get the SyncReaderPreview by ID  
      const response: any = await this.syncReaderPreviewService.getById(this.idOfSyncReader);

      // Format the JSON with pretty print (2 spaces indentation)
      const prettyJsonString = JSON.stringify(response.value, null, 2);

      // Drop it into the TextArea control on the UI
      this.f.resultData.setValue(prettyJsonString);
    }
  } 
}
