export interface SyncWriterColumn {
  id: number;
  syncWriterID: number;
  sequenceNumber: number;
  columnName: string;
}

export const emptySyncWriterColumn: SyncWriterColumn = {
  id: null,
  syncWriterID: null,
  sequenceNumber: null,
  columnName: ''
};
