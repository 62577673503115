import { Injectable } from '@angular/core';
import { NotificationService } from "@progress/kendo-angular-notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationHelper {
  constructor(private notificationService: NotificationService) { }

  public showStatus(message, style): void {
    let position: "top" | "bottom" = "bottom";
    let closable: boolean = false;

    if (style == "error") {
      position = "top";
      closable = true;
    }
    this.notificationService.show({
      content: message,
      //cssClass: "button-notification",
      animation: { type: "slide", duration: 400 },
      position: { horizontal: "center", vertical: position },
      type: { style: style, icon: true },      
      //hideAfter: 0,
      closable: closable
    });
  }

  public showStatusOnDialog(message, style, container): void {

    //Remove all prior notifications.
    const myNode = document.getElementById("container");
    let position: "top" | "bottom" = "bottom";
    let closable: boolean = false;

    if (style == "error") {
      position = "top";
      closable = true;
    }

    if (myNode != null) {
      myNode.innerHTML = '';   
    }    

    this.notificationService.show({
      content: message,
      cssClass: "button-notification",
      appendTo: container,
      animation: { type: "slide", duration: 400 },
      position: { horizontal: "center", vertical: position },
      type: { style: style, icon: true },
      closable: closable
    });
  }
}
