import { Component, OnInit, Input, ViewChild, ViewChildren, ViewEncapsulation, QueryList } from '@angular/core';
import { Pagination, mockedData, getPaginationHeader, } from 'src/app/utils/getPaginationHeader';
import { StorageService } from '../../../app/utils/StorageHelper';
import { PriceSchedule } from 'src/app/services/price-schedules/price-schedules.model';
import { PriceScheduleCalculationsService } from '../../services/price-schedule-calc/price-schedule-calc.service';
import { PriceScheduleCalculation } from '../../services/price-schedule-calc/price-schedule-calc.model';
import { PriceScheduleChildrenService } from '../../services/price-schedule-children/price-schedule-children.service';
import { PriceScheduleChild } from '../../services/price-schedule-children/price-schedule-children.model';
import { GridComponent } from "@progress/kendo-angular-grid";

@Component({
  selector: 'app-pricing-tree-branch',
  templateUrl: './pricing-tree-branch.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PricingTreeBranchComponent implements OnInit {
  @ViewChildren(GridComponent) grids: QueryList<GridComponent> = new QueryList<GridComponent>();
  @Input() priceSchedule: PriceSchedule;
  public gridViewCalc: PriceScheduleCalculation[];
  public gridViewChild: PriceScheduleChild[];
  constructor(
    private priceScheduleCalculationsService: PriceScheduleCalculationsService,
    private priceScheduleChildrenService: PriceScheduleChildrenService,
  ) {
  }
  priceScheduleCalculations: PriceScheduleCalculation[] = [];
  priceScheduleChildren: PriceScheduleChild[] = [];

  loading: boolean = true;
  query = { PageSize: 50, SortTerm: '', PageNumber: 1, SearchTerm: '' };

  ngOnInit() {
    this.loading = true;
    this.loadListDataChild();
    this.loadListDataCalc();
    this.loading = false;
  }

  onRefresh() {
    this.ngOnInit();
  }

  public dragData = ({ dragTarget }) => {
    return { 
      fromGrid: +dragTarget
        .closest(".k-grid")
        .getAttribute("data-kendo-grid-index"),
      fromIndex: +dragTarget.getAttribute("data-kendo-grid-item-index"),
    };
  };


  public async loadListDataChild() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceScheduleChildrenService.getList(this.query);
      this.priceScheduleChildren = response.body;
      const filteredPriceScheduleChildren = this.priceScheduleChildren.filter(calc => calc.priceScheduleID == this.priceSchedule.id);
      filteredPriceScheduleChildren.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
      });
      this.gridViewChild = filteredPriceScheduleChildren;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public async loadListDataCalc() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.priceScheduleCalculationsService.getList(this.query);
      this.priceScheduleCalculations = response.body;
      const filteredPriceScheduleCalcs = this.priceScheduleCalculations.filter(calc => calc.priceScheduleID == this.priceSchedule.id);
      filteredPriceScheduleCalcs.sort((a, b) => {
        if (a.rank < b.rank) return -1;
        if (a.rank > b.rank) return 1;
      });
      this.gridViewCalc = filteredPriceScheduleCalcs;
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

}
