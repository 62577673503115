export interface SyncReader {
  id: number;
  name: string;
  description: string;
  syncConnectorID: number;
  syncConnector: {
    name: string,
    id: number,
    authTypeID: number
  };
  query: string;
  reportID: number;
  report: {
    name: string,
    id: number
  };
  filename: string;
  pageSize: number;  
}

export const emptySyncReader: SyncReader = {
  id: null,
  name: '',
  description: '',
  syncConnectorID: null,
  syncConnector: {
    name: '',
    id: null,
    authTypeID: null
  },
  query: '',
  reportID: null,
  report: {
    name: '',
    id: null
  },
  filename: '',
  pageSize: null,
};
