<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/SyncMap.png" alt="Sync Maps"
       style="padding-right: 5px;">
  Sync Maps
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Add.png" (click)="onCreate()"
              title="Add New">
        Add
        New
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn"
              imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">
        Edit
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
              imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()"
              title="Delete Selected">
        Delete
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)"
              title="Export to Excel">
        Export
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()"
              title="Import from Excel">
        Import
      </button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-dropdownbutton [data]="runNowMenuItems" [disabled]="disableDeleteBtn" style="border: none"
                          imageUrl="../../../../assets/images/Action.png" title="Run Now" (itemClick)="onClick_RunNow($event)">
      Run Now
    </kendo-dropdownbutton>

    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>
  <kendo-grid-column field="name" title="Name"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="description" title="Description"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="syncSet.name" title="Sync Set"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="triggeringEntity.name" title="Triggering Entity"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="rank" title="Rank"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="isActive" filter="boolean" title="Active" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No">
    </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem> {{dataItem.isActive ? 'Yes' : 'No'}} </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="syncReader.name" title="Sync Reader"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="syncWriter.name" title="Sync Writer"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="filter" title="Filter"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="singleRecordFilter" title="Single Record Filter"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="createdOnFilter" title="Created On Filter"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="modifiedOnFilter" title="Modified On Filter"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="SyncMaps.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true', italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="description" title="Description" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="syncSet.name" title="SyncSet" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="triggeringEntity.name" title="TriggeringEntity" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="rank" title="Rank" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="isActive" title="IsActive" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="syncReader.name" title="SyncReader" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="syncWriter.name" title="SyncWriter" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="filter" title="Filter" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="singleRecordFilter" title="SingleRecordFilter" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="createdOnFilter" title="CreatedOnFilter" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="modifiedOnFilter" title="ModifiedOnFilter" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>

</kendo-grid>
