export interface SyncReaderColumn {
  id: number;
  syncReaderID: number;
  sequenceNumber: number;
  columnName: string;
}

export const emptySyncReaderColumn: SyncReaderColumn = {
  id: null,
  syncReaderID: null,
  sequenceNumber: null,
  columnName: ''
};
