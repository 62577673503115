import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { tap, catchError } from 'rxjs/operators';
@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const newRequest = request.clone({
      url: `${environment.apiUrl}/${request.url}`,
      headers: request.headers.append('CompanyID', environment.companyId)
    });
    return next.handle(newRequest).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          //console.log(evt.headers);
          //console.log(evt.headers.get('Content-Type'));
          //console.log(evt.headers.get('X-Pagination'));
        }
      }),
    );
  }
}
