export interface Setting {
    id: number;
    name: string;
    value: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    companyID: string;
}

export const emptySetting: Setting = {
    id: null,
    name: '',
    value: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: '',
    companyID: null
};
