export interface Report {
  id: number;
  name: string;
  sqlScriptText: string;
  resultSet: string;
  ranOn: Date;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyReport: Report = {
  id: null,
  name: '',
  sqlScriptText: '',
  resultSet: '',
  ranOn: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
