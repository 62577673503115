export interface HistoricalSale {
  id: number;
  erpid: string;
  docReference: string;
  docLineReference: string;
  dateSold: Date;
  customerID: number;
  customer: {
    id: number,
    name: string,
    number: string,
  };
  itemID: number;
  item: {
    id: number,
    name: string,
    number: string,
  };
  quantityInBase: number;
  extendedPriceAmount: number;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyHistoricalSale: HistoricalSale = {
  id: null,
  erpid: '',
  docReference: '',
  docLineReference: '',
  dateSold: null,
  customerID: null,
  customer: {
    id: null,
    name: '',
    number: '',
  },
  itemID: null,
  item: {
    id: null,
    name: '',
    number: '',
  },
  quantityInBase: null,
  extendedPriceAmount: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};

