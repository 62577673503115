import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersService } from '../../../services/customers/customers.service';
import { Customer, emptyCustomer } from '../../../services/customers/customers.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { CustomerAttributeValuesService } from '../../../services/customer-attribute-values/customer-attribute-values.service';
import { CustomerAttributeValue } from '../../../services/customer-attribute-values/customer-attribute-values.model';
import { CustomerAttributeValueSubListComponent } from '../../customer-attribute-values/customer-attribute-value-sublist/customer-attribute-value-sublist.component';
import { promise, utils } from 'protractor';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { emptyCustomerAttribute } from 'src/app/services/customer-attributes/customer-attributes.model';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { v4 as uuidv4 } from 'uuid';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';
import { PricingFilterValueCollectionsService } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.service';
import { PricingFilterValueCollection } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.model';

/* enum Filters {
  Name = 1,
  Number,
  ERPID,
} */
@Component({
  selector: 'app-customer-single',
  templateUrl: './customer-single.component.html',
  styleUrls: ['./customer-single.component.scss'],
})
export class CustomerSingleComponent implements OnInit {
  @ViewChild('subgrid') customerAttributeValuesSubGrid: CustomerAttributeValueSubListComponent;

  idOfCustomer: number | null = null;
  customer: Customer = emptyCustomer;
  //customerAttributeValues: CustomerAttributeValue[] = [];
  loading = true;
  customerForm: FormGroup;
  submitted = false;
  //paginationObject: any = { customerAttributeValues: mockedData };
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  public isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private customersService: CustomersService,
    private pricingFilterValuesService: PricingFilterValuesService,
    private pricingFilterValueCollectionsService: PricingFilterValueCollectionsService,
    //private customerAttributeValuesService: CustomerAttributeValuesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private sideNavService: SideNavService,
    private router: Router,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.customer = emptyCustomer;
        this.idOfCustomer = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfCustomer = params.id;
      this.loadData();
    });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.customerAttributeValuesSubGrid.gridClassName = "CustomerAttributeValueSubgridClass"
        }
        else {
          this.customerAttributeValuesSubGrid.gridClassName = "CustomerAttributeValueFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.customerForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfCustomer) {
        this.customerForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }
  buildForm() {
    this.customerForm = this.fb.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      number: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      erpid: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
    this.customerForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.customerForm.controls;
  }
  updateForm() {
    this.customerForm.patchValue({ ...this.customer });
    this.isFormChanged = false;
  }
  async deleteRecord(modal) {
    if (await this.isUsedOnPricingFilter(this.customer.id, 'Customer')) {
      this.snack.openSnackBar('Customer is in use on a Pricing Filter Value.', '', true, 'Error', 'alert-danger');
      modal.close();
    }
    else {
      const ids = [];
      ids.push(this.customer.id);
      await this.customersService
        .removeByIds(ids)
        .then((showSuccess: VoidFunction) => {
          this.snack.openSnackBar(
            'Record(s) deleted successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          modal.close();
          this.router.navigate(['/price/customers']);
        }
        )
        .catch((err: HttpErrorResponse) => {
          this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
          modal.close();
        });
    }
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.customer && this.customer.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/customer/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/customer/new']);
      });
    }
  }

  onClick_Save() {
    this.saveRecord(null);
  }

  onClick_SaveClose() {
    this.saveRecord("Close");
  }

  onClick_SaveNew() {
    this.saveRecord("New");
  }


  async saveRecord(action) {
    this.customerForm.markAllAsTouched();
    this.submitted = true;
    if (this.customerForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.customer,
      ...this.customerForm.getRawValue() // customerForm.value does not include disabled controls.
    };
    // Check to see if Customer Number already exists.
    if ((await this.validation_CheckForExistingNumber()) === true) {
      this.snack.openSnackBar(
        'Customer Number already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }
    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.snack.openSnackBar(
        'ERP ID already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }

    if (this.customer.id) {
      try {
        const response: any = await this.customersService.update(
          this.customer.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Record updated successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/customers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/customer/new']);
            });
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    } else {
      try {
        const response: any = await this.customersService.create(
          this.customerForm.getRawValue(), // customerForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.customer = response.body;
          this.idOfCustomer = this.customer.id;
          this.snack.openSnackBar(
            'Record saved successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/customers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/customer/new']);
            });
          }
          else {
            this.customer = response.body;
            this.router.navigate(['/price/customer/', this.customer.id]);
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
    this.isFormChanged = false;
  }

  async isUsedOnPricingFilter(value: number, Filter_EntityName): Promise<boolean> {
    var isUsedOnPricingFilter: boolean = false;

    const PageSize = StorageService.PageSize() ?? 50;
    const Operand_Value = 'eq';
    const Operand_EntityName = 'eq';
    let Filter_Value: string = value.toString();
    const params = { Filter_Value, Operand_Value, Filter_EntityName, Operand_EntityName, PageSize };
    const existingPricingFilterValues: PricingFilterValue[] = await (
      await this.pricingFilterValuesService.getList(params)
    ).body;
    if (existingPricingFilterValues.length > 0) {
      isUsedOnPricingFilter = true;
    }
    else {
      //Need to check Pricing Filter Value Collections
      const params = { Filter_Value, Operand_Value, PageSize };
      const existingPricingFilterValueCollections: PricingFilterValueCollection[] = await (
        await this.pricingFilterValueCollectionsService.getList(params)
      ).body;
      if (existingPricingFilterValueCollections.length > 0) {
        for (var pricingFilterValueCollection of existingPricingFilterValueCollections) {
          let currentPFV: PricingFilterValue = await this.pricingFilterValuesService.getById(pricingFilterValueCollection.pricingFilterValueID);
          if (currentPFV.entityID = 68) { //Customer
            isUsedOnPricingFilter = true;
            break;
          }
        }
      }
    }  
    return isUsedOnPricingFilter;
  }

  async validation_CheckForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.customerForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingCustomers: Customer[] = await (
      await this.customersService.getList(params)
    ).body;
    if (existingCustomers.length > 0) {
      if (this.customer.id) {
        for (const key in existingCustomers) {
          const currentCustomer: Customer = existingCustomers[key];
          if (currentCustomer.id != this.customer.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }
  async validation_CheckForExistingNumber() {
    var numberExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Number = this.customerForm.controls['number'].value;
    const Operand_Number = 'eq';
    const params = { Filter_Number, Operand_Number, PageSize };
    const existingCustomers: Customer[] = await (
      await this.customersService.getList(params)
    ).body;
    if (existingCustomers.length > 0) {
      if (this.customer.id) {
        for (const key in existingCustomers) {
          const cust: Customer = existingCustomers[key];
          if (cust.id != this.customer.id) {
            numberExists = true;
          }
        }
      } else {
        numberExists = true;
      }
    }
    return numberExists;
  }

  async getCustomerById() {
    if (this.idOfCustomer) {
      return await this.customersService.getById(this.idOfCustomer);
    } else {
      return emptyCustomer;
    }
  }

  // filterChange(event, arr) {
  //   this.getCustomerAttributeValues(event);
  // }

  // async getCustomerAttributeValues(params) {
  //   try {
  //     const resp = await this.customerAttributeValuesService.getList({
  //       Filter_CustomerID: this.idOfCustomer,
  //       ...params,
  //     });
  //     // @ts-ignore
  //     this.customerAttributeValues = resp.body;
  //     this.paginationObject.customerAttributeValues = getPaginationHeader(
  //       // @ts-ignore
  //       resp.headers,
  //     );
  //   } catch (e) {
  //     this.customerAttributeValues = [];
  //   }

  //   return this.customerAttributeValues;
  // }

  async loadData() {
    this.loading = true;
    try {
      [this.customer] = await Promise.all([this.getCustomerById()]);
      this.updateForm();
      // [this.customer, this.customerAttributeValues] = await Promise.all([
      //   this.getCustomerById(),
      //   this.idOfCustomer ? this.getCustomerAttributeValues(null) : [],
      // ]);
      // this.prepareData();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  // prepareData() {
  //   [this.customerAttributeValues] = prepareData(
  //     this.customer,
  //     this.customerAttributeValues,
  //   );
  //   this.updateForm();
  // }
  /*
  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.AttributeDataType:
        this.attributeDataTypes = await this.attributeDataTypeService.getList(params);
        break;
      default:
        break;
    }
  }*/

  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
