import {  Component,  OnInit,  Input} from '@angular/core';
import * as vkbeautify from 'vkbeautify';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pricing-scenario-xml-single',
  templateUrl: './pricing-scenario-xml-single.component.html',
  styleUrls: ['./pricing-scenario-xml-single.component.scss'],
})
export class PricingScenarioXMLSingleComponent implements OnInit {
  @Input() xmlString: string;
  formattedXMLString: string;

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }
  ngOnInit(): void {   
    this.formattedXMLString = vkbeautify.xml(this.xmlString);
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

}
