<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Sheet.png" alt="System Job Errors" style="padding-right: 5px;">
  System Job Errors
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
              (click)="onClick_Delete()" title="Delete Selected">
        Delete
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)"
              title="Export to Excel">
        Export
      </button>
    </kendo-buttongroup>
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
  </ng-template>

  <kendo-grid-column field="errorDateTime" title="Error Date Time" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.errorDateTime }} </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="errorMessage" title="Error Message" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="entityName" title="Entity Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="jsonSnippet" title="JSON Snippet" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="errorNumber" title="Error Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="errorSeverity" title="Error Severity" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="errorState" title="Error State" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column field="recordReferenceID" title="Record Reference ID" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="SystemJobErrors.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="errorDateTime" title="ErrorDateTime" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="errorMessage" title="ErrorMessage" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="entityName" title="EntityName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="jsonSnippet" title="JSONSnippet" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="errorNumber" title="ErrorNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="errorSeverity" title="ErrorSeverity" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="errorState" title="ErrorState" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="recordReferenceID" title="RecordReferenceID" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
