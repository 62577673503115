import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  /** config.js file that will contain out environment variables */
  private readonly CONFIG_URL = 'assets/configs/config.json'; 

  constructor(
    private http: HttpClient
    ) { }

    /**
     * Method for loading configuration
     */
    loadConfiguration(){   
      return this.http.get(this.CONFIG_URL)
      .toPromise()
      .then(result => { 
        var jsonString = JSON.stringify(result);
        var jsonObject = JSON.parse(jsonString);
        environment.apiUrl = jsonObject.apiUrl;
        environment.expireCacheBefore = jsonObject.expireCacheBefore;
        environment.requestTimeout = jsonObject.requestTimeout; // load the timeout
       return Promise.resolve(result);});     
      }
  }
