import { Component, OnInit, Output, EventEmitter, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemJobLogsService } from '../../../services/system-job-logs/system-job-logs.service';
import { SystemJobLog, emptySystemJobLog } from '../../../services/system-job-logs/system-job-logs.model';
import { FormGroup, FormControl } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemJobErrorListComponent } from '../../system-job-errors/system-job-error-list/system-job-error-list.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { Location } from '@angular/common';

enum Filters {
  Name = 1,
}
@Component({
  selector: 'app-system-job-log-single',
  templateUrl: './system-job-log-single.component.html',
  styleUrls: ['./system-job-log-single.component.scss'],
})
export class SystemJobLogSingleComponent implements OnInit {
  @ViewChild('subgrid') SystemJobErrorsSubGrid: SystemJobErrorListComponent;

  filters = Filters;
  idOfSystemJobLog: number | null = null;
  SystemJobLog: SystemJobLog = emptySystemJobLog;
  loading: boolean = true;
  SystemJobLogForm: FormGroup;
  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  paginationObject: any = { SystemJobErrors: mockedData };
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  subgridmode = 'subgrid';

  constructor(
    private route: ActivatedRoute,
    private SystemJobLogsService: SystemJobLogsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private router: Router,
    private sideNavService: SideNavService,
    private copyService: CopyService,
    private location: Location,
    private utilitiesService: UtilitiesService,
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.SystemJobLog = emptySystemJobLog;
        this.idOfSystemJobLog = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        this.SystemJobErrorsSubGrid.systemJobLogID = null;
        this.SystemJobErrorsSubGrid.loadSubGridData();
        return;
      }
      this.idOfSystemJobLog = params.id;
      this.loadData();
    });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.SystemJobErrorsSubGrid.gridClassName = "SystemJobErrorSubgridClass"
        }
        else {
          this.SystemJobErrorsSubGrid.gridClassName = "SystemJobErrorFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
  }

  buildForm() {
    this.SystemJobLogForm = new FormGroup({
      name: new FormControl(''),
      beginDateTime: new FormControl(''),
      endDateTime: new FormControl(''),
      minutesToComplete: new FormControl(''),
      status: new FormControl(''),
      comment: new FormControl(''),
    });
    this.SystemJobLogForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.SystemJobLogForm.controls;
  }

  updateForm() {
    this.SystemJobLogForm.patchValue({ ...this.SystemJobLog });
    this.isFormChanged = false;
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMSystemJobLog", this.SystemJobLog.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.SystemJobLog.id);
    await this.SystemJobLogsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/system-job-logs']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableButtons() {
    if (this.SystemJobLog && this.SystemJobLog.id) {
      return false;
    }
    else {
      return true;
    }
  }

  onClickBack(): void {
    this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.SystemJobLogForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingSystemJobLogs: SystemJobLog[] = await (
      await this.SystemJobLogsService.getList(params)
    ).body;
    if (existingSystemJobLogs.length > 0) {
      if (this.SystemJobLog.id) {
        for (const key in existingSystemJobLogs) {
          const currentSystemJobLog: SystemJobLog = existingSystemJobLogs[key];
          if (currentSystemJobLog.id !== this.SystemJobLog.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  confirmCancel() {
    if (this.SystemJobLogForm.get('status').value.startsWith('Complete')) {
      alert('Completed Records cannot be canceled.');
      return;
    }
    if (this.SystemJobLogForm.get('status').value.startsWith('Cancel')) {
      alert('Canceled or Canceling Records cannot be canceled.');
      return;
    }
    this.submit("Cancel");
  }

  async submit(action) {
    this.SystemJobLogForm.markAllAsTouched();
    this.submitted = true;
    if (this.SystemJobLogForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = { ...this.SystemJobLog, ...this.SystemJobLogForm.value };
    if (action == "Cancel") {
      data.Status = "Canceling";
    }
    // Check to see if SystemJobLog Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'SystemJobLog Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.SystemJobLog.id) {
        try {
          const response: any = await this.SystemJobLogsService.update(
            this.SystemJobLog.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close" || action == "Cancel") {
              this.router.navigate(['/price/system-job-logs']);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.SystemJobLogsService.create(
            this.SystemJobLogForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.SystemJobLog = response.body;
            this.idOfSystemJobLog = this.SystemJobLog.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close" || action == "Cancel") {
              this.router.navigate(['/price/system-job-logs']);
            }
            else if (action == "New") {
              this.SystemJobLog = emptySystemJobLog;
              this.idOfSystemJobLog = null;
              this.submitted = false;
              this.updateForm();
              this.buildForm();
            }
            else {
              this.SystemJobLog = response.body;
              this.router.navigate(['/price/system-job-log/', this.SystemJobLog.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getSystemJobLogById() {
    if (this.idOfSystemJobLog) {
      return await this.SystemJobLogsService.getById(this.idOfSystemJobLog);
    } else {
      return emptySystemJobLog;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [
        this.SystemJobLog,
      ] = await Promise.all([
        this.getSystemJobLogById(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async onClickCalculate(): Promise<void> {
    try {
      const params = [
        {
          Name: "@SystemJobLogID",
          Value: this.idOfSystemJobLog
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMCalculateSystemJobLog', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records calculated successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onClickBenchmark(): Promise<void> {
    try {
      const params = [
        {
          Name: "@SystemJobLogID",
          Value: this.idOfSystemJobLog
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMBenchmarkSystemJobLog', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records benchmarked successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }
}
