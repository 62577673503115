<kendo-dialog [minWidth]="450" (close)="onClick_Close('Cancel')" [width]="900" [height]="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/View_Diagnostics.png" alt="Pricing Scenario - Input XML" style="padding-right: 5px;">
      Pricing Scenario - Input XML
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>
  <form>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="inputXML" text="Input XML"></kendo-label>
          <!-- TODO: Determine how to make this size dynamically.-->
          <textarea id="inputXML" [value]="formattedXMLString" wrap="off" style="height: 400px; overflow-y: scroll; overflow-x: scroll;" class="form-control" formControlName="inputXML" readonly="true" ></textarea>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')" primary="true"><span class="k-icon k-i-cancel"></span>Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
