import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef, } from '@angular/core';
import { LocationsService } from '../../../services/locations/locations.service';
import { Location, emptyLocation } from '../../../services/locations/locations.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-location-single',
  templateUrl: './location-single.component.html',
  styleUrls: ['./location-single.component.scss'],
})
export class LocationSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfLocation: number | null = null;
  @Input() location: Location = emptyLocation;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  locationForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;

  constructor(  
    private locationsService: LocationsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,   
    private notificationHelper: NotificationHelper
  ) {    
  }

  ngOnInit(): void {
    if (this.idOfLocation) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.locationForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfLocation) {
        this.locationForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }
  buildForm() {
    this.locationForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      erpid: new FormControl({ value: '', disabled: true }, [
        Validators.required,
        Validators.maxLength(50),
      ]),
    });
    this.locationForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.locationForm.controls;
  }
  updateForm() {
    this.locationForm.patchValue({ ...this.location });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.locationForm.markAllAsTouched();
    this.submitted = true;
    if (this.locationForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.location,
      ...this.locationForm.getRawValue(), // locationForm.value does not include disabled controls.
    };
    // Check to see if Location Name already exists.
    if ((await this.validation_CheckForExistingName()) === true) {
      this.notificationHelper.showStatusOnDialog('Location Name already exists!', 'error', this.container);
      return;
    }
    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.notificationHelper.showStatusOnDialog('ERP ID already exists!', 'error', this.container);
      return;
    }

    if (this.location.id) {      
      try {
        const response: any = await this.locationsService.update(
          this.location.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.locationsService.create(
          this.locationForm.getRawValue(), // locationForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }   
    this.onFormSaved.emit(false);
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.locationForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingLocations: Location[] = await (
      await this.locationsService.getList(params)
    ).body;
    if (existingLocations.length > 0) {
      if (this.location.id) {
        for (const key in existingLocations) {
          const currentLocation: Location = existingLocations[key];
          if (currentLocation.id != this.location.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async validation_CheckForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.locationForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingLocations: Location[] = await (
      await this.locationsService.getList(params)
    ).body;
    if (existingLocations.length > 0) {
      if (this.location.id) {
        for (const key in existingLocations) {
          const currentLocation: Location = existingLocations[key];
          if (currentLocation.id != this.location.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }

  async getLocationById() {
    if (this.idOfLocation) {
      return await this.locationsService.getById(this.idOfLocation);
    } else {
      return emptyLocation;
    }
  }

  async loadData() {
    try {
      [this.location] = await Promise.all([this.getLocationById()]);      
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

}
