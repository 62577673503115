export interface SyncSet {
  id: number;
  name: string;
  description: string;
  readerSyncConnectorID: number;
  readerSyncConnector: {
    name: string,
    id: number
  };
  writerSyncConnectorID: number;
  writerSyncConnector: {
    name: string,
    id: number
  };
  isDefault: boolean;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptySyncSet: SyncSet = {
  id: null,
  name: '',
  description: '',  
  readerSyncConnectorID: null,
  readerSyncConnector: {
    name: '',
    id: null
  },
  writerSyncConnectorID: null,
  writerSyncConnector: {
    name: '',
    id: null
  },
  isDefault: false,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
