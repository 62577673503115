import { Component, OnInit, Output, EventEmitter, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogsService } from '../../../services/catalogs/catalog.service';
import { Catalog, emptyCatalog, } from '../../../services/catalogs/catalog.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData, } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CatalogSheetListComponent } from '../../catalog-sheets/catalog-sheet-list/catalog-sheet-list.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { CopyService } from '../../../services/copy/copy.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { CatalogSheetsService } from '../../../services/catalog-sheets/catalog-sheets.service';
import { CatalogLinesService } from '../../../services/catalog-lines/catalog-lines.service';

enum Filters {
  Name = 1,
}
@Component({
  selector: 'app-catalog-single',
  templateUrl: './catalog-single.component.html',
  styleUrls: ['./catalog-single.component.scss'],
})
export class CatalogSingleComponent implements OnInit {
  @ViewChild('subgrid') catalogSheetsSubGrid: CatalogSheetListComponent;

  filters = Filters;
  idOfCatalog: number | null = null;
  catalog: Catalog = emptyCatalog;
  loading: boolean = true;
  catalogForm: FormGroup;
  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  paginationObject: any = { catalogSheets: mockedData };
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  subgridmode = 'subgrid';

  constructor(
    private route: ActivatedRoute,
    private catalogsService: CatalogsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private router: Router,
    private sideNavService: SideNavService,
    private copyService: CopyService,
    private utilitiesService: UtilitiesService,
    private catalogSheetsService: CatalogSheetsService,
    private catalogLinesService: CatalogLinesService,
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.catalog = emptyCatalog;
        this.idOfCatalog = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        this.catalogSheetsSubGrid.catalogID = null;
        this.catalogSheetsSubGrid.loadSubGridData();
        return;
      }
      this.idOfCatalog = params.id;
      this.loadData();
    });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.catalogSheetsSubGrid.gridClassName = "CatalogSheetSubgridClass"
        }
        else {
          this.catalogSheetsSubGrid.gridClassName = "CatalogSheetFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
  }
  buildForm() {
    this.catalogForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
    });
    this.catalogForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.catalogForm.controls;
  }

  updateForm() {
    this.catalogForm.patchValue({ ...this.catalog });
    this.isFormChanged = false;
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMCatalog", this.catalog.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.catalog.id);
    await this.catalogsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/catalogs']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  onClickAddNew() {
    this.router.navigate(['/price/catalog/new'], { replaceUrl: true });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableButtons() {
    if (this.catalog && this.catalog.id) {
      return false;
    }
    else {
      return true;
    }
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.catalogForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingCatalogs: Catalog[] = await (
      await this.catalogsService.getList(params)
    ).body;
    if (existingCatalogs.length > 0) {
      if (this.catalog.id) {
        for (const key in existingCatalogs) {
          const currentCatalog: Catalog = existingCatalogs[key];
          if (currentCatalog.id !== this.catalog.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.catalogForm.markAllAsTouched();
    this.submitted = true;
    if (this.catalogForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = { ...this.catalog, ...this.catalogForm.value };

    // Check to see if Catalog Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Catalog Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.catalog.id) {
        try {
          const response: any = await this.catalogsService.update(
            this.catalog.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close") {
              this.router.navigate(['/price/catalogs']);
            }
            else if (action == "New") {
              //this.catalog = emptyCatalog;
              //this.idOfCatalog = null;
              //this.submitted = false;
              //this.updateForm();
              //this.buildForm();
              this.onClickAddNew();
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.catalogsService.create(
            this.catalogForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.catalog = response.body;
            this.idOfCatalog = this.catalog.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            if (action == "Close") {
              this.router.navigate(['/price/catalogs']);
            }
            else if (action == "New") {
              this.catalog = emptyCatalog;
              this.idOfCatalog = null;
              this.submitted = false;
              this.updateForm();
              this.buildForm();
            }
            else {
              this.catalog = response.body;
              this.router.navigate(['/price/catalog/', this.catalog.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getCatalogById() {
    if (this.idOfCatalog) {
      return await this.catalogsService.getById(this.idOfCatalog);
    } else {
      return emptyCatalog;
    }
  }

  async loadData() {
    this.loading = true;
    try {
      [
        this.catalog,
      ] = await Promise.all([
        this.getCatalogById(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async onClickCalculate(): Promise<void> {
    const count = await this.sumCatalogLineCounts();
    if (count > 1000) {
      const warnMsg = `This catalog has ${count} records and is too long to calculate using the web interface.  Please use an alternate method, such as calculating each Sheet, or contact Support.`;
      this.snack.openSnackBar(warnMsg, '', true, 'Warning', 'alert-warning');
      return;
    }
    try {
      const params = [
        {
          Name: "@CatalogID",
          Value: this.idOfCatalog
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMCalculateCatalog', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records calculated successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onClickBenchmark(): Promise<void> {
    const count = await this.sumCatalogLineCounts();
    if (count > 10000) {
      const warnMsg = `This catalog has ${count} records and is too long to benchmark using the web interface.  Please use an alternate method, such as benchmarking each Sheet, or contact Support.`;
      this.snack.openSnackBar(warnMsg, '', true, 'Warning', 'alert-warning');
      return;
    }
    try {
      const params = [
        {
          Name: "@CatalogID",
          Value: this.idOfCatalog
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMBenchmarkCatalog', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records benchmarked successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async sumCatalogLineCounts() {
    let totalLinesCount = 0;
    try {
      const sheetResponse = await this.catalogSheetsService.getList({ Filter_CatalogID : this.idOfCatalog});
      const sheetList = sheetResponse.body;
      for (const sheet of sheetList) {
        const lineResponse = await this.catalogLinesService.getList({ Filter_CatalogSheetID: sheet.id, PageSize: 1, PageNumber: 1 });
        const pagination = getPaginationHeader(lineResponse.headers);
        totalLinesCount += pagination.TotalCount;
      }
      console.log("Total lines count across all sheets:", totalLinesCount);
    } catch (error) {
      this.loading = false;
      console.error("Error fetching total count of Catalog Sheets and Lines: ", error);
    }
    return totalLinesCount;
  }
}
