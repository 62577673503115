import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { SyncReaderColumnSingleComponent } from '../sync-reader-column-single/sync-reader-column-single.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { SyncReaderColumnsService } from '../../../services/sync-reader-columns/sync-reader-columns.service';
import { SyncReaderColumn } from '../../../services/sync-reader-columns/sync-reader-columns.model';

@Component({
  selector: 'app-sync-reader-column-sublist',
  templateUrl: './sync-reader-column-sublist.component.html',
  styleUrls: ['./sync-reader-column-sublist.component.scss'],
})
export class SyncReaderColumnSubListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  
  constructor(
    private syncReaderColumnsService: SyncReaderColumnsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() syncReaderID: number;
  @Input() loading: boolean = true;
  @Input() syncReaderColumns: SyncReaderColumn[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input()
  mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  public isAdmin: boolean;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;


  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Sequence Number',
      field: 'sequenceNumber',
      width: 175,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Column Name',
      field: 'columnName',
      width: 175,
      orderIndex: 1,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.syncReaderColumnsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('SyncReaderColumnList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('SyncReaderColumnList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('SyncReaderColumnList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.isAdmin = StorageService.IsAdmin();
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('SyncReaderColumnList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onEdit(this.clickedRowItem);
    }
  }

  onCreate() {
    const modalRef = this.modalService.open(
      SyncReaderColumnSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "CustomerAttributeValueModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.idOfSyncReader = this.syncReaderID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'sequenceNumber') {
          const Filter_SequenceNumber = myFilter.value;
          const Operand_SequenceNumber = myFilter.operator;
          const params = { ...this.query, Filter_SequenceNumber, Operand_SequenceNumber };
          this.query = params;
        }
        if (myFilter.field == 'columnName') {
          const Filter_ColumnName = myFilter.value;
          const Operand_ColumnName = myFilter.operator;
          const params = { ...this.query, Filter_ColumnName, Operand_ColumnName };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.syncReaderID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.syncReaderColumnsService.getList({
          Filter_SyncReaderID: this.syncReaderID,
          ...this.query,
        });
        this.syncReaderColumns = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.syncReaderColumns,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onEdit(syncReaderColumn) {
    const modalRef = this.modalService.open(SyncReaderColumnSingleComponent);
    modalRef.componentInstance.syncReaderColumn = syncReaderColumn;
    modalRef.componentInstance.idOfSyncReader = syncReaderColumn.syncReaderID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.syncReaderColumnsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }
  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }
  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

}
