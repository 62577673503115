export interface SyncQueue {
  id: number;
  entryTime: Date;
  syncSetID: number;
  syncSet: {
    name: string,
    id: number
  };
  entityID: number;
  entity: {
    name: string,
    id: number
  };
  queueTypeID: number;
  queueType: {
    name: string,
    id: number
  };
  recordID: string;
  sinceTime: Date;
  statusID: number;
  status: {
    name: string,
    id: number
  };
  completedTime: Date;
  errorMessage: string;
  TestRecordCount: number;
}

export const emptySyncQueue: SyncQueue = {
  id: null,
  entryTime: null,
  syncSetID: null,
  syncSet: {
    name: '',
    id: null
  },
  entityID: null,
  entity: {
    name: '',
    id: null
  },
  queueTypeID: null,
  queueType: {
    name: '',
    id: null
  },
  recordID: '',
  sinceTime: null,
  statusID: null,
  status: {
    name: '',
    id: null
  },
  completedTime: null,
  errorMessage: '',
  TestRecordCount: null
};
