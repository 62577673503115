export function clearParamsFromNull(params) {
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const element = params[key];
      isNullOrUndefined(element) && delete params[key];
    }
  }
  return params;
}
export function isNullOrUndefined(val) {
  return val === undefined || val === '' || val === 'undefined';
}
