<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Currency.png" alt="Currency" style="padding-right: 5px;">
      Create / Update Currency
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="currencyForm">
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Name" maxlength="100" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror *ngIf="submitted && f.name.errors && f.name.errors.required">Name is required</kendo-formerror>
          <kendo-formerror *ngIf="submitted && f.name.errors && f.name.errors.maxlength">Name must be 100 characters or less!</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="alphaCode" text="Alpha Code"></kendo-label>
          <kendo-textbox class="form-control" formControlName="alphaCode" placeholder="Enter Alpha Code" maxlength="3"
                         [clearButton]="true" [showSuccessIcon]="false">
          </kendo-textbox>
          <kendo-formerror>Alpha Code must be 3 characters or less!</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="numericCode" text="Numeric Code"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="numericCode" [min]="0" [max]="999" [maxlength]="3"
                                placeholder="Enter Numeric Code" [decimals]="0" [format]="'n0'">
          </kendo-numerictextbox>
          <kendo-formerror>Numeric Code must be 3 digits or less!</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="erpid" text="ERP ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="erpid" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
