import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { PriceScheduleChild } from './price-schedule-children.model';
import { clearParamsFromNull } from '../params-utils/index';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { map } from 'rxjs/operators';
import { StorageService } from 'src/app/utils/StorageHelper';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PriceScheduleChildrenService {
  model = 'RPMPriceScheduleChildren';
  constructor(private httpClient: HttpClient) { }

  getList(params = {}): Promise<HttpResponse<PriceScheduleChild[]>> {
    params = clearParamsFromNull(params);
    // @ts-ignore
    // observe: 'response'
    return this.httpClient
      .get(this.model, { params, observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<PriceScheduleChild[]>>;
  }
  getExportList(params = {}): Promise<GridDataResult> {
    params = clearParamsFromNull(params);
    // @ts-ignore
    // observe: 'response'
    return this.httpClient
      .get(this.model, { params, observe: 'response' })
      .pipe(
        timeout(environment.requestTimeout),
        map(response => (<GridDataResult>{
          data: response.body,
          total: 200000,
        })))
      .toPromise() as Promise<GridDataResult>;
  }
  getById(id: number): Promise<PriceScheduleChild> {
    return this.httpClient.get(`${this.model}/${id}`)
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<PriceScheduleChild>;
  }
  removeById(id: number) {
    return this.httpClient.delete(`${this.model}/${id}`)
      .pipe(timeout(environment.requestTimeout))
      .toPromise();
  }
  removeByIds(ids: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json-patch+json',
      }),
      body: ids,
    };
    return this.httpClient.delete(`${this.model}/`, options)
      .pipe(timeout(environment.requestTimeout))
      .toPromise();
  }
  create(data): Promise<HttpResponse<PriceScheduleChild>> {
    data.CreatedOn = new Date();
    data.CreatedBy = StorageService.UserID();
    return this.httpClient
      .post(this.model, data, { observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<PriceScheduleChild>>;
  }
  update(id, data): Promise<HttpResponse<PriceScheduleChild>> {
    data.ModifiedOn = new Date();
    data.ModifiedBy = StorageService.UserID();
    return this.httpClient
      .put(`${this.model}/${id}`, data, { observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<PriceScheduleChild>>;
  }
}
