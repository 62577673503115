<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="1000" [height]="620">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Catalog_Sheet.png" alt="Price Sheets" style="padding-right: 5px;">
      Create / Update Catalog Line
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="catalogLineForm">

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="customerID" text="Customer"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="customerID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.customerID.errors,'w-100': true}"
                       placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.Customer)">
              <ng-option *ngFor="let item of customers" [value]="item.id"> {{ item?.number }} - {{ item?.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Customer()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="itemID" text="Item"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="itemID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="itemID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.itemID.errors,'w-100': true}"
                       placeholder="Select an Item" [searchable]="true" (search)="search($event, filters.Item)"
                       (change)="onSelectBoxChanged($event, 'item', items)">
              <ng-option *ngFor="let item of items" [value]="item.id"> {{ item?.number }} - {{ item?.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Item()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="uofMID" text="Unit of Measure"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="uofMID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="uofMID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.uofMID.errors,'w-100': true}"
                       placeholder="Select a Unit of Measure" [searchable]="true" (search)="search($event, filters.UofM)">
              <ng-option *ngFor="let item of uofMs" [value]="item.id"> {{ item.name }} | {{ item.uofMSchedule.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_UofM()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="effectiveDate" text="Effective Date"></kendo-label>
          <kendo-datepicker id="effectiveDate" class="form-control" formControlName="effectiveDate"
                            [(value)]="effectiveDate">
          </kendo-datepicker>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="quantity" text="Quantity"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="quantity" [min]="0" [decimals]="5" [format]="'n5'" [max]="99999999999999"
                                [autoCorrect]="true" placeholder="Enter Quantity">
          </kendo-numerictextbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="priceScheduleID" text="Price Schedule"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="priceScheduleID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="priceScheduleID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.pricingFilterID.errors,'w-100': true}"
                       placeholder="Select a Price Schedule" [searchable]="true" (search)="search($event, filters.PriceSchedule)">
              <ng-option *ngFor="let priceSchedule of priceSchedules" [value]="priceSchedule.id"> {{ priceSchedule.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_PriceSchedule()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield *ngIf="singleCurrency">
          <kendo-label for="currencyLabelName" text="Currency"></kendo-label>
          <kendo-textbox class="form-control" formControlName="currencyLabelName" maxlength="100" disabled="true">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield *ngIf="!singleCurrency">
          <kendo-label for="currencyID" text="Currency"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="currencyID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="currencyID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.currencyID.errors,'w-100': true}"
                       placeholder="Select a Currency" [searchable]="true" (search)="search($event, filters.Currency)">
              <ng-option *ngFor="let item of currencies" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Currency()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="locationID" text="Location"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="locationID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="locationID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.locationID.errors,'w-100': true}"
                       placeholder="Select a Location" [searchable]="true" (search)="search($event, filters.Location)">
              <ng-option *ngFor="let item of locations" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Location()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="externalItemNumber" text="External Item Number"></kendo-label>
          <kendo-textbox class="form-control" formControlName="externalItemNumber" placeholder="Enter an External Item Number"
                         title="Store information such as a Customer Item Number, useful if this catalog is exported and distributed.">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="comment" text="Comment"></kendo-label>
          <kendo-textbox class="form-control" formControlName="comment" placeholder="Enter a comment">
          </kendo-textbox>
        </kendo-formfield>
      </div>

    </div>

    <hr>

    <div class="row">

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="calculatedUnitPrice" text="Calculated Unit Price"></kendo-label>
          <kendo-textbox class="form-control" formControlName="calculatedUnitPrice" placeholder="Unit Price once calculated" readonly [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="lastCalculatedDateTime" text="Last Calculated Date and Time"></kendo-label>
          <kendo-textbox class="form-control" formControlName="lastCalculatedDateTime" placeholder="Date and Time last calculated" readonly [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="benchmarkedUnitPrice" text="Benchmarked Unit Price"></kendo-label>
          <kendo-textbox class="form-control" formControlName="benchmarkedUnitPrice" placeholder="Unit Price once benchmarked" readonly [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="lastBenchmarkedDateTime" text="Last Benchmarked Date and Time"></kendo-label>
          <kendo-textbox class="form-control" formControlName="lastBenchmarkedDateTime" placeholder="Date and Time last benchmarked" readonly [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

    </div>

    <div class="row">

      <div class="col form-group">  <!--mhr cannot get name to display-->
        <kendo-formfield>
          <kendo-label for="calculatedPriceSchedule.name" text="Calculated Price Schedule"></kendo-label>
          <kendo-textbox class="form-control" formControlName="calculatedPriceSchedule.name" placeholder="Price Schedule found from calculating" readonly [readonly]="true">
            <ng-option *ngFor="let item of calculatedPriceSchedules" [value]="item.id"> {{ item.name }} </ng-option>
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="processStatus" text="Process Status"></kendo-label>
          <kendo-textbox class="form-control" formControlName="processStatus" readonly [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="errorMessage" text="Error Message"></kendo-label>
          <kendo-textbox class="form-control" formControlName="errorMessage" readonly [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>

    </div>

  </form>

  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_SaveClose()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save & Close</button>
  </kendo-dialog-actions>
</kendo-dialog>
