export interface UofM {
    id: number;
    erpid: string;
    name: string;
    uofMScheduleID: number;
    uofMSchedule: {
        name: string,
        id: number,
        erpid: string
    };
    isBase: boolean;
    quantityInBase: number;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyUofM: UofM = {
    id: null,
    erpid: null,
    name: '',
    uofMScheduleID: null,
    uofMSchedule: {
        name: '',
        id: null,
        erpid: '',
    },
    isBase: false,
    quantityInBase: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
