import { Component, OnInit, Output, EventEmitter, Input,  ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceSheetsService } from '../../../services/price-sheets/price-sheets.service';
import {
  PriceSheet,
  emptyPriceSheet,
} from '../../../services/price-sheets/price-sheets.model';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { Currency } from 'src/app/services/currencies/currencies.model';

import { PriceBookService } from '../../../services/price-book/price-book.service';
import { emptyPriceBook, PriceBook } from 'src/app/services/price-book/price-book.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { PriceBookListComponent } from '../../price-book/price-book-list/price-book-list.component';
import { PriceBookLookupComponent } from '../../price-book/price-book-lookup/price-book-lookup.component';
import { PriceSheetDetailListComponent } from '../../price-sheet-details/price-sheet-detail-list/price-sheet-detail-list.component';
import { CurrencyListComponent } from '../../currencies/currency-list/currency-list.component';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { PriceSheetDetail } from 'src/app/services/price-sheet-details/price-sheet-details.model';
import { PriceSheetDetailsService } from 'src/app/services/price-sheet-details/price-sheet-details.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../../../services/settings/settings.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { Setting } from 'src/app/services/settings/settings.model';
import { Location } from '@angular/common';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { CopyService } from '../../../services/copy/copy.service';

enum Filters {
  Name = 1,
  PriceBook = 2,
  Currency = 3,
  PricingFilter = 4
}
@Component({
  selector: 'app-price-sheet-single',
  templateUrl: './price-sheet-single.component.html',
  styleUrls: ['./price-sheet-single.component.scss'],
})
export class PriceSheetSingleComponent implements OnInit {
  @ViewChild('subgrid') priceSheetDetailsSubGrid: PriceSheetDetailListComponent;

  filters = Filters;
  idOfPriceSheet: number | null = null;
  @Input() idOfPriceBook: number | null = null;
  @Input() createFromBook: boolean = false;
  priceSheet: PriceSheet = emptyPriceSheet;
  loading: boolean = true;
  priceSheetForm: FormGroup;
  currencies: Currency[] = [];
  priceBooks: PriceBook[] = [];
  defaultCurrencyID: number;
  singleCurrency: boolean;
  pricingFilters: PricingFilter[] = [];
  priceSheetDetails: PriceSheetDetail[] = [];
  revisionDate: Date = new Date();
  effectiveBeginDate: Date = new Date();
  effectiveEndDate: Date = new Date();
  public min: Date = new Date(2000, 0);

  //TODO
  paginationObject_PriceBooks: any = { priceBooks: mockedData };
  paginationObject_Currencies: any = { currencies: mockedData };
  paginationObject_PriceSheetDetails: any = { priceSheetDetails: mockedData };
  submitted = false;
  date: { year: number; month: number };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();

  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  priceBookLookupHasFocus = false;

  constructor(
    private route: ActivatedRoute,
    private priceSheetsService: PriceSheetsService,
    private priceSheetDetailsService: PriceSheetDetailsService,
    private priceBooksService: PriceBookService,
    private currenciesService: CurrenciesService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private location : Location,
    private router: Router,
    private notificationHelper: NotificationHelper,
    private pricingFiltersService: PricingFiltersService,
    private copyService: CopyService
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.priceSheet = emptyPriceSheet;
        this.idOfPriceSheet = null;
        this.submitted = false;

        this.idOfPriceBook = Number(this.route.snapshot.paramMap.get('priceBookID'));
        // this.updateForm();
        // this.buildForm();
        return;
      }
      else{
        this.idOfPriceSheet = params.id;
      }
    });
    this.priceBookSearch = debounce(this.priceBookSearch, 400, {});
    this.currencySearch = debounce(this.currencySearch, 400, {});

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {          
          this.priceSheetDetailsSubGrid.gridClassName = "PriceSheetDetailSubgridClass"
        }
        else {
          this.priceSheetDetailsSubGrid.gridClassName = "PriceSheetDetailFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.idOfPriceBook) {      
      this.priceSheet.priceBookID = this.idOfPriceBook;
    }
    this.loadData();
    
  }

  buildForm() {
    this.priceSheetForm = this.fb.group({
      name: new FormControl('', Validators.required),
      priceBookID: new FormControl(null, Validators.required),
      pricingFilterID: new FormControl(''),
      attributeValue: new FormControl(''),
      currencyID: new FormControl('', Validators.required),
      currencyLabelName: new FormControl(),
      isActive: new FormControl(true),
      effectiveBeginDate: new FormControl('', Validators.required),
      effectiveEndDate: new FormControl('', Validators.required),
      revisionDate: new FormControl(''),
      rank: new FormControl(1, Validators.required),
      dp: new FormControl(''),
    });
    this.setDefaultDates();
    this.priceSheetForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getUseSingleCurrencySettings();
    
  }

  setDefaultDates() {

    // Default in Start Date
    const year = new Date().getFullYear();
    var beginString = "01/01/" + year.toString();
    const beginDate = new Date(beginString);
    this.priceSheet.effectiveBeginDate = new Date(beginDate);
    this.priceSheetForm.patchValue({ ['effectiveBeginDate']: this.priceSheet.effectiveBeginDate });

    // Default in End Date
    var endString = "12/31/" + year.toString();
    const endDate = new Date(endString);
    this.priceSheet.effectiveEndDate = new Date(endDate);
    this.priceSheetForm.patchValue({ ['effectiveEndDate']: this.priceSheet.effectiveEndDate });
  }

  get f() {
    return this.priceSheetForm.controls;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPriceSheet", this.priceSheet.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  updateForm() {
    this.priceSheetForm.patchValue({ ...this.priceSheet });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.priceSheet.id);
    await this.priceSheetsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/price-sheets']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.priceSheet && this.priceSheet.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/price-sheet/new'));
    if (canContinue) {
      if (this.idOfPriceBook) {
        const currentIDOfPriceBook: number = this.idOfPriceBook;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/price/price-sheet/new', { priceBookID: currentIDOfPriceBook }],);
        });
      }
      else if (this.priceSheet.priceBookID) {
        const currentIDOfPriceBook: number = this.priceSheet.priceBookID;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/price/price-sheet/new', { priceBookID: currentIDOfPriceBook }],);
        });
      }
    }
  }

  onClick_Back(): void {
    if (this.idOfPriceBook) {
      this.router.navigate(['/price/price-book/' + this.idOfPriceBook]);
    } else {
      this.location.back();
    }
  }
  
  onClick_Save() {
    this.submit(null);
  }

  onClick_SaveClose() {
    this.submit("Close");
  }

  onClick_SaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.priceSheetForm.controls['name'].value;
    const Operand_Name = 'eq';
    const Filter_PriceBookID = this.priceSheet.priceBookID;
    const Operand_PriceBookID = 'eq';

    const params = { Filter_Name, Operand_Name, Filter_PriceBookID, Operand_PriceBookID, PageSize };
    const existingPriceSheets: PriceSheet[] = await (
      await this.priceSheetsService.getList(params)
    ).body;
    if (existingPriceSheets.length > 0) {
      if (this.priceSheet.id) {
        for (const key in existingPriceSheets) {
          const currentPriceSheet: PriceSheet = existingPriceSheets[key];
          if (currentPriceSheet.id !== this.priceSheet.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    console.log('errors on begin date', this.f.effectiveBeginDate.errors)
    this.priceSheetForm.markAllAsTouched();
    this.submitted = true;
    if (this.priceSheetForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.priceSheet,
      ...this.priceSheetForm.value,
    };
    // Check to see if Price Sheet Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Price Sheet Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.priceSheet.id) {
        try {
          const response: any = await this.priceSheetsService.update(
            this.priceSheet.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/price-books']);
            }
            else if (action == "New") {
              const currentIDOfPriceBook: number = this.priceSheet.priceBookID;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/price-sheet/new', { priceBookID: currentIDOfPriceBook }],);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.priceSheetsService.create(
            this.priceSheetForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.priceSheet = response.body;
            this.idOfPriceSheet = this.priceSheet.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/price-books']);
            }
            else if (action == "New") {
              const currentIDOfPriceBook: number = this.idOfPriceBook;
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/price-sheet/new', { priceBookID: currentIDOfPriceBook }],);
              });
            }
            else {
              this.priceSheet = response.body;
              this.router.navigate(['/price/price-sheet/', this.priceSheet.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      this.onFormSaved.emit(false);
      this.onFormChanged.emit(false);
      console.log('Form Changed', this.isFormChanged)

      if (this.createFromBook) {
        this.router.navigate([`/price/price-sheet/${this.idOfPriceSheet}`]);
      }
    }
  }

  async loadData() {
    this.loading = true;
    try {
      //
      if (this.idOfPriceSheet) {
        // If this is an existing record we populate the values.
        this.priceSheet = await Promise.resolve(
          await this.priceSheetsService.getById(this.idOfPriceSheet));

        if (this.priceSheet.priceBookID){
          const currentPriceBook: PriceBook = await Promise.resolve(
            await this.priceBooksService.getById(this.priceSheet.priceBookID));
          this.priceBooks.push(currentPriceBook);  
          this.priceSheet.priceBook = currentPriceBook;
        }

        if (this.priceSheet.currencyID){
          const currentCurrency: Currency = await Promise.resolve(
            await this.currenciesService.getById(this.priceSheet.currencyID));
          this.currencies.push(currentCurrency);  
          this.priceSheet.currency = currentCurrency;
        }

        if (this.priceSheet.pricingFilterID) {
          const currentPricingFilter: PricingFilter = await Promise.resolve(this.getPricingFilterById(this.priceSheet.pricingFilterID));
          this.pricingFilters.push(currentPricingFilter);
        }

        if (this.priceSheet.effectiveBeginDate) {
          this.priceSheet.effectiveBeginDate = new Date(this.priceSheet.effectiveBeginDate)
        }

        if (this.priceSheet.effectiveEndDate) {
          this.priceSheet.effectiveEndDate = new Date(this.priceSheet.effectiveEndDate)
        }

        if (this.priceSheet.revisionDate) {
          this.priceSheet.revisionDate = new Date(this.priceSheet.revisionDate)
        }
        this.updateForm();
      }
      else {
        if (this.idOfPriceBook) {
          const defaultPriceBook: PriceBook = await Promise.resolve(
            await this.priceBooksService.getById(this.idOfPriceBook));
          this.priceBooks.push(defaultPriceBook);
          this.priceSheet.priceBook = defaultPriceBook;
          this.priceSheet.priceBookID = defaultPriceBook.id;
          this.priceSheetForm.patchValue({ ['priceBookID']: defaultPriceBook.id });

          //// Default in Start Date
          //const year = new Date().getFullYear();
          //var beginString = "01/01/" + year.toString();
          //const beginDate = new Date(beginString);
          //this.priceSheet.effectiveBeginDate = new Date(beginDate);
          //this.priceSheetForm.patchValue({ ['effectiveBeginDate']: this.priceSheet.effectiveBeginDate });

          //// Default in End Date
          //var endString = "12/31/" + year.toString();
          //const endDate = new Date(endString);
          //this.priceSheet.effectiveEndDate = new Date(endDate);
          //this.priceSheetForm.patchValue({ ['effectiveEndDate']: this.priceSheet.effectiveEndDate });
        }
      }
      this.isFormChanged = false;

    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  async getPricingFilterById(id) {
    return await this.pricingFiltersService.getById(id);
  }

  async priceBookSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getPriceBooks({ SearchTerm });
  }

  async currencySearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCurrencies({ SearchTerm });
  }

  async getPriceBooks(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.priceBooksService.getList({
        ...params, PageSize
      });
      // @ts-ignore
      this.priceBooks = resp.body;
      this.paginationObject_PriceBooks.priceBooks = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.priceBooks = [];
    }
    return this.priceBooks;
  }

  async getCurrencies(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.currenciesService.getList({ ...params, PageSize });
      this.currencies = resp.body;
      this.paginationObject_Currencies.currencies = getPaginationHeader( resp.headers );
    } catch (e) {
      this.currencies = [];
    }
    return this.currencies;
  }

  onSelectBoxChanged(id: number, key: keyof PriceSheet, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.priceSheet[key] = item;
    if (key === 'priceBookID') {  
      this.idOfPriceBook = id;
      this.priceSheet.priceBookID = id;  
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.PriceBook:
        this.priceBooks = [];
        this.priceBooks = await (await this.priceBooksService.getList(params))
          .body;
        break;
      case Filters.PricingFilter:
        this.pricingFilters = await (
          await this.pricingFiltersService.getList(params)
        ).body;
        break;
      case Filters.Currency:
        this.currencies = await (await this.currenciesService.getList(params))
          .body;
        break;
      default:
        break;
    }
  }

  onBlur(event) {
    if (event) {
      this.priceBookLookupHasFocus = false;
    }
  }

  onFocus(event) {
    if (event) {
      this.priceBookLookupHasFocus = true;
    }
  }

  async onLookup_priceBook() {

    // If the user hits the enter key but is not on this button then ignoe it.
    if (!this.priceBookLookupHasFocus) {
      return;
    }

    const modalRef = this.modalService.open(PriceBookLookupComponent, { });

    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceBook = receivedEntry;
      this.priceBooks = [];
      this.priceBooks.push(recordSelected);
      this.priceSheetForm.patchValue({ ['priceBookID']: receivedEntry.id });
      this.priceSheet.priceBook = receivedEntry;
      this.priceSheet.priceBookID = receivedEntry.id; 
      this.idOfPriceBook = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_pricingFilter() {
    const modalRef = this.modalService.open(PricingFilterLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PricingFilterModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PricingFilterDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingFilter = receivedEntry;
      this.pricingFilters = [];
      this.pricingFilters.push(recordSelected);
      this.priceSheetForm.patchValue({ ['pricingFilterID']: receivedEntry.id });
      this.priceSheet.pricingFilter = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_currency() {
    const modalRef = this.modalService.open(CurrencyLookupComponent, { });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.priceSheetForm.patchValue({ ['currencyID']: receivedEntry.id });
      this.priceSheet.currency = receivedEntry;
      modalRef.close('test');
    });
  }

  // filterChange(event, arr) {
  //   this.getPriceSheetDetails(event);
  // }

  // async getPriceSheetDetails(params) {
  //   try {
  //     const resp = await this.priceSheetDetailsService.getList({
  //       Filter_PriceSheetID: this.idOfPriceSheet,
  //       ...params,
  //     });
  //     // @ts-ignore
  //     this.priceSheetDetails = resp.body;
  //     this.paginationObject_PriceSheetDetails.priceSheetDetails = getPaginationHeader(
  //       // @ts-ignore
  //       resp.headers,
  //     );
  //   } catch (e) {
  //     this.priceSheetDetails = [];
  //   }

  //   return this.priceSheetDetails;
  // }
  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {    
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async getUseSingleCurrencySettings() {
    const Filter_Name = 'UseSingleCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    this.singleCurrency = setting[0].value == 'true';
    //if (this.singleCurrency && this.idOfPriceSheet) {
    if (this.singleCurrency) {
        this.getDefaultCurrencySettings();
    }
  }

  async getDefaultCurrencySettings() {
    const Filter_Name = 'DefaultCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    if (setting && setting[0]) {
      this.defaultCurrencyID = +setting[0].value;
      const resp = await this.currenciesService.getById(this.defaultCurrencyID);
      let defaultCurrency = resp;
      this.currencies = [];
      this.currencies.push(defaultCurrency);
      this.priceSheetForm.patchValue({ ['currencyID']: defaultCurrency.id });
      this.priceSheetForm.patchValue({ ['currencyLabelName']: defaultCurrency.name });
      this.priceSheet.currency = defaultCurrency;
      this.isFormChanged = false;
    }
    else {
      this.snack.openSnackBar("Default Currency is missing from Settings", '', true, 'Error', 'alert-danger');
    }
  }
}
