<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">  
  Quantity Breaks</div>

<kendo-grid
  #grid="kendoGrid"
  [data]="gridView"
  [pageSize]="query.PageSize"
  [skip]="skip"
  [sort]="query.SortTerm"
  [reorderable]="true"
  [loading]="loading"
  [pageable]="true"
  (pageChange)="pageChange($event)"
  [filter]="filter"
  [filterable]="filterable"
  (filterChange)="gridFilterChange($event)"
  [sortable]="true"
  [sort]="sort"
  (sortChange)="sortChange($event)"
  [selectable]="{ mode: 'multiple' }"
  kendoGridSelectBy="id"
  [selectedKeys]="mySelection"
  (cellClick)="onCellClick($event)"
  (dblclick)="onDblClick()"
            [height]="300"
>
  <ng-template kendoGridToolbarTemplate style="padding-right: 500">
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" id="btnAddQtyBreak" class="showTooltip" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!this.priceSheetDetailID" imageUrl="../../../../assets/images/Add.png" (click)="onCreate()" title="Save the record above to be able to start adding these records here.">Add New</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()" title="Edit Selected">Edit</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>  
  <kendo-grid-column field="quantityFromCount" title="Quantity From" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}" width="50"></kendo-grid-column>
  <kendo-grid-column field="priceAmount" format="##############.00000" title="Price" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}" width="50"></kendo-grid-column>
</kendo-grid>
