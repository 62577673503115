import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { SyncReadersService } from '../../../services/sync-readers/sync-readers.service';
import { SyncReader, emptySyncReader } from '../../../services/sync-readers/sync-readers.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ActivatedRoute, Router } from '@angular/router';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { SyncReaderColumnSubListComponent } from '../../sync-reader-columns/sync-reader-column-sublist/sync-reader-column-sublist.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CopyService } from '../../../services/copy/copy.service';
import { Setting } from '../../../services/settings/settings.model';
import { SettingsService } from '../../../services/settings/settings.service';
import { emptySyncConnector, SyncConnector } from '../../../services/sync-connectors/sync-connectors.model';
import { SyncConnectorsService } from '../../../services/sync-connectors/sync-connectors.service';
import { SyncConnectorLookupComponent } from '../../sync-connectors/sync-connector-lookup/sync-connector-lookup.component';
import { emptyReport, Report } from '../../../services/reports/reports.model';
import { ReportsService } from '../../../services/reports/reports.service';
import { ReportLookupComponent } from '../../reports/report-lookup/report-lookup.component';
import { SyncReaderPreviewComponent } from '../sync-reader-preview/sync-reader-preview.component';

enum Filters {
  SyncConnector = 1,
  Report = 2
}

@Component({
  selector: 'app-sync-reader-single',
  templateUrl: './sync-reader-single.component.html',
  styleUrls: ['./sync-reader-single.component.scss'],
})
export class SyncReaderSingleComponent implements OnInit {
  @ViewChild('subgrid') syncReaderColumnSubGrid: SyncReaderColumnSubListComponent;
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncReader: number | null = null;
  @Input() syncReader: SyncReader = emptySyncReader;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  filters = Filters;
  myResponse: string;
  syncReaderForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  entryTime: Date = new Date();
  sinceTime: Date = new Date();
  startOn: Date = new Date();
  public isAdmin: boolean;
  goBackForce = false;
  nextURL = '';
  settings: Setting[] = [];
  settingsRetrieved: boolean = false;
  syncConnectors: SyncConnector[] = [];
  selectedSyncConnector: SyncConnector = emptySyncConnector;
  currentAuthType: number;
  reports: Report[] = [];
  selectedReport: Report = emptyReport;

  constructor(
    private route: ActivatedRoute,
    private syncReadersService: SyncReadersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private snack: MySnackBarService,
    private router: Router,
    private copyService: CopyService,
    private settingsService: SettingsService,
    private syncConnectorService: SyncConnectorsService,
    private reportService: ReportsService,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.syncReader = emptySyncReader;
        this.idOfSyncReader = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfSyncReader = params.id;
      this.loadData();
    });
    this.buildForm();
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (this.idOfSyncReader) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncReaderForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      syncConnectorID: new FormControl('', Validators.required),
      query: new FormControl(''),
      reportID: new FormControl(''),
      filename: new FormControl(''),
      pageSize: new FormControl(1000, Validators.required)
    });
    this.syncReaderForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.syncReaderForm.get('syncConnectorID').valueChanges.subscribe((newValue) => {
      this.onSyncConnectorIDChange(newValue);
    });
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMSyncReader", this.syncReader.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  //async onClickPreview2() {
  //  var previewURL: string;
  //  var baseURL: string;
  //  if (this.currentBaseURL) {
  //    previewURL = this.currentBaseURL + this.syncReader.query + '&$top=5';
  //    const width = 600;
  //    const height = 400;
  //    const left = (window.screen.width / 2) - (width / 2);
  //    const top = (window.screen.height / 2) - (height / 2);
  //    const windowFeatures = `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,left=${left},top=${top}`;
  //    window.open(previewURL, '_blank', windowFeatures);
  //  }
  //  else {
  //    this.snack.openSnackBar("Base URL not found on Sync Connector.", '', true, 'Error', 'alert-danger');
  //  }
  //}

  async onClickPreview() {
    if (this.idOfSyncReader) {
      const modalRef = this.modalService.open(SyncReaderPreviewComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.idOfSyncReader = this.idOfSyncReader;
    }
    else {
      this.snack.openSnackBar(
        'Unable to retrieve Preview.',
        '',
        false,
        'Info',
        'alert-info',
      );
    }
  }  

  async getSettings() {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const params = { PageSize };
      const resp = await this.settingsService.getList(params);
      this.settings = resp.body;
      this.settingsRetrieved = true;
    } catch (e) {
      this.settings = [];
    }
    return this.settings;
  }

  get disableDeleteBtn() {
    if (this.syncReader && this.syncReader.id) {
      return false;
    }
    else {
      return true;
    }
  }

  get f() {
    return this.syncReaderForm.controls;
  }
  updateForm() {
    this.syncReaderForm.patchValue({ ...this.syncReader });
    this.isFormChanged = false;
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  //onClick_Close(message) {
  //  if (this.isFormChanged) {
  //    this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
  //    this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
  //    this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
  //  } else {
  //    this.activeModal.close(message);
  //  }
  //}

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/sync-readers/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/sync-readers/new']);
      });
    }
  }

  onClick_Save() {
    this.saveRecord(null);
  }

  onClick_SaveClose() {
    this.saveRecord("Close");
  }

  onClick_SaveNew() {
    this.saveRecord("New");
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.syncReader.id);
    await this.syncReadersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/sync-readers']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async saveRecord(action) {
    this.syncReaderForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncReaderForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncReader,
      ...this.syncReaderForm.value,
    };
    if (this.syncReader.id) {
      try {
        const response: any = await this.syncReadersService.update(
          this.syncReader.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-readers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-readers/new']);
            });
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.syncReadersService.create(
          this.syncReaderForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-readers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-readers/new']);
            });
          }
          else {
            this.syncReader = response.body;
            this.router.navigate(['/price/sync-readers/', this.syncReader.id]);
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);

  }

  async getSyncReaderById() {
    if (this.idOfSyncReader) {
      return await this.syncReadersService.getById(
        this.idOfSyncReader,
      );
    } else {
      return emptySyncReader;
    }
  }

  async getSyncConnectorById(id) {
    return await this.syncConnectorService.getById(id);
  }

  async getReportById(id) {
    return await this.reportService.getById(id);
  }

  async onLookup_SyncConnector() {
    const modalRef = this.modalService.open(SyncConnectorLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: SyncConnector = receivedEntry;
      this.syncConnectors = [];
      this.syncConnectors.push(recordSelected);
      this.syncReaderForm.patchValue({ ['syncConnectorID']: receivedEntry.id });
      this.syncReader.syncConnector = receivedEntry;
      this.selectedSyncConnector = receivedEntry;
      //this.syncReader.syncConnectorID = receivedEntry.id;
      this.currentAuthType = recordSelected.authTypeID;
      modalRef.close('test');
    });
  }

  async onLookup_Report() {
    const modalRef = this.modalService.open(ReportLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Report = receivedEntry;
      this.reports = [];
      this.reports.push(recordSelected);
      this.syncReaderForm.patchValue({ ['reportID']: receivedEntry.id });
      this.syncReader.report = receivedEntry;
      this.selectedReport = receivedEntry;
      //this.syncReader.reportID = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async loadData() {
    try {
      [this.syncReader] = await Promise.all([
        this.getSyncReaderById(),
      ]);
      if (this.syncReader.syncConnectorID) {
        const currentSyncConnector: SyncConnector = await Promise.resolve(this.getSyncConnectorById(this.syncReader.syncConnectorID));
        this.syncConnectors.push(currentSyncConnector);
        this.currentAuthType = currentSyncConnector.authTypeID;
      }
      if (this.syncReader.reportID) {
        const currentReport: Report = await Promise.resolve(this.getReportById(this.syncReader.reportID));
        this.reports.push(currentReport);
      }
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  async onSyncConnectorIDChange(newValue: any) {
    console.log('syncConnectorID changed to:', newValue);
    const currentSyncConnector: SyncConnector = await Promise.resolve(this.getSyncConnectorById(newValue));
    this.currentAuthType = currentSyncConnector.authTypeID;
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.SyncConnector:
        this.syncConnectors = [];
        this.syncConnectors = await (await this.syncConnectorService.getList(params))
          .body;
        break;
      case Filters.Report:
        this.reports = [];
        this.reports = await (await this.reportService.getList(params))
          .body;
        break;
      default:
        break;
    }
  }

  onSelectBoxChanged(id: number, key: keyof SyncReader, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
