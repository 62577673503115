export interface SyncWriter {
  id: number;
  name: string;
  description: string;
  syncConnectorID: number;
  syncConnector: {
    name: string,
    id: number,
    authTypeID: number
  };
  query: string;
  storedProcName: string;
  filename: string;
}

export const emptySyncWriter: SyncWriter = {
  id: null,
  name: '',
  description: '',
  syncConnectorID: null,
  syncConnector: {
    name: '',
    id: null,
    authTypeID: null
  },
  query: '',
  storedProcName: '',
  filename: ''
};
