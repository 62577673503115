export interface SyncMap {
  id: number;
  name: string;
  description: string;
  syncSetID: number;
  syncSet: {
    name: string,
    id: number
  };
  triggeringEntityID: number;
  triggeringEntity: {
    name: string,
    id: number
  };
  rank: number;
  syncReaderID: number;
  syncReader: {
    name: string,
    id: number
  };
  syncWriterID: number;
  syncWriter: {
    name: string,
    id: number
  };
  isActive: boolean;
  filter: string;
  singleRecordFilter: string;
  createdOnFilter: string;
  modifiedOnFilter: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptySyncMap: SyncMap = {
  id: null,
  name: '',
  description: '',  
  syncSetID: null,
  syncSet: {
    name: '',
    id: null
  },
  triggeringEntityID: null,
  triggeringEntity: {
    name: '',
    id: null
  },
  rank: null,
  syncReaderID: null,
  syncReader: {
    name: '',
    id: null
  },
  syncWriterID: null,
  syncWriter: {
    name: '',
    id: null
  },
  isActive: false,
  filter: '',
  singleRecordFilter: '',
  createdOnFilter: '',
  modifiedOnFilter: '',
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
