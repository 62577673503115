import { PriceCalculation } from 'src/app/services/price-calculations/price-calculations.model';
import { uniqBy } from 'lodash';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { UofM } from 'src/app/services/uofms/uofms.model';
import { GLAccount } from 'src/app/services/glaccounts/glaccounts.model';
import { RoundingRule } from 'src/app/services/rounding-rules/rounding-rules.model';
import { PricingFilter } from 'src/app/services/pricing-filters/pricing-filters.model';
import { PricingCondition } from 'src/app/services/pricing-conditions/pricing-conditions.model';
export function prepareData(
  priceCalculation: PriceCalculation,
  debitGLAccounts: GLAccount[],
  creditGLAccounts: GLAccount[],
  roundingRules: RoundingRule[],
  pricingFilters: PricingFilter[],
  pricingConditions: PricingCondition[],
  priceModeOptions: Lookup[],
) {
  try {
    if (priceCalculation.debitGLAccount) {
      debitGLAccounts = uniqBy(
        [...debitGLAccounts, { ...priceCalculation.debitGLAccount }],
        'id',
      );
    }
    if (priceCalculation.creditGLAccount) {
      creditGLAccounts = uniqBy(
        [...creditGLAccounts, { ...priceCalculation.creditGLAccount }],
        'id',
      );
    }
    if (priceCalculation.roundingRule) {
      roundingRules = uniqBy(
        [...roundingRules, { ...priceCalculation.roundingRule }],
        'id',
      );
    }
    if (priceCalculation.pricingFilter) {
      pricingFilters = uniqBy(
        [...pricingFilters, { ...priceCalculation.pricingFilter }],
        'id',
      );
    }
    if (priceCalculation.priceModeOptionID) {
      priceModeOptions = uniqBy(
        [...priceModeOptions, { ...priceCalculation.priceModeOption }],
        'id',
      );
    }
    return [
      debitGLAccounts,
      creditGLAccounts,
      roundingRules,
      pricingFilters,
      pricingConditions,
      priceModeOptions,
    ];
  } catch (e) {
    console.error(e);
  }
}
