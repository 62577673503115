import { Routes, RouterModule } from '@angular/router';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { BoolToHumanPipe } from './pipes/bool-to-human.pipe';
import { SidenavComponent} from './side-nav/sidenav.component';
import { MySnackbarComponent} from './snackbar/my-snackbar.component';
import { MatSnackBarModule, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TrashIconComponent } from './trash-icon/trash-icon.component';
import { PenIconComponent } from './pen-icon/pen-icon.component';
import { SortArrowsComponent } from './sort-arrows/sort-arrows.component';
import { TableHeaderSearchComponent } from './table-header-search/table-header-search.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { DndComponent } from './dnd/dnd.component';
import { DndModule } from 'ngx-drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OnlyActivePipe } from './pipes/only-active.pipe';
import { UnsavedChangesModalComponent } from './unsaved-changes-modal/unsaved-changes-modal.component';
import { DeleteConfirmationComponent } from './delete-confirmation/delete-confirmation.component';
import { ExportConfirmationComponent } from './export-confirmation/export-confirmation.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PanelBarModule, DrawerModule } from '@progress/kendo-angular-layout';
import { DialogsModule } from '@progress/kendo-angular-dialog';

import { PriceRoutingModule } from '../price/price-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    BoolToHumanPipe,
    TrashIconComponent,
    PenIconComponent,
    SortArrowsComponent,
    TableHeaderSearchComponent,
    TableHeaderComponent,
    DndComponent,
    OnlyActivePipe,
    UnsavedChangesModalComponent,
    SidenavComponent,
    MySnackbarComponent,
    DeleteConfirmationComponent,
    ExportConfirmationComponent
  ],
  imports: [
    CommonModule,
    DndModule,
    NgbModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatSidenavModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    PriceRoutingModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    PanelBarModule,
    DrawerModule,
    DialogsModule
     ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SpinnerComponent,
    BoolToHumanPipe,
    TrashIconComponent,
    PenIconComponent,
    SortArrowsComponent,
    TableHeaderSearchComponent,
    TableHeaderComponent,
    DndComponent,
    OnlyActivePipe,
    UnsavedChangesModalComponent,
    SidenavComponent,
    DeleteConfirmationComponent,
    ExportConfirmationComponent
  ]
})
export class SharedModule {}
