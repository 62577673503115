import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PricingScenarioDocumentValuesService } from '../../../services/pricing-scenario-document-values/pricing-scenario-document-values.service';
import {  PricingScenarioDocumentValue,  emptyPricingScenarioDocumentValue} from '../../../services/pricing-scenario-document-values/pricing-scenario-document-values.model';
import {  FormGroup,  FormControl,  Validators} from '@angular/forms';
import { debounce } from 'lodash';
import {  getPaginationHeader,  mockedData} from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { DocumentAttribute } from 'src/app/services/document-attributes/document-attributes.model';
import { DocumentAttributesService } from 'src/app/services/document-attributes/document-attributes.service';
import { DocumentAttributeListComponent } from '../../document-attributes/document-attribute-list/document-attribute-list.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';

enum Filters {
  DocumentAttribute = 1
}
@Component({
  selector: 'app-pricing-scenario-document-value-single',
  templateUrl: './pricing-scenario-document-value-single.component.html',
  styleUrls: ['./pricing-scenario-document-value-single.component.scss'],
})
export class PricingScenarioDocumentValueSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfPricingScenarioDocumentValue: number | null = null;
  @Input() idOfPricingScenario: number | null = null;
  pricingScenarioDocumentValue: PricingScenarioDocumentValue = emptyPricingScenarioDocumentValue;
  loading: boolean = true;
  pricingScenarioDocumentValueForm: FormGroup;
  submitted = false;
  documentAttributes: DocumentAttribute[] = [];
  pagination_documentAttributes: any = { documentAttributes: mockedData };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';

  constructor(
    private route: ActivatedRoute,
    private pricingScenarioDocumentValuesService: PricingScenarioDocumentValuesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private documentAttributesService: DocumentAttributesService,
    private snack: MySnackBarService,
    private router: Router,
    private notificationHelper: NotificationHelper 
  ) {
    this.documentAttributeSearch = debounce(this.documentAttributeSearch, 400, {});   
  }

  ngOnInit(): void {

    if (this.idOfPricingScenarioDocumentValue) {
      this.loadData();
    }
    if (this.idOfPricingScenario) {
      this.pricingScenarioDocumentValue.pricingScenarioID = this.idOfPricingScenario;
    }
    this.buildForm();
    this.updateForm();
  }
  buildForm() {
    this.pricingScenarioDocumentValueForm = new FormGroup({
      pricingScenarioID: new FormControl(''),
      documentAttributeID: new FormControl('', [Validators.required]),
      value: new FormControl('', [Validators.required])
    });
    this.pricingScenarioDocumentValueForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.pricingScenarioDocumentValueForm.controls;
  }
  updateForm() {
    this.pricingScenarioDocumentValueForm.patchValue({ ...this.pricingScenarioDocumentValue });
    this.isFormChanged = false;
  }

  onSelectBoxChanged(id: number, key: keyof PricingScenarioDocumentValue, arr) {
    let item = null;
    console.log('key: ' + key);
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.pricingScenarioDocumentValue[key] = item;
  }

  private IsPricingScenarioDocumentValueUnique(): boolean {
    // TODO: need to code stored proc here
    const isPricingScenarioDocumentValueUnique: boolean = false;

    return isPricingScenarioDocumentValueUnique
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.pricingScenarioDocumentValueForm.markAllAsTouched();
    this.submitted = true;
    if (this.pricingScenarioDocumentValueForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.pricingScenarioDocumentValue,
      ...this.pricingScenarioDocumentValueForm.value,
    };

    // TODO: Call stored proc above to check that PSD is unique.

    if (this.pricingScenarioDocumentValue.id) {
      try {
        const response: any = await this.pricingScenarioDocumentValuesService.update(
          this.pricingScenarioDocumentValue.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.pricingScenarioDocumentValuesService.create(
          this.pricingScenarioDocumentValueForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.pricingScenarioDocumentValue = response.body;
          this.idOfPricingScenarioDocumentValue = this.pricingScenarioDocumentValue.id;
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }
  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  async getPricingScenarioDocumentValueById() {
    if (this.idOfPricingScenarioDocumentValue) {
      return await this.pricingScenarioDocumentValuesService.getById(
        this.idOfPricingScenarioDocumentValue,
      );
    } else {
      return emptyPricingScenarioDocumentValue;
    }
  }

  async getDocumentAttributeById(id) {
    return await this.documentAttributesService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfPricingScenarioDocumentValue) {
        this.pricingScenarioDocumentValue = await Promise.resolve(this.getPricingScenarioDocumentValueById());
      }
      if (this.pricingScenarioDocumentValue.documentAttributeID) {
        const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.getDocumentAttributeById(this.pricingScenarioDocumentValue.documentAttributeID));
        this.documentAttributes.push(currentDocumentAttribute);
      }

      this.updateForm();
    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }

    this.onFormChanged.emit(false);
  }

  async documentAttributeSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getDocumentAttributes({ SearchTerm });
  }

  async onLookup_DocumentAttribute() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, { });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes = [];
      this.documentAttributes.push(recordSelected);
      this.pricingScenarioDocumentValueForm.patchValue({ ['documentAttributeID']: receivedEntry.id });
      this.pricingScenarioDocumentValue.documentAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.DocumentAttribute:
        // @ts-ignore
        this.documentAttributes = this.getDocumentAttributes(params);
        break;
      default:
        break;
    }
  }


  async getDocumentAttributes(params) {
    try {
      const resp = await this.documentAttributesService.getList({ ...params });
      this.documentAttributes = resp.body;
      this.pagination_documentAttributes = getPaginationHeader(resp.headers);
    } catch (e) {
      this.documentAttributes = [];
    }
    return this.documentAttributes;
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
 
}
