import { PricingFilterValue } from 'src/app/services/pricing-filter-values/pricing-filter-values.model';
import { uniqBy } from 'lodash';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { CustomerAttribute } from 'src/app/services/customer-attributes/customer-attributes.model';
import { DocumentAttribute } from 'src/app/services/document-attributes/document-attributes.model';
import { ItemAttribute } from 'src/app/services/item-attributes/item-attributes.model';
export function prepareData(
  pricingFilterValue: PricingFilterValue,
  customerAttributes : CustomerAttribute[],
  documentAttributes : DocumentAttribute[],
  itemAttributes : ItemAttribute[],
  operandOptions: Lookup[]  
) {
  try {
    if (pricingFilterValue.customerAttribute) {
      customerAttributes = uniqBy(
        [...customerAttributes, { ...pricingFilterValue.customerAttribute }],
        'id',
      );
    }
    if (pricingFilterValue.documentAttribute) {
      documentAttributes = uniqBy(
        [...documentAttributes, { ...pricingFilterValue.documentAttribute }],
        'id',
      );
    }
    if (pricingFilterValue.itemAttribute) {
      itemAttributes = uniqBy(
        [...itemAttributes, { ...pricingFilterValue.itemAttribute }],
        'id',
      );
    }
    if (pricingFilterValue.operand) {
      operandOptions = uniqBy(
        [...operandOptions, { ...pricingFilterValue.operand }],
        'id',
      );
    }
    return [
      itemAttributes,
      operandOptions
    ];
  } catch (e) {
    console.error(e);
  }
}
