import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { SyncSetsService } from '../../../services/sync-sets/sync-sets.service';
import { SyncSet, emptySyncSet } from '../../../services/sync-sets/sync-sets.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ActivatedRoute, Router } from '@angular/router';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { SyncSetEntitySubListComponent } from '../../sync-set-entities/sync-set-entity-sublist/sync-set-entity-sublist.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CopyService } from '../../../services/copy/copy.service';
import { Setting } from '../../../services/settings/settings.model';
import { SettingsService } from '../../../services/settings/settings.service';
import { emptySyncConnector, SyncConnector } from '../../../services/sync-connectors/sync-connectors.model';
import { SyncConnectorsService } from '../../../services/sync-connectors/sync-connectors.service';
import { SyncConnectorLookupComponent } from '../../sync-connectors/sync-connector-lookup/sync-connector-lookup.component';
import { emptyReport, Report } from '../../../services/reports/reports.model';

enum Filters {
  readerSyncConnector = 1,
  writerSyncConnector = 2,
}

@Component({
  selector: 'app-sync-set-single',
  templateUrl: './sync-set-single.component.html',
  styleUrls: ['./sync-set-single.component.scss'],
})
export class SyncSetSingleComponent implements OnInit {
  @ViewChild('subgrid') syncSetColumnSubGrid: SyncSetEntitySubListComponent;
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncSet: number | null = null;
  @Input() syncSet: SyncSet = emptySyncSet;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  filters = Filters;
  myResponse: string;
  syncSetForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  entryTime: Date = new Date();
  sinceTime: Date = new Date();
  startOn: Date = new Date();
  public isAdmin: boolean;
  goBackForce = false;
  nextURL = '';
  settings: Setting[] = [];
  settingsRetrieved: boolean = false;
  readerSyncConnectors: SyncConnector[] = [];
  writerSyncConnectors: SyncConnector[] = [];
  reports: Report[] = [];
  selectedReport: Report = emptyReport;

  constructor(
    private route: ActivatedRoute,
    private syncSetsService: SyncSetsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper,
    private snack: MySnackBarService,
    private router: Router,
    private copyService: CopyService,
    private settingsService: SettingsService,
    private syncConnectorService: SyncConnectorsService,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.syncSet = emptySyncSet;
        this.idOfSyncSet = null;
        this.submitted = false;
        return;
      }
      this.idOfSyncSet = params.id;
    });
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (this.idOfSyncSet) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncSetForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      readerSyncConnectorID: new FormControl('', Validators.required),
      writerSyncConnectorID: new FormControl('', Validators.required),
      isDefault: new FormControl(''),
    });
    this.syncSetForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMSyncSet", this.syncSet.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async getSettings() {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const params = { PageSize };
      const resp = await this.settingsService.getList(params);
      this.settings = resp.body;
      this.settingsRetrieved = true;
    } catch (e) {
      this.settings = [];
    }
    return this.settings;
  }
  get disableDeleteBtn() {
    if (this.syncSet && this.syncSet.id) {
      return false;
    }
    else {
      return true;
    }
  }
  get f() {
    return this.syncSetForm.controls;
  }

  updateForm() {
    this.syncSetForm.patchValue({ ...this.syncSet });
    this.isFormChanged = false;
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/sync-sets/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/sync-sets/new']);
      });
    }
  }

  onClick_Save() {
    this.saveRecord(null);
  }

  onClick_SaveClose() {
    this.saveRecord("Close");
  }

  onClick_SaveNew() {
    this.saveRecord("New");
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.syncSet.id);
    await this.syncSetsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/sync-sets']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async saveRecord(action) {
    this.syncSetForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncSetForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncSet,
      ...this.syncSetForm.value,
    };
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar('Sync Set Name already exists', '', true, 'Error', 'alert-danger',);
      return;
    }
    if ((await this.checkForExistingDefault()) === true) {
      this.snack.openSnackBar('Another Sync Set is already marked as Default', '', true, 'Error', 'alert-danger',);
      return;
    }
    if (this.syncSet.id) {
      try {
        const response: any = await this.syncSetsService.update(
          this.syncSet.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-sets']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-sets/new']);
            });
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.syncSetsService.create(
          this.syncSetForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-sets']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-sets/new']);
            });
          }
          else {
            this.syncSet = response.body;
            this.router.navigate(['/price/sync-sets/', this.syncSet.id]);
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false)
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.syncSetForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingSyncSets: SyncSet[] = await (await this.syncSetsService.getList(params)).body;
    if (existingSyncSets.length > 0) {
      if (this.syncSet.id) {
        for (const key in existingSyncSets) {
          const currentSyncSet: SyncSet = existingSyncSets[key];
          if (currentSyncSet.id !== this.syncSet.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async checkForExistingDefault() {
    var defaultExists: boolean = false;
    if (this.syncSetForm.controls['isDefault'].value == true) {
      const PageSize = StorageService.PageSize() ?? 50;
      const Filter_IsDefault = true;
      //const Operand_Name = 'eq';
      const params = { Filter_IsDefault, PageSize };
      const existingSyncSets: SyncSet[] = await (await this.syncSetsService.getList(params)).body;
      if (existingSyncSets.length > 0) {
        if (this.syncSet.id) {
          for (const key in existingSyncSets) {
            const currentSyncSet: SyncSet = existingSyncSets[key];
            if (currentSyncSet.id !== this.syncSet.id) {
              defaultExists = true;
            }
          }
        } else {
          defaultExists = true;
        }
      }
    }
    return defaultExists;
  }

  async getSyncSetById() {
    if (this.idOfSyncSet) {
      return await this.syncSetsService.getById(
        this.idOfSyncSet,
      );
    } else {
      return emptySyncSet;
    }
  }

  async getSyncConnectorById(id) {
    return await this.syncConnectorService.getById(id);
  }

  async onLookup_ReaderSyncConnector() {
    const modalRef = this.modalService.open(SyncConnectorLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: SyncConnector = receivedEntry;
      this.readerSyncConnectors = [];
      this.readerSyncConnectors.push(recordSelected);
      this.syncSetForm.patchValue({ ['readerSyncConnectorID']: receivedEntry.id });
      this.syncSet.readerSyncConnector = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_WriterSyncConnector() {
    const modalRef = this.modalService.open(SyncConnectorLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: SyncConnector = receivedEntry;
      this.writerSyncConnectors = [];
      this.writerSyncConnectors.push(recordSelected);
      this.syncSetForm.patchValue({ ['writerSyncConnectorID']: receivedEntry.id });
      this.syncSet.writerSyncConnector = receivedEntry;
      modalRef.close('test');
    });
  }

  async loadData() {
    try {
      [this.syncSet] = await Promise.all([
        this.getSyncSetById(),
      ]);
      if (this.syncSet.readerSyncConnectorID) {
        const currentSyncConnector: SyncConnector = await Promise.resolve(this.getSyncConnectorById(this.syncSet.readerSyncConnectorID));
        this.readerSyncConnectors.push(currentSyncConnector);
      }
      if (this.syncSet.writerSyncConnectorID) {
        const currentSyncConnector: SyncConnector = await Promise.resolve(this.getSyncConnectorById(this.syncSet.writerSyncConnectorID));
        this.writerSyncConnectors.push(currentSyncConnector);
      }
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.readerSyncConnector:
        this.readerSyncConnectors = [];
        this.readerSyncConnectors = await (await this.syncConnectorService.getList(params))
          .body;
        break;
      case Filters.writerSyncConnector:
        this.writerSyncConnectors = [];
        this.writerSyncConnectors = await (await this.syncConnectorService.getList(params))
          .body;
        break;
      default:
        break;
    }
  }

  onSelectBoxChanged(id: number, key: keyof SyncSet, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
