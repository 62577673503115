import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Lookup } from './lookups.model';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LookupsService {
  model = 'RPMLookupValues';
  constructor(private httpClient: HttpClient) { }
  getList(params = {}): Promise<Lookup[]> {
    //return this.httpClient.get(this.model, { params }).toPromise() as Promise< Lookup[] >;
    return this.httpClient.get(this.model, { params })
      .pipe(timeout(environment.requestTimeout))
      .toPromise()
      .then((data: Lookup[]) => {
        return data.sort((a, b) => a.sortOrder - b.sortOrder);
      }) as Promise<Lookup[]>;
  }
  getById(id: number): Promise<Lookup> {
    return this.httpClient.get(`${this.model}/${id}`)
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<Lookup>;
  }
}
