import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AttributeMapsService } from '../../../services/attribute-maps/attribute-maps.service';
import { AttributeMap } from '../../../services/attribute-maps/attribute-maps.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { StorageService } from '../../../../app/utils/StorageHelper';

@Component({
  selector: 'app-attribute-map-lookup',
  templateUrl: './attribute-map-lookup.component.html',
  styleUrls: ['./attribute-map-lookup.component.scss'],
})
export class AttributeMapLookupComponent implements OnInit {
  constructor(
    private attributeMapsService: AttributeMapsService, 
    public activeModal: NgbActiveModal
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() attributeMaps: AttributeMap[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: AttributeMap[];
  public exportAll: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];

  ngOnInit(): void {
    this.loadListData();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }
  onClick_Close(message) {
    this.activeModal.close(message);
  }

  onClick_Save() {
    this.onDblClick();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onSelectSingle.emit(this.clickedRowItem);
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.attributeMapsService.getList(this.query);
      this.attributeMaps = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.attributeMaps,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
