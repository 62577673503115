
<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/SyncQueue.png" alt="Sync Queue Logs" style="padding-right: 5px;">
  Sync Queue Logs
</div>

<form [formGroup]="syncQueueForm">
  <div class="row">
    <div class="col form-group col-3">
      <kendo-formfield>
        <kendo-label for="entryTime" text="Entry Time"></kendo-label>
        <kendo-textbox class="form-control" formControlName="entryTime" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="col form-group col-3">
      <kendo-formfield>
        <kendo-label for="syncSet" text="Sync Set"></kendo-label>
        <kendo-textbox class="form-control" formControlName="syncSet" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="col form-group col-3">
      <kendo-formfield>
        <kendo-label for="queueType.name" text="Queue Type"></kendo-label>
        <kendo-textbox class="form-control" formControlName="queueType" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="col form-group col-3">
      <kendo-formfield>
        <kendo-label for="entity" text="Entity"></kendo-label>
        <kendo-textbox class="form-control" formControlName="entity" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="card p-3" style="background-color: rgb(234, 234, 234); min-height: 500px;">
    <app-system-job-log-list #subgrid [syncQueueID]='idOfSyncQueue' mode="view"
                             [pagination]="paginationObject_SystemJobLogs" [loading]="loading"
                             class="SystemJobLogSubgridClass">
    </app-system-job-log-list>
  </div>

</form>

