import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttributeMapsService } from '../../../services/attribute-maps/attribute-maps.service';
import { AttributeMap, emptyAttributeMap} from '../../../services/attribute-maps/attribute-maps.model';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl} from '@angular/forms';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute } from 'src/app/services/customer-attributes/customer-attributes.model';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { DocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { AttributeMapValueListComponent } from '../../attribute-map-values/attribute-map-value-list/attribute-map-value-list.component';
import { AttributeMapValue } from 'src/app/services/attribute-map-values/attribute-map-values.model';
import { AttributeMapValuesService } from 'src/app/services/attribute-map-values/attribute-map-values.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../../../services/settings/settings.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { Location } from '@angular/common';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { CopyService } from '../../../services/copy/copy.service';

enum Filters {
  Name = 1,
  EntityType1,
  CustomerAttribute1,
  ItemAttribute1,
  DocumentAttribute1,
  EntityType2,
  CustomerAttribute2,
  ItemAttribute2,
  DocumentAttribute2,
  EntityType3,
  CustomerAttribute3,
  ItemAttribute3,
  DocumentAttribute3
}
@Component({
  selector: 'app-attribute-map-single',
  templateUrl: './attribute-map-single.component.html',
  styleUrls: ['./attribute-map-single.component.scss'],
})
export class AttributeMapSingleComponent implements OnInit {
  @ViewChild('subgrid') AttributeMapValuesSubGrid: AttributeMapValueListComponent;

  filters = Filters;
  idOfAttributeMap: number | null = null;
  attributeMap: AttributeMap = emptyAttributeMap;
  loading: boolean = true;
  attributeMapForm: FormGroup;
  paginationObject: any = { AttributeMapValues: mockedData };
  subgridmode = 'subgrid';
  attributeMapValues: AttributeMapValue[] = [];
  paginationObject_AttributeMapValues: any = { attributeMapValues: mockedData };

  entityTypes1: Lookup[] = [];
  customerAttributes1: CustomerAttribute[] = [];
  itemAttributes1: ItemAttribute[] = [];
  documentAttributes1: DocumentAttribute[] = [];
  paginationObject_EntityTypes1: any = { entityTypes1: mockedData };
  paginationObject_CustomerAttributes1: any = { customerAttributes1: mockedData };
  paginationObject_ItemAttributes1: any = { itemAttributes1: mockedData };
  paginationObject_DocumentAttributes1: any = { documentAttributes1: mockedData };
  attribute1ColumnName: string = 'Attribute 1 Value'

  entityTypes2: Lookup[] = [];
  customerAttributes2: CustomerAttribute[] = [];
  itemAttributes2: ItemAttribute[] = [];
  documentAttributes2: DocumentAttribute[] = [];
  paginationObject_EntityTypes2: any = { entityTypes2: mockedData };
  paginationObject_CustomerAttributes2: any = { customerAttributes2: mockedData };
  paginationObject_ItemAttributes2: any = { itemAttributes2: mockedData };
  paginationObject_DocumentAttributes2: any = { documentAttributes2: mockedData };
  attribute2ColumnName: string = 'Attribute 2 Value'

  entityTypes3: Lookup[] = [];
  customerAttributes3: CustomerAttribute[] = [];
  itemAttributes3: ItemAttribute[] = [];
  documentAttributes3: DocumentAttribute[] = [];
  paginationObject_EntityTypes3: any = { entityTypes3: mockedData };
  paginationObject_CustomerAttributes3: any = { customerAttributes3: mockedData };
  paginationObject_ItemAttributes3: any = { itemAttributes3: mockedData };
  paginationObject_DocumentAttributes3: any = { documentAttributes3: mockedData };
  attribute3ColumnName: string = 'Attribute 3 Value'

  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();

  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';

  constructor(
    private route: ActivatedRoute,
    private attributeMapsService: AttributeMapsService,
    private attributeMapValuesService: AttributeMapValuesService,
    private customerAttributesService: CustomerAttributesService,
    private itemAttributesService: ItemAttributesService,
    private documentAttributesService: DocumentAttributesService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private location: Location,
    private router: Router,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService,
    private lookupsService: LookupsService,

  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.attributeMap = emptyAttributeMap;
        this.idOfAttributeMap = null;
        this.submitted = false;
        this.attributeMap.attributeCount = 1;
        this.attributeMap.entityType1ID = 75;
        this.f.customerAttribute1ID.setValidators(Validators.required);
        //this.updateForm();
        //this.buildForm();
        return;
      }
      else {
        this.idOfAttributeMap = params.id;
        this.loadData();
      }
    });

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.AttributeMapValuesSubGrid.gridClassName = "AttributeMapValueSubgridClass"
        }
        else {
          this.AttributeMapValuesSubGrid.gridClassName = "AttributeMapValueFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadData();
  }

  ngAfterViewChecked() {
    this.updateColumnNames();
  }

  buildForm() {
    this.attributeMapForm = new FormGroup({
      name: new FormControl('', Validators.required),
      attributeCount: new FormControl(1, Validators.required), 
      defaultMapAmount: new FormControl(),
      description: new FormControl(''),
      entityType1ID: new FormControl(75, Validators.required), //default to Cust Attrib
      customerAttribute1ID: new FormControl(null, Validators.required),
      itemAttribute1ID: new FormControl(),
      documentAttribute1ID: new FormControl(),
      entityType2ID: new FormControl(75), 
      customerAttribute2ID: new FormControl(),
      itemAttribute2ID: new FormControl(),
      documentAttribute2ID: new FormControl(),
      entityType3ID: new FormControl(75), 
      customerAttribute3ID: new FormControl(),
      itemAttribute3ID: new FormControl(),
      documentAttribute3ID: new FormControl(),
    });
    this.getEntityTypes();
    this.attributeMapForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.attributeMapForm.controls;
  }

  onClickClose(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMAttributeMap", this.attributeMap.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  updateForm() {
    this.attributeMapForm.patchValue({ ...this.attributeMap });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.attributeMap.id);
    await this.attributeMapsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/attribute-maps']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.attributeMap && this.attributeMap.id) {
      return false;
    }
    else {
      return true;
    }
  }
    
  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/attribute-map/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/attribute-map/new']);
      });
    }
  }

  onClickBack(): void {
      this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.attributeMapForm.controls['name'].value;
    const Operand_Name = 'eq';

    const params = { Filter_Name, Operand_Name, PageSize };
    const existingAttributeMaps: AttributeMap[] = await (
      await this.attributeMapsService.getList(params)
    ).body;
    if (existingAttributeMaps.length > 0) {
      if (this.attributeMap.id) {
        for (const key in existingAttributeMaps) {
          const currentAttributeMap: AttributeMap = existingAttributeMaps[key];
          if (currentAttributeMap.id !== this.attributeMap.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async submit(action) {
    this.attributeMapForm.markAllAsTouched();
    this.submitted = true;
    if (this.attributeMapForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.attributeMap,
      ...this.attributeMapForm.value,
    };
    // Check to see if Attribute Map Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Attribute Map Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.attributeMap.id) {
        try {
          const response: any = await this.attributeMapsService.update(
            this.attributeMap.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/attribute-maps']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/attribute-map/new'],);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.attributeMapsService.create(
            this.attributeMapForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.attributeMap = response.body;
            this.idOfAttributeMap = this.attributeMap.id;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/attribute-maps']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/attribute-map/new'],);
              });
            }
            else {
              this.attributeMap = response.body;
              this.router.navigate(['/price/attribute-map/', this.attributeMap.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
      this.onFormSaved.emit(false);
      this.onFormChanged.emit(false);
      console.log('Form Changed', this.isFormChanged)
    }
  }

  async loadData() {
    this.loading = true;
    try {
      //
      if (this.idOfAttributeMap) {
        // If this is an existing record we populate the values.
        this.attributeMap = await Promise.resolve(
          await this.attributeMapsService.getById(this.idOfAttributeMap));

        if (this.attributeMap.customerAttribute1ID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.attributeMap.customerAttribute1ID));
          this.customerAttributes1.push(currentCustomerAttribute);
          this.attributeMap.customerAttribute1 = currentCustomerAttribute;
        }
        if (this.attributeMap.itemAttribute1ID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.attributeMap.itemAttribute1ID)); 
          this.itemAttributes1.push(currentItemAttribute);
          this.attributeMap.itemAttribute1 = currentItemAttribute;
        }
        if (this.attributeMap.documentAttribute1ID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.attributeMap.documentAttribute1ID));
          this.documentAttributes1.push(currentDocumentAttribute);
          this.attributeMap.documentAttribute1 = currentDocumentAttribute;
        }

        if (this.attributeMap.customerAttribute2ID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.attributeMap.customerAttribute2ID));
          this.customerAttributes2.push(currentCustomerAttribute);
          this.attributeMap.customerAttribute2 = currentCustomerAttribute;
        }
        if (this.attributeMap.itemAttribute2ID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.attributeMap.itemAttribute2ID));
          this.itemAttributes2.push(currentItemAttribute);
          this.attributeMap.itemAttribute2 = currentItemAttribute;
        }
        if (this.attributeMap.documentAttribute2ID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.attributeMap.documentAttribute2ID));
          this.documentAttributes2.push(currentDocumentAttribute);
          this.attributeMap.documentAttribute2 = currentDocumentAttribute;
        }

        if (this.attributeMap.customerAttribute3ID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(await this.customerAttributesService.getById(this.attributeMap.customerAttribute3ID));
          this.customerAttributes3.push(currentCustomerAttribute);
          this.attributeMap.customerAttribute3 = currentCustomerAttribute;
        }
        if (this.attributeMap.itemAttribute3ID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.itemAttributesService.getById(this.attributeMap.itemAttribute3ID));
          this.itemAttributes3.push(currentItemAttribute);
          this.attributeMap.itemAttribute3 = currentItemAttribute;
        }
        if (this.attributeMap.documentAttribute3ID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.documentAttributesService.getById(this.attributeMap.documentAttribute3ID));
          this.documentAttributes3.push(currentDocumentAttribute);
          this.attributeMap.documentAttribute3 = currentDocumentAttribute;
        }
        //this.entityType1Change(this.attributeMap.entityType1ID || 75);
        //this.entityType2Change(this.attributeMap.entityType1ID || 75);
        //this.entityType3Change(this.attributeMap.entityType1ID || 75);

        this.updateForm();
      }
      this.isFormChanged = false;
    }
    catch (e) {
      console.log(e);
    }
    finally {
      this.loading = false;
    }
  }
  
  onSelectBoxChanged(id: number, key: keyof AttributeMap, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.attributeMap[key] = item;
    if (key == "entityType1ID") {
      this.entityType1Change(id);
    }
    if (key == "entityType2ID") {
      this.entityType2Change(id);
    }
    if (key == "entityType3ID") {
      this.entityType3Change(id);
    }
    if (key == "customerAttribute1ID") {
      this.customerAttribute1Change(id);
    }
    this.updateColumnNames();
  }

  async updateColumnNames() {
    let temp1Name = 'Attribute 1 Value';
    let temp2Name = 'Attribute 2 Value';
    let temp3Name = 'Attribute 3 Value';

    let entityID = this.attributeMapForm.get('entityType1ID').value;
    if (entityID) {
      const enType = this.entityTypes1.find(et => et.id === entityID);
      temp1Name = enType ? enType.name : 'Unknown';
      if (entityID == 75) { temp1Name += ' ' + (this.customerAttributes1.find(at => at.id === this.attributeMapForm.get('customerAttribute1ID').value) || {}).name || 'Unknown'; }
      if (entityID == 76) { temp1Name += ' ' + (this.itemAttributes1.find(at => at.id === this.attributeMapForm.get('itemAttribute1ID').value) || {}).name || 'Unknown'; }
      if (entityID == 70) { temp1Name += ' ' + (this.documentAttributes1.find(at => at.id === this.attributeMapForm.get('documentAttribute1ID').value) || {}).name || 'Unknown'; }
      temp1Name += ' Value';
    }
    entityID = this.attributeMapForm.get('entityType2ID').value;
    if (entityID) {
      const enType = this.entityTypes2.find(et => et.id === entityID);
      temp2Name = enType ? enType.name : 'Unknown';
      if (entityID == 75) { temp2Name += ' ' + (this.customerAttributes2.find(at => at.id === this.attributeMapForm.get('customerAttribute2ID').value) || {}).name || 'Unknown'; }
      if (entityID == 76) { temp2Name += ' ' + (this.itemAttributes2.find(at => at.id === this.attributeMapForm.get('itemAttribute2ID').value) || {}).name || 'Unknown'; }
      if (entityID == 70) { temp2Name += ' ' + (this.documentAttributes2.find(at => at.id === this.attributeMapForm.get('documentAttribute2ID').value) || {}).name || 'Unknown'; }
      temp2Name += ' Value';
    }
    entityID = this.attributeMapForm.get('entityType3ID').value;
    if (entityID) {
      const enType = this.entityTypes3.find(et => et.id === entityID);
      temp3Name = enType ? enType.name : 'Unknown';
      if (entityID == 75) { temp3Name += ' ' + (this.customerAttributes3.find(at => at.id === this.attributeMapForm.get('customerAttribute3ID').value) || {}).name || 'Unknown'; }
      if (entityID == 76) { temp3Name += ' ' + (this.itemAttributes3.find(at => at.id === this.attributeMapForm.get('itemAttribute3ID').value) || {}).name || 'Unknown'; }
      if (entityID == 70) { temp3Name += ' ' + (this.documentAttributes3.find(at => at.id === this.attributeMapForm.get('documentAttribute3ID').value) || {}).name || 'Unknown'; }
      temp3Name += ' Value';
    }
    //const selectedEntityType = this.entityTypes1.find(et => et.id === selectedId);
    //const selectedName = selectedEntityType ? selectedEntityType.name : null;  
    //this.attribute1ColumnName = selectedName;
    //this.attribute1ColumnName = (this.entityTypes1.find(et => et.id === this.attributeMapForm.get('entityType1ID').value) || {}).name || null;

    this.attribute1ColumnName = temp1Name;
    this.attribute2ColumnName = temp2Name;
    this.attribute3ColumnName = temp3Name;
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.CustomerAttribute1:
        this.customerAttributes1 = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute1:
        this.itemAttributes1 = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute1:
        this.documentAttributes1 = await (await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.CustomerAttribute2:
        this.customerAttributes2 = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute2:
        this.itemAttributes2 = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute2:
        this.documentAttributes2 = await (await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.CustomerAttribute3:
        this.customerAttributes3 = await (await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute3:
        this.itemAttributes3 = await (await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute3:
        this.documentAttributes3 = await (await this.documentAttributesService.getList(params)).body;
        break;
      default:
        break;
    }
  }
  
  async onLookup_customerAttribute1() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes1 = [];
      this.customerAttributes1.push(recordSelected);
      this.attributeMapForm.patchValue({ ['customerAttribute1ID']: receivedEntry.id });
      this.attributeMap.customerAttribute1 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_itemAttribute1() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes1 = [];
      this.itemAttributes1.push(recordSelected);
      this.attributeMapForm.patchValue({ ['itemAttribute1ID']: receivedEntry.id });
      this.attributeMap.itemAttribute1 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_documentAttribute1() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes1 = [];
      this.documentAttributes1.push(recordSelected);
      this.attributeMapForm.patchValue({ ['documentAttribute1ID']: receivedEntry.id });
      this.attributeMap.documentAttribute1 = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customerAttribute2() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes2 = [];
      this.customerAttributes2.push(recordSelected);
      this.attributeMapForm.patchValue({ ['customerAttribute2ID']: receivedEntry.id });
      this.attributeMap.customerAttribute2 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_itemAttribute2() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes2 = [];
      this.itemAttributes2.push(recordSelected);
      this.attributeMapForm.patchValue({ ['itemAttribute2ID']: receivedEntry.id });
      this.attributeMap.itemAttribute2 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_documentAttribute2() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes2 = [];
      this.documentAttributes2.push(recordSelected);
      this.attributeMapForm.patchValue({ ['documentAttribute2ID']: receivedEntry.id });
      this.attributeMap.documentAttribute2 = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customerAttribute3() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes3 = [];
      this.customerAttributes3.push(recordSelected);
      this.attributeMapForm.patchValue({ ['customerAttribute3ID']: receivedEntry.id });
      this.attributeMap.customerAttribute3 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_itemAttribute3() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes3 = [];
      this.itemAttributes3.push(recordSelected);
      this.attributeMapForm.patchValue({ ['itemAttribute3ID']: receivedEntry.id });
      this.attributeMap.itemAttribute3 = receivedEntry;
      modalRef.close('test');
    });
  }
  async onLookup_documentAttribute3() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes3 = [];
      this.documentAttributes3.push(recordSelected);
      this.attributeMapForm.patchValue({ ['documentAttribute3ID']: receivedEntry.id });
      this.attributeMap.documentAttribute3 = receivedEntry;
      modalRef.close('test');
    });
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async getEntityTypes() {
    const Filter_OptionSet = 'EntityType';
    const params = { Filter_OptionSet };
    const allEntityTypes: Lookup[] = await this.lookupsService.getList(params);

    this.entityTypes1 = [];
    this.entityTypes2 = [];
    this.entityTypes3 = [];

    if (allEntityTypes.length > 0) {
      for (const key in allEntityTypes) {
        const currentLookup: Lookup = allEntityTypes[key];
        if (currentLookup.name == "Customer Attribute" || currentLookup.name == "Document Attribute" || currentLookup.name == "Item Attribute") {
          this.entityTypes1.push(currentLookup);
          this.entityTypes2.push(currentLookup);
          this.entityTypes3.push(currentLookup);
        }
      }
    }
  }

  entityType1Change(entityType1ID) {
    if (entityType1ID == 75) {
      // Require the Customer Attribute
      this.f.customerAttribute1ID.setValidators(Validators.required);
      this.f.customerAttribute1ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute1ID']: null });
      this.f.documentAttribute1ID.clearValidators();
      this.f.documentAttribute1ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute1ID']: null });
      this.f.itemAttribute1ID.clearValidators();
      this.f.itemAttribute1ID.updateValueAndValidity();
    }
    else if (entityType1ID == 76) {
      // Require the Item Attribute
      this.f.itemAttribute1ID.setValidators(Validators.required);
      this.f.itemAttribute1ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute1ID']: null });
      this.f.customerAttribute1ID.clearValidators();
      this.f.customerAttribute1ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute1ID']: null });
      this.f.documentAttribute1ID.clearValidators();
      this.f.documentAttribute1ID.updateValueAndValidity();
    }
    else if (entityType1ID == 70) {
      // Require the Document Attribute
      this.f.documentAttribute1ID.setValidators(Validators.required);
      this.f.documentAttribute1ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute1ID']: null });
      this.f.customerAttribute1ID.clearValidators();
      this.f.customerAttribute1ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute1ID']: null });
      this.f.itemAttribute1ID.clearValidators();
      this.f.itemAttribute1ID.updateValueAndValidity();
    }
    this.updateColumnNames();
  }
  
  entityType2Change(entityType2ID) {
    if (entityType2ID == 75) {
      // Require the Customer Attribute
      this.f.customerAttribute2ID.setValidators(Validators.required);
      this.f.customerAttribute2ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute2ID']: null });
      this.f.documentAttribute2ID.clearValidators();
      this.f.documentAttribute2ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute2ID']: null });
      this.f.itemAttribute2ID.clearValidators();
      this.f.itemAttribute2ID.updateValueAndValidity();
    }
    else if (entityType2ID == 76) {
      // Require the Item Attribute
      this.f.itemAttribute2ID.setValidators(Validators.required);
      this.f.itemAttribute2ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute2ID']: null });
      this.f.customerAttribute2ID.clearValidators();
      this.f.customerAttribute2ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute2ID']: null });
      this.f.documentAttribute2ID.clearValidators();
      this.f.documentAttribute2ID.updateValueAndValidity();
    }
    else if (entityType2ID == 70) {
      // Require the Document Attribute
      this.f.documentAttribute2ID.setValidators(Validators.required);
      this.f.documentAttribute2ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute2ID']: null });
      this.f.customerAttribute2ID.clearValidators();
      this.f.customerAttribute2ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute2ID']: null });
      this.f.itemAttribute2ID.clearValidators();
      this.f.itemAttribute2ID.updateValueAndValidity();
    }
    this.updateColumnNames();
  }

  entityType3Change(entityType3ID) {
    if (entityType3ID == 75) {
      // Require the Customer Attribute
      this.f.customerAttribute3ID.setValidators(Validators.required);
      this.f.customerAttribute3ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute3ID']: null });
      this.f.documentAttribute3ID.clearValidators();
      this.f.documentAttribute3ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute3ID']: null });
      this.f.itemAttribute3ID.clearValidators();
      this.f.itemAttribute3ID.updateValueAndValidity();
    }
    else if (entityType3ID == 76) {
      // Require the Item Attribute
      this.f.itemAttribute3ID.setValidators(Validators.required);
      this.f.itemAttribute3ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute3ID']: null });
      this.f.customerAttribute3ID.clearValidators();
      this.f.customerAttribute3ID.updateValueAndValidity();
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute3ID']: null });
      this.f.documentAttribute3ID.clearValidators();
      this.f.documentAttribute3ID.updateValueAndValidity();
    }
    else if (entityType3ID == 70) {
      // Require the Document Attribute
      this.f.documentAttribute3ID.setValidators(Validators.required);
      this.f.documentAttribute3ID.updateValueAndValidity();
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute3ID']: null });
      this.f.customerAttribute3ID.clearValidators();
      this.f.customerAttribute3ID.updateValueAndValidity();
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute3ID']: null });
      this.f.itemAttribute3ID.clearValidators();
      this.f.itemAttribute3ID.updateValueAndValidity();
    }
    this.updateColumnNames();
  }

  customerAttribute1Change(entityTypeID) {
  }

  get attributeCount() {
    return this.attributeMapForm.get('attributeCount').value;
  }

  async attributeCountChange() {
    const attributeCountValue = this.attributeMapForm.get('attributeCount').value;
    if (attributeCountValue < 3) {
      // Clear the Entity Type
      this.attributeMapForm.patchValue({ ['entityType3ID']: null });
      this.f.entityType3ID.clearValidators();
      this.f.entityType3ID.updateValueAndValidity();
      this.attributeMap.entityType3ID = null;
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute3ID']: null });
      this.f.customerAttribute3ID.clearValidators();
      this.f.customerAttribute3ID.updateValueAndValidity();
      this.attributeMap.customerAttribute3ID = null;
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute3ID']: null });
      this.f.itemAttribute3ID.clearValidators();
      this.f.itemAttribute3ID.updateValueAndValidity();
      this.attributeMap.itemAttribute3ID = null;
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute3ID']: null });
      this.f.documentAttribute3ID.clearValidators();
      this.f.documentAttribute3ID.updateValueAndValidity();
      this.attributeMap.documentAttribute3ID = null;
    }
    if (attributeCountValue < 2) {
      // Clear the Entity Type
      this.attributeMapForm.patchValue({ ['entityType2ID']: null });
      this.f.entityType2ID.clearValidators();
      this.f.entityType2ID.updateValueAndValidity();
      this.attributeMap.entityType2ID = null;
      // Clear the Customer Attribute
      this.attributeMapForm.patchValue({ ['customerAttribute2ID']: null });
      this.f.customerAttribute2ID.clearValidators();
      this.f.customerAttribute2ID.updateValueAndValidity();
      this.attributeMap.customerAttribute2ID = null;
      // Clear the Item Attribute
      this.attributeMapForm.patchValue({ ['itemAttribute2ID']: null });
      this.f.itemAttribute2ID.clearValidators();
      this.f.itemAttribute2ID.updateValueAndValidity();
      this.attributeMap.itemAttribute2ID = null;
      // Clear the Document Attribute
      this.attributeMapForm.patchValue({ ['documentAttribute2ID']: null });
      this.f.documentAttribute2ID.clearValidators();
      this.f.documentAttribute2ID.updateValueAndValidity();
      this.attributeMap.documentAttribute2ID = null;
    }
    if (attributeCountValue > 1) {
      this.f.entityType2ID.setValidators(Validators.required);
      this.f.entityType2ID.updateValueAndValidity();
      if (this.attributeMap.entityType2ID == null) {
        this.attributeMapForm.patchValue({ ['entityType2ID']: 75 });
        this.attributeMap.entityType2ID = 75;
      }
      this.f.customerAttribute2ID.clearValidators();
      this.f.itemAttribute2ID.clearValidators();
      this.f.documentAttribute2ID.clearValidators();
      if (this.attributeMap.entityType2ID == 75) { this.f.customerAttribute2ID.setValidators(Validators.required); }
      if (this.attributeMap.entityType2ID == 76) { this.f.itemAttribute2ID.setValidators(Validators.required); }
      if (this.attributeMap.entityType2ID == 70) { this.f.documentAttribute2ID.setValidators(Validators.required); }
      this.f.customerAttribute2ID.updateValueAndValidity();
      this.f.itemAttribute2ID.updateValueAndValidity();
      this.f.documentAttribute2ID.updateValueAndValidity();
    }
    if (attributeCountValue > 2) {
      this.f.entityType3ID.setValidators(Validators.required);
      this.f.entityType3ID.updateValueAndValidity();
      if (this.attributeMap.entityType3ID == null) {
        this.attributeMapForm.patchValue({ ['entityType3ID']: 75 });
        this.attributeMap.entityType3ID = 75;
      }
      this.f.customerAttribute3ID.clearValidators();
      this.f.itemAttribute3ID.clearValidators();
      this.f.documentAttribute3ID.clearValidators();
      if (this.attributeMap.entityType3ID == 75) { this.f.customerAttribute3ID.setValidators(Validators.required); }
      if (this.attributeMap.entityType3ID == 76) { this.f.itemAttribute3ID.setValidators(Validators.required); }
      if (this.attributeMap.entityType3ID == 70) { this.f.documentAttribute3ID.setValidators(Validators.required); }
      this.f.customerAttribute3ID.updateValueAndValidity();
      this.f.itemAttribute3ID.updateValueAndValidity();
      this.f.documentAttribute3ID.updateValueAndValidity();
    }
    //do this always
    this.f.customerAttribute1ID.clearValidators();
    this.f.itemAttribute1ID.clearValidators();
    this.f.documentAttribute1ID.clearValidators();
    if (this.attributeMap.entityType1ID == 75) { this.f.customerAttribute1ID.setValidators(Validators.required); }
    if (this.attributeMap.entityType1ID == 76) { this.f.itemAttribute1ID.setValidators(Validators.required); }
    if (this.attributeMap.entityType1ID == 70) { this.f.documentAttribute1ID.setValidators(Validators.required); }
    this.f.customerAttribute1ID.updateValueAndValidity();
    this.f.itemAttribute1ID.updateValueAndValidity();
    this.f.documentAttribute1ID.updateValueAndValidity();
    this.updateColumnNames();
  }

}
