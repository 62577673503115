import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
  ColumnReorderConfig,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { ItemAttributeSingleComponent } from '../item-attribute-single/item-attribute-single.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { PricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';

@Component({
  selector: 'app-item-attribute-list',
  templateUrl: './item-attribute-list.component.html',
  styleUrls: ['./item-attribute-list.component.scss'],
})
export class ItemAttributeListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private itemAttributesService: ItemAttributesService,
    private pricingFilterValuesService: PricingFilterValuesService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() itemAttributes: ItemAttribute[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: ItemAttribute[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public itemAttributeDataTypeOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 475,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Description',
      field: 'description',
      width: 475,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Data Type',
      field: 'attributeDataType.name',
      width: 475,
      orderIndex: 2,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    if (await this.isUsedOnPricingFilter(ids, 'Item Attribute')) {
      this.snack.openSnackBar('An Item Attribute or Item Attributes selected is in use on a Pricing Filter Value.', '', true, 'Error', 'alert-danger');
    }
    else {
      await this.itemAttributesService
        .removeByIds(ids)
        .then((showSuccess: VoidFunction) => {
          this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
          this.mySelection = [];
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.showStatus(err.error, "error");
        });
    }

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  async getItemAttributeDataTypeOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'AttributeDataType';
    const params = { Filter_OptionSet, PageSize };
    this.itemAttributeDataTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "AttributeDataType";
    defaultLookup.name = "(All)";
    this.itemAttributeDataTypeOptions.reverse();
    this.itemAttributeDataTypeOptions.push(defaultLookup);
    this.itemAttributeDataTypeOptions.reverse();
    this.itemAttributeDataTypeOptions = this.itemAttributeDataTypeOptions.filter(
      x => x.name !== "Lookup" && x.name !== "Text" && x.name !== "Time");
    return this.itemAttributeDataTypeOptions;
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('ItemAttributeList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('ItemAttributeList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('ItemAttributeList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save columns configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('ItemAttributeList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();

    this.getItemAttributeDataTypeOptions();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onEdit(itemAttribute) {
    const modalRef = this.modalService.open(ItemAttributeSingleComponent);
    modalRef.componentInstance.itemAttribute = itemAttribute;
    modalRef.componentInstance.idOfItemAttribute = itemAttribute.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  onCreate() {
    const modalRef = this.modalService.open(ItemAttributeSingleComponent);
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.itemAttributesService.getList(this.query);
      this.itemAttributes = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.itemAttributes,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMItemAttribute');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }

  onFileChangeOLD(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(
        inputXML,
        'RPMItemAttribute',
      );
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.onEdit(this.clickedRowItem);        
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'description') {
          const Filter_Description =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_Description = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Description,
            Operand_Description,
          };
          this.query = params;
        }
        if (myFilter.field == 'attributeDataType.name') {
          if (myFilter.value != '(All)') {
            const Filter_DataType = myFilter.value;
            const Operand_DataType = myFilter.operator;
            const params = { ...this.query, Filter_DataType, Operand_DataType };
            this.query = params;
          }
        }
      });
    }
    this.onFilterChange();
  }
  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.itemAttributesService.getExportList(this.query);
  };

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  edit(id: number) {
    this.router.navigate([`/price/item-attribute/${id}`]);
  }

  //async showRemovePopUp(content) {
  //  const result = await this.modalService.open(content, {
  //    ariaLabelledBy: 'modal-basic-title',
  //  }).result;
  //}

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.itemAttributesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });
    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }


  async isUsedOnPricingFilter(ids: number[], Filter_EntityName): Promise<boolean> {
    var isUsedOnPricingFilter: boolean = false;

    const PageSize = StorageService.PageSize() ?? 50;
    const Operand_AttributeName = 'eq';
    const Operand_EntityName = 'eq';
    for (var id of ids) {
      let currentItemAttribute: ItemAttribute = await this.itemAttributesService.getById(id);
      let Filter_AttributeName: string = currentItemAttribute.name;
      const params = { Filter_AttributeName, Operand_AttributeName, Filter_EntityName, Operand_EntityName, PageSize };
      const existingPricingFilterValues: PricingFilterValue[] = await (
        await this.pricingFilterValuesService.getList(params)
      ).body;
      if (existingPricingFilterValues.length > 0) {
        isUsedOnPricingFilter = true;
        break;
      }
    }
    return isUsedOnPricingFilter;
  }
}
