export interface PriceSheetDetail {
  id: number;
  itemID: number;
  item: {
    description: string,
    name: string,
    number: string,
    id: number,
    uofMScheduleID: number;
  };
  uofMID: number;
  uofM: {
    name: string,
    id: number
  };
  locationID: number;
  location: {
    name: string,
    id: number
  };
  customerID: number;
  customer: {
    name: string,
    id: number
  };
  price: number;
  quantityRulesOptionID: number;
  quantityRulesOption: {
    name: string,
    id: number,
    optionSet: string
  };
  priceSheetID: number;
  priceSheet: {
    name: string,
    id: number
  };
  useFilter: boolean;
  pricingFilterID: number;
  pricingFilter: {
    name: string,
    description: string,
    id: number
  };
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPriceSheetDetail: PriceSheetDetail = {
  id: null,
  itemID: null,
  item: {
    description: '',
    name: '',
    number: '',
    id: null,
    uofMScheduleID: null
  },  
  uofMID: null,
  uofM: {
    name: '',
    id: null
  },
  locationID: null,
  location: {
    name: '',
    id: null
  },
  customerID: null,
  customer: {
    name: '',
    id: null
  },
  price: null,
  quantityRulesOptionID: null,
  quantityRulesOption: {
    name: '',
    id: null,
    optionSet: ''
  },
  priceSheetID: null,
  priceSheet: {
    name: '',
    id: null
  },
  useFilter: false,
  pricingFilterID: null,
  pricingFilter: {
    name: '',
    description: '',
    id: null
  },
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
