<kendo-dialog [minWidth]="250" [minHeight]="520" (close)="onClick_Close('Cancel')" [width]="950">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Pricing_Tree.png" alt="Sync Connector" style="padding-right: 5px;">
      Create / Update Sync Connector
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <!--
  144	AuthType	No Auth
  145	AuthType	Basic Auth
  146	AuthType	Rockton Pricing Management
  147	AuthType	SQL Connection
  148	AuthType	Dynamics GP
  149	AuthType	Dynamics BC
  150	AuthType	Acumatica
  151	AuthType	Excel File
  -->

  <form [formGroup]="syncConnectorForm">
    <div class="row">
      <div class="col form-group col-8">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Name" maxlength="100" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-4">
        <kendo-formfield>
          <kendo-label for="authType" class="font-weight-bold" text="* Auth Type">
          </kendo-label>
          <ng-select id="authType" [multiple]="false" [selectOnTab]="true"
                     formControlName="authTypeID" [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.authTypeID.errors,'w-100': true}"
                     placeholder="Select an Auth Type" [searchable]="true"
                     (change)="onSelectBoxChanged($event, 'authType', authTypes)">
            <ng-option *ngFor="let item of authTypes" [value]="item.id">{{ item.name }}</ng-option>
          </ng-select>
          <kendo-formerror>Auth Type is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="description" text="Description"></kendo-label>
          <kendo-textbox class="form-control" formControlName="description" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group col-4" *ngIf="[144,145,148,149,150].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="httpMethod" class="font-weight-bold" text="* HTTP Method"></kendo-label>
          <ng-select id="httpMethod" [multiple]="false" [selectOnTab]="true" formControlName="httpMethodID"
                     [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'is-invalid': submitted && f.httpMethodID?.errors,'w-100': true}"
                     placeholder="Select an HTTP Method" [searchable]="true"
                     (change)="onSelectBoxChanged($event, 'httpMethod', httpMethods)"
                     [required]="[144,145,148,149,150].includes(f.authTypeID?.value)">
            <ng-option *ngFor="let item of httpMethods" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-formerror>HTTP Method is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="[144,145,148,149,150].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="baseURL" class="font-weight-bold" text="* Base URL"></kendo-label>
          <kendo-textbox class="form-control" formControlName="baseURL" [clearButton]="true"
                         [required]="[144, 145, 148, 149, 150].includes(f.authTypeID?.value)">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="[147].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="server" text="Server"></kendo-label>
          <kendo-textbox class="form-control" formControlName="server" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="[145,147,148,150].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="username" text="Username"></kendo-label>
          <kendo-textbox class="form-control" formControlName="username" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
 
      <div class="col form-group" *ngIf="[145,147,148,150].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="password" text="Password"></kendo-label>
          <div class="password-container">
            <input type="password" id="password" class="form-control" formControlName="password" >
            <button type="button" class="toggle-button" (click)="togglePasswordVisibility()">Show</button>
          </div>
        </kendo-formfield>
      </div>

    </div>
    <div class="row">
      <div class="col form-group" *ngIf="[150].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="endpointName" text="Endpoint Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="endpointName" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="[150].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="endpointVersion" text="Endpoint Version"></kendo-label>
          <kendo-textbox class="form-control" formControlName="endpointVersion" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="[149].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="clientID" text="Client ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="clientID" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group" *ngIf="[149].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="clientSecret" text="Client Secret"></kendo-label>
          <kendo-textbox class="form-control" formControlName="clientSecret" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="[149].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="tenantID" text="Tenant ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="tenantID" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
      <div class="col form-group" *ngIf="[151].includes(f.authTypeID?.value)">
        <kendo-formfield>
          <kendo-label for="pathname" text="Pathname"></kendo-label>
          <kendo-textbox class="form-control" formControlName="pathname" [clearButton]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
