import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute, emptyCustomerAttribute } from '../../../services/customer-attributes/customer-attributes.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  AttributeDataType = 1,
}

@Component({
  selector: 'app-customer-attribute-single',
  templateUrl: './customer-attribute-single.component.html',
  styleUrls: ['./customer-attribute-single.component.scss'],
})
export class CustomerAttributeSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfCustomerAttribute: number | null = null;
  @Input() customerAttribute: CustomerAttribute = emptyCustomerAttribute;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  filters = Filters;
  myResponse: string;
  customerAttributeForm: FormGroup;
  attributeDataTypes: Lookup[] = [];
  submitted = false;
  modalReference: any;
  isFormChanged = false;

  constructor(
    private customerAttributesService: CustomerAttributesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfCustomerAttribute) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.customerAttributeForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
      ]),
      description: new FormControl('', Validators.maxLength(200)),
      attributeDataTypeID: new FormControl(null, Validators.required),
    });
    this.customerAttributeForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getAttributeDataTypes();
  }

  get f() {
    return this.customerAttributeForm.controls;
  }
  updateForm() {
    this.customerAttributeForm.patchValue({ ...this.customerAttribute });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.customerAttributeForm.markAllAsTouched();
    this.submitted = true;
    if (this.customerAttributeForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.customerAttribute,
      ...this.customerAttributeForm.value,
    };
    // Check to see if Customer Attribute Name already exists.
    if (await this.validation_CheckForExistingName() === true) {
      this.notificationHelper.showStatusOnDialog('Customer Attribute Name already exists!', 'error', this.container);
    } else {
      if (this.customerAttribute.id) {        
        try {
          const response: any = await this.customerAttributesService.update(
            this.customerAttribute.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {
          const response: any = await this.customerAttributesService.create(
            this.customerAttributeForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }
  }
  async getAttributeDataTypes() {
    const Filter_OptionSet = 'AttributeDataType';
    const params = { Filter_OptionSet };
    this.attributeDataTypes = await this.lookupService.getList(params);
    this.attributeDataTypes = this.attributeDataTypes.filter(
      x => x.name !== "Lookup" && x.name !== "Text" && x.name !== "Time");
    return this.attributeDataTypes;
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const Filter_Name = this.customerAttributeForm.controls['name'].value;
    const Operand_Name = 'eq';
    const PageSize = StorageService.PageSize();
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingCustomerAttributes: CustomerAttribute[] = await (
      await this.customerAttributesService.getList(params)
    ).body;
    if (existingCustomerAttributes.length > 0) {
      if (this.customerAttribute.id) {
        for (const key in existingCustomerAttributes) {
          const custAttr: CustomerAttribute = existingCustomerAttributes[key];
          if (custAttr.id != this.customerAttribute.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }
  async getCustomerAttributeById() {
    if (this.idOfCustomerAttribute) {
      return await this.customerAttributesService.getById(
        this.idOfCustomerAttribute,
      );
    } else {
      return emptyCustomerAttribute;
    }
  }
  async loadData() {
    try {
      [this.customerAttribute, this.attributeDataTypes] = await Promise.all([
        this.getCustomerAttributeById(),
        this.getAttributeDataTypes(),
      ]);
      console.log(this.attributeDataTypes);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  onSelectBoxChanged(id: number, key: keyof CustomerAttribute, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.customerAttribute[key] = item;
  }

}
