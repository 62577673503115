export interface CustomerAttribute {
    id: number;
    attributeDataTypeID: number;
    attributeDataType: {
        name: string,
        id: number
    };
    name: string;
    description: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyCustomerAttribute: CustomerAttribute = {
    id: null,
    attributeDataTypeID: null,
    attributeDataType: {
        name: '',
        id: null
    },
    name: '',
    description: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
