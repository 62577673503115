import { PriceSchedule } from '../price-schedules/price-schedules.model';

export interface PriceScheduleChild {
  id: number;
  priceScheduleID: number;
  rank: number;
  childPriceScheduleID: number;
  createdOn?: any;
  createdBy?: any;
  modifiedOn?: any;
  modifiedBy?: any;
  priceSchedule: PriceSchedule;
  childPriceSchedule: PriceSchedule;
}

export const emptyPriceScheduleChild: PriceScheduleChild = {
  id: null,
  priceScheduleID: null,
  rank: null,
  childPriceScheduleID: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: '',
  priceSchedule: null,
  childPriceSchedule: null
 };
