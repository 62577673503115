<div class="row">
  <div class="col-12 text-center">
    <p>This is a preview of mapped columns. To edit the columns, modify the respective Sync Reader or Sync Writer.</p>
  </div>
  <div class="col-6">
    <kendo-grid [kendoGridBinding]="gridViewReader"
                idField="id"
                [resizable]="true">
      <kendo-grid-column field="sequenceNumber" title="Seq" width="50" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
      <kendo-grid-column field="columnName" title="Reader Columns" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
    </kendo-grid>
  </div>
  <div class="col-6">
    <kendo-grid [kendoGridBinding]="gridViewWriter"
                idField="id"
                [resizable]="true">
      <kendo-grid-column field="sequenceNumber" title="Seq" width="50" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
      <kendo-grid-column field="columnName" title="Writer Columns" width="200" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
    </kendo-grid>
  </div>
</div>
