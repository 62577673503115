<div>
  <ng-template #areYouSure let-modal>
    <div class="modal-header">Alert</div>
    <div class="modal-body">
      <p>Are you sure you want to delete this record?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
        Yes
      </button>
      <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
        No
      </button>
    </div>
  </ng-template>

  <div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
    <img src="../../../../assets/images/Price_Sheet.png" alt="Price Sheet" style="padding-right: 5px;">
    Create / Update Price Sheet
  </div>
  <kendo-buttongroup>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
      imageUrl="../../../../assets/images/Back.png" (click)="onClick_Back()"
      routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">
      SAVE
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Save_Close.png" (click)="onClick_SaveClose()" title="Save & Close">
      SAVE &
      CLOSE
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Save_New.png" (click)="onClick_SaveNew()" title="Save & New">
      SAVE &
      NEW
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            imageUrl="../../../../assets/images/Add.png" (click)="onClick_AddNew()" title="Add New">
      ADD NEW
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
      COPY
    </button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
            [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
            (click)="showRemovePopUp(areYouSure)" title="Delete Record">
      DELETE
    </button>
  </kendo-buttongroup>
  <form [formGroup]="priceSheetForm">
    <div class="row">

      <div class="col-8 form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Price Sheet Name">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col-2 form-group">
        <kendo-formfield>
          <kendo-label for="rank" class="font-weight-bold" text="* Rank"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="rank" [min]="1" [autoCorrect]="true"
                                [decimals]="0" [format]="'n0'" [ngClass]="{ 'is-invalid': submitted && f.rank.errors }"
                                [max]="9999" placeholder="Enter a Rank">
          </kendo-numerictextbox>
          <kendo-formerror>Rank is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col-2 form-group d-flex align-items-center">
        <div class="form-check">
          <kendo-formfield>
            <input class="form-check-input" type="checkbox" formControlName="isActive" id="isActive" />
            <kendo-label class="form-check-kendo-label" for="isActive" text="Is Active"></kendo-label>
          </kendo-formfield>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="priceBook" class="font-weight-bold" text="* Price Book"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="priceBook" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="priceBookID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'borderless': true, 'is-invalid': submitted && f.priceBookID.errors,'w-100': true }"
                       placeholder="Select a Price Book" [searchable]="true" (search)="search($event, filters.PriceBook)"
                       (change)="onSelectBoxChanged($event,'priceBookID',priceBooks)">
              <ng-option *ngFor="let item of priceBooks" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (focus)="onFocus($event)" (blur)="onBlur($event)" (click)="onLookup_priceBook()" icon="search"></button>
          </span>
          <kendo-formerror>Price Book is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="pricingFilterID" text="Pricing Filter"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="pricingFilterID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="pricingFilterID" [ngClass]="{
              'is-invalid': submitted && f.pricingFilterID.errors,
              'w-100': true}" [searchable]="true" [notFoundText]="'Please start typing to search'"
                       (search)="search($event, filters.PricingFilter)" (change)="
            onSelectBoxChanged($event,'pricingFilterID',pricingFilters)" placeholder="Select a Pricing Filter">
              <ng-option *ngFor="let item of pricingFilters" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton type="button" look="clear" (click)="onLookup_pricingFilter()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="attributeValue" text="Filtering Attribute Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="attributeValue" placeholder="Enter an Attribute Value"
                         title="Independent of a Pricing Filter, the Attribute Value can filter this Price Sheet to scenarios that match this value">
          </kendo-textbox>
        </kendo-formfield>
      </div>

      <div class="col form-group">
        <kendo-formfield *ngIf="singleCurrency">
          <kendo-label class="font-weight-bold" for="currencyLabelName" text="* Currency"></kendo-label>
          <kendo-textbox class="form-control" formControlName="currencyLabelName" maxlength="100" disabled="true">
          </kendo-textbox>
        </kendo-formfield>
        <kendo-formfield *ngIf="!singleCurrency">
          <kendo-label for="currency" class="font-weight-bold" text="* Currency"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="currencyID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="currencyID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.currencyID.errors,'w-100': true}" placeholder="Select a Currency"
                       [searchable]="true" (search)="search($event, filters.Currency)" (change)="
              onSelectBoxChanged(
                $event,
                'currencyID',
                currencies)">
              <ng-option *ngFor="let item of currencies" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_currency()" icon="search"></button>
          </span>
          <kendo-formerror>Currency is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="effectiveBeginDate" class="font-weight-bold" text="* Effective Begin Date"></kendo-label>
          <kendo-datepicker id="effectiveBeginDate" class="form-control" formControlName="effectiveBeginDate"
                            [(value)]="effectiveBeginDate" [ngClass]="{ 'is-invalid': submitted && f.effectiveBeginDate.errors }" [min]="min">
          </kendo-datepicker>
          <kendo-formerror *ngIf="f.effectiveBeginDate.errors && f.effectiveBeginDate.errors.required">Effective Begin Date is required</kendo-formerror>
          <kendo-formerror *ngIf="f.effectiveBeginDate.errors && f.effectiveBeginDate.errors.minError">Date Must be Greater than Jan 1st, 2000</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="effectiveEndDate" class="font-weight-bold" text="* Effective End Date"></kendo-label>
          <kendo-datepicker id="effectiveEndDate" class="form-control" formControlName="effectiveEndDate"
                            [(value)]="effectiveEndDate" [ngClass]="{ 'is-invalid': submitted && f.effectiveEndDate.errors }" [min]="min">
          </kendo-datepicker>
          <kendo-formerror *ngIf="f.effectiveEndDate.errors && f.effectiveEndDate.errors.required">Effective End Date is required</kendo-formerror>
          <kendo-formerror *ngIf="f.effectiveEndDate.errors && f.effectiveEndDate.errors.minError">Date Must be Greater than Jan 1st, 2000</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="revisionDate" text="Revision Date"></kendo-label>
          <kendo-datepicker id="revisionDate" class="form-control" formControlName="revisionDate"
                            [(value)]="revisionDate" [ngClass]="{ 'is-invalid': submitted && f.revisionDate.errors }" [min]="min">
          </kendo-datepicker>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <div class="card p-3" style="background-color:rgb(234, 234, 234)">
    <app-price-sheet-detail-list #subgrid [priceSheetID]='idOfPriceSheet' mode='subgrid'
                                 [pagination]="paginationObject_PriceSheetDetails" [loading]="loading">
    </app-price-sheet-detail-list>
  </div>
</div>
