import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { CompaniesUsersService } from '../../../services/companies-users/companies-users.service';
import { CompanyUser, emptyCompanyUser } from '../../../services/companies-users/companies-users.model';
import { UsersService } from '../../../services/users/users.service';
import { User, emptyUser } from '../../../services/users/users.model';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';

@Component({
  selector: 'app-user-single',
  templateUrl: './user-single.component.html',
  styleUrls: ['./user-single.component.scss'],
})
export class UserSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfUser: string | null = null;
  @Input() mode: 'view' | 'dialog' = 'view';
  @Output() onFormSaved = new EventEmitter<Boolean>();

  user: User = emptyUser;
  loading: boolean = true;
  userForm: FormGroup;
  companies: Array<any> = [];
  submitted = false;  
  isFormChanged = false;
  modalReference: any;
  public loggedInUser: User = emptyUser;
  limits = [25, 50, 75, 100, 250];
  public disableCompanies = false;
  adminCompanyUsers: CompanyUser[];

  constructor(
    private companiesUsersService: CompaniesUsersService,
    private usersService: UsersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {

  }
  onClose(message) {
    this.activeModal.close(message);
  }
  ngOnInit(): void {
    this.buildForm();
    this.loadData();
  }

  get companiesFormArray() {
    return this.userForm.controls.assignedCompanies as FormArray;
  }

  buildForm() {
    this.userForm = new FormGroup({
      userName: new FormControl('', Validators.required),
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
      isAdmin: new FormControl(false),
      isDisabled: new FormControl(false),
      assignedCompanies: new FormArray([]),
      pageSize: new FormControl(''),
    });

    this.addCompanyCheckboxes();

    this.userForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });

  }
  async addCompanyCheckboxes() {
    // Get all of the companies that the logged in user has access to
    const params = { PageSize: 25 };
    const Filter_UserID = StorageService.UserID();
    this.adminCompanyUsers = await (
      await Promise.resolve(
        this.companiesUsersService.getList({ Filter_UserID, ...params }),
      )
    ).body;
    // const adminCompanyUsers: CompanyUser[] = await (
    //   await Promise.resolve(
    //     this.companiesUsersService.getList({ Filter_UserID, ...params }),
    //   )
    // ).body;

    // Foreach through those companies and retrieve users associated with that company
    for (const adminCompanyUser of this.adminCompanyUsers) {
      this.companies.push({
        name: adminCompanyUser.company.name,
        value: adminCompanyUser.company.id,
        checked: false
      });
    }

    // Add the checkboxes.
    this.companies.forEach(() =>
      this.companiesFormArray.push(new FormControl(false)),
    );
  }

  get f() {
    return this.userForm.controls;
  }
  updateForm() {
    this.userForm.patchValue({ ...this.user });
    this.isFormChanged = false;
  }

  async checkIfUserNameExists() {
    var userNameExists: boolean = false;
    const Filter_UserName = this.userForm.controls['userName'].value;
    const params = { Filter_UserName };
    const existingUsers: User[] = await (
      await this.usersService.getList(params)
    ).body;
    if (existingUsers.length > 0) {
      if (this.user.id) {
        for (const key in existingUsers) {
          const currentUser: User = existingUsers[key];
          if (currentUser.id != this.user.id) {
            userNameExists = true;
          }
        }
      } else {
        userNameExists = true;
      }
    }
    return userNameExists;
  }

  onCheckboxChange(e) {
    const myCompany = this.companies.find(j => j.value == e.target.value);
    if (myCompany) {
      this.companies.find(j => j.value == e.target.value).checked = e.target.checked;
    }
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.userForm.markAllAsTouched();
    this.submitted = true;
    if (this.userForm.invalid) {
      return window.scrollTo(0, 0);
    }

    // Reset the invalid attempts
    if (this.user.isDisabled && !this.f.isDisabled) {
      this.user.invalidAttempts = 0;
    }

    const data = {
      ...this.user,
      ...this.userForm.value,
    };

    // Check if this username already exists.
    if ((await this.checkIfUserNameExists()) === true) {
      this.notificationHelper.showStatusOnDialog('This UserName already exists!', 'error', this.container);
      return;
    }

    let companyChecked: boolean = false;
    for (const currentCompany of this.companies) {
      if (currentCompany.checked) {
        companyChecked = true;
        break;
      }
    }

    if (!companyChecked) {
      this.notificationHelper.showStatusOnDialog('You must select at least one company for this user!', 'error', this.container);
      return;
    }

    if (this.user.id) {
      try {
        // Get all of the companies that the selected user has access to
        const params = { PageSize: 25 };
        const Filter_UserID = this.user.id;
        const currentCompanyUsers: CompanyUser[] = await (
          await Promise.resolve(
            this.companiesUsersService.getList({ Filter_UserID, ...params }),
          )
        ).body;
               
        // Foreach through those and delete all existing CompanyUser records.
        const ids = [];
        for (const currentCompanyUser of currentCompanyUsers) {
          // Only remove existing Companies that the Admin user has access to.
          for (const currentAdminCompanyUser of this.adminCompanyUsers) {
            if (currentCompanyUser.company.name == currentAdminCompanyUser.company.name) {
              ids.push(currentCompanyUser.id);
              break;
            }
          }
        }
        await this.companiesUsersService.removeByIds(ids);

        // Create current CompanyUser records based on checked companies..
        for (const currentCompany of this.companies) {
          if (currentCompany.checked) {
            const newCompanyUser: CompanyUser = emptyCompanyUser;
            newCompanyUser.userID = this.user.id;
            newCompanyUser.companyID = currentCompany.value;
            delete newCompanyUser.id;
            this.companiesUsersService.create(newCompanyUser);
          }
        }

        const response: any = await this.usersService.update(
          this.user.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          StorageService.setPageSize(this.f.pageSize.value);
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.usersService.create(
          this.userForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.user = response.body;
          // Create current CompanyUser records based on checked companies..
          for (const currentCompany of this.companies) {
            if (currentCompany.checked) {
              const newCompanyUser: CompanyUser = emptyCompanyUser;
              newCompanyUser.userID = this.user.id;
              newCompanyUser.companyID = currentCompany.value;
              delete newCompanyUser.id;
              this.companiesUsersService.create(newCompanyUser);
            }
          }
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }

  async getUserById(id) {
    return await this.usersService.getById(id);
  }

  async loadData() {

    this.loggedInUser = await Promise.resolve(this.getUserById(StorageService.UserID()));

    this.loading = true;
    try {
      if (this.idOfUser) {
        // If this is an existing record we populate the values.
        this.user = await Promise.resolve(this.getUserById(this.idOfUser));

        // Get all of the companies that the selected user has access to
        const params = { PageSize: 25 };
        const Filter_UserID = this.user.id;
        const currentCompanyUsers: CompanyUser[] = await (
          await Promise.resolve(
            this.companiesUsersService.getList({ Filter_UserID, ...params }),
          )
        ).body;

        // Foreach through those companies check the checkbox
        for (const currentCompanyUser of currentCompanyUsers) {
          const myCompany = this.companies.find(e => e.value == currentCompanyUser.companyID);
          if (myCompany) {
            myCompany.checked = true;
          }

        }
      }
      this.updateForm();

      // If the logged in User is not an Admin then limit what they can change.
      if (!this.loggedInUser.isAdmin) {
        this.f.userName.disable();
        this.f.isAdmin.disable();
        this.f.isDisabled.disable();
        this.disableCompanies = true;
      }
      else {
        if (this.user.id == this.loggedInUser.id) {
          this.f.userName.disable();
          this.f.isAdmin.disable();
          this.f.isDisabled.disable();
          this.disableCompanies = true;
        }
      }

      this.isFormChanged = false;

    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
}
