<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Catalog_Sheet.png" alt="Catalog Sheets" style="padding-right: 5px;">
  Catalog Sheets
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" id="btnAdd" class="showTooltip" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="mode==='subgrid' && !this.catalogID" imageUrl="../../../../assets/images/Add.png"
              (click)="onCreate()" title="Save the record above to be able to start adding these records here.">
        Add New
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()"
              title="Edit Selected">
        Edit
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
              (click)="onClick_Delete()" title="Delete Selected">
        Delete
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)"
              title="Export to Excel">
        Export
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()"
              title="Import from Excel">
        Import
      </button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>

  <kendo-grid-column field="catalog.name" title="Catalog Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <!--<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.catalog.name }} </span>
    </ng-template>-->
  </kendo-grid-column>

  <kendo-grid-column field="name" title="Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/catalog-sheet/', dataItem.id]"> {{ dataItem.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="description" title="Description" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceSchedule.name" title="Price Schedule" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/price-schedule/', dataItem.priceSchedule?.id]"> {{ dataItem.priceSchedule?.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.priceSchedule?.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="customer.name" title="Customer Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/customer/', dataItem.customer?.id]"> {{ dataItem.customer?.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.customer?.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="customer.number" title="Customer Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/customer/', dataItem.customer?.id]"> {{ dataItem.customer?.number }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.customer?.number }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="item.name" title="Item Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/item/', dataItem.item?.id]"> {{ dataItem.item?.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.item?.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="item.number" title="Item Number" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/item/', dataItem.item?.id]"> {{ dataItem.item?.number }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.item?.number }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="useBaseUofM" filter="boolean" title="Use Base UofM" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No"> </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.useBaseUofM ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="location.name" title="Location" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/location/', dataItem.location?.id]"> {{ dataItem.location?.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.location?.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="currency.name" title="Currency" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view' || mode =='subgrid'" [routerLink]="['/price/currency/', dataItem.currency?.id]"> {{ dataItem.currency?.name }} </a>
      <span role="button" *ngIf="mode == 'lookup'"> {{ dataItem.currency?.name }} </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="effectivedate" title="Effective Date" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.effectiveDate | date: 'MM/dd/yyyy' }} </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="useToday" filter="boolean" title="Use Today" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <kendo-grid-messages filterIsTrue="Yes" filterIsFalse="No"> </kendo-grid-messages>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{dataItem.useToday ? 'Yes' : 'No'}}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="CatalogSheets.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true',italic:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="description" title="Description" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="catalog.name" title="CatalogName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="priceSchedule.name" title="PriceScheduleName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.number" title="CustomerNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.name" title="CustomerName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="item.number" title="ItemNumber" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="item.name" title="ItemName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="useBaseUofM" title="UseBaseUofM" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="location.name" title="LocationName" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="currency.name" title="CurrencyName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="currency.alphaCode" title="CurrencyAlphaCode" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="effectiveDate" title="EffectiveDate" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="useToday" title="UseToday" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
