import { PricingCondition } from 'src/app/services/pricing-conditions/pricing-conditions.model';
import { uniqBy } from 'lodash';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { UofM } from 'src/app/services/uofms/uofms.model';
import { GLAccount } from 'src/app/services/glaccounts/glaccounts.model';
import { RoundingRule } from 'src/app/services/rounding-rules/rounding-rules.model';
import { PricingFilter } from 'src/app/services/pricing-filters/pricing-filters.model';
export function prepareData(
  pricingCondition: PricingCondition,
  conditionalPricingFilters: PricingFilter[],
) {
  try {
    if (pricingCondition.conditionalPricingFilter) {
      conditionalPricingFilters = uniqBy(
        [...conditionalPricingFilters, { ...pricingCondition.conditionalPricingFilter }],
        'id',
      );
    }
    return [
      conditionalPricingFilters,
    ];
  } catch (e) {
    console.error(e);
  }
}
