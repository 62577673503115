import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
  ViewChild,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  RequiredValidator,
} from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import {
  getPaginationHeader,
  mockedData,
} from 'src/app/utils/getPaginationHeader';
//import { COLUMNS, IColumns } from './utils/config';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MySnackBarService } from '../../shared/snackbar/my-snackbar.service';
import {
  Currency,
  emptyCurrency,
} from '../../services/currencies/currencies.model';
import { CurrenciesService } from '../../services/currencies/currencies.service';
import { CurrencyListComponent } from '../currencies/currency-list/currency-list.component';
import {
  PriceSchedule,
  emptyPriceSchedule,
} from '../../services/price-schedules/price-schedules.model';
import {
  Location,
  emptyLocation,
} from '../../services/locations/locations.model';
import {
  Customer,
  emptyCustomer,
} from '../../services/customers/customers.model';
import { Item, emptyItem } from '../../services/items/items.model';
import { UofM, emptyUofM } from '../../services/uofms/uofms.model';
import { StorageService } from '../../utils/StorageHelper';
import { PriceSchedulesService } from '../../services/price-schedules/price-schedules.service';
import { LocationsService } from '../../services/locations/locations.service';
import { CustomersService } from '../../services/customers/customers.service';
import { ItemsService } from '../../services/items/items.service';
import { UofMsService } from '../../services/uofms/uofms.service';
import { LocationListComponent } from '../locations/location-list/location-list.component';
import { CustomerListComponent } from '../customers/customer-list/customer-list.component';
import { PriceScheduleListComponent } from '../price-schedules/price-schedule-list/price-schedule-list.component';
import { ItemListComponent } from '../items/item-list/item-list.component';
import { UofMListComponent } from '../uofms/uofm-list/uofm-list.component';
import { Breakpoints } from '@angular/cdk/layout';
import { ToolsService } from '../../services/tools/tools.service';
import { ParseTreeResult, XmlParser } from '@angular/compiler';
import { GLAccountsService } from '../../services/glaccounts/glaccounts.service';
import { GLAccount, emptyGLAccount } from '../../services/glaccounts/glaccounts.model';
import { SettingsService } from '../../services/settings/settings.service';

import { PricingDiagnosticsService } from 'src/app/services/pricing-diagnostics/pricing-diagnostics.service';
import { PricingDiagnostic } from 'src/app/services/pricing-diagnostics/pricing-diagnostics.model';
import { DatePipe } from '@angular/common';
import { PricingDiagnosticSingleComponent } from '../pricing-diagnostics/pricing-diagnostic-single/pricing-diagnostic-single.component';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import { PriceAdjustment } from 'src/app/services/price-adjustments/price-adjustments.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


enum Filters {
  PriceSchedule = 1,
  Item,
  UnitOfMeasure,
  Currency,
  Customer,
  Location,
}
@Component({
  selector: 'app-get-price',
  templateUrl: './get-price.component.html',
  styleUrls: ['./get-price.component.scss'],
})
export class GetPriceComponent implements OnInit {
  filters = Filters;
  loading = true;
  getPriceForm: FormGroup;
  priceSchedules: PriceSchedule[] = [];
  currencies: Currency[] = [];
  locations: Location[] = [];
  customers: Customer[] = [];
  items: Item[] = [];
  uofMs: UofM[] = [];
  selectedPriceSchedule: PriceSchedule = emptyPriceSchedule;
  selectedCurrency: Currency = emptyCurrency;
  selectedLocation: Location = emptyLocation;
  selectedCustomer: Customer = emptyCustomer;
  selectedItem: Item = emptyItem;
  selectedUofM: UofM = emptyUofM;
  defaultCurrencyID : number;
  singleCurrency: boolean;
  submitted = false;
  paginationObject: any = {
    currencies: mockedData,
    priceSchedules: mockedData,
    locations: mockedData,
    customers: mockedData,
    items: mockedData,
    uofMs: mockedData,
  };
  currencyID: number = null;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  //columnDefs: IColumns[] = [];
  effectiveDate: Date = new Date();
  pricingDiagnosticRunID: string;
  priceWasFound: number = null;
  public gridView: GridDataResult;
  priceAdjustments: PriceAdjustment[] = [];

  constructor(
    private route: ActivatedRoute,
    // private settingsService: SettingsService,
    private priceSchedulesService: PriceSchedulesService,
    private currenciesService: CurrenciesService,
    private locationsService: LocationsService,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private uofMsService: UofMsService,
    private settingsService: SettingsService,
    private toolsService: ToolsService,
    private glAccountsService: GLAccountsService,
    private pricingDiagnosticsService: PricingDiagnosticsService,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private datePipe: DatePipe,
  ) {
    this.route.params.subscribe(() => {
      //this.loadData();
    });
    this.priceScheduleSearch = debounce(this.priceScheduleSearch, 400, {});
    this.currencySearch = debounce(this.currencySearch, 400, {});
    this.locationSearch = debounce(this.locationSearch, 400, {});
    this.customerSearch = debounce(this.customerSearch, 400, {});
    this.itemSearch = debounce(this.itemSearch, 400, {});
    this.uofMSearch = debounce(this.uofMSearch, 400, {});
  }

  ngOnInit(): void {
    this.buildForm();
    this.updateForm();
    //this.columnDefs = this._columns;
  }

  public valueNormalizer = (text: Observable<string>) => text.pipe(map((text: string) => {
    return {
        id: this.currencies[this.currencies.length - 1].id + 1,
        name: text
    };
  }));

  buildForm() {
    this.getUseSingleCurrencySettings();
    this.getDefaultCurrencySettings();
    this.getPriceForm = this.fb.group({
      priceScheduleID: new FormControl(''),
      effectiveDate: new FormControl(
        new Date(),
        Validators.required,
      ),
      quantity: new FormControl(1),
      unitPriceAmount: new FormControl(0),
      itemID: new FormControl('', Validators.required),
      unitOfMeasureID: new FormControl('', Validators.required),
      currencyID: new FormControl('', Validators.required),
      customerID: new FormControl(''),
      locationID: new FormControl(''),
      returnedUnitPrice: new FormControl(''),
      pricingRunID: new FormControl(''),
      returnedPriceScheduleName: new FormControl(''),
      pricingRunTime: new FormControl('')
    });
  }
  get f() {
    return this.getPriceForm.controls;
  };

  updateForm() {
    //this.onFormChanged.emit(false);
  }

  clear() {
    this.submitted = false;
    this.buildForm();
    this.gridView = {
      data: [],
      total: this.priceAdjustments.length,
    };
  }

  async execute() {
    this.getPriceForm.markAllAsTouched();
    this.submitted = true;
    if (this.getPriceForm.invalid) {
      return window.scrollTo(0, 0);
    }

    var inputDoc: XMLDocument = new Document();
    var documentNode = inputDoc.createElement('Document'); //TODO this is where we add stuff for multi doc lines
    inputDoc.appendChild(documentNode);

    var rootNode = inputDoc.createElement('DocumentLine');
    documentNode.appendChild(rootNode);
    var docLineIDNode = inputDoc.createElement('DocumentLineID');
    docLineIDNode.innerHTML = 'TESTING';
    rootNode.appendChild(docLineIDNode);
    var priceScheduleIDNode = inputDoc.createElement('PriceScheduleID');
    priceScheduleIDNode.innerHTML = this.selectedPriceSchedule?.erpid ?? '';
    rootNode.appendChild(priceScheduleIDNode);
    var customerIDNode = inputDoc.createElement('CustomerID');
    customerIDNode.innerHTML = this.selectedCustomer?.erpid ?? '';
    rootNode.appendChild(customerIDNode);
    var itemIDNode = inputDoc.createElement('ItemID');
    itemIDNode.innerHTML = this.selectedItem?.erpid ?? '';
    rootNode.appendChild(itemIDNode);
    var locationIDNode = inputDoc.createElement('LocationID');
    locationIDNode.innerHTML = this.selectedLocation?.erpid ?? '';
    rootNode.appendChild(locationIDNode);
    var uofMIDNode = inputDoc.createElement('UofMID');
    uofMIDNode.innerHTML = this.selectedUofM?.erpid ?? '';
    rootNode.appendChild(uofMIDNode);
    var quantityNode = inputDoc.createElement('Quantity');
    quantityNode.innerHTML = this.getPriceForm?.value['quantity'] ?? 0;
    rootNode.appendChild(quantityNode);
    var currencyIDNode = inputDoc.createElement('CurrencyID');
    currencyIDNode.innerHTML = this.selectedCurrency?.erpid ?? '';
    rootNode.appendChild(currencyIDNode);
    var dateNode = inputDoc.createElement('Date');
    const formattedEffectiveDate = this.datePipe.transform(
      this.effectiveDate,
      'MM/dd/yyyy',
    );
    dateNode.innerHTML = formattedEffectiveDate;
    rootNode.appendChild(dateNode);
    var originatingUnitPriceNode = inputDoc.createElement(
      'OriginatingUnitPrice',
    );
    originatingUnitPriceNode.innerHTML = this.getPriceForm?.value[
      'unitPriceAmount'
    ];
    rootNode.appendChild(originatingUnitPriceNode);

    console.log(inputDoc);
    // TODO: Determine why we have to add these single quotes.
    const inputXML: string =
      "'" +
      new XMLSerializer().serializeToString(inputDoc.documentElement) +
      "'";
    this.getPrice(inputXML);
  }

  async getPriceScheduleByERPID(erpID) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = erpID;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingPriceSchedules: PriceSchedule[] = await (
      await this.priceSchedulesService.getList(params)
    ).body;
    if (existingPriceSchedules.length > 0) {
      return existingPriceSchedules[0];
    }
  }

  async getDiagnosticById(id) {
    return await this.pricingDiagnosticsService.getById(id);
  }

  async getGLAccountByERPId(erpid) {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERPID = erpid;
    const params = { Filter_ERPID, PageSize };
    const existingGLAccounts: GLAccount[] = await (await this.glAccountsService.getList(params)).body;
    if (existingGLAccounts.length == 1) {
      return existingGLAccounts[0];
    }
  }

  public show_PricingDiagnostic() {
    const modalRef = this.modalService.open(PricingDiagnosticSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.idOfPricingDiagnostic = this.pricingDiagnosticRunID;
  }

  async getPrice(inputXML) {
    try {

      const response: any = await this.toolsService.getPrice(inputXML);
      const status: any = response.status;
      if (status === 200) {
        const parser = new DOMParser();
        const outputDoc = parser.parseFromString(
          response.body,
          'application/xml',
        );

        // Clear out existing output values.
        this.f.returnedUnitPrice.patchValue('');
        this.f.returnedPriceScheduleName.patchValue('');
        this.f.pricingRunID.patchValue('');
        this.f.pricingRunTime.patchValue('');
        this.priceAdjustments = [];

        // Get Price Schedule Name
        var priceScheduleIDNode = outputDoc.querySelector('PriceScheduleID');
        if (priceScheduleIDNode.hasChildNodes) {
          var childNode = priceScheduleIDNode.childNodes[0];
          if (childNode != undefined) {
            var priceScheduleERPID = childNode.nodeValue;
            // @ts-ignore
            const priceSchedule: PriceSchedule = await this.getPriceScheduleByERPID(priceScheduleERPID);
            this.f.returnedPriceScheduleName.patchValue(priceSchedule.name);
          }
        }

        // Get Modified Unit Price
        var modifiedUnitPriceNode = outputDoc.querySelector('ModifiedUnitPrice');
        if (modifiedUnitPriceNode.hasChildNodes) {
          var childNode = modifiedUnitPriceNode.childNodes[0];
          if (childNode != undefined) {
            var modifiedUnitPrice = childNode.nodeValue;
            this.f.returnedUnitPrice.patchValue(modifiedUnitPrice);
          }
        }

        // Get the PricingDiagnostics Details
        var pricingDiagnosticRunIDNode = outputDoc.querySelector('PricingDiagnosticRunID');
        if (pricingDiagnosticRunIDNode.hasChildNodes) {
          var childNode = pricingDiagnosticRunIDNode.childNodes[0];
          if (childNode != undefined) {
            this.pricingDiagnosticRunID = childNode.nodeValue;
            // @ts-ignore
            const pricingDiagnostic: PricingDiagnostic = await this.getDiagnosticById(this.pricingDiagnosticRunID);
            this.f.pricingRunID.patchValue(pricingDiagnostic.id);
            const runDateTime = this.datePipe.transform(pricingDiagnostic.runDateTime, 'MM/dd/yyyy HH:mm:ss');
            this.f.pricingRunTime.patchValue(runDateTime);
          }
        }

        // Get the Price Was Found
        var priceWasFoundNode = outputDoc.querySelector('PriceWasFound');
        if (priceWasFoundNode.hasChildNodes) {
          var childNode = priceWasFoundNode.childNodes[0];
          if (childNode != undefined) {
            this.priceWasFound = parseInt(childNode.nodeValue);
          }
        }

        // Fill in Price Adjustments.
        var priceAdjustmentNodes = outputDoc.getElementsByTagName('PriceAdjustment');
        var inputList = Array.prototype.slice.call(priceAdjustmentNodes);
        inputList.forEach(async (value) => {
          var foo = this.GetEmptyPriceAdjustment();

          const priceadjustmentname = value.childNodes[0].innerHTML;
          foo.PriceAdjustmentName = priceadjustmentname;

          const priceadjustmentamount = value.childNodes[1].innerHTML;
          foo.PriceAdjustmentAmount = priceadjustmentamount;

          const debitGLAccountERPID = value.childNodes[2].innerHTML;
          const debitGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(debitGLAccountERPID)));
          foo.DebitGLAccountName = debitGLAccount.name;
          foo.DebitGLAccountNumber = debitGLAccount.number;

          const creditGLAccountERPID = value.childNodes[3].innerHTML;
          const creditGLAccount: GLAccount = (await Promise.resolve(this.getGLAccountByERPId(creditGLAccountERPID)));
          foo.CreditGLAccountName = creditGLAccount.name;
          foo.CreditGLAccountNumber = creditGLAccount.number;

          const usercanedit = value.childNodes[4].innerHTML;
          if (usercanedit == 1) {
            foo.UserCanEdit = "Yes";
          }
          else {
            foo.UserCanEdit = "No";
          }

          const adjustmentaffectsmargins = value.childNodes[5].innerHTML;
          if (adjustmentaffectsmargins == 1) {
            foo.AdjustmentAffectsMargins = "Yes";
          }
          else {
            foo.AdjustmentAffectsMargins = "No";
          }

          const adjustmentmodifiesunitprice = value.childNodes[6].innerHTML;
          if (adjustmentmodifiesunitprice == 1) {
            foo.AdjustmentModifiesUnitPrice = "Yes";
          }
          else {
            foo.AdjustmentModifiesUnitPrice = "No";
          }

          this.priceAdjustments.push(foo);
        });
        this.gridView = {
          data: this.priceAdjustments,
          total: this.priceAdjustments.length,
        };
      }
    } catch (e) {
      this.snack.openSnackBar(e.message, '', true, 'Error', 'alert-danger');
    }
  }

  GetEmptyPriceAdjustment() {
    return {
      PriceAdjustmentName: '',
      PriceAdjustmentAmount: null,
      DebitGLAccountName: '',
      DebitGLAccountNumber: '',
      CreditGLAccountName: '',
      CreditGLAccountNumber: '',
      UserCanEdit: '',
      AdjustmentAffectsMargins: '',
      AdjustmentModifiesUnitPrice: ''
    };
  };

  // async loadData2() {
  //   this.loading = true;
  //   try {
  //     [
  //       this.priceSchedules,
  //       this.currencies,
  //       this.locations,
  //       this.customers,
  //       this.items,
  //       this.uofMs,
  //     ] = await Promise.all([
  //       (await this.priceSchedulesService.getList()).body,
  //       (await this.currenciesService.getList()).body,
  //       (await this.locationsService.getList()).body,
  //       (await this.customersService.getList()).body,
  //       (await this.itemsService.getList()).body,
  //       (await this.uofMsService.getList()).body,
  //     ]);
  //     //this.prepareData();
  //   } catch (e) {
  //   } finally {
  //     this.loading = false;
  //   }
  // }

  // prepareData() {
  //       [
  //         //@ts-ignore
  //         this.priceSchedules,
  //         //@ts-ignore
  //         this.currencies,
  //         //@ts-ignore
  //         this.locations,
  //         //@ts-ignore
  //         this.customers,
  //         //@ts-ignore
  //         this.items,
  //         //@ts-ignore
  //         this.uofms
  //       ] = prepareData(
  //         this.priceSchedules,
  //         this.currencies,
  //         this.locations,
  //         this.customers,
  //         this.items,
  //         this.uofms
  //         );
  //   this.updateForm();
  // }

  async priceScheduleSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getPriceSchedules({ SearchTerm });
  }

  async currencySearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCurrencies({ SearchTerm });
  }

  async locationSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getLocations({ SearchTerm });
  }

  async customerSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCustomers({ SearchTerm });
  }

  async itemSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getItems({ SearchTerm });
  }

  async uofMSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getUofMs({ SearchTerm });
  }

  async getPriceSchedules(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.priceSchedulesService.getList({
        ...params, PageSize
      });
      // @ts-ignore
      this.priceSchedules = resp.body;
      this.paginationObject.priceSchedules = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.priceSchedules = [];
    }
    return this.priceSchedules;
  }

  async getCurrencies(params) {
    try {
      const resp = await this.currenciesService.getList({
        ...params
      });
      // @ts-ignore
      this.currencies = resp.body;
      this.paginationObject.currencies = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.currencies = [];
    }
    return this.currencies;
  }

  async getLocations(params) {
    try {
      const resp = await this.locationsService.getList({
        ...params,
      });
      // @ts-ignore
      this.locations = resp.body;
      this.paginationObject.locations = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.locations = [];
    }
    return this.locations;
  }

  async getCustomers(params) {
    try {
      const resp = await this.customersService.getList({
        ...params,
      });
      // @ts-ignore
      this.customers = resp.body;
      this.paginationObject.customers = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.customers = [];
    }
    return this.customers;
  }

  async getItems(params) {
    try {
      const resp = await this.itemsService.getList({
        ...params,
      });
      // @ts-ignore
      this.items = resp.body;
      this.paginationObject.items = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.items = [];
    }
    return this.items;
  }

  async getUofMs(params) {
    try {
      const resp = await this.uofMsService.getList({
        ...params,
      });
      // @ts-ignore
      this.uofMs = resp.body;
      this.paginationObject.uofMs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.uofMs = [];
    }
    return this.uofMs;
  }

  onSelectBoxChanged(id: number, key, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      switch (key) {
        case 'priceScheduleID':
          this.getPrice[key] = item;

          this.selectedPriceSchedule = item;
          break;
        case 'currencyID':
          if (item){
            this.selectedCurrency = item;
          }
          break;
        case 'locationID':
          this.selectedLocation = item;
          break;
        case 'customerID':
          this.selectedCustomer = item;
          break;
        case 'itemID':
          this.selectedItem = item;
          this.selectedUofM = null;
          this.f.unitOfMeasureID.patchValue('');
          break;
        case 'unitOfMeasureID':
          this.selectedUofM = item;
          break;
        default:
          break;
      }
    }
  }

  async search($event, type) {
    const SearchTerm = $event;
    const PageSize = 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.PriceSchedule:
        this.priceSchedules = await (
          await this.priceSchedulesService.getList(params)
        ).body;
        break;
      case Filters.Currency:
        this.currencies = await (await this.currenciesService.getList(params))
          .body;
        break;
      case Filters.Location:
        this.locations = await (await this.locationsService.getList(params))
          .body;
        break;
      case Filters.Customer:
        this.customers = await (await this.customersService.getList(params))
          .body;
        break;
      case Filters.Item:
        this.items = await (await this.itemsService.getList(params)).body;
        break;
      case Filters.UnitOfMeasure:
        this.uofMs = await (
          await this.uofMsService.getList({
            Filter_UofMScheduleID: this.selectedItem.uofMScheduleID,
            ...params,
          })
        ).body;
        break;
      default:
        break;
    }
  }

  async onLookup_priceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass : "PriceScheduleModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PriceScheduleDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.priceSchedules = [];
      this.priceSchedules.push(recordSelected);
      this.getPriceForm.patchValue({ ['priceScheduleID']: receivedEntry.id });
      this.selectedPriceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_currency() {
    const modalRef = this.modalService.open(CurrencyListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass : "CurrencyModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "CurrencyDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.getPriceForm.patchValue({ ['currencyID']: receivedEntry.name });
      this.selectedCurrency = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_location() {
    const modalRef = this.modalService.open(LocationListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass : "LocationModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "LocationDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.getPriceForm.patchValue({ ['locationID']: receivedEntry.id });
      this.selectedLocation = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_customer() {
    const modalRef = this.modalService.open(CustomerListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass : "CustomerModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "CustomerDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.getPriceForm.patchValue({ ['customerID']: receivedEntry.id });
      this.selectedCustomer = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_item() {
    const modalRef = this.modalService.open(ItemListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass : "ItemModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "ItemDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.getPriceForm.patchValue({ ['itemID']: receivedEntry.id });
      this.selectedItem = receivedEntry;
      // Clear the UofM since it is dependent upon item.
      this.selectedUofM = null;
      this.f.unitOfMeasureID.patchValue('');
      modalRef.close('test');
    });
  }

  async onLookup_unitOfMeasure() {
    const modalRef = this.modalService.open(UofMListComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass : "UofMModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "UofMDialogGrid";
    modalRef.componentInstance.uofMScheduleID = this.selectedItem.uofMScheduleID;
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofM = receivedEntry;
      this.uofMs = [];
      this.uofMs.push(recordSelected);
      this.getPriceForm.patchValue({ ['unitOfMeasureID']: receivedEntry.id });
      this.selectedUofM = receivedEntry;
      modalRef.close('test');
    });
  }
  canDeactivate() { }

  async getUseSingleCurrencySettings() {
    const Filter_Name = 'UseSingleCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    // @ts-ignore
    const setting: Setting = resp.body;
    this.singleCurrency = setting[0].value == 'true';
  }

  async getDefaultCurrencySettings() {
    const Filter_Name = 'DefaultCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    // @ts-ignore
    const setting: Setting = resp.body;
    if (setting && setting[0]){
      this.defaultCurrencyID = setting[0].value;

      const resp = await this.currenciesService.getById(this.defaultCurrencyID);
      // @ts-ignore
      let defaultCurrency = resp;

      //let defaultCurrency : Currency = this.currencies.find((e) => e.id ===  this.defaultCurrencyID);
      this.currencies = [];
      this.currencies.push(defaultCurrency);
      this.getPriceForm.patchValue({ ['currencyID']: defaultCurrency.name });
      this.selectedCurrency = defaultCurrency;
    }    
  }
}
