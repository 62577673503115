// Angular & Bootstrap Components
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

// 3rd Party Components
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

// Rockton API Components
import { ItemsService } from '../../../services/items/items.service';
import { Item, emptyItem } from '../../../services/items/items.model';
import { UofMSchedulesService } from '../../../services/uofm-schedules/uofm-schedules.service';
import { UofMSchedule } from 'src/app/services/uofm-schedules/uofm-schedules.model';
import { UofMScheduleListComponent } from '../../uofm-schedules/uofm-schedule-list/uofm-schedule-list.component';

// Shared & Utility Components
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { StorageService } from 'src/app/utils/StorageHelper';
import { mockedData } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { ItemCurrencyListComponent } from '../../item-currencies/item-currency-list/item-currency-list.component';
import { ItemAttributeValueListComponent } from '../../item-attribute-values/item-attribute-value-list/item-attribute-value-list.component';
import { UofMScheduleLookupComponent } from '../../uofm-schedules/uofm-schedule-lookup/uofm-schedule-lookup.component';
import { ItemAttributeValueSubListComponent } from '../../item-attribute-values/item-attribute-value-sublist/item-attribute-value-sublist.component';
import { ItemCurrencySubListComponent } from '../../item-currencies/item-currency-sublist/item-currency-sublist.component';
import { PricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilterValueCollection } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.model';
import { PricingFilterValueCollectionsService } from '../../../services/pricing-filter-value-collections/pricing-filter-value-collections.service';

enum Filters {
  UofMSchedule = 1,
}
@Component({
  selector: 'app-item-single',
  templateUrl: './item-single.component.html',
  styleUrls: ['./item-single.component.scss'],
})
export class ItemSingleComponent implements OnInit {
  @ViewChild('itemAttributeValuesSubgrid') itemAttributeValuesSubGrid: ItemAttributeValueSubListComponent;
  @ViewChild('itemCurrencySubgrid') itemCurrenciesSubGrid: ItemCurrencySubListComponent;

  filters = Filters;
  idOfItem: number | null = null;
  item: Item = emptyItem;
  loading: boolean = true;
  itemForm: FormGroup;
  uofMSchedules: UofMSchedule[] = [];
  submitted = false;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  paginationObject: any = {
    uofMSchedules: mockedData
  };
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  public isAdmin: boolean;
  pagination_uofMSchedules: any = { uofMSchedules: mockedData };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private itemsService: ItemsService,
    private pricingFilterValuesService: PricingFilterValuesService,
    private pricingFilterValueCollectionsService: PricingFilterValueCollectionsService,
    private uofmSchedulesService: UofMSchedulesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private sideNavService: SideNavService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.item = emptyItem;
        this.idOfItem = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfItem = params.id;
      this.loadData();
    });
    this.search = debounce(this.search, 300, { leading: true });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.itemAttributeValuesSubGrid.gridClassName = "ItemAttributeValueSubgridClass"
          this.itemCurrenciesSubGrid.gridClassName = "ItemCurrencySubgridClass"
        }
        else {
          this.itemAttributeValuesSubGrid.gridClassName = "ItemAttributeValueFullSubgridClass"
          this.itemCurrenciesSubGrid.gridClassName = "ItemCurrencyFullSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.itemForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfItem) {
        this.itemForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }
  buildForm() {
    this.itemForm = this.fb.group({
      name: new FormControl('', Validators.required),
      uofMScheduleID: new FormControl(null, Validators.required),
      description: new FormControl(''),
      number: new FormControl('', Validators.required),
      erpid: new FormControl({ value: '', disabled: true }, [
        Validators.required]),
    });
    this.itemForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.itemForm.controls;
  }
  updateForm() {
    this.itemForm.patchValue({ ...this.item });
    this.isFormChanged = false;
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.item && this.item.id) {
      return false;
    }
    else {
      return true;
    }
    //return !this.currency.id;
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/item/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/item/new']);
      });
    }
    //this.router.navigate(['/price/item/new'], { replaceUrl: true });
  }


  async submit(action) {
    this.itemForm.markAllAsTouched();
    this.submitted = true;
    if (this.itemForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.item,
      ...this.itemForm.getRawValue() // itemForm.value does not include disabled controls.
    };

    // Check to see if Customer Number already exists.
    if ((await this.checkForExistingNumber()) === true) {
      this.snack.openSnackBar(
        'Item Number already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }
    // Check to see if ERPID already exists.
    if ((await this.checkForExistingERPID()) === true) {
      this.snack.openSnackBar(
        'ERP ID already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
      return;
    }

    if (this.item.id) {
      try {
        const response: any = await this.itemsService.update(
          this.item.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Record updated successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/items']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/item/new']);
            });
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    } else {
      try {
        const response: any = await this.itemsService.create(
          this.itemForm.getRawValue(), // itemForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.item = response.body;
          this.idOfItem = this.item.id;
          this.snack.openSnackBar(
            'Record saved successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/items']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/item/new']);
            });
          }
          else {
            this.item = response.body;
            this.router.navigate(['/price/item/', this.item.id]);
          }
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    if (await this.isUsedOnPricingFilter(this.item.id, 'Item')) {
      this.snack.openSnackBar('Item is in use on a Pricing Filter Value.', '', true, 'Error', 'alert-danger');
      modal.close();
    }
    else {
      const ids = [];
      ids.push(this.item.id);
      await this.itemsService
        .removeByIds(ids)
        .then((showSuccess: VoidFunction) => {
          this.snack.openSnackBar(
            'Record(s) deleted successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
          modal.close();
          this.router.navigate(['/price/items']);
        }
        )
        .catch((err: HttpErrorResponse) => {
          this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
          modal.close();
        });
    }
    this.snack.openSnackBar('Item is in use on a Pricing Filter Value.', '', true, 'Error', 'alert-danger');
    modal.close();
  }

  async isUsedOnPricingFilter(value: number, Filter_EntityName): Promise<boolean> {
    var isUsedOnPricingFilter: boolean = false;

    const PageSize = StorageService.PageSize() ?? 50;
    const Operand_Value = 'eq';
    const Operand_EntityName = 'eq';
    let Filter_Value: string = value.toString();
    const params = { Filter_Value, Operand_Value, Filter_EntityName, Operand_EntityName, PageSize };
    const existingPricingFilterValues: PricingFilterValue[] = await (
      await this.pricingFilterValuesService.getList(params)
    ).body;
    if (existingPricingFilterValues.length > 0) {
      isUsedOnPricingFilter = true;
    }
    else {
      //Need to check Pricing Filter Value Collections
      const params = { Filter_Value, Operand_Value, PageSize };
      const existingPricingFilterValueCollections: PricingFilterValueCollection[] = await (
        await this.pricingFilterValueCollectionsService.getList(params)
      ).body;
      if (existingPricingFilterValueCollections.length > 0) {
        for (var pricingFilterValueCollection of existingPricingFilterValueCollections) {
          let currentPFV: PricingFilterValue = await this.pricingFilterValuesService.getById(pricingFilterValueCollection.pricingFilterValueID);
          if (currentPFV.entityID = 71) { //Item
            isUsedOnPricingFilter = true;
            break;
          }
        }
      }
    }
    return isUsedOnPricingFilter;
  }

  async checkForExistingERPID() {
    var erpIDExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.itemForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingItems: Item[] = await (
      await this.itemsService.getList(params)
    ).body;
    if (existingItems.length > 0) {
      if (this.item.id) {
        for (const key in existingItems) {
          const currentItem: Item = existingItems[key];
          if (currentItem.id != this.item.id) {
            erpIDExists = true;
          }
        }
      } else {
        erpIDExists = true;
      }
    }
    return erpIDExists;
  }

  async checkForExistingNumber() {
    var numberExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Number = this.itemForm.controls['number'].value;
    const Operand_Number = 'eq';
    const params = { Filter_Number, Operand_Number, PageSize };
    const existingItems: Item[] = await (
      await this.itemsService.getList(params)
    ).body;
    if (existingItems.length > 0) {
      if (this.item.id) {
        for (const key in existingItems) {
          const currentItem: Item = existingItems[key];
          if (currentItem.id != this.item.id) {
            numberExists = true;
          }
        }
      } else {
        numberExists = true;
      }
    }
    return numberExists;
  }

  async getItemById() {
    if (this.idOfItem) {
      return await this.itemsService.getById(this.idOfItem);
    } else {
      return emptyItem;
    }
  }

  async getUofMScheduleById(id) {
    return await this.uofmSchedulesService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfItem) {
        this.item = await Promise.resolve(this.getItemById());
        const currentUofMSchedule: UofMSchedule = await Promise.resolve(this.getUofMScheduleById(this.item.uofMScheduleID));
        this.uofMSchedules.push(currentUofMSchedule);
      }
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.UofMSchedule:
        if (SearchTerm) {
          this.uofMSchedules = (await this.uofmSchedulesService.getList(params)).body;
        }
        else {
          this.uofMSchedules = [];
        }
        break;
      default:
        break;
    }
  }

  async onLookup_uofMSchedule() {
    const modalRef = this.modalService.open(UofMScheduleLookupComponent, {});
    //modalRef.componentInstance.mode = 'lookup';
    //modalRef.componentInstance.gridClassName = "UofMScheduleDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofMSchedule = receivedEntry;
      this.uofMSchedules = [];
      this.uofMSchedules.push(recordSelected);
      this.itemForm.patchValue({ ['uofMScheduleID']: receivedEntry.id });
      this.item.uofmSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  onSelectBoxChanged(id: number, key: keyof Item, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.item[key] = item;
  }
  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    console.log('close')
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
