<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="650" [height]="650">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Price_Book.png" alt="Price Book" style="padding-right: 5px;">
      Price Books Lookup
    </div>
  </kendo-dialog-titlebar>
  <kendo-grid #grid="kendoGrid"
              [height]="520"
              [data]="gridView"
              [pageSize]="query.PageSize"
              [skip]="skip"
              [sort]="query.SortTerm"
              [loading]="loading"
              [pageable]="true"
              (pageChange)="pageChange($event)"
              [filter]="filter"
              [filterable]="filterable"
              (filterChange)="gridFilterChange($event)"
              [autoSize]="true"
              [sortable]="true"
              [sort]="sort"
              (sortChange)="sortChange($event)"
              [selectable]="{ mode: 'multiple' }"
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              (cellClick)="onCellClick($event)"
              (dblclick)="onDblClick()">
    <ng-template kendoGridToolbarTemplate>
      <kendo-buttongroup>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
        <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
      </kendo-buttongroup>
    </ng-template>
    <kendo-grid-column field="name" title="Name"
                       [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
          <kendo-filter-eq-operator></kendo-filter-eq-operator>
          <kendo-filter-neq-operator></kendo-filter-neq-operator>
          <kendo-filter-contains-operator></kendo-filter-contains-operator>
          <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
          <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
          <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
        </kendo-grid-string-filter-cell>
      </ng-template>    
    </kendo-grid-column>   
  </kendo-grid>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-check-outline"></span>Select</button>
  </kendo-dialog-actions>
</kendo-dialog>
