<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Schedule.png" alt="Price Schedule" style="padding-right: 5px;">
  Create / Update Price Schedule
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" (click)="onClickBack()"
          routerLinkActive="router-link-active" title="Go Back">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">
    SAVE & NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
          title="Delete Record">
    DELETE
  </button>
</kendo-buttongroup>

<form [formGroup]="priceScheduleForm">
  <div class="row">
    <div class="col form-group col-5">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Price Schedule Name">
        </kendo-textbox>
        <kendo-formerror>Name</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col-4 form-group d-flex align-items-center">
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="isActive" formControlName="isActive" #isactive kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="isactive" text="Is Active"></kendo-label>
      </div>
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="isHighestPrice" (change)="onMaxMinBoxChange($event, 'isHighestPrice')"
               formControlName="isHighestPrice" #isHighestPrice kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="isHighestPrice" text="Maximum Price"></kendo-label>
      </div>
      <div class="wrap" style="padding-right: 10px;">
        <input type="checkbox" id="isLowestPrice" (change)="onMaxMinBoxChange($event, 'isLowestPrice')"
               formControlName="isLowestPrice" #isLowestPrice kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="isLowestPrice" text="Minimum Price"></kendo-label>
      </div>
    </div>
    <div class="col-3 form-group d-flex align-items-center">
      <div class="wrap">
        <input type="checkbox" id="useInDynamicPricing" formControlName="useInDynamicPricing" (change)="onDynamicPricingChanged()" #useInDynamicPricing kendoCheckBox />
        <kendo-label class="k-checkbox-label" [for]="useInDynamicPricing" text="Use In Dynamic Pricing"></kendo-label>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-group col-9">
      <kendo-formfield>
        <kendo-label for="pricingFilterID" text="Pricing Filter"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="pricingFilterID" [multiple]="false" class="noarrow" [selectOnTab]="true"
                     formControlName="pricingFilterID" [ngClass]="{
            'is-invalid': submitted && f.pricingFilterID.errors,
            'w-100': true
          }" [searchable]="true" placeholder="Select a Pricing Filter" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.PricingFilter)"
                     (change)="onSelectBoxChanged($event, 'pricingFilterID', pricingFilters)">
            <ng-option *ngFor="let item of pricingFilters" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_PricingFilter()" icon="search"></button>
        </span>
      </kendo-formfield>
    </div>
    <div class="col form-group col-3">
      <kendo-formfield>
        <kendo-label for="dynamicPricingRank" text="Dynamic Pricing Rank" *ngIf="!useDynamicPricing"></kendo-label>
        <kendo-label for="dynamicPricingRank" class="font-weight-bold" text="* Dynamic Pricing Rank" *ngIf="useDynamicPricing"></kendo-label>
        <kendo-numerictextbox placeholder="Enter a Dynamic Pricing Rank" class="form-control"
                              formControlName="dynamicPricingRank" [min]="1" [max]="9999" [autoCorrect]="true" [decimals]="0" [format]="'n0'">
        </kendo-numerictextbox>
        <kendo-formerror>Dynamic Price Rank is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group col-6">
      <kendo-formfield>
        <kendo-label for="priceSourceOptionID" class="font-weight-bold" text="* Price Source Option"></kendo-label>
        <div class="d-flex input-group-prepend">
          <ng-select id="priceSourceOptionID"
                     [(ngModel)]="selectedPriceSourceOption"
                     (change)="priceSourceOptionChanged($event)"
                     [multiple]="false" [clearable]="false"
                     [selectOnTab]="true" formControlName="priceSourceOptionID" [notFoundText]="'Please start typing to search'"
                     placeholder="Select a Price Source Option" [ngClass]="{
            'is-invalid': submitted && f.priceSourceOptionID.errors,
            'w-100': true
          }" [searchable]="true">
            <ng-option *ngFor="let item of priceSourceOptions" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
        </div>
        <kendo-formerror>Price Source Option is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <div>
        <kendo-formfield *ngIf="priceSourceOptionID == 40">
          <kendo-label for="calculateAfterPriceScheduleID" class="font-weight-bold"
                       text="* Calculate After Price Schedule"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="calculateAfterPriceScheduleID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       [notFoundText]="'Please start typing to search'" formControlName="calculateAfterPriceScheduleID" [ngClass]="{
                        'is-invalid': submitted && f.calculateAfterPriceScheduleID.errors,
                        'w-100': true}" [searchable]="true"
                       placeholder="Select a Price Schedule" (search)="search($event, filters.CalculateAfterPrice)" (change)="
              onSelectBoxChanged(
                $event,
                'calculateAfterPriceSchedule',
                calculateAfterPriceSchedules
              )">
              <ng-option *ngFor="let item of calculateAfterPriceSchedules" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_CalculateAfterPriceSchedule()" icon="search"></button>
          </span>
          <kendo-formerror>Calculate After Price Schedule is required</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="priceSourceOptionID == 37">
          <kendo-label for="priceBookID" class="font-weight-bold" text="* Price Book"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="priceBookID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="priceBookID" [ngClass]="{
              'is-invalid': submitted && f.priceBookID.errors,
              'w-100': true
              }" [searchable]="true" [notFoundText]="'Please start typing to search'" placeholder="Select a Price Book"
                       (search)="search($event, filters.PriceBook)"
                       (change)="onSelectBoxChanged($event, 'priceBookID', priceBooks)">
              <ng-option *ngFor="let item of priceBooks" [value]="item.id">
                {{ item.name }}
              </ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_PriceBook()" icon="search"></button>
          </span>
          <kendo-formerror>Price Book is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="roundingRuleID" text="Rounding Rule"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="roundingRuleID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="roundingRuleID" [notFoundText]="'Please start typing to search'" [ngClass]="{
            'is-invalid': submitted && f.roundingRuleID.errors,
            'w-100': true
          }" (change)="onSelectBoxChanged($event, 'roundingRuleID', roundingRules)"
                     placeholder="Select a Rounding Rule" (search)="search($event, filters.RoundingRule)" [searchable]="true">
            <ng-option *ngFor="let item of roundingRules" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_RoundingRule()" icon="search"></button>
        </span>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="erpid" text="ERP ID"></kendo-label>
        <kendo-textbox formControlName="erpid" class="form-control" id="erpid" aria-describedby="emailHelp"
                       data-maxtextlength="2">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
</form>

<div *ngIf="priceSourceOptionID == 84" class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-price-schedule-child-list #subgrid [priceScheduleID]='idOfPriceSchedule' mode="view"
                                 class="PriceScheduleChildSubgridClass" (onSelectSingle)="onSelectChildPriceSchedule($event, 'priceSchedules')">
  </app-price-schedule-child-list>
  <br />
  <br />
  <br />
</div>

<div *ngIf="priceSourceOptionID == 84">
  <br />
</div>

<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-price-schedule-calculation-list #subgrid [priceScheduleID]='idOfPriceSchedule' mode="view"
                                       class="PriceCalculationSubgridClass" (onSelectSingle)="onSelectPriceCalculation($event, 'priceCalculations')">
  </app-price-schedule-calculation-list>
  <br />
  <br />
  <br />
</div>

