import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
})
export class TableHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() sortable: boolean;
  @Input() searchable: boolean;
  @Input() model: string;
  @Input() ordering: string;
  @Input() searchType: string;
  @Input() sortName: string;
  @Input() queryValue: string;
  @Output() onSearch = new EventEmitter();
  @Output() onSort = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  search(value) {
    this.onSearch.emit(value);
  }
  sort(value) {
    this.onSort.emit(value);
  }
  onSelectSearch(value) {
    this.onSearch.emit({ [this.model]: value });
  }
}
