import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { HistoricalSalesService } from '../../../services/historical-sales/historical-sales.service';
import { HistoricalSale, emptyHistoricalSale } from '../../../services/historical-sales/historical-sales.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { v4 as uuidv4 } from 'uuid'
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { Customer } from 'src/app/services/customers/customers.model';
import { CustomersService } from '../../../services/customers/customers.service';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { Item } from 'src/app/services/items/items.model';
import { ItemsService } from '../../../services/items/items.service';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';

enum Filters {
  Customer = 1,
  Item = 2
}
@Component({
  selector: 'app-historical-sale-single',
  templateUrl: './historical-sale-single.component.html',
  styleUrls: ['./historical-sale-single.component.scss'],
})
export class HistoricalSaleSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfHistoricalSale: number | null = null;
  @Input() historicalSale: HistoricalSale = emptyHistoricalSale;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  filters = Filters;
  historicalSaleForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;
  customers: Customer[] = [];
  items: Item[] = [];
  dateSold: Date = new Date();
  public min: Date = new Date(2000, 0);
  pagination_customers: any = { customers: mockedData };
  pagination_items: any = { items: mockedData };

  constructor(
    private historicalSalesService: HistoricalSalesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private notificationHelper: NotificationHelper,
    private customersService: CustomersService,
    private itemsService: ItemsService,
  ) {
  }

  ngOnInit(): void {
    if (this.idOfHistoricalSale) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.historicalSaleForm.disable();
    } else {
      // if new record, default erpid field to new GUID
      if (!this.idOfHistoricalSale) {
        this.historicalSaleForm.controls['erpid'].patchValue(`RPM-{${uuidv4()}}`);
        this.isFormChanged = false;
      }
    }
  }

  buildForm() {
    this.historicalSaleForm = new FormGroup({
      docReference: new FormControl('', Validators.required),
      docLineReference: new FormControl('', Validators.required),
      dateSold: new FormControl('', Validators.required),      
      customerID: new FormControl('', Validators.required),
      itemID: new FormControl('', Validators.required),
      quantityInBase: new FormControl(null, Validators.required),
      extendedPriceAmount: new FormControl(null, Validators.required),
      erpid: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.maxLength(50) ]),
    });
    this.setDefaultDate();
    this.historicalSaleForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  setDefaultDate() {
    // Default in Sold Date - this is required to make the date picker connection.
    this.historicalSale.dateSold = new Date();
    this.historicalSaleForm.patchValue({ ['dateSold']: this.historicalSale.dateSold });
  }

  get f() {
    return this.historicalSaleForm.controls;
  }
  updateForm() {
    this.historicalSaleForm.patchValue({ ...this.historicalSale });
    this.isFormChanged = false;
  }

  onClick_Save() {
    this.saveRecord();
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  async saveRecord() {
    this.historicalSaleForm.markAllAsTouched();
    this.submitted = true;

    if (this.historicalSaleForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.historicalSale,
      ...this.historicalSaleForm.getRawValue(), // historicalSaleForm.value does not include disabled controls.
    };
    // Check to see if ERPID already exists.
    if ((await this.validation_CheckForExistingERPID()) === true) {
      this.notificationHelper.showStatusOnDialog('ERP ID already exists.', 'error', this.container);
      return;
    }

    if (this.historicalSale.id) {      
      try {
        const response: any = await this.historicalSalesService.update(
          this.historicalSale.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.historicalSalesService.create(
          this.historicalSaleForm.getRawValue(), // historicalSaleForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);

  }

  async validation_CheckForExistingERPID() {
    var erpidExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_ERP = this.historicalSaleForm.controls['erpid'].value;
    const Operand_ERP = 'eq';
    const params = { Filter_ERP, Operand_ERP, PageSize };
    const existingHistoricalSales: HistoricalSale[] = await (
      await this.historicalSalesService.getList(params)
    ).body;
    if (existingHistoricalSales.length > 0) {
      if (this.historicalSale.id) {
        for (const key in existingHistoricalSales) {
          const currentHistoricalSale: HistoricalSale = existingHistoricalSales[key];
          if (currentHistoricalSale.id != this.historicalSale.id) {
            erpidExists = true;
          }
        }
      } else {
        erpidExists = true;
      }
    }
    erpidExists = false; //mhr - this validation is not working, always returns true
    return erpidExists;
  }

  async getHistoricalSaleById() {
    if (this.idOfHistoricalSale) {
      return await this.historicalSalesService.getById(this.idOfHistoricalSale);
    } else {
      return emptyHistoricalSale;
    }
  }

  async loadData() {
    try {
      [this.historicalSale] = await Promise.all([this.getHistoricalSaleById()]);

      // Customers
      if (this.historicalSale.customerID) {
        const currentCustomer: Customer = await Promise.resolve(this.getCustomerById(this.historicalSale.customerID));
        this.customers.push(currentCustomer);
      }

      // Items     
      if (this.historicalSale.itemID) {
        const currentItem: Item = await Promise.resolve(this.getItemById(this.historicalSale.itemID));
        this.items.push(currentItem);
      }

      if (this.historicalSale.dateSold) {
        this.historicalSale.dateSold = new Date(this.historicalSale.dateSold);
      }

      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  async getCustomers(params) {
    try {
      const resp = await this.customersService.getList({
        ...params,
      });
      // @ts-ignore
      this.customers = resp.body;
      this.pagination_customers.customers = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.customers = [];
    }
    return this.customers;
  }

  async getItems(params) {
    try {
      const resp = await this.itemsService.getList({
        ...params,
      });
      // @ts-ignore
      this.items = resp.body;
      this.pagination_items.items = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.items = [];
    }
    return this.items;
  }

  async onLookup_Customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.historicalSaleForm.patchValue({ ['customerID']: receivedEntry.id });
      this.historicalSale.customer = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_Item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.historicalSaleForm.patchValue({ ['itemID']: receivedEntry.id });
      this.historicalSaleForm.patchValue({ ['uofMID']: null });
      this.historicalSale.item = receivedEntry;
      modalRef.close('test');
    });
  }

  async getCustomerById(id) {
    return await this.customersService.getById(id);
  }

  async getItemById(id) {
    return await this.itemsService.getById(id);
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.Customer:
        // @ts-ignore
        this.customers = this.getCustomers(params);
        break;
      case Filters.Item:
        // @ts-ignore
        this.items = this.getItems(params);
        break;
      default:
        break;
    }
  }
}
