export interface ItemCurrency {
    id: number;
    itemID: number;
    item: {
        name: string,
        id: number,
        erpid: string,
        uofMScheduleID: number
    };
    currencyID: number;
    currency: {
        name: string,
        id: number,
        erpid: string
    };
    uofMID: number;
    uofM: {
        name: string,
        id: number,
        erpid: string
    };
    costAmount: number;
    listPriceAmount: number;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyItemCurrency: ItemCurrency = {
    id: null,
    itemID: null,
    item: {
        name: '',
        id: null,
        erpid: '',
        uofMScheduleID: null
    },
    currencyID: null,
    currency: {
        name: null,
        id: null,
        erpid: ''
    },
    uofMID: null,
    uofM: {
        name: null,
        id: null,
        erpid: ''
    },
    costAmount: null,
    listPriceAmount: null,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
