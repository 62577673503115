import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricingDiagnosticsService } from '../../../services/pricing-diagnostics/pricing-diagnostics.service';
import { PricingDiagnostic } from '../../../services/pricing-diagnostics/pricing-diagnostics.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { PricingDiagnosticSingleComponent } from '../pricing-diagnostic-single/pricing-diagnostic-single.component';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';

@Component({
  selector: 'app-pricing-diagnostic-list',
  templateUrl: './pricing-diagnostic-list.component.html',
  styleUrls: ['./pricing-diagnostic-list.component.scss'],
})
export class PricingDiagnosticListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private pricingDiagnosticsService: PricingDiagnosticsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private datePipe: DatePipe,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private utilitiesService: UtilitiesService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() pricingDiagnostics: PricingDiagnostic[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PricingDiagnostic[];
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public purgeDialogOpened = false; 
  public isAdmin: boolean;
  public purgeDaysToKeep: number;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'ID',
      field: 'id',
      width: 100,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Run Date',
      field: 'runDateTime',
      width: 200,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Result Summary',
      field: 'resultSummary',
      width: 600,
      orderIndex: 2,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
    this.isAdmin = StorageService.IsAdmin();
    this.purgeDaysToKeep = 7;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PricingDiagnosticList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  onRefresh() {
    this.loadListData();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PricingDiagnosticList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PricingDiagnosticList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PricingDiagnosticList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {          
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.pricingDiagnosticsService.getList(
        this.query
      );
      this.pricingDiagnostics = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.pricingDiagnostics,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      const selectedID = this.mySelection[0];
      const modalRef = this.modalService.open(PricingDiagnosticSingleComponent, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.idOfPricingDiagnostic = this.clickedRowItem.id;
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'id') {
          const Filter_ID = myFilter.value;
          const Operand_ID = myFilter.operator;
          const params = { ...this.query, Filter_ID, Operand_ID };
          this.query = params;
        }
        if (myFilter.field == 'runDateTime') {
          const Filter_RunDate = this.datePipe.transform(
            myFilter.value,
            'MM/dd/yyyy',
          );
          const Operand_RunDate = myFilter.operator;
          const params = { ...this.query, Filter_RunDate, Operand_RunDate };
          this.query = params;
        }
        if (myFilter.field == 'resultSummary') {
          const Filter_ResultSummary =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_ResultSummary = myFilter.operator;
          const params = {
            ...this.query,
            Filter_ResultSummary,
            Operand_ResultSummary,
          };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  edit(id: number) {
    this.router.navigate([`/price/pricing-diagnostic/${id}`]);
  }

  async showRemovePopUp(content, id) {
    this.idForRemove = id;
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.pricingDiagnosticsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  public closePurgeDialog(): void {
    //console.log(this.purgeDaysToKeep);
    this.purgeDialogOpened = false;
  }

  public openPurgeDialog(): void {
    this.purgeDialogOpened = true;
  }

  async processPurge(): Promise<void> {
    try
    {
      const params = [
        {
          Name: "@OverDaysOld",
          Value: this.purgeDaysToKeep
        }
      ];
      const paramList = JSON.stringify(params);
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMUtilityPurgeDiagnosticLog', paramList);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Diagnostic Log purged successfully.' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      console.error(e);
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }

    this.purgeDialogOpened = false;
    this.onRefresh();
  }

}
