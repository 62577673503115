import { Component, ViewChild } from '@angular/core';
//import { HeaderComponent } from '../app/shared/header/header.component';
//import { SidenavComponent } from '../app/shared/side-nav/sidenav.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  //@ViewChild(HeaderComponent,{static:false}) appHeader;
  //@ViewChild(SidenavComponent,{static:false}) sideNav;

//   onActivate(componentReference) {
//     // If the component being rendered is the Authentication component then we want to subscribe
//     // to the onAuthenticed so we can load the users companies in the header.
//     if (componentReference.constructor.name == "AuthComponent"){
//       componentReference.onAuthenticated.subscribe((receivedEntry) => {
//         //this.appHeader.UpdateSecurity();
//         //this.sideNav.UpdateSecurity();
//       });
//     }
//  }
}
