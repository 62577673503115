<ng-container>
  <div *ngIf="sortable">
    <app-sort-arrows
      (orderingChange)="sort($event)"
      [asc]="sortName"
      [desc]="'-' + sortName"
      [title]="title"
      [ordering]="ordering"
    >
    </app-sort-arrows>
  </div>
  <div *ngIf="!sortable">
    {{ title }}
  </div>
  <div *ngIf="searchable">
    <app-table-header-search
      *ngIf="searchType === 'free'"
      (onSearch)="search($event)"
      [model]="model"
      [queryValue]="queryValue"
    >
    </app-table-header-search>
    <select
      *ngIf="searchType === 'bool'"
      class="form-control"
      (change)="onSelectSearch($event.target.value)"
    >
      <option [value]="undefined"> All</option>
      <!-- <option [value]="null"> Not Set</option> -->
      <option [value]="true">
        Yes
      </option>
      <option [value]="false">
        No
      </option>
    </select>
  </div>
</ng-container>
