<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="800">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <img src="../../../../assets/images/Price_Sheet.png" alt="System Job Parameter" style="padding-right: 5px;">
      Create / Update System Job Parameter
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="systemJobParameterForm">
    <div class="row">
      <div class="col form-group col-2">
        <kendo-formfield>
          <kendo-label for="sequenceNumber" class="font-weight-bold" text="* Sequence"></kendo-label>
          <kendo-textbox class="form-control" formControlName="sequenceNumber" [readonly]="systemJobParameterForm.get('isSystemDefined').value">
          </kendo-textbox>
          <kendo-formerror>Sequence is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-7">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" [readonly]="systemJobParameterForm.get('isSystemDefined').value">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-3">
        <kendo-formfield>
          <kendo-label for="isSystemDefined" text="System Defined"></kendo-label>
          <kendo-textbox class="form-control" formControlName="isSystemDefined" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>

    <div class="row">

      <div class="col form-group col-6">
        <kendo-formfield>
          <kendo-label for="dataTypeID" class="font-weight-bold" text="* Data Type"></kendo-label>
          <div class="d-flex input-group-prepend">
            <ng-select id="dataTypeID" [multiple]="false" [selectOnTab]="true" formControlName="dataTypeID"
                       [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.dataTypeID.errors,'w-100': true}" placeholder="Select a Data Type"
                       [searchable]="true" (change)="dataTypeChanged($event)"
                       [readonly]="systemJobParameterForm.get('isSystemDefined').value">
              <ng-option *ngFor="let item of dataTypes" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
          </div>
          <kendo-formerror>Data Type is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col form-group col-6" *ngIf="dataTypeID == 15">
        <kendo-formfield>
          <kendo-label for="entityTypeID" class="font-weight-bold" text="* Entity Type"></kendo-label>
          <div class="d-flex input-group-prepend">
            <ng-select id="entityTypeID" [multiple]="false" [selectOnTab]="true" formControlName="entityTypeID"
                       [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.entityTypeID.errors,'w-100': true}" placeholder="Select an Entity Type"
                       [searchable]="true" (change)="entityTypeChanged($event)"
                       [readonly]="systemJobParameterForm.get('isSystemDefined').value">
              <ng-option *ngFor="let item of entityTypes" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
          </div>
          <kendo-formerror>Entity Type is required</kendo-formerror>
        </kendo-formfield>
      </div>

      <div class="col form-group col-6" *ngIf="dataTypeID == 140">
        <kendo-formfield>
          <kendo-label for="lookupListOptionSet" class="font-weight-bold" text="* Lookup List Option Set"></kendo-label>
          <kendo-textbox class="form-control" formControlName="lookupListOptionSet" (change)="lookupListOptionSetChanged1($event)">
          </kendo-textbox>
          <kendo-formerror>Lookup List Option Set is required</kendo-formerror>
        </kendo-formfield>
      </div>

    </div>

    <div class="row" *ngIf="dataTypeID == 15 || dataTypeID ==140">
      <div class="col-10 form-group" *ngIf="dataTypeID == 15 && entityTypeID ==136">
        <kendo-formfield>
          <kendo-label for="catalogID" class="font-weight-bold" text="* Catalog"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="catalogID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="catalogID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.catalogID.errors,'w-100': true}"
                       placeholder="Select a Catalog" [searchable]="true" (search)="search($event, filters.Catalog)">
              <ng-option *ngFor="let item of catalogs" [value]="item?.id">{{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Catalog()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col-10 form-group" *ngIf="dataTypeID == 15 && entityTypeID ==137">
        <kendo-formfield>
          <kendo-label for="catalogSheetID" class="font-weight-bold" text="* Catalog Sheet"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="catalogSheetID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="catalogSheetID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.catalogSheetID.errors,'w-100': true}"
                       placeholder="Select a Catalog Sheet" [searchable]="true" (search)="search($event, filters.CatalogSheet)">
              <ng-option *ngFor="let item of catalogSheets" [value]="item?.id">{{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_CatalogSheet()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col-10 form-group" *ngIf="dataTypeID == 15 && entityTypeID ==152">
        <kendo-formfield>
          <kendo-label for="syncSetID" class="font-weight-bold" text="* Sync Set"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="syncSetID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="syncSetID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.syncSetID.errors,'w-100': true}"
                       placeholder="Select a Sync Set" [searchable]="true" (search)="search($event, filters.SyncSet)">
              <ng-option *ngFor="let item of syncSets" [value]="item?.id">{{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_SyncSet()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col-10 form-group" *ngIf="dataTypeID == 15 && entityTypeID ==153">
        <kendo-formfield>
          <kendo-label for="syncSetEntityID" class="font-weight-bold" text="* Sync Set Entity"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="syncSetEntityID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="syncSetEntityID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.syncSetEntityID.errors,'w-100': true}"
                       placeholder="Select a Sync Set Entity" [searchable]="true" (search)="search($event, filters.SyncSetEntity)">
              <ng-option *ngFor="let item of syncSetEntities" [value]="item?.id">{{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_SyncSetEntity()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
      <div class="col form-group col-10" *ngIf="dataTypeID == 140 && lookupListOptionSet == 'SyncEntityType'">
        <kendo-formfield>
          <kendo-label for="syncEntityTypeID" class="font-weight-bold" text="* Triggering Sync Entity Type"></kendo-label>
          <div class="d-flex input-group-prepend">
            <ng-select id="syncEntityTypeID" [multiple]="false" [selectOnTab]="true" formControlName="syncEntityTypeID"
                       [notFoundText]="'Please start typing to search'" appendTo="body"
                       [ngClass]="{'is-invalid': submitted && f.syncEntityTypeID.errors,'w-100': true}" placeholder="Select an Entity Type"
                       [searchable]="true" (change)="syncEntityTypeChanged($event)">
              <ng-option *ngFor="let item of syncEntityTypes" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
          </div>
          <kendo-formerror>Sync Entity Type is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-10" *ngIf="dataTypeID == 140 && lookupListOptionSet == 'QueueType'">
        <kendo-formfield>
          <kendo-label for="queueTypeID" class="font-weight-bold" text="* Queue Type"></kendo-label>
          <div class="d-flex input-group-prepend">
            <ng-select id="queueTypeID" [multiple]="false" [selectOnTab]="true" formControlName="queueTypeID"
                       [notFoundText]="'Please start typing to search'" appendTo="body"
                       [ngClass]="{'is-invalid': submitted && f.queueTypeID.errors,'w-100': true}" placeholder="Select a Queue Type"
                       [searchable]="true" (change)="queueTypeChanged($event)">
              <ng-option *ngFor="let item of queueTypes" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
          </div>
          <kendo-formerror>Queue Type is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-10" *ngIf="dataTypeID == 140 && lookupListOptionSet == 'QueueStatus'">
        <kendo-formfield>
          <kendo-label for="queueStatusID" class="font-weight-bold" text="* Queue Status"></kendo-label>
          <div class="d-flex input-group-prepend">
            <ng-select id="queueStatusID" [multiple]="false" [selectOnTab]="true" formControlName="queueStatusID"
                       [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.queueStatusID.errors,'w-100': true}" placeholder="Select a Queue Status"
                       [searchable]="true" (change)="queueStatusChanged($event)">
              <ng-option *ngFor="let item of queueStatuses" [value]="item.id"> {{ item.name }} </ng-option>
            </ng-select>
          </div>
          <kendo-formerror>Queue Status is required</kendo-formerror>
        </kendo-formfield>
      </div>
      <div class="col form-group col-2">
        <kendo-formfield>
          <kendo-label for="entityRecordID" text="Record ID"></kendo-label>
          <kendo-textbox class="form-control" formControlName="entityRecordID" [readonly]="true">
          </kendo-textbox>
        </kendo-formfield>
      </div>
    </div>
        
    <!--<div class="row">
      <div class="col-12 form-group">
        <kendo-formfield>
          <kendo-label for="customerID" class="font-weight-bold" text="* Customer"></kendo-label>
          <span class="k-textbox" style="width: 100%;">
            <ng-select id="customerID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                       formControlName="customerID" [notFoundText]="'Please start typing to search'"
                       [ngClass]="{'is-invalid': submitted && f.customerID.errors,'w-100': true}"
                       placeholder="Select a Customer" [searchable]="true" (search)="search($event, filters.Customer)">
              <ng-option *ngFor="let item of customers" [value]="item?.id">{{ item?.number }} - {{ item?.name }}</ng-option>
            </ng-select>
            <kendo-textbox-separator></kendo-textbox-separator>
            <button kendoButton look="clear" (click)="onLookup_Customer()" icon="search"></button>
          </span>
        </kendo-formfield>
      </div>
    </div>-->

    <div class="row" *ngIf="dataTypeID != 134 && dataTypeID != 15 && dataTypeID != 140">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="value" text="Value"></kendo-label>
          <kendo-textbox class="form-control" formControlName="value" [readonly]="false">
          </kendo-textbox>
          <kendo-formerror *ngIf="dataTypeID == 13">Checkbox Value must be 0 or 1</kendo-formerror>
          <kendo-formerror *ngIf="dataTypeID == 14">Number Value must be integer or decimal value</kendo-formerror>
          <kendo-formerror *ngIf="dataTypeID == 16">Date Value must be in format MM/DD/YYYY</kendo-formerror>
          <kendo-formerror *ngIf="dataTypeID == 135">Time Value must be in format HH:MM</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>

    <div class="row" *ngIf="dataTypeID == 134">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="sqlScriptText" text="SQL Script"></kendo-label>
          <kendo-textarea class="form-control" width="100%" formControlName="sqlScriptText" rows="6" style="width: 100%; overflow-y: auto;"
                          [readonly]="!isRocktonAdmin" title="Scripts can only be edited by Rockton Support">
          </kendo-textarea>
        </kendo-formfield>
      </div>
    </div>

  </form>

  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>

