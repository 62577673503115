import * as internal from "events";

export interface PricingScenario {
  id: number;
  name: string;
  priceScheduleID: number;
  priceSchedule: {
    name: string;
    id: number;
    erpid: string;
  };
  effectiveDate: Date;
  useToday: boolean;
  quantity: number;
  itemID: number;
  item: {
    name: string;
    number: string;
    id: number;
    erpid: string;
    uofMScheduleID: number;
  };
  uofMID: number;
  uofM: {
    name: string;
    id: number;
    erpid: string;
  };
  currencyID: number;
  currency: {
    name: string;
    id: number;
    erpid: string;
  };
  customerID: number;
  customer: {
    name: string;
    number: string;
    id: number;
    erpid: string;
  };
  locationID: number;
  location: {
    name: string;
    id: number;
    erpid: string;
  };
  lastReturnedUnitPrice: number;
  lastReturnedPriceScheduleID: number;
  lastReturnedPriceSchedule: {
    name: string;
    id: number;
  };
  processStatus: string;
  expectedUnitPriceAmount: number;
  expectedPriceScheduleID: number;
  expectedPriceSchedule: {
    name: string;
    id: number;
  };
  mismatch: string;
  multiLineTag: string;
  scenarioGroup: string;
  lastExecutionDateTime: Date;
  priceAdjustmentsXML: string;
  lastExecutionDiagnosticID: number;
  valueFromERP: number;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyPricingScenario: PricingScenario = {
  id: null,
  name: '',
  priceScheduleID: null,
  priceSchedule: {
    name: '',
    id: null,
    erpid: '',
  },
  effectiveDate: null,
  useToday: null,
  quantity: null,
  itemID: null,
  item: {
    name: '',
    number: '',
    id: null,
    erpid: '',
    uofMScheduleID: null
  },
  uofMID: null,
  uofM: {
    name: '',
    id: null,
    erpid: '',
  },
  currencyID: null,
  currency: {
    name: '',
    id: null,
    erpid: '',
  },
  customerID: null,
  customer: {
    name: '',
    number: '',
    id: null,
    erpid: '',
  },
  locationID: null,
  location: {
    name: '',
    id: null,
    erpid: '',
  },
  lastReturnedUnitPrice: null,
  lastReturnedPriceScheduleID: null,
  lastReturnedPriceSchedule: {
    name: '',
    id: null,
  },
  processStatus: '',
  expectedUnitPriceAmount: null,
  expectedPriceScheduleID: null,
  expectedPriceSchedule: {
    name: '',
    id: null,
  },
  mismatch: null,
  multiLineTag: null,
  scenarioGroup: null,
  lastExecutionDateTime: null,
  priceAdjustmentsXML: '',
  lastExecutionDiagnosticID: null,
  valueFromERP: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
