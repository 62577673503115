export interface ItemAttributeValue {
    id: number;
    itemID: number;
    item: {
        name: string;
        id: number;
        erpid: string;
        number: string;
      };
    itemAttributeID: number;
    itemAttribute: {
        name: string;
        id: number;
        description: string;
        attributeDataTypeID: number;
      };
    value: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyItemAttributeValue: ItemAttributeValue = {
    id: null,
    itemID: null,
    item: {
        name: '',
        id: null,
        erpid: '',
        number: '',
      },
    itemAttributeID: null,
    itemAttribute: {
        name: '',
        id: null,
        description: '',
        attributeDataTypeID: null,
      },
    value: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
}
