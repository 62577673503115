export interface SyncConnector {
  id: number;
  name: string;
  description: string;
  authTypeID: number;
  authType: {
    name: string,
    id: number
  };
  httpMethodID: number;
  httpMethod: {
    name: string,
    id: number
  };
  baseURL: string;
  username: string;
  password: string;
  server: string;
  endpointName: string;
  endpointVersion: string;
  clientID: string;
  clientSecret: string;
  tenantID: string;
  pathname: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptySyncConnector: SyncConnector = {
  id: null,
  name: '',
  description: '',
  authTypeID: null,
  authType: {
    name: '',
    id: null
  },
  httpMethodID: null,
  httpMethod: {
    name: '',
    id: null
  },
  baseURL: '',
  username: '',
  password: '',
  server: '',
  endpointName: '',
  endpointVersion: '',
  clientID: '',
  clientSecret: '',
  tenantID: '',
  pathname: '',
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
