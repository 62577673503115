import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PriceCalculationsService } from '../../../services/price-calculations/price-calculations.service';
import { PriceCalculation, emptyPriceCalculation } from '../../../services/price-calculations/price-calculations.model';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { RoundingRulesService } from '../../../services/rounding-rules/rounding-rules.service';
import { RoundingRule } from '../../../services/rounding-rules/rounding-rules.model';
import { PriceFilterService } from '../../../services/price-filter/price-filter.service';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { PricingConditionsService } from '../../../services/pricing-conditions/pricing-conditions.service';
import { PricingCondition } from '../../../services/pricing-conditions/pricing-conditions.model';
import { GLAccountsService } from '../../../services/glaccounts/glaccounts.service';
import { GLAccount } from '../../../services/glaccounts/glaccounts.model';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GLAccountLookupComponent } from '../../gl-accounts/gl-account-lookup/gl-account-lookup.component';
import { RoundingRuleLookupComponent } from '../../rounding-rules/rounding-rule-lookup/rounding-rule-lookup.component';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { PricingConditionLookupComponent } from '../../pricing-conditions/pricing-condition-lookup/pricing-condition-lookup.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { Location } from '@angular/common';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { StorageService } from 'src/app/utils/StorageHelper';
import { CustomerAttribute, emptyCustomerAttribute } from '../../../services/customer-attributes/customer-attributes.model';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { emptyItemAttribute, ItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';
import { PriceBookLookupComponent } from '../../price-book/price-book-lookup/price-book-lookup.component';
import { PriceBookService } from '../../../services/price-book/price-book.service';
import { PriceBook, emptyPriceBook } from '../../../services/price-book/price-book.model';
import { AttributeMapLookupComponent } from '../../attribute-maps/attribute-map-lookup/attribute-map-lookup.component';
import { AttributeMapsService } from '../../../services/attribute-maps/attribute-maps.service';
import { AttributeMap, emptyAttributeMap } from '../../../services/attribute-maps/attribute-maps.model';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { DocumentAttribute, emptyDocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { CopyService } from '../../../services/copy/copy.service';

enum Filters {
  PriceModeOption = 1,
  PerUofMName = 2,
  DebitGLAccount = 3,
  CreditGLAccount = 4,
  RoundingRule = 5,
  PricingFilter = 6,
  PricingCondition = 7,
  CustomerAttribute = 8,
  ItemAttribute = 9,
  DocumentAttribute = 10,
  PriceBook = 11,
  AttributeMap = 12,
}
@Component({
  selector: 'app-price-calculation-single',
  templateUrl: './price-calculation-single.component.html',
  styleUrls: ['./price-calculation-single.component.scss'],
})
export class PriceCalculationSingleComponent implements OnInit {
  filters = Filters;
  idOfPriceCalculation: number | null = null;
  priceCalculation: PriceCalculation = emptyPriceCalculation;
  loading: boolean = true;
  priceCalculationForm: FormGroup;
  roundingRules: RoundingRule[] = [];
  pricingFilters: PricingFilter[] = [];
  pricingConditions: PricingCondition[] = [];
  debitGLAccounts: GLAccount[] = [];
  creditGLAccounts: GLAccount[] = [];
  priceModeOptions: Lookup[] = [];

  paginationObject: any = {
    uofMs: mockedData,
    glAccounts: mockedData,
    roundingrules: mockedData,
    pricingFilters: mockedData,
    //pricingConditions: mockedData 
  };
  submitted = false;
  @Output()
  onFormChanged = new EventEmitter<boolean>();
  modalReference: any;
  showPricePercent: boolean = false;
  showPriceAmount: boolean = false;
  showEntity: boolean = false;
  showCustomerAttribute: boolean = false;
  showDocumentAttribute: boolean = false;
  showItemAttribute: boolean = false;
  showConvertToUofM: boolean = false;
  showPriceBook: boolean = false;
  showAttributeMap: boolean = false;
  trackAdjustmentValue: boolean = false;
  areGLAccountsRequired: boolean = false;
  convertToUofMValue: boolean = false;
  abortIfValueNotFound: boolean = false;
  isFormChanged = false;
  goBackForce = false;
  nextURL = '';
  customerAttributes: CustomerAttribute[] = [];
  selectedCustomerAttribute: CustomerAttribute = emptyCustomerAttribute;
  documentAttributes: DocumentAttribute[] = [];
  selectedDocumentAttribute: DocumentAttribute = emptyDocumentAttribute;
  itemAttributes: ItemAttribute[] = [];
  selectedItemAttribute: ItemAttribute = emptyItemAttribute;
  priceBooks: PriceBook[] = [];
  selectedPriceBook: PriceBook = emptyPriceBook;
  attributeMaps: AttributeMap[] = [];
  selectedAttributeMap: AttributeMap = emptyAttributeMap;

  entityTypes: Lookup[] = [];
  min: Date = new Date(2000, 0);

  constructor(
    private route: ActivatedRoute,
    private priceCalculationsService: PriceCalculationsService,
    private uofMsService: UofMsService,
    private roundingRulesService: RoundingRulesService,
    private priceFiltersService: PriceFilterService,
    private pricingFiltersService: PricingFiltersService,
    private pricingConditionsService: PricingConditionsService,
    private gLAccountsService: GLAccountsService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private router: Router,
    private customerAttributesService: CustomerAttributesService,
    private documentAttributesService: DocumentAttributesService,
    private itemAttributesService: ItemAttributesService,
    private priceBooksService: PriceBookService,
    private attributeMapsService: AttributeMapsService,
    private location: Location,
    private copyService: CopyService
  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') {
        this.priceCalculation = emptyPriceCalculation;
        this.idOfPriceCalculation = null;
        this.submitted = false;
        this.priceCalculation.modifyUnitPrice = true;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfPriceCalculation = params.id;
      this.loadData();
    });

    this.debitGLAccountSearch = debounce(
      this.debitGLAccountSearch, 400,
      {}
    );
    this.creditGLAccountSearch = debounce(
      this.creditGLAccountSearch, 400,
      {}
    );
    this.roundingRuleSearch = debounce(
      this.roundingRuleSearch, 400,
      {}
    );
    this.pricingFilterSearch = debounce(
      this.pricingFilterSearch, 400,
      {}
    );
    this.pricingConditionSearch = debounce(
      this.pricingConditionSearch, 400,
      {}
    );

  }
  ngOnInit(): void {
    this.buildForm();
    this.updateForm();
  }
  buildForm() {
    this.priceCalculationForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100)]),
      convertToUofM: new FormControl(false),
      priceAmount: new FormControl(''),
      pricePercent: new FormControl(''),
      perUofMName: new FormControl(''),
      fromTheBaseStartingPrice: new FormControl(false),
      roundingRuleID: new FormControl(''),
      pricingFilterID: new FormControl(''),
      pricingConditionID: new FormControl(''),
      modifyUnitPrice: new FormControl(true),
      trackAdjustment: new FormControl(false),
      trackAdjustmentExtended: new FormControl(false),
      userCanEdit: new FormControl(false),
      affectsMarginCalc: new FormControl(false),
      debitGLAccountID: new FormControl(''),
      creditGLAccountID: new FormControl(''),
      priceModeOptionID: new FormControl('', [Validators.required]),
      useValueFromAttribute: new FormControl(false),
      useDefaultCurrency: new FormControl(false),
      abortIfValueNotFound: new FormControl(false),
      entityID: new FormControl(''),
      customerAttributeID: new FormControl(''),
      documentAttributeID: new FormControl(''),
      itemAttributeID: new FormControl(''),
      priceBookID: new FormControl(''),
      attributeMapID: new FormControl('')
    });
    this.getPriceModeOptions();
    this.getEntityOptions();
    this.priceCalculationForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  async getEntityOptions() {
    const Filter_OptionSet = 'EntityType';
    const params = { Filter_OptionSet };
    const allEntityTypes: Lookup[] = await this.lookupService.getList(params);

    if (allEntityTypes.length > 0) {
      for (const key in allEntityTypes) {
        const currentLookup: Lookup = allEntityTypes[key];
        if (currentLookup.name == "Customer Attribute" || currentLookup.name == "Document Attribute" || currentLookup.name == "Item Attribute" || currentLookup.name == "Price Book" || currentLookup.name == "Attribute Map") {
          this.entityTypes.push(currentLookup);
        }
      }
    }
    return this.entityTypes;
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMPriceCalculation", this.priceCalculation.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async onLookup_CustomerAttribute() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "CustomerAttributeDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes = [];
      this.customerAttributes.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['customerAttributeID']: receivedEntry.id });
      this.priceCalculation.customerAttribute = receivedEntry;
      this.selectedCustomerAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_DocumentAttribute() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "DocumentAttributeDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes = [];
      this.documentAttributes.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['documentAttributeID']: receivedEntry.id });
      this.priceCalculation.documentAttribute = receivedEntry;
      this.selectedDocumentAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_ItemAttribute() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes = [];
      this.itemAttributes.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['itemAttributeID']: receivedEntry.id });
      this.priceCalculation.itemAttribute = receivedEntry;
      this.selectedItemAttribute = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PriceBook() {
    const modalRef = this.modalService.open(PriceBookLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceBook = receivedEntry;
      this.priceBooks = [];
      this.priceBooks.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['priceBookID']: receivedEntry.id });
      this.priceCalculation.priceBook = receivedEntry;
      this.selectedPriceBook = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_AttributeMap() {
    const modalRef = this.modalService.open(AttributeMapLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: AttributeMap = receivedEntry;
      this.attributeMaps = [];
      this.attributeMaps.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['attributeMapID']: receivedEntry.id });
      this.priceCalculation.attributeMap = receivedEntry;
      this.selectedAttributeMap = receivedEntry;
      modalRef.close('test');
    });
  }

  get f() {
    return this.priceCalculationForm.controls;
  }

  updateForm() {
    this.priceCalculationForm.patchValue({ ...this.priceCalculation });
    this.isFormChanged = false;
  }

  clearPrefill() {
    setTimeout(() => {
      this.debitGLAccounts = [];
      this.creditGLAccounts = [];
      this.roundingRules = [];
      this.pricingFilters = [];
      this.pricingConditions = [];
    });
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.priceCalculation.id);
    await this.priceCalculationsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/price-calculations']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }


  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.priceCalculation && this.priceCalculation.id) {
      return false;
    }
    else {
      return true;
    }
    //return !this.currency.id;
  }

  onClickBack(): void {
    this.location.back();
  }

  onClickSave() {
    this.submit(null);
  }

  onClickSaveClose() {
    this.submit("Close");
  }

  onClickSaveNew() {
    this.submit("New");
  }

  async onClickAddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/price-calculation/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/price-calculation/new']);
      });
    }
    //this.router.navigate(['/price/price-calculation/new'], { replaceUrl: true });
  }

  async submit(action) {
    this.priceCalculationForm.markAllAsTouched();
    this.submitted = true;
    if (this.priceCalculationForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.priceCalculation,
      ...this.priceCalculationForm.value
    };

    // Check to see if Price Calculation Name already exists.
    if ((await this.checkForExistingName()) === true) {
      this.snack.openSnackBar(
        'Price Calculation Name already exists!',
        '',
        true,
        'Error',
        'alert-danger',
      );
    } else {
      if (this.priceCalculation.id) {        
        try {
          const response: any = await this.priceCalculationsService.update(
            this.priceCalculation.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.snack.openSnackBar(
              'Record updated successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/price-calculations']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/price-calculation/new']);
              });
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      } else {
        try {
          const response: any = await this.priceCalculationsService.create(
            this.priceCalculationForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.priceCalculation = response.body;
            this.snack.openSnackBar(
              'Record saved successfully!',
              '',
              false,
              'Success',
              'alert-success',
            );
            this.isFormChanged = false;
            if (action == "Close") {
              this.router.navigate(['/price/price-calculations']);
            }
            else if (action == "New") {
              this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['/price/price-calculation/new']);
              });
            }
            else {
              this.priceCalculation = response.body;
              this.router.navigate(['/price/price-calculation/', this.priceCalculation.id]);
            }
          }
        } catch (e) {
          this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
        }
      }
    }
    this.isFormChanged = false;
  }

  async getPriceModeOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'PriceMode';
    const params = { Filter_OptionSet, PageSize };
    this.priceModeOptions = await this.lookupService.getList(params);
    return this.priceModeOptions;
  }

  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.priceCalculationForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingPriceCalculations: PriceCalculation[] = await (
      await this.priceCalculationsService.getList(params)
    ).body;
    if (existingPriceCalculations.length > 0) {
      if (this.priceCalculation.id) {
        for (const key in existingPriceCalculations) {
          const currentPriceCalculation: PriceCalculation = existingPriceCalculations[key];
          if (currentPriceCalculation.id !== this.priceCalculation.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async getPriceCalculationById() {
    if (this.idOfPriceCalculation) {
      return await this.priceCalculationsService.getById(this.idOfPriceCalculation);
    } else {
      return emptyPriceCalculation;
    }
  }

  async getUofMById(id) {
    return await this.uofMsService.getById(id);
  }

  async getDebitGLAccountById(id) {
    return await this.gLAccountsService.getById(id);
  }

  async getCreditGLAccountById(id) {
    return await this.gLAccountsService.getById(id);
  }

  async getRoundingRuleById(id) {
    return await this.roundingRulesService.getById(id);
  }

  async getPriceFilterById(id) {
    return await this.priceFiltersService.getById(id);
  }

  async getPricingFilterById(id) {
    return await this.pricingFiltersService.getById(id);
  }

  async getPricingConditionById(id) {
    return await this.pricingConditionsService.getById(id);
  }
  async getCustomerAttributeById(id) {
    return await this.customerAttributesService.getById(id);
  }

  async getDocumentAttributeById(id) {
    return await this.documentAttributesService.getById(id);
  }

  async getItemAttributeById(id) {
    return await this.itemAttributesService.getById(id);
  }

  async getPriceBookById(id) {
    return await this.priceBooksService.getById(id);
  }

  async getAttributeMapById(id) {
    return await this.attributeMapsService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfPriceCalculation) {
        this.priceCalculation = await Promise.resolve(this.getPriceCalculationById());

        if (this.priceCalculation.customerAttributeID) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(this.getCustomerAttributeById(this.priceCalculation.customerAttributeID));
          this.customerAttributes.push(currentCustomerAttribute);
          this.showCustomerAttribute = true;

        }

        if (this.priceCalculation.documentAttributeID) {
          const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.getDocumentAttributeById(this.priceCalculation.documentAttributeID));
          this.documentAttributes.push(currentDocumentAttribute);
          this.showDocumentAttribute = true;
        }

        if (this.priceCalculation.itemAttributeID) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.getItemAttributeById(this.priceCalculation.itemAttributeID));
          this.itemAttributes.push(currentItemAttribute);
          this.showItemAttribute = true;
        }

        if (this.priceCalculation.priceBookID) {
          const currentPriceBook: PriceBook = await Promise.resolve(this.getPriceBookById(this.priceCalculation.priceBookID));
          this.priceBooks.push(currentPriceBook);
          this.showPriceBook = true;
        } 

        if (this.priceCalculation.attributeMapID) {
          const currentAttributeMap: AttributeMap = await Promise.resolve(this.getAttributeMapById(this.priceCalculation.attributeMapID));
          this.attributeMaps.push(currentAttributeMap);
          this.showAttributeMap = true;
        } 

        if (this.priceCalculation.debitGLAccountID) {
          const currentDebitGLAccount: GLAccount = await Promise.resolve(this.getDebitGLAccountById(this.priceCalculation.debitGLAccountID));
          this.debitGLAccounts.push(currentDebitGLAccount);
        }

        if (this.priceCalculation.creditGLAccountID) {
          const currentCreditGLAccount: GLAccount = await Promise.resolve(this.getCreditGLAccountById(this.priceCalculation.creditGLAccountID));
          this.creditGLAccounts.push(currentCreditGLAccount);
        }

        if (this.priceCalculation.roundingRuleID) {
          const currentRoundingRule: RoundingRule = await Promise.resolve(this.getRoundingRuleById(this.priceCalculation.roundingRuleID));
          this.roundingRules.push(currentRoundingRule);
        }

        if (this.priceCalculation.pricingFilterID) {
          const currentPricingFilter: PricingFilter = await Promise.resolve(this.getPricingFilterById(this.priceCalculation.pricingFilterID));
          this.pricingFilters.push(currentPricingFilter);
        }

        if (this.priceCalculation.pricingConditionID) {
          const currentPricingCondition: PricingCondition = await Promise.resolve(this.getPricingConditionById(this.priceCalculation.pricingConditionID));
          this.pricingConditions.push(currentPricingCondition);
        }
      }

      this.getPriceModeOptions();
      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }
    if (this?.priceCalculation?.priceModeOption != null) {
      this.priceModeChange(this.priceCalculation.priceModeOption.id);
    }
    if (this?.priceCalculation?.convertToUofM) {
      this.convertToUofMValue = this.priceCalculation.convertToUofM;
    }

    if (this?.priceCalculation?.abortIfValueNotFound) {
      this.abortIfValueNotFound = this.priceCalculation.abortIfValueNotFound;
    }

    if (this?.priceCalculation?.trackAdjustment) {
      this.trackAdjustmentValue = this.priceCalculation.trackAdjustment;
    }

    this.isFormChanged = false;
  }

  prepareData() {
    [
      //@ts-ignore
      this.uofMs,
      //@ts-ignore
      this.debitGLAccounts,
      //@ts-ignore
      this.creditGLAccounts,
      //@ts-ignore
      this.roundingRules,
      //@ts-ignore
      this.conditionalPricingFilters,
      //@ts-ignore
      this.pricingFilters,
      //@ts-ignore
      this.conditionalPricingConditions,
      //@ts-ignore
      this.pricingConditions,
      //@ts-ignore
      this.priceModeOptions
    ] = prepareData(
      this.priceCalculation,
      this.debitGLAccounts,
      this.creditGLAccounts,
      this.roundingRules,
      this.pricingFilters,
      this.pricingConditions,
      this.priceModeOptions
    );

    this.updateForm();
  }

  async debitGLAccountSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getDebitGLAccounts({ SearchTerm });
  }

  async creditGLAccountSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCreditGLAccounts({ SearchTerm });
  }

  async roundingRuleSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getRoundingRules({ SearchTerm });
  }

  async pricingFilterSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getPricingFilters({ SearchTerm });
  }

  async pricingConditionSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getPricingConditions({ SearchTerm });
  }

  async getDebitGLAccounts(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.gLAccountsService.getList({ ...params, PageSize });
      // @ts-ignore
      this.debitGLAccounts = resp.body;
      this.paginationObject.debitGLAccounts = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.debitGLAccounts = [];
    }
    return this.debitGLAccounts;
  }

  async getCreditGLAccounts(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.gLAccountsService.getList({ ...params, PageSize });
      // @ts-ignore
      this.creditGLAccounts = resp.body;
      this.paginationObject.creditGLAccounts = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.creditGLAccounts = [];
    }
    return this.creditGLAccounts;
  }

  async getRoundingRules(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.roundingRulesService.getList({ ...params, PageSize });
      // @ts-ignore
      this.roundingRules = resp.body;
      this.paginationObject.roundingRules = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.roundingRules = [];
    }
    return this.roundingRules;
  }

  async getPricingFilters(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.pricingFiltersService.getList({ ...params, PageSize });
      this.pricingFilters = resp.body;
      this.paginationObject.pricingFilters = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.pricingFilters = [];
    }
    return this.pricingFilters;
  }

  async getPricingConditions(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.pricingConditionsService.getList({ ...params, PageSize });
      this.pricingConditions = resp.body;
      this.paginationObject.pricingConditions = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.pricingConditions = [];
    }
    return this.pricingConditions;
  }

  onSelectBoxChanged(id: number, key: keyof PriceCalculation, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.priceCalculation[key] = item;
    if (key == "priceModeOption") {
      this.priceModeChange(id);
    }
    else if (key == "entityID") {
      this.entityTypeChange(id);
    }
    //if (key === 'priceBookID') {
    //  this.idOfPriceBook = id;
    //}

    this.gLAccountIDsChanged();
  }

  entityTypeChange(entityTypeID) {
    if (entityTypeID == 75) {  //Cust Attrib
      // Require the Customer Attribute
      this.f.customerAttributeID.setValidators(Validators.required);
      this.f.customerAttributeID.updateValueAndValidity();
      this.showCustomerAttribute = true;

      // Clear the Document Attribute
      this.priceCalculationForm.patchValue({ ['documentAttributeID']: null });
      this.f.documentAttributeID.clearValidators();
      this.f.documentAttributeID.updateValueAndValidity();
      this.showDocumentAttribute = false;

      // Clear the Item Attribute
      this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
      this.f.itemAttributeID.clearValidators();
      this.f.itemAttributeID.updateValueAndValidity();
      this.showItemAttribute = false;

      // Clear the Price Book
      this.priceCalculationForm.patchValue({ ['priceBookID']: null });
      this.f.priceBookID.clearValidators();
      this.f.priceBookID.updateValueAndValidity();
      this.showPriceBook = false;

      // Clear the Attribute Map
      this.priceCalculationForm.patchValue({ ['attributeMapID']: null });
      this.f.attributeMapID.clearValidators();
      this.f.attributeMapID.updateValueAndValidity();
      this.showAttributeMap = false;      
    }
    else if (entityTypeID == 70) { //Doc Attrib
      // Require the Document Attribute
      this.f.documentAttributeID.setValidators(Validators.required);
      this.f.documentAttributeID.updateValueAndValidity();
      this.showDocumentAttribute = true;

      // Clear the Customer Attribute
      this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
      this.f.customerAttributeID.clearValidators();
      this.f.customerAttributeID.updateValueAndValidity();
      this.showCustomerAttribute = false;

      // Clear the Item Attribute
      this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
      this.f.itemAttributeID.clearValidators();
      this.f.itemAttributeID.updateValueAndValidity();
      this.showItemAttribute = false;

      // Clear the Price Book
      this.priceCalculationForm.patchValue({ ['priceBookID']: null });
      this.f.priceBookID.clearValidators();
      this.f.priceBookID.updateValueAndValidity();
      this.showPriceBook = false;

      // Clear the Attribute Map
      this.priceCalculationForm.patchValue({ ['attributeMapID']: null });
      this.f.attributeMapID.clearValidators();
      this.f.attributeMapID.updateValueAndValidity();
      this.showAttributeMap = false;      
    }
    else if (entityTypeID == 77) {  //Price Book
      // Require the Price Book
      this.f.priceBookID.setValidators(Validators.required);
      this.f.priceBookID.updateValueAndValidity();
      this.showPriceBook = true;

      // Clear the Customer Attribute
      this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
      this.f.customerAttributeID.clearValidators();
      this.f.customerAttributeID.updateValueAndValidity();
      this.showCustomerAttribute = false;

      // Clear the Document Attribute
      this.priceCalculationForm.patchValue({ ['documentAttributeID']: null });
      this.f.documentAttributeID.clearValidators();
      this.f.documentAttributeID.updateValueAndValidity();
      this.showDocumentAttribute = false;

      // Clear the Item Attribute
      this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
      this.f.itemAttributeID.clearValidators();
      this.f.itemAttributeID.updateValueAndValidity();
      this.showItemAttribute = false;

      // Clear the Attribute Map
      this.priceCalculationForm.patchValue({ ['attributeMapID']: null });
      this.f.attributeMapID.clearValidators();
      this.f.attributeMapID.updateValueAndValidity();
      this.showAttributeMap = false;      
    }
    else if (entityTypeID == 85) { //Attrib Map
      // Require the Attribute Map
      this.f.attributeMapID.setValidators(Validators.required);
      this.f.attributeMapID.updateValueAndValidity();
      this.showAttributeMap = true;

      // Clear the Customer Attribute
      this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
      this.f.customerAttributeID.clearValidators();
      this.f.customerAttributeID.updateValueAndValidity();
      this.showCustomerAttribute = false;

      // Clear the Document Attribute
      this.priceCalculationForm.patchValue({ ['documentAttributeID']: null });
      this.f.documentAttributeID.clearValidators();
      this.f.documentAttributeID.updateValueAndValidity();
      this.showDocumentAttribute = false;

      // Clear the Item Attribute
      this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
      this.f.itemAttributeID.clearValidators();
      this.f.itemAttributeID.updateValueAndValidity();
      this.showItemAttribute = false;

      // Clear the Price Book
      this.priceCalculationForm.patchValue({ ['priceBookID']: null });
      this.f.priceBookID.clearValidators();
      this.f.priceBookID.updateValueAndValidity();
      this.showPriceBook = false;
    }
    else { //Item Attrib
      // Require the Item Attribute
      this.f.itemAttributeID.setValidators(Validators.required);
      this.f.itemAttributeID.updateValueAndValidity();
      this.showItemAttribute = true;

      // Clear the Customer Attribute
      this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
      this.f.customerAttributeID.clearValidators();
      this.f.customerAttributeID.updateValueAndValidity();
      this.showCustomerAttribute = false;

      // Clear the Document Attribute
      this.priceCalculationForm.patchValue({ ['documentAttributeID']: null });
      this.f.documentAttributeID.clearValidators();
      this.f.documentAttributeID.updateValueAndValidity();
      this.showDocumentAttribute = false;

      // Clear the Price Book
      this.priceCalculationForm.patchValue({ ['priceBookID']: null });
      this.f.priceBookID.clearValidators();
      this.f.priceBookID.updateValueAndValidity();
      this.showPriceBook = false;

      // Clear the Attribute Map
      this.priceCalculationForm.patchValue({ ['attributeMapID']: null });
      this.f.attributeMapID.clearValidators();
      this.f.attributeMapID.updateValueAndValidity();
      this.showAttributeMap = false;      
    }
  }

  priceModeChange(priceModeOptionID) {
    if (priceModeOptionID == 32 || priceModeOptionID == 74 || priceModeOptionID == 88) { // Fixed Adjustment

      this.showConvertToUofM = true;

      if (this?.f?.useValueFromAttribute) {
        if (this.f.useValueFromAttribute.value == true) {

          this.priceCalculationForm.patchValue({ ['pricePercent']: null });
          this.f.pricePercent.clearValidators();
          this.f.pricePercent.updateValueAndValidity();
          this.showPricePercent = false;

          this.priceCalculationForm.patchValue({ ['priceAmount']: null });
          this.f.priceAmount.clearValidators();
          this.f.priceAmount.updateValueAndValidity();
          this.showPriceAmount = false;

          this.showEntity = true;
          this.f.entityID.setValidators(Validators.required);
          this.f.entityID.updateValueAndValidity();

        }
        else {
          this.priceCalculationForm.patchValue({ ['pricePercent']: null });
          this.f.pricePercent.clearValidators();
          this.f.pricePercent.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['entityID']: null });
          this.f.entityID.clearValidators();
          this.f.entityID.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
          this.f.customerAttributeID.clearValidators();
          this.f.customerAttributeID.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
          this.f.itemAttributeID.clearValidators();
          this.f.itemAttributeID.updateValueAndValidity();

          this.showPricePercent = false;
          this.showPriceAmount = true;
          this.f.priceAmount.setValidators(Validators.required);
          this.f.priceAmount.updateValueAndValidity();
        }
      }
    } else if (priceModeOptionID == 33 || priceModeOptionID == 34 || priceModeOptionID == 35) { // Percentage
      this.showConvertToUofM = false;
      this.priceCalculationForm.patchValue({ ['convertToUofM']: false });
      this.priceCalculationForm.patchValue({ ['perUofMName']: null });
      this.f.perUofMName.clearValidators();
      this.f.perUofMName.updateValueAndValidity();

      if (this?.f?.useValueFromAttribute) {
        if (this.f.useValueFromAttribute.value == true) {

          this.priceCalculationForm.patchValue({ ['pricePercent']: null });
          this.f.pricePercent.clearValidators();
          this.f.pricePercent.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['priceAmount']: null });
          this.f.priceAmount.clearValidators();
          this.f.priceAmount.updateValueAndValidity();

          this.showPricePercent = false;
          this.showPriceAmount = false;
          this.showEntity = true;
          this.f.entityID.setValidators(Validators.required);
          this.f.entityID.updateValueAndValidity();
        }
        else {
          this.priceCalculationForm.patchValue({ ['priceAmount']: null });
          this.f.priceAmount.clearValidators();
          this.f.priceAmount.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['entityID']: null });
          this.f.entityID.clearValidators();
          this.f.entityID.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
          this.f.customerAttributeID.clearValidators();
          this.f.customerAttributeID.updateValueAndValidity();

          this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
          this.f.itemAttributeID.clearValidators();
          this.f.itemAttributeID.updateValueAndValidity();

          this.showPricePercent = true;
          this.showPriceAmount = false;
          this.f.pricePercent.setValidators(Validators.required);
          this.f.pricePercent.updateValueAndValidity();
        }
      }
    }
    else {
      this.showConvertToUofM = false;
      this.priceCalculationForm.patchValue({ ['convertToUofM']: false });

      this.priceCalculationForm.patchValue({ ['entityID']: null });
      this.f.entityID.clearValidators();
      this.f.entityID.updateValueAndValidity();
      this.showEntity = false;

      this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
      this.f.customerAttributeID.clearValidators();
      this.f.customerAttributeID.updateValueAndValidity();
      this.showCustomerAttribute = false;

      this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
      this.f.itemAttributeID.clearValidators();
      this.f.itemAttributeID.updateValueAndValidity();
      this.showItemAttribute = false;

      this.priceCalculationForm.patchValue({ ['pricePercent']: null });
      this.f.pricePercent.clearValidators();
      this.f.pricePercent.updateValueAndValidity();
      this.showPricePercent = false;

      this.priceCalculationForm.patchValue({ ['priceAmount']: null });
      this.f.priceAmount.clearValidators();
      this.f.priceAmount.updateValueAndValidity();
      this.showPriceAmount = false;
    }
    //disable Use Value From Entity if No Change or Custom SQL, otherwise enable.
    if (priceModeOptionID == 31 || priceModeOptionID == 59) {
      this.f.useValueFromAttribute.patchValue(null);
      this.f.useValueFromAttribute.updateValueAndValidity();
      this.f.useValueFromAttribute.disable();
      this.useValueFromAttributeChanged();
    }
    else {
      this.f.useValueFromAttribute.enable();
    }
  }

  useValueFromAttributeChanged() {
    if (this?.f?.useValueFromAttribute) {
      if (this.f.useValueFromAttribute.value == true) {
        this.priceCalculationForm.patchValue({ ['pricePercent']: null });
        this.f.pricePercent.clearValidators();
        this.f.pricePercent.updateValueAndValidity();
        this.showPricePercent = false;

        this.priceCalculationForm.patchValue({ ['priceAmount']: null });
        this.f.priceAmount.clearValidators();
        this.f.priceAmount.updateValueAndValidity();
        this.showPriceAmount = false;

        this.priceCalculationForm.patchValue({ ['entityID']: null });
        this.f.entityID.setValidators(Validators.required);
        this.f.entityID.updateValueAndValidity();
        this.showEntity = true;
      }
      else {
        this.priceCalculationForm.patchValue({ ['entityID']: null });
        this.f.entityID.clearValidators();
        this.f.entityID.updateValueAndValidity();
        this.showEntity = false;
        this.priceCalculationForm.patchValue({ ['customerAttributeID']: null });
        this.f.customerAttributeID.clearValidators();
        this.f.customerAttributeID.updateValueAndValidity();
        this.showCustomerAttribute = false;
        this.priceCalculationForm.patchValue({ ['documentAttributeID']: null });
        this.f.documentAttributeID.clearValidators();
        this.f.documentAttributeID.updateValueAndValidity();
        this.showDocumentAttribute = false;
        this.priceCalculationForm.patchValue({ ['itemAttributeID']: null });
        this.f.itemAttributeID.clearValidators();
        this.f.itemAttributeID.updateValueAndValidity();
        this.showItemAttribute = false;
        this.priceCalculationForm.patchValue({ ['priceBookID']: null });
        this.f.priceBookID.clearValidators();
        this.f.priceBookID.updateValueAndValidity();
        this.showPriceBook = false;
        this.priceCalculationForm.patchValue({ ['attributeMapID']: null });
        this.f.attributeMapID.clearValidators();
        this.f.attributeMapID.updateValueAndValidity();
        this.showAttributeMap = false;
        this.priceCalculationForm.patchValue({ ['abortIfValueNotFound']: null });

        if (this?.f?.priceModeOptionID) {
          if (this.f.priceModeOptionID.value == 32 || this.f.priceModeOptionID.value == 74) {
            this.f.priceAmount.setValidators(Validators.required);
            this.f.priceAmount.updateValueAndValidity();
            this.showPriceAmount = true;
            this.f.pricePercent.clearValidators();
            this.f.pricePercent.updateValueAndValidity();
            this.showPricePercent = false;
          }
          else if (this.f.priceModeOptionID.value == 33 || this.f.priceModeOptionID.value == 34 || this.f.priceModeOptionID.value == 35) {
            this.f.priceAmount.clearValidators();
            this.f.priceAmount.updateValueAndValidity();
            this.showPriceAmount = false;
            this.f.pricePercent.setValidators(Validators.required);
            this.f.pricePercent.updateValueAndValidity();
            this.showPricePercent = true;
          }
          else {
            this.showPriceAmount = false;
            this.showPricePercent = false;
          }
        }
      }
    }
  }

  convertToUofMChanged() {
    if (this?.f?.convertToUofM) {
      if (this.f.convertToUofM.value == true) {
        this.f.perUofMName.setValidators(Validators.required);
        this.f.perUofMName.updateValueAndValidity();
      }
      else {
        this.priceCalculationForm.patchValue({ ['perUofMName']: null });
        this.f.perUofMName.clearValidators();
        this.f.perUofMName.updateValueAndValidity();
      }
      this.convertToUofMValue = this.f.convertToUofM.value
    }
  }

 async trackAsAdjustmentChanged() {
    if (this?.f?.trackAdjustment) {
      if (this.f.trackAdjustment.value == false) {
        this.priceCalculationForm.patchValue({ ['creditGLAccountID']: null });
        this.f.creditGLAccountID.clearValidators();
        this.f.creditGLAccountID.updateValueAndValidity();
        this.priceCalculationForm.patchValue({ ['debitGLAccountID']: null });
        this.f.debitGLAccountID.clearValidators();
        this.f.debitGLAccountID.updateValueAndValidity();
      }
      this.trackAdjustmentValue = this.f.trackAdjustment.value
      await this.gLAccountIDsChanged();
    }
  }

  async gLAccountIDsChanged() {
    if (this.f.debitGLAccountID.value || this.f.creditGLAccountID.value) {
      this.f.creditGLAccountID.setValidators(Validators.required);
      this.f.creditGLAccountID.updateValueAndValidity();
      this.f.debitGLAccountID.setValidators(Validators.required);
      this.f.debitGLAccountID.updateValueAndValidity();
      this.priceCalculationForm.updateValueAndValidity();
      this.areGLAccountsRequired = true;
    }
    else {
      this.f.creditGLAccountID.clearValidators();
      this.f.creditGLAccountID.updateValueAndValidity();
      this.f.debitGLAccountID.clearValidators();
      this.f.debitGLAccountID.updateValueAndValidity();
      this.priceCalculationForm.updateValueAndValidity();
      this.areGLAccountsRequired = false;
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.PriceModeOption:
        {
          const Filter_OptionSet = 'PriceMode';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.priceModeOptions = await this.lookupService.getList(params);
        }
        break;
      case Filters.DebitGLAccount:
        this.debitGLAccounts = await (
          await this.gLAccountsService.getList(params)
        ).body;
        break;
      case Filters.CreditGLAccount:
        this.creditGLAccounts = await (
          await this.gLAccountsService.getList(params)
        ).body;
        break;
      case Filters.RoundingRule:
        this.roundingRules = await (
          await this.roundingRulesService.getList(params)
        ).body;
        break;
      case Filters.CustomerAttribute:
        this.customerAttributes = await (
          await this.customerAttributesService.getList(params)
        ).body;
        break;
      case Filters.DocumentAttribute:
        this.documentAttributes = await (
          await this.documentAttributesService.getList(params)
        ).body;
        break;
      case Filters.ItemAttribute:
        this.itemAttributes = await (
          await this.itemAttributesService.getList(params)
        ).body;
        break;
      case Filters.PricingFilter:
        this.pricingFilters = await (
          await this.pricingFiltersService.getList(params)
        ).body;
        break;
      case Filters.PricingCondition:
        this.pricingConditions = await (
          await this.pricingConditionsService.getList(params)
        ).body;
        break;
      case Filters.PriceBook:
        this.priceBooks = await (
          await this.priceBooksService.getList(params)
        ).body;
        break;
      case Filters.AttributeMap:
        this.attributeMaps = await (
          await this.attributeMapsService.getList(params)
        ).body;
        break;
      default:
        break;
    }
  }

  async onLookup_debitGLAccount() {
    const modalRef = this.modalService.open(GLAccountLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "GLAccountModalClass",
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "GLAccountDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: GLAccount = receivedEntry;
      this.debitGLAccounts = [];
      this.debitGLAccounts.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['debitGLAccountID']: receivedEntry.id });
      this.priceCalculation.debitGLAccount = receivedEntry;
      this.priceCalculation.debitGLAccountID = receivedEntry.id; 
      this.gLAccountIDsChanged();
      modalRef.close('test');
    });
  }

  async onLookup_creditGLAccount() {
    const modalRef = this.modalService.open(GLAccountLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "GLAccountModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "GLAccountDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: GLAccount = receivedEntry;
      this.creditGLAccounts = [];
      this.creditGLAccounts.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['creditGLAccountID']: receivedEntry.id });
      this.priceCalculation.creditGLAccount = receivedEntry;
      this.priceCalculation.creditGLAccountID = receivedEntry.id; 
      this.gLAccountIDsChanged();
      modalRef.close('test');
    });
  }

  async onLookup_pricingFilter() {
    const modalRef = this.modalService.open(PricingFilterLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PricingFilterModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PricingFilterDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingFilter = receivedEntry;
      this.pricingFilters = [];
      this.pricingFilters.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['pricingFilterID']: receivedEntry.id });
      this.priceCalculation.pricingFilter = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_pricingCondition() {
    const modalRef = this.modalService.open(PricingConditionLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PricingConditionModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "PricingConditionDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PricingCondition = receivedEntry;
      this.pricingConditions = [];
      this.pricingConditions.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['pricingConditionID']: receivedEntry.id });
      this.priceCalculation.pricingCondition = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_roundingRule() {
    const modalRef = this.modalService.open(RoundingRuleLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'RoundingRuleModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "RoundingRuleDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: RoundingRule = receivedEntry;
      this.roundingRules = [];
      this.roundingRules.push(recordSelected);
      this.priceCalculationForm.patchValue({ ['roundingRuleID']: receivedEntry.id });
      this.priceCalculation.roundingRule = receivedEntry;
      modalRef.close('test');
    });
  }


  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
