import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemJobParametersService } from '../../../services/system-job-parameters/system-job-parameters.service';
import { SystemJobParameter } from '../../../services/system-job-parameters/system-job-parameters.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { SystemJobParameterSingleComponent } from '../system-job-parameter-single/system-job-parameter-single.component';
import { ColumnSettings } from "../../../../app/utils/column-settings.interface";
import { StorageService } from '../../../../app/utils/StorageHelper';
import { ImportsService } from '../../../services/imports/imports.service'
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-system-job-parameter-list',
  templateUrl: './system-job-parameter-list.component.html',
  styleUrls: ['./system-job-parameter-list.component.scss'],
})
export class SystemJobParameterListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;
  constructor(
    private SystemJobParametersService: SystemJobParametersService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private datePipe: DatePipe,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() systemJobID: number;
  @Input() SystemJobParameters: SystemJobParameter[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'sequenceNumber', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: SystemJobParameter[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public globalRecordCount: number = 1;
  public clickedRowItem;
  @Input() gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'sequenceNumber',
      dir: 'asc',
    },
  ];

  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Sequence',
      field: 'sequenceNumber',
      width: 150,
      orderIndex: 0,
      hidden: false
    },
    {
      title: 'Name',
      field: 'name',
      width: 150,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Data Type',
      field: 'dataType.name',
      width: 150,
      orderIndex: 2,
      hidden: false
    },
    {
      title: 'Entity Name',
      field: 'entityType.name',
      width: 150,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Value',
      field: 'value',
      width: 300,
      orderIndex: 4,
      hidden: false
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClickCopy() {
    try {
      const ids = [];
      this.mySelection.forEach(async (value) => {
        const response: any = await this.copyService.performCopy(null, "RPMSystemJobParameter", Number(value));
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Completed Copy successfully!' + response.body,
            '',
            false,
            'Success',
            'alert-success',
          );
          this.loadListData();
        }
      });
    } catch (e) {
      this.snack.openSnackBar(e.parameter, '', true, 'Error', 'alert-danger');
    }
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords2() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.SystemJobParametersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async DeleteRecords() {
    const ids = this.mySelection.map(Number);
    const fetchPromises = ids.map(id =>
      this.SystemJobParametersService.getById(id).then(record =>
        ({ id, isSystemDefined: record.isSystemDefined })
      )
    );
    const records = await Promise.all(fetchPromises);
    const idsToDelete = records.filter(record => !record.isSystemDefined).map(record => record.id);
    const systemDefinedIds = records.filter(record => record.isSystemDefined).map(record => record.id);
    if (systemDefinedIds.length > 0) {
      this.notificationHelper.showStatus('Some records could not be deleted because they are system-defined.', 'error');
    }
    if (idsToDelete.length > 0) {
      await this.SystemJobParametersService.removeByIds(idsToDelete)
        .then(() => {
          this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
          this.mySelection = this.mySelection.filter(id => !idsToDelete.includes(Number(id)));
          this.loadListData();
        })
        .catch((err: HttpErrorResponse) => {
          this.notificationHelper.showStatus(err.error, "error");
        });
    } else {
      this.loadListData();
    }
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('SystemJobParameterList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('SystemJobParameterList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('SystemJobParameterList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('SystemJobParameterList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;

    if (this.mode == 'view' || this.mode == 'lookup') {
      this.loadListData();
      this.gridClassName = "DefaultGrid";
    }
    //else if (this.mode == 'subgrid') {
    //  this.loadSubGridData();
    //  this.gridClassName = "SystemJobParameterSubgridClass";
    //}

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        if (this.mode == "view") {
          let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
          if (SideNavExpanded_Config == true) {
            this.gridClassName = "DefaultGrid"
          }
          else {
            this.gridClassName = "FullScreenGrid"
          }
        }
      },
    );
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.systemJobID) {
        const response: any = await this.SystemJobParametersService.getList({
          Filter_SystemJobID: this.systemJobID, ...this.query
        });
        this.SystemJobParameters = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.SystemJobParameters,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        //this.router.navigate(['/price/system-job-parameter/' + this.clickedRowItem.id]);
        this.onEdit(this.clickedRowItem);
      }
    }
  }

  onEdit(systemJobParameter) {
    const modalRef = this.modalService.open(SystemJobParameterSingleComponent);
    modalRef.componentInstance.systemJobParameter = systemJobParameter;
    modalRef.componentInstance.idOfSystemJobParameter = systemJobParameter.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }


  onClickAddNew() {
    const modalRef = this.modalService.open(
      SystemJobParameterSingleComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: "SystemJobParameterModalClass",
        centered: true,
        backdrop: 'static',
      },
    );
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }


  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;

        //mhr this code ChatGPT made more universal
        const fieldName = (myFilter.field as string).replace(/\./g, '').toLowerCase(); //remove periods
        let filterValue = myFilter.value || "";
        if (fieldName.includes('date')) {
          filterValue = this.datePipe.transform(myFilter.value, "MM/dd/yyyy");
        }
        if (fieldName.includes('use')) { //need to specify booleans to be false rather than blank
          filterValue = myFilter.value || false;
        }
        const filterField = `Filter_${fieldName}`;
        const operandField = `Operand_${fieldName}`;
        const params = { ...this.query, [filterField]: filterValue, [operandField]: myFilter.operator };
        this.query = params;
      });
    }
    this.onFilterChange();
  }
  edit(id: number) {
    this.router.navigate([`/price/system-job-parameter/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.SystemJobParametersService.getExportList(this.query);
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.SystemJobParametersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    let fieldName = sort[0].field.toLowerCase();
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = fieldName;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + fieldName;
    } else {
      this.query.SortTerm = fieldName;
    }
    this.loadListData();
  }
}
