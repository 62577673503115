import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { emptySyncReaderColumn, SyncReaderColumn } from '../../../services/sync-reader-columns/sync-reader-columns.model';
import { SyncReaderColumnsService } from '../../../services/sync-reader-columns/sync-reader-columns.service';
import { SyncReadersService } from '../../../services/sync-readers/sync-readers.service';
import { SyncReader } from '../../../services/sync-readers/sync-readers.model';

@Component({
  selector: 'app-sync-reader-column-single',
  templateUrl: './sync-reader-column-single.component.html',
  styleUrls: ['./sync-reader-column-single.component.scss'],
})
export class SyncReaderColumnSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncReaderColumn: number | null = null;
  @Input() idOfSyncReader: number | null = null;
  @Input() syncReaderColumn: SyncReaderColumn = emptySyncReaderColumn;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  myResponse: string;
  syncReaderColumnForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;


  constructor(
    private syncReaderColumnsService: SyncReaderColumnsService,
    private syncReadersService: SyncReadersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfSyncReaderColumn) {
      this.loadData();
    }
    if (this.idOfSyncReader) {
      this.syncReaderColumn.syncReaderID = this.idOfSyncReader;
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncReaderColumnForm = new FormGroup({
      syncReaderID: new FormControl(''),
      sequenceNumber: new FormControl(null, Validators.required),
      columnName: new FormControl('', [Validators.maxLength(50), Validators.required]),
    });
    this.syncReaderColumnForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  get f() {
    return this.syncReaderColumnForm.controls;
  }
  updateForm() {
    this.syncReaderColumnForm.patchValue({ ...this.syncReaderColumn });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async checkForExistingSequenceNumber() {
    var sequenceNumberExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const sequenceNumber = this.syncReaderColumnForm.controls['sequenceNumber'].value;
    const Filter_SyncReaderID = this.idOfSyncReader;
    const params = { Filter_SyncReaderID, PageSize };
    const existingSyncReaderColumns: SyncReaderColumn[] = await (await this.syncReaderColumnsService.getList(params)).body;
    if (existingSyncReaderColumns.length > 0) {
      for (const key in existingSyncReaderColumns) {
        const currentSyncReaderColumn: SyncReaderColumn = existingSyncReaderColumns[key];
        if (currentSyncReaderColumn.sequenceNumber == sequenceNumber) {
          if (currentSyncReaderColumn.id != this.syncReaderColumn.id) {
            sequenceNumberExists = true;
          }
        }
      }
    }
    return sequenceNumberExists;
  }


  async saveRecord() {
    this.syncReaderColumnForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncReaderColumnForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncReaderColumn,
      ...this.syncReaderColumnForm.value,
    };
    // Check to see if Sequence Number already exists.
    if ((await this.checkForExistingSequenceNumber()) === true) {
      this.notificationHelper.showStatusOnDialog('Sequence Number already exists!', 'error', this.container);
    }
    else {
      if (this.syncReaderColumn.id) {
        try {
          const response: any = await this.syncReaderColumnsService.update(
            this.syncReaderColumn.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {

          const response: any = await this.syncReaderColumnsService.create(
            this.syncReaderColumnForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }
  }


  async getSyncReaderColumnById() {
    if (this.idOfSyncReaderColumn) {
      return await this.syncReaderColumnsService.getById(
        this.idOfSyncReaderColumn,
      );
    } else {
      return emptySyncReaderColumn;
    }
  }
  async loadData() {
    try {
      [this.syncReaderColumn] = await Promise.all([
        this.getSyncReaderColumnById(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

}
