import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { UofM } from '../../../services/uofms/uofms.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { UofMSchedule } from 'src/app/services/uofm-schedules/uofm-schedules.model';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';

@Component({
  selector: 'app-uofm-lookup',
  templateUrl: './uofm-lookup.component.html',
  styleUrls: ['./uofm-lookup.component.scss'],
})
export class UofMLookupComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private uofMsService: UofMsService,
    public activeModal: NgbActiveModal
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() uofMs: UofM[] = [];
  @Input() uofMScheduleID: number;
  @Input() itemID: number;
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Input() defaultUofMSchedule: UofMSchedule;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  public isAdmin: boolean;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: UofM[];
  public exportAll: boolean;
  public clickedRowItem;
  public gridClassName: string = "UofMSubgridClass";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Unit of Measure Schedule',
      field: 'uofMSchedule.name',
      width: 250,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Is Base',
      field: 'isBase',
      width: 100,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Quantity in Base',
      field: 'quantityInBase',
      width: 200,
      orderIndex: 3,
      hidden: false,
    },
    {
      title: 'ERP ID',
      field: 'erpid',
      width: 250,
      orderIndex: 4,
      hidden: true,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('UofMLookup_Config');
    this.columnsConfig = this.defaultColumnsConfig;
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('UofMLookup_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('UofMLookup_Config', this.columnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('UofMLookup_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.isAdmin = StorageService.IsAdmin();
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;   
      this.loadListData();
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  onClick_Save() {
    this.onDblClick();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onSelectSingle.emit(this.clickedRowItem);
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'uofMSchedule.name') {
          const Filter_UofMSchedule = myFilter.value;
          const Operand_UofMSchedule = myFilter.operator;
          const params = {
            ...this.query,
            Filter_UofMSchedule,
            Operand_UofMSchedule,
          };
          this.query = params;
        }
        if (myFilter.field == 'isBase') {
          const Filter_IsBase = myFilter.value;
          const Operand_IsBase = myFilter.operator;
          const params = { ...this.query, Filter_IsBase, Operand_IsBase };
          this.query = params;
        }
        if (myFilter.field == 'quantityInBase') {
          const Filter_QuantityInBase = myFilter.value;
          const Operand_QuantityInBase = myFilter.operator;
          const params = {
            ...this.query,
            Filter_QuantityInBase,
            Operand_QuantityInBase,
          };
          this.query = params;
        }

        if (myFilter.field == 'erpid') {
          const Filter_ERP = myFilter.value;
          const Operand_ERP = myFilter.operator;
          const params = { ...this.query, Filter_ERP, Operand_ERP };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {

      const response: any = await this.uofMsService.getList({
        Filter_UofMScheduleID: this.uofMScheduleID,
        ...this.query
      });
      this.uofMs = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.uofMs,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  
  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
