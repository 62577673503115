import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SyncProcessingService {
  model = 'RPMSyncProcessing';
  constructor(private httpClient: HttpClient,  
  ) { }
  
  async performSync(data): Promise<HttpResponse<string>> {
    const headerValues = new HttpHeaders({ 'Content-Type': 'application/*+json' });
    headerValues.append('Content-Type', 'application/*+json');

    return this.httpClient
      .post(this.model, data, { headers: headerValues, responseType: 'text', observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<string>>;
  }

}
