export interface SystemJobParameter {
  id: number;
  sequenceNumber: number;
  name: string;
  systemJobID: number;
  systemJob: {
    id: number;
    name: string;
  };
  isSystemDefined: boolean;
  dataTypeID: number;
  dataType: {
    id: number;
    name: string;
  };
  lookupListOptionSet: string;
  entityTypeID: number;
  entityType: {
    id: number;
    name: string;
  };
  entityRecordID: number;
  value: string;
  sqlScriptText: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptySystemJobParameter: SystemJobParameter = {
  id: null,
  sequenceNumber: null,
  name: '',
  systemJobID: null,
  systemJob: {
    id: null,
    name: ''
  },
  isSystemDefined: false,
  dataTypeID: null,
  dataType: {
    id: null,
    name: ''
  },
  lookupListOptionSet: '',
  entityTypeID: null,
  entityType: {
    id: null,
    name: ''
  },
  entityRecordID: null,
  value: '',
  sqlScriptText: '',
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
