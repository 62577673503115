<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Catalog.png" alt="Catalog" style="padding-right: 5px;">
  Create / Update Catalog
</div>

<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/catalogs']" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">SAVE & CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">COPY</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Price_Calculation.png" (click)="onClickCalculate()" title="Calculate Catalog">CALCULATE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Actions.png" (click)="onClickBenchmark()" title="Benchmark Catalog">BENCHMARK</button>
</kendo-buttongroup>

<form [formGroup]="catalogForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Catalog Name">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Catalog Description">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
</form>
<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-catalog-sheet-list #subgrid [catalogID]='idOfCatalog'
                          [pagination]="paginationObject" [loading]="loading"
                          [mode]="subgridmode">
  </app-catalog-sheet-list>
</div>
<!--<div class="row">
  <div class="col">
    <button type="submit" class="btn btn-outline-secondary" [routerLink]="['/price/catalogs']"
      routerLinkActive="router-link-active">
      Go Back
    </button>
  </div>
  <div class="col flex-grow-1"></div>
  <div class="align-content-end">
    <button type="submit" (click)="submit()" class="btn btn-primary mr-3">
      Save
    </button>
  </div>
</div>-->
