import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogLinesService } from '../../../services/catalog-lines/catalog-lines.service';
import { CatalogLine, emptyCatalogLine } from '../../../services/catalog-lines/catalog-lines.model';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
//import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { Customer } from 'src/app/services/customers/customers.model';
import { CustomersService } from '../../../services/customers/customers.service';
import { emptyItem, Item } from 'src/app/services/items/items.model';
import { ItemsService } from '../../../services/items/items.service';
import { UofM } from 'src/app/services/uofms/uofms.model';
import { UofMsService } from '../../../services/uofms/uofms.service';
import { Location } from 'src/app/services/locations/locations.model';
import { LocationsService } from '../../../services/locations/locations.service';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { emptyPriceSchedule, PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { emptyCurrency, Currency } from '../../../services/currencies/currencies.model';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { UofMLookupComponent } from '../../uofms/uofm-lookup/uofm-lookup.component';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { PriceScheduleLookupComponent } from '../../price-schedules/price-schedule-lookup/price-schedule-lookup.component';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { emptyCatalog } from '../../../services/catalogs/catalog.model';
import { SettingsService } from '../../../services/settings/settings.service';
import { Setting } from 'src/app/services/settings/settings.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';

enum Filters {
  Customer = 1,
  Item,
  UofM,
  Location,
  PriceSchedule,
  Currency,
}
@Component({
  selector: 'app-catalog-line-single',
  templateUrl: './catalog-line-single.component.html',
  styleUrls: ['./catalog-line-single.component.scss'],
})
export class CatalogLineSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfCatalogLine: number | null = null;
  @Input() idOfCatalogSheet: number | null = null;
  catalogLine: CatalogLine = emptyCatalogLine;
  loading: boolean = true;
  catalogLineForm: FormGroup;
  submitted = false;
  customers: Customer[] = [];
  items: Item[] = [];
  uofMs: UofM[] = [];
  locations: Location[] = [];
  priceSchedules: PriceSchedule[] = [];
  calculatedPriceSchedules: PriceSchedule[] = [];
  currencies: Currency[] = [];
  pagination_customers: any = { customers: mockedData };
  pagination_items: any = { items: mockedData };
  pagination_uofMs: any = { uofMs: mockedData };
  pagination_locations: any = { locations: mockedData };
  pagination_priceSchedules: any = { priceSchedules: mockedData };
  pagination_calculatedPriceSchedules: any = { calculatedPriceSchedules: mockedData };
  pagination_currencies: any = { currencies: mockedData };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  effectiveDate: Date = new Date();
  currencyID: number;
  singleCurrency: boolean;

  constructor(
    private route: ActivatedRoute,
    private catalogLinesService: CatalogLinesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private uofMsService: UofMsService,
    private locationsService: LocationsService,
    private priceSchedulesService: PriceSchedulesService,
    private currenciesService: CurrenciesService,
    private settingsService: SettingsService,
    private snack: MySnackBarService,
    private router: Router,
    private notificationHelper: NotificationHelper
  ) {     
  }

  ngOnInit(): void {   

    if (this.idOfCatalogLine) {
      this.loadData();
    }
    else {
      this.catalogLine = emptyCatalogLine;
      this.catalogLine.priceSchedule = emptyPriceSchedule;
      this.catalogLine.calculatedPriceSchedule = emptyPriceSchedule;
      this.catalogLine.currency = emptyCatalog;
      this.catalogLine.catalogSheetID = this.idOfCatalogSheet;
      this.idOfCatalogLine = null;
      this.submitted = false;      
    }
    this.buildForm();
    this.updateForm();
  }
  async buildForm() {
    this.catalogLineForm = new FormGroup({
      catalogSheetID: new FormControl(),
      effectiveDate: new FormControl(),
      customerID: new FormControl(),
      itemID: new FormControl(),
      uofMID: new FormControl(),
      locationID: new FormControl(),
      currencyID: new FormControl(),
      currencyLabelName: new FormControl(),
      quantity: new FormControl(),
      priceScheduleID: new FormControl(),
      externalItemNumber: new FormControl(),
      calculatedPriceScheduleID: new FormControl(),
      calculatedUnitPrice: new FormControl(),
      benchmarkedUnitPrice: new FormControl(),
      lastCalculatedDateTime: new FormControl(),
      lastBenchmarkedDateTime: new FormControl(),
      comment: new FormControl(),
      processStatus: new FormControl(),
      errorMessage: new FormControl()
    });

    this.catalogLineForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.getUseSingleCurrencySettings();
  }
  get f() {
    return this.catalogLineForm.controls;
  }
  updateForm() {
    this.catalogLineForm.patchValue({ ...this.catalogLine });
    this.isFormChanged = false;
  }    
  
  onSelectBoxChanged(id: number, key: keyof CatalogLine, arr) {
    let item = null;
    console.log('key: ' + key);
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.catalogLine[key] = item;
    if (key == 'item') {
      this.uofMs = [];
      this.catalogLineForm.patchValue({ ['uofMID']: null });
    }
  }

  async submit() {
    this.catalogLineForm.markAllAsTouched();
    this.submitted = true;
    //need some way to keep the form open and check for at least one value being present; this here is not working
    //if (this.catalogLine.customerID == null && this.catalogLine.itemID == null && this.catalogLine.effectiveDate == null
    //  && this.catalogLine.uofMID == null && this.catalogLine.currencyID == null && this.catalogLine.locationID == null) {
    //  alert('Error: At least one of these fields must have data: Customer, Item, Date, UofM, Currency, or Location.');
    //  return;
    //}
    if (this.catalogLineForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.catalogLine,
      ...this.catalogLineForm.value,
    };

    if (this.catalogLine.id) {
      try {
        const response: any = await this.catalogLinesService.update(
          this.catalogLine.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatusOnDialog('Record updated successfully!', "success", this.container);
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.catalogLinesService.create(
          this.catalogLineForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.onClick_Close('test');
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }

  onClick_Save() {
    this.submit();
  }

  onClick_SaveClose() {
    this.submit();
    this.onClick_Close("");
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }
  async getCatalogLineById() {
    if (this.idOfCatalogLine) {
      return await this.catalogLinesService.getById(
        this.idOfCatalogLine,
      );
    } else {
      return emptyCatalogLine;
    }
  }

  async getCustomerById(id) {
    return await this.customersService.getById(id);
  }  
  async getItemById(id) {
    return await this.itemsService.getById(id);
  }  
  async getUofMById(id) {
    return await this.uofMsService.getById(id);
  }
  async getLocationById(id) {
    return await this.locationsService.getById(id);
  }
  async getPriceScheduleById(id) {
    return await this.priceSchedulesService.getById(id);
  }
  async getCurrencyById(id) {
    return await this.currenciesService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      //[
      //  this.catalogLine,
      //] = await Promise.all([
      //  this.getCatalogLineById(),
      //  this.idOfCatalogLine ? this.getQuantityBreaks(null) : [],  //mhr is this needed, without the qtybreaks part? I put it on the next line
      //]);

      [this.catalogLine] = await Promise.all([this.getCatalogLineById()]);

      // Customers     
      if (this.catalogLine.customerID) {        
        const currentCustomer: Customer = await Promise.resolve(this.getCustomerById(this.catalogLine.customerID));
        this.customers.push(currentCustomer);
      }

      // Items     
      if (this.catalogLine.itemID) {
        const currentItem: Item = await Promise.resolve(this.getItemById(this.catalogLine.itemID));
        this.items.push(currentItem);
      }

      // UofMs     
      if (this.catalogLine.uofMID) {
        const currentUofM: UofM = await Promise.resolve(this.getUofMById(this.catalogLine.uofMID));
        this.uofMs.push(currentUofM);
      }

      // Location      
      if (this.catalogLine.locationID) {
        const currentLocation: Location = await Promise.resolve(this.getLocationById(this.catalogLine.locationID));
        this.locations.push(currentLocation);
      }

      // Price Schedule      
      if (this.catalogLine.priceScheduleID) {
        const currentPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleById(this.catalogLine.priceScheduleID));
        this.priceSchedules.push(currentPriceSchedule);
      }
      if (this.catalogLine.calculatedPriceScheduleID) {
        const currentCalculatedPriceSchedule: PriceSchedule = await Promise.resolve(this.getPriceScheduleById(this.catalogLine.calculatedPriceScheduleID));
        this.calculatedPriceSchedules.push(currentCalculatedPriceSchedule);
        //this.catalogLineForm.patchValue({ ['calculatedPriceScheduleID']: currentCalculatedPriceSchedule.id });
      }

      // Currency      
      if (this.catalogLine.currencyID) {
        const currentCurrency: Currency = await Promise.resolve(this.getCurrencyById(this.catalogLine.currencyID));
        this.currencies.push(currentCurrency);
      }

      // Effective Date
      if (this.catalogLine.effectiveDate) {
        this.catalogLine.effectiveDate = new Date(this.catalogLine.effectiveDate)
      }

      this.updateForm();
    } catch (e) {
    } finally {
      this.loading = false;
    }

    this.onFormChanged.emit(false);
  }

  async getCustomers(params) {
    try {
      const resp = await this.customersService.getList({
        ...params,
      });
      // @ts-ignore
      this.customers = resp.body;
      this.pagination_customers.customers = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.customers = [];
    }
    return this.customers;
  }

  async onLookup_Customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.catalogLineForm.patchValue({['customerID']: receivedEntry.id });
      this.catalogLine.customer = receivedEntry;
      modalRef.close('test');
    });
  }

  async getItems(params) {
    try {
      const resp = await this.itemsService.getList({
        ...params,
      });
      // @ts-ignore
      this.items = resp.body;
      this.pagination_items.items = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.items = [];
    }
    return this.items;
  }

  async onLookup_Item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.catalogLineForm.patchValue({ ['itemID']: receivedEntry.id });
      this.uofMs = [];
      this.catalogLineForm.patchValue({ ['uofMID']: null });
      this.catalogLine.item = receivedEntry;
      modalRef.close('test');
    });
  }

  async getUofMs(params) {
    try {
      const resp = await this.uofMsService.getList({
        ...params,
      });
      // @ts-ignore
      this.uofMs = resp.body;
      this.pagination_uofMs.uofMs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.uofMs = [];
    }
    return this.uofMs;
  }

  async onLookup_UofM() {
    const modalRef = this.modalService.open(UofMLookupComponent, {});
    modalRef.componentInstance.uofMScheduleID = this.catalogLine.item.uofMScheduleID;
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: UofM = receivedEntry;
      this.uofMs = [];
      this.uofMs.push(recordSelected);
      this.catalogLineForm.patchValue({['uofMID']: receivedEntry.id});
      this.catalogLine.uofM = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_PriceSchedule() {
    const modalRef = this.modalService.open(PriceScheduleLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: PriceSchedule = receivedEntry;
      this.priceSchedules = [];
      this.priceSchedules.push(recordSelected);
      this.catalogLineForm.patchValue({ ['priceScheduleID']: receivedEntry.id });
      this.catalogLine.priceSchedule = receivedEntry;
      modalRef.close('test');
    });
  }

  async onLookup_Currency() {
    const modalRef = this.modalService.open(CurrencyLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.catalogLineForm.patchValue({ ['currencyID']: receivedEntry.id });
      this.catalogLine.currency = receivedEntry;
      modalRef.close('test');
    });
  }

  async getLocations(params) {
    try {
      const resp = await this.locationsService.getList({
        ...params,
      });
      // @ts-ignore
      this.locations = resp.body;
      this.pagination_locations.locations = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.locations = [];
    }
    return this.locations;
  }

  async getPriceSchedules(params) {
    try {
      const resp = await this.priceSchedulesService.getList({
        ...params,
      });
      // @ts-ignore
      this.priceSchedules = resp.body;
      this.pagination_priceSchedules.priceSchedules = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.priceSchedules = [];
    }
    return this.priceSchedules;
  }

  async getCurrencies(params) {
    try {
      const resp = await this.currenciesService.getList({
        ...params,
      });
      // @ts-ignore
      this.currencies = resp.body;
      this.pagination_currencies.currencies = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.currencies = [];
    }
    return this.currencies;
  }

  async onLookup_Location() {
    const modalRef = this.modalService.open(LocationLookupComponent, {    });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.catalogLineForm.patchValue({['locationID']: receivedEntry.id});
      this.catalogLine.location = receivedEntry;
      modalRef.close('test');
    });
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_UofMScheduleID = this.catalogLine.item.uofMScheduleID;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.Customer:
        // @ts-ignore
        this.customers = this.getCustomers(params);
        break;     
      case Filters.Item:
        // @ts-ignore
        this.items = this.getItems(params);
        break;
      case Filters.UofM:
        // @ts-ignore
        this.uofMs = this.getUofMs({ Filter_UofMScheduleID, ...params });
        //this.uofMs = this.getUofMs(params);
        break;
      case Filters.Location:
        // @ts-ignore
        this.locations = this.getLocations(params);
        break;
      case Filters.PriceSchedule:
        // @ts-ignore
        this.priceSchedules = this.getPriceSchedules(params);
        break;
      case Filters.Currency:
        // @ts-ignore
        this.currencies = this.getCurrencies(params);
        break;
      default:
        break;
    }
  }
  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  async getUseSingleCurrencySettings() {
    const Filter_Name = 'UseSingleCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    this.singleCurrency = setting[0].value == 'true';
    if (this.singleCurrency) {
      this.getDefaultCurrencySettings();
    }
  }

  async getDefaultCurrencySettings() { //global default
    const Filter_Name = 'DefaultCurrency';
    const resp = await this.settingsService.getList({ Filter_Name });
    const setting: Setting[] = resp.body;
    if (setting && setting[0]) {
      this.currencyID = +setting[0].value;
      const resp = await this.currenciesService.getById(this.currencyID);
      let tempDefaultCurrency = resp;
      this.currencies = [];
      this.currencies.push(tempDefaultCurrency);
      this.catalogLineForm.patchValue({ ['currencyID']: tempDefaultCurrency.id });
      this.catalogLineForm.patchValue({ ['currencyLabelName']: tempDefaultCurrency.name });
      this.catalogLine.currency = tempDefaultCurrency;
      this.isFormChanged = false;
    }
    else {
      this.snack.openSnackBar("Default Currency is missing from Settings", '', true, 'Error', 'alert-danger');
    }
  }
}
