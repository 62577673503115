import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { Currency } from '../../../services/currencies/currencies.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { GridComponent, GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';


@Component({
  selector: 'app-currency-lookup',
  templateUrl: './currency-lookup.component.html',
  styleUrls: ['./currency-lookup.component.scss'],
})
export class CurrencyLookupComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private currenciesService: CurrenciesService,
    public activeModal: NgbActiveModal
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() currencies: Currency[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() onSelectSingle = new EventEmitter();
  //@Input() mode: 'view' | 'lookup' = 'view';
  //selectedIds: { [key: number]: boolean } = {};
  //idForRemove: number | null = null;
  //public isAdmin: boolean;
  //public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 490,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Alpha Code',
      field: 'alphaCode',
      width: 120,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Numeric Code',
      field: 'numericCode',
      width: 120,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'ERP ID',
      field: 'erpid',
      width: 120,
      orderIndex: 3,
      hidden: true,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  onClick_Select() {
    this.onGrid_DblClick();
  }

  ngAfterViewInit() {   
    this.loading = true;

    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('CurrencyLookup_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('CurrencyLookup_Config', this.columnsConfig);
    } else {
      // each time lookup is opened, default erpid column to hidden.
      for (var i = 0; i < gridConfig.length; i++) {
        if (gridConfig[i].field === 'erpid') {
          gridConfig[i].hidden = true;
        }
      }

      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });

    this.loading = false;
    this.loadListData();
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('CurrencyLookup_Config', this.columnsConfig);
  }

  onFilterChange() {
    this.loadListData();
  }

  public async loadListData() {
    this.currencies = [];
    this.loading = true;
    this.query.PageSize = StorageService.PageSize();
    try {
      const response: any = await this.currenciesService.getList(this.query);
      this.currencies = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.currencies,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onClick_Refresh() {
    this.loadListData();
  }

  // GRID EVENTS

  public onGrid_CellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  public onGrid_DblClick() {
    if (this.clickedRowItem) {
      this.onSelectSingle.emit(this.clickedRowItem);
    }
  }

  public onGrid_FilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'alphaCode') {
          const Filter_AlphaCode = myFilter.value == null ? '' : myFilter.value;
          const Operand_AlphaCode = myFilter.operator;
          const params = { ...this.query, Filter_AlphaCode, Operand_AlphaCode };
          this.query = params;
        }
        if (myFilter.field == 'numericCode') {
          const Filter_NumericCode =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_NumericCode = myFilter.operator;
          const params = {
            ...this.query,
            Filter_NumericCode,
            Operand_NumericCode,
          };
          this.query = params;
        }
        if (myFilter.field == 'erpid') {
          const Filter_ERP = myFilter.value;
          const Operand_ERP = myFilter.operator;
          const params = { ...this.query, Filter_ERP, Operand_ERP };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
   
  public onGrid_PageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public onGrid_SortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

}
