<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      <!--<img src="../../../../assets/images/SyncWriter.png" alt="Sync Writers" style="padding-right: 5px;">-->
      Create / Update Sync Writer Column
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="syncWriterColumnForm">
    <input hidden="true" class="form-control" formControlName="syncWriterID"
           [ngClass]="{ 'is-invalid': submitted && f.syncWriterID.errors }" />
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="sequenceNumber" class="font-weight-bold" text="* Sequence Number"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="sequenceNumber" [min]="1" [autoCorrect]="true" [min]="1"
                                [decimals]="0" [format]="'n0'" [ngClass]="{ 'is-invalid': submitted && f.sequenceNumber.errors }"
                                [max]="999" placeholder="Enter a Sequence Number">
          </kendo-numerictextbox>
          <kendo-formerror>Sequence Number is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="columnName" class="font-weight-bold" text="* Column Name"></kendo-label>
          <kendo-textbox class="form-control"
                         formControlName="columnName"
                         placeholder="Enter Column Name"
                         maxlength="200" [clearButton]="true">
          </kendo-textbox>
          <kendo-formerror>Column Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
