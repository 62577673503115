import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemJobLogsService } from '../../../services/system-job-logs/system-job-logs.service';
import { SystemJobLog } from '../../../services/system-job-logs/system-job-logs.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, PageChangeEvent, GridComponent, ExcelComponent } from '@progress/kendo-angular-grid';
import { ColumnSettings } from "../../../../app/utils/column-settings.interface";
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor, } from '@progress/kendo-data-query';
import { ImportsService } from '../../../services/imports/imports.service'
import { StorageService } from '../../../../app/utils/StorageHelper';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { CopyService } from '../../../services/copy/copy.service';

@Component({
  selector: 'app-system-job-log-list',
  templateUrl: './system-job-log-list.component.html',
  styleUrls: ['./system-job-log-list.component.scss'],
})
export class SystemJobLogListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private systemJobLogsService: SystemJobLogsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private copyService: CopyService,
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() systemJobID: number;
  @Input() syncQueueID: number;
  @Input() systemJobLogs: SystemJobLog[] = [];
  @Input() query = { PageSize: 25, SortTerm: '-beginDateTime', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: SystemJobLog[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public showQueueColumn: boolean = true;
  public globalRecordCount: number = 1;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'beginDateTime',
      dir: 'desc'
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Begin Date Time',
      field: 'beginDateTime',
      width: 175,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Name',
      field: 'name',
      width: 250,
      orderIndex: 2,
      hidden: false
    },
    {
      title: 'End Date Time',
      field: 'endDateTime',
      width: 150,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Minutes To Complete',
      field: 'minutesToComplete',
      width: 120,
      orderIndex: 4,
      hidden: false
    },
    {
      title: 'Status',
      field: 'status',
      width: 150,
      orderIndex: 5,
      hidden: false
    },
    {
      title: 'Queue',
      field: 'syncQueueID',
      width: 60,
      orderIndex: 6,
      hidden: false
    },
    {
      title: 'Comment',
      field: 'comment',
      width: 300,
      orderIndex: 7,
      hidden: false
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }


  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.systemJobLogsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = 0;  //no siblings
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      console.log(options.optTechnicalStyle, options.optDescColumns, options.optRowID, options.optSiblingRecords);
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        excelComponent.fetchData = this.allData;
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('SystemJobLogList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('SystemJobLogList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('SystemJobLogList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('SystemJobLogList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;

    if (this.mode == 'view' || this.mode == 'lookup') {
      this.loadListData();
      this.gridClassName = "DefaultGrid";
    }
    else if (this.mode == 'subgrid') {
      this.loadSubGridData();
      this.gridClassName = "SystemJobLogSubgridClass";
    }

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        if (this.mode == "view") {
          let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
          if (SideNavExpanded_Config == true) {
            this.gridClassName = "DefaultGrid"
          }
          else {
            this.gridClassName = "FullScreenGrid"
          }
        }
      },
    );
  }

  async loadListDatax() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.systemJobLogsService.getList(this.query);
      this.systemJobLogs = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.systemJobLogs,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.systemJobID) {
        const response: any = await this.systemJobLogsService.getList({
          Filter_SystemJobID: this.systemJobID, ...this.query
        });
        this.systemJobLogs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.systemJobLogs,
          total: this.pagination.TotalCount,
        };
      }
      else if (this.syncQueueID) {
        this.showQueueColumn = false; //hide this column if we're already showing the Queue it links to
        const response: any = await this.systemJobLogsService.getList({
          Filter_SyncQueueID: this.syncQueueID, ...this.query
        });
        this.systemJobLogs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.systemJobLogs,
          total: this.pagination.TotalCount,
        };
      }
      else {
        const response: any = await this.systemJobLogsService.getList(this.query);
        this.systemJobLogs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.systemJobLogs,
          total: this.pagination.TotalCount,
        };

      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount();
  }

  async loadSubGridData() {
    const params = { PageSize: StorageService.PageSize() ?? 50 };
    this.systemJobLogs = [];
    this.gridView = {
      data: this.systemJobLogs,
      total: 0,
    };
    this.loading = true;
    try {
      if (this.systemJobID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.systemJobLogsService.getList({
          Filter_SystemJobID: this.systemJobID, ...params
        });
        this.systemJobLogs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.systemJobLogs,
          total: this.pagination.TotalCount,
        };
      }
      if (this.syncQueueID) {
        this.showQueueColumn = false; //hide this column if we're already showing the Queue it links to
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.systemJobLogsService.getList({
          Filter_SyncQueueID: this.syncQueueID, ...params
        });
        this.systemJobLogs = response.body;
        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.systemJobLogs,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount();
  }

  async getGlobalSiblingDataCount() {
    this.loading = true;
    try {
      const response: any = await this.systemJobLogsService.getList({ PageSize: 1, PageNumber: 1 });
      this.loading = false;
      const pagination = getPaginationHeader(response.headers);
      this.globalRecordCount = pagination.TotalCount;
    } catch (error) {
      this.loading = false;
      console.error("Error fetching total count: ", error);
    }
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/system-job-log/' + this.clickedRowItem.id]);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: '-beginDateTime', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        let tempName: string | undefined;
        if (typeof myFilter.field === 'string') {
          tempName = myFilter.field.includes('.') ? myFilter.field.split('.')[0] : myFilter.field;
        }
        const filterField = 'Filter_' + tempName;
        const operandField = 'Operand_' + tempName;
        const params = { ...this.query, [filterField]: myFilter.value, [operandField]: myFilter.operator };
        this.query = params;
      });
    }
    this.onFilterChange();
  }

  edit(id: number) {
    this.router.navigate([`/price/system-job-log/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.systemJobLogsService.getExportList(this.query);
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.systemJobLogsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(
          err.error,
          '',
          true,
          'Error',
          'alert-danger',
        );
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    let sortName = sort[0].field.includes('.') ? sort[0].field.split('.')[0] : sort[0].field;
    console.log(sortName);
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sortName
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sortName;
    } else {
      this.query.SortTerm = sortName;
    }
    this.loadListData();
  }
}
