<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">Customer Attribute Values</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip" [sort]="query.SortTerm"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [resizable]="true" [sortable]="true" [sort]="sort"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">
        Refresh
      </button>
      <button kendoButton type="button" id="btnAdd" class="showTooltip" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || !this.customerID"
              imageUrl="../../../../assets/images/Add.png" (click)="onCreate()" title="Save the record above to be able to start adding these records here.">
        Add
        New
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="!isAdmin || disableEditBtn" imageUrl="../../../../assets/images/Edit.png" (click)="onDblClick()"
              title="Edit Selected">
        Edit
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              [disabled]="!isAdmin || disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png"
              (click)="onClick_Delete()" title="Delete Selected">
        Delete
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
              imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)"
              title="Export to Excel">
        Export
      </button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
              imageUrl="../../../../assets/images/Excel_Import.png" (click)="hiddenfileinput.click()"
              title="Import from Excel">
        Import
      </button>
    </kendo-buttongroup>
    <input type="file" style="display: none" (change)="onFileChange($event)" multiple="false" #hiddenfileinput />
    <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true" style="float: right;">Columns</kendo-grid-column-chooser>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template> 
  <kendo-grid-column field="customerAttribute.name" width="300" title="Attribute Name"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="value" width="300" title="Attribute Value"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-contains-operator></kendo-filter-contains-operator>
        <kendo-filter-not-contains-operator></kendo-filter-not-contains-operator>
        <kendo-filter-startswith-operator></kendo-filter-startswith-operator>
        <kendo-filter-endswith-operator></kendo-filter-endswith-operator>
      </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-excel #exportToExcel fileName="CustomerAttributeValues.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.name" title="CustomerName*" *ngIf="exportAll && optDescColumns"></kendo-excelexport-column>
    <kendo-excelexport-column field="customer.number" title="CustomerNumber" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="customerAttribute.name" title="CustomerAttributeName" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
    <kendo-excelexport-column field="value" title="Value" [headerCellOptions]="{bold:'true'}" *ngIf="exportAll"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
