import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { emptySyncWriter, SyncWriter } from '../../../services/sync-writers/sync-writers.model';
import { SyncWritersService } from '../../../services/sync-writers/sync-writers.service';
import { SyncWriterColumnSubListComponent } from '../../sync-writer-columns/sync-writer-column-sublist/sync-writer-column-sublist.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { CopyService } from '../../../services/copy/copy.service';
import { emptySyncConnector, SyncConnector } from '../../../services/sync-connectors/sync-connectors.model';
import { SyncConnectorsService } from '../../../services/sync-connectors/sync-connectors.service';
import { SyncConnectorLookupComponent } from '../../sync-connectors/sync-connector-lookup/sync-connector-lookup.component';

enum Filters {
  SyncConnector = 1,
  Report = 2
}

@Component({
  selector: 'app-sync-writer-single',
  templateUrl: './sync-writer-single.component.html',
  styleUrls: ['./sync-writer-single.component.scss'],
})
export class SyncWriterSingleComponent implements OnInit {
  @ViewChild('subgrid') syncWriterColumnSubGrid: SyncWriterColumnSubListComponent;
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncWriter: number | null = null;
  @Input() syncWriter: SyncWriter = emptySyncWriter;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  filters = Filters;
  myResponse: string;
  syncWriterForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;
  goBackForce = false;
  nextURL = '';
  syncConnectors: SyncConnector[] = [];
  selectedSyncConnector: SyncConnector = emptySyncConnector;
  currentAuthType: number;
  constructor(
    private route: ActivatedRoute,
    private syncWritersService: SyncWritersService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private notificationHelper: NotificationHelper,
    private snack: MySnackBarService,
    private router: Router,
    private copyService: CopyService,
    private syncConnectorService: SyncConnectorsService,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.syncWriter = emptySyncWriter;
        this.idOfSyncWriter = null;
        this.submitted = false;
        this.updateForm();
        this.buildForm();
        return;
      }
      this.idOfSyncWriter = params.id;
      this.loadData();
    });
    this.buildForm();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.syncWriterColumnSubGrid.gridClassName = "SyncWriterColumnSubgridClass"
        }
        else {
          this.syncWriterColumnSubGrid.gridClassName = "SyncWriterColumnSubgridClass"
        }
      },
    );
  }

  ngOnInit(): void {
    this.isAdmin = StorageService.IsAdmin();
    if (this.idOfSyncWriter) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncWriterForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(''),
      syncConnectorID: new FormControl('', Validators.required),
      query: new FormControl(''),
      storedProcName: new FormControl(''),
      filename: new FormControl(''),
    });
    this.syncWriterForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
    this.syncWriterForm.get('syncConnectorID').valueChanges.subscribe((newValue) => {
      this.onSyncConnectorIDChange(newValue);
    });
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMSyncWriter", this.syncWriter.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableDeleteBtn() {
    if (this.syncWriter && this.syncWriter.id) {
      return false;
    }
    else {
      return true;
    }
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.syncWriter.id);
    await this.syncWritersService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/sync-writers']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  get f() {
    return this.syncWriterForm.controls;
  }
  updateForm() {
    this.syncWriterForm.patchValue({ ...this.syncWriter });
    this.isFormChanged = false;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async onClick_AddNew() {
    let canContinue: boolean = await Promise.resolve(this.canDeactivate('/price/sync-writers/new'));
    if (canContinue) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/price/sync-writers/new']);
      });
    }
  }

  onClick_Save() {
    this.saveRecord(null);
  }

  onClick_SaveClose() {
    this.saveRecord("Close");
  }

  onClick_SaveNew() {
    this.saveRecord("New");
  }

  async saveRecord(action) {
    this.syncWriterForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncWriterForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncWriter,
      ...this.syncWriterForm.value,
    };
    if (this.syncWriter.id) {
      try {
        const response: any = await this.syncWritersService.update(
          this.syncWriter.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-writers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-writers/new']);
            });
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.syncWritersService.create(
          this.syncWriterForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
          if (action == "Close") {
            this.router.navigate(['/price/sync-writers']);
          }
          else if (action == "New") {
            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
              this.router.navigate(['/price/sync-writers/new']);
            });
          }
          else {
            this.syncWriter = response.body;
            this.router.navigate(['/price/sync-writers/', this.syncWriter.id]);
          }
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);

  }

  async getSyncConnectorById(id) {
    return await this.syncConnectorService.getById(id);
  }

  async onLookup_SyncConnector() {
    const modalRef = this.modalService.open(SyncConnectorLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: SyncConnector = receivedEntry;
      this.syncConnectors = [];
      this.syncConnectors.push(recordSelected);
      this.syncWriterForm.patchValue({ ['syncConnectorID']: receivedEntry.id });
      this.syncWriter.syncConnector = receivedEntry;
      this.selectedSyncConnector = receivedEntry;
      this.syncWriter.syncConnectorID = receivedEntry.id;
      this.currentAuthType = recordSelected.authTypeID;
      modalRef.close('test');
    });
  }


  async getSyncWriterById() {
    if (this.idOfSyncWriter) {
      return await this.syncWritersService.getById(
        this.idOfSyncWriter,
      );
    } else {
      return emptySyncWriter;
    }
  }
  async loadData() {
    try {
      [this.syncWriter] = await Promise.all([
        this.getSyncWriterById(),
      ]);
      if (this.syncWriter.syncConnectorID) {
        const currentSyncConnector: SyncConnector = await Promise.resolve(this.getSyncConnectorById(this.syncWriter.syncConnectorID));
        this.syncConnectors.push(currentSyncConnector);
        this.currentAuthType = currentSyncConnector.authTypeID;
      }
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

  async onSyncConnectorIDChange(newValue: any) {
    console.log('syncConnectorID changed to:', newValue);
    const currentSyncConnector: SyncConnector = await Promise.resolve(this.getSyncConnectorById(newValue));
    this.currentAuthType = currentSyncConnector.authTypeID;
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.SyncConnector:
        this.syncConnectors = [];
        this.syncConnectors = await (await this.syncConnectorService.getList(params))
          .body;
        break;
      default:
        break;
    }
  }

  onSelectBoxChanged(id: number, key: keyof SyncWriter, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
  }

  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
