import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef, } from '@angular/core';
import { SyncConnectorsService } from '../../../services/sync-connectors/sync-connectors.service';
import { SyncConnector, emptySyncConnector } from '../../../services/sync-connectors/sync-connectors.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';

enum Filters {
  HTTPMethod = 1,
  AuthType = 2
}

@Component({
  selector: 'app-sync-connector-single',
  templateUrl: './sync-connector-single.component.html',
  styleUrls: ['./sync-connector-single.component.scss'],
})
export class SyncConnectorSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncConnector: number | null = null;
  @Input() syncConnector: SyncConnector = emptySyncConnector;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  syncConnectorForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;
  httpMethods: Lookup[] = [];
  authTypes: Lookup[] = [];
  isPasswordVisible: boolean = false;

  constructor(
    private syncConnectorsService: SyncConnectorsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper,
    private lookupService: LookupsService,
  ) {
  }

  ngOnInit(): void {
    if (this.idOfSyncConnector) {
      this.loadData();
    }
    this.buildForm();
    this.updateForm();
    this.isAdmin = StorageService.IsAdmin();
  }

  buildForm() {
    this.syncConnectorForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      baseURL: new FormControl(''),
      httpMethodID: new FormControl(''),
      authTypeID: new FormControl('', Validators.required),
      username: new FormControl(''),
      password: new FormControl(''),
      server: new FormControl(''),
      endpointName: new FormControl(''),
      endpointVersion: new FormControl(''),
      clientID: new FormControl(''),
      clientSecret: new FormControl(''),
      tenantID: new FormControl(''),
      pathname: new FormControl(''),
    });
    this.getHTTPMethods();
    this.getAuthTypes();
    this.syncConnectorForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.syncConnectorForm.controls;
  }
  updateForm() {
    this.syncConnectorForm.patchValue({ ...this.syncConnector });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.syncConnectorForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncConnectorForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncConnector,
      ...this.syncConnectorForm.getRawValue(),
    };
    // Check to see if SyncConnector Name already exists.
    if ((await this.validation_CheckForExistingName()) === true) {
      this.notificationHelper.showStatusOnDialog('Sync Connector Name already exists!', 'error', this.container);
      return;
    }

    if (this.syncConnector.id) {
      try {
        const response: any = await this.syncConnectorsService.update(
          this.syncConnector.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.syncConnectorsService.create(
          this.syncConnectorForm.getRawValue(), // syncConnectorForm.value does not include disabled controls.
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
          this.isFormChanged = false;
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }

  async validation_CheckForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.syncConnectorForm.controls['name'].value;
    const Operand_Name = 'eq';
    const params = { Filter_Name, Operand_Name, PageSize };
    const existingSyncConnectors: SyncConnector[] = await (
      await this.syncConnectorsService.getList(params)
    ).body;
    if (existingSyncConnectors.length > 0) {
      if (this.syncConnector.id) {
        for (const key in existingSyncConnectors) {
          const currentSyncConnector: SyncConnector = existingSyncConnectors[key];
          if (currentSyncConnector.id != this.syncConnector.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async getSyncConnectorById() {
    if (this.idOfSyncConnector) {
      return await this.syncConnectorsService.getById(this.idOfSyncConnector);
    } else {
      return emptySyncConnector;
    }
  }

  async loadData() {
    try {
      [
        this.syncConnector,
        this.authTypes,
        this.httpMethods,
      ] = await Promise.all([
        this.getSyncConnectorById(),
        this.getAuthTypes(),
        this.getHTTPMethods(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
    if (this?.syncConnector?.httpMethodID != null) {
      this.httpMethodChange(this.syncConnector.httpMethod.id);
    }
    if (this?.syncConnector?.authTypeID != null) {
      this.authTypeChange(this.syncConnector.authType.id);
    }
  }

  async getHTTPMethods() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'HTTPMethod';
    const params = { Filter_OptionSet, PageSize };
    this.httpMethods = await this.lookupService.getList(params);
    return this.httpMethods;
  }

  async getAuthTypes() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'AuthType';
    const params = { Filter_OptionSet, PageSize };
    this.authTypes = await this.lookupService.getList(params);
    return this.authTypes;
  }

  onSelectBoxChanged(id: number, key: keyof SyncConnector, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.syncConnector[key] = item;
  }

  authTypeChange(authTypeID) {
    if (authTypeID == 1) {

    }
  }

  httpMethodChange(httpMethodID) {
    if (httpMethodID == 32 || httpMethodID == 74 || httpMethodID == 88) { // Fixed Adjustment

      if (this?.f?.useValueFromAttribute) {
        if (this.f.useValueFromAttribute.value == true) {

          //this.priceCalculationForm.patchValue({ ['pricePercent']: null });
          //this.f.pricePercent.clearValidators();
          //this.f.pricePercent.updateValueAndValidity();
          //this.showPricePercent = false;

          //this.priceCalculationForm.patchValue({ ['priceAmount']: null });
          //this.f.priceAmount.clearValidators();
          //this.f.priceAmount.updateValueAndValidity();
          //this.showPriceAmount = false;

          //this.showEntity = true;
          //this.f.entityID.setValidators(Validators.required);
          //this.f.entityID.updateValueAndValidity();

        }
        else {
          //this.priceCalculationForm.patchValue({ ['pricePercent']: null });
          //this.f.pricePercent.clearValidators();
          //this.f.pricePercent.updateValueAndValidity();


        }
      }


    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.HTTPMethod:
        {
          const Filter_OptionSet = 'HTTPMethod';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.httpMethods = await this.lookupService.getList(params);
        }
        break;
      case Filters.AuthType:
        {
          const Filter_OptionSet = 'AuthType';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.authTypes = await this.lookupService.getList(params);
        }
        break;
      default:
        break;
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    if (this.isPasswordVisible) {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  }
}

