export interface CustomerAttributeValue {
    id: number;
    customerID: number;
    customer: {
        name: string;
        id: number;
        erpid: string;
        number: string;
      };
    customerAttributeID: number;
    customerAttribute: {
        name: string;
        id: number;
        description: string;
        attributeDataTypeID: number;
      };
    value: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyCustomerAttributeValue: CustomerAttributeValue = {
    id: null,
    customerID: null,
    customer: {
        name: '',
        id: null,
        erpid: '',
        number: ''
      },
    customerAttributeID: null,
    customerAttribute: {
        name: '',
        id: null,
        description: '',
        attributeDataTypeID: null
      },
    value: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
