<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Reports.png" alt="Reports" style="padding-right: 5px;">
  Create / Update Report
</div>

<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/reports']"
          routerLinkActive="router-link-active" title="Go Back to List">
    BACK
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">
    SAVE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_Close.png" (click)="onClick_SaveClose()" title="Save & Close">
    SAVE &
    CLOSE
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Save_New.png" (click)="onClick_SaveNew()" title="Save & New">
    SAVE & NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
          imageUrl="../../../../assets/images/Add.png" (click)="onClick_AddNew()" title="Add New">
    ADD NEW
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
          imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">
    COPY
  </button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn"
          imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
          title="Delete Record">
    DELETE
  </button>

</kendo-buttongroup>

<form [formGroup]="reportForm">
  <div class="row">
    <div class="col form-group col-9">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Name" maxlength="100" [clearButton]="true">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group col-3">
      <kendo-formfield>
        <kendo-label for="ranOn" text="Ran On"></kendo-label>
        <kendo-textbox class="form-control" formControlName="ranOn" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Description" maxlength="250" [clearButton]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>


  <kendo-tabstrip (tabSelect)="onTabSelect($event)" [tabPosition]="'top'">
    <kendo-tabstrip-tab [title]="'Results'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color:rgb(234, 234, 234)">
          <kendo-grid style="height: 400px; " #grid="kendoGrid"
                      [data]="gridView"
                      [pageable]="true"
                      (pageChange)="pageChange($event)"
                      [pageSize]="query.PageSize"
                      [skip]="skip"
                      [loading]="loading"
                      [resizable]="true"
                      [sortable]="true"
                      [selectable]="{ mode: 'multiple' }">
            <ng-template kendoGridToolbarTemplate>
              <kendo-buttongroup>
                <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Price_Calculation.png" (click)="onClickCalculate()" title="Generate Report">Generate</button>
                <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Cancel.png" (click)="onClickClearData()" title="Regenerate Report">Clear Data</button>
                <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClickExport(grid, exportToExcel)" title="Export to Excel">Export</button>
              </kendo-buttongroup>
            </ng-template>
            <kendo-grid-excel #exportToExcel fileName="Report.xlsx">
            </kendo-grid-excel>
          </kendo-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'JSON Result Set'">
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color:rgb(234, 234, 234)">
          <div class="row">
            <div class="col-12 form-group">
              <kendo-formfield style="width: 100%;">
                <kendo-label for="resultSet" text="JSON Result Set"> </kendo-label>
                <kendo-textarea class="form-control" formControlName="resultSet" rows="15" style="overflow-y:auto; width:100%;"
                                [readonly]="true">
                </kendo-textarea>
              </kendo-formfield>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Definition'">
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color:rgb(234, 234, 234)">
          <div class="row">
            <div class="col-12 form-group">
              <kendo-formfield style="width: 100%;">
                <kendo-label for="sqlScriptText" text="SQL Script"> </kendo-label>
                <kendo-textarea class="form-control" formControlName="sqlScriptText" rows="15" style="overflow-y:auto; width:100%;"
                                [readonly]="!isRocktonAdmin" title="Scripts can only be edited by Rockton Support">
                </kendo-textarea>
              </kendo-formfield>
            </div>
          </div>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

</form>
