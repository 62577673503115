import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit, } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { DocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, orderBy, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';

@Component({
  selector: 'app-document-attribute-lookup',
  templateUrl: './document-attribute-lookup.component.html',
  styleUrls: ['./document-attribute-lookup.component.scss'],
})
export class DocumentAttributeLookupComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private documentAttributesService: DocumentAttributesService,
    private lookupService: LookupsService,
    public activeModal: NgbActiveModal
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() documentAttributes: DocumentAttribute[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: DocumentAttribute[];
  public exportAll: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public documentAttributeDataTypeOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 475,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Description',
      field: 'description',
      width: 475,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Data Type',
      field: 'attributeDataType.name',
      width: 475,
      orderIndex: 2,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async getDocumentAttributeDataTypeOptions() {
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_OptionSet = 'AttributeDataType';
    const params = { Filter_OptionSet, PageSize };
    this.documentAttributeDataTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "AttributeDataType";
    defaultLookup.name = "(All)";
    this.documentAttributeDataTypeOptions.reverse();
    this.documentAttributeDataTypeOptions.push(defaultLookup);
    this.documentAttributeDataTypeOptions.reverse();
    this.documentAttributeDataTypeOptions = this.documentAttributeDataTypeOptions.filter(
      x => x.name !== "Lookup" && x.name !== "Text" && x.name !== "Time");
    return this.documentAttributeDataTypeOptions;
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('DocumentAttributeLookup_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('DocumentAttributeLookup_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('DocumentAttributeLookup_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save columns configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('DocumentAttributeLookup_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();
    this.getDocumentAttributeDataTypeOptions();
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }

  onClick_Save() {
    this.onDblClick();
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.documentAttributesService.getList(
        this.query
      );
      this.documentAttributes = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.documentAttributes,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      this.onSelectSingle.emit(this.clickedRowItem);
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'name') {
          const Filter_Name = myFilter.value;
          const Operand_Name = myFilter.operator;
          const params = { ...this.query, Filter_Name, Operand_Name };
          this.query = params;
        }
        if (myFilter.field == 'description') {
          const Filter_Description =
            myFilter.value == null ? '' : myFilter.value;
          const Operand_Description = myFilter.operator;
          const params = {
            ...this.query,
            Filter_Description,
            Operand_Description,
          };
          this.query = params;
        }
        if (myFilter.field == 'attributeDataType.name') {
          if (myFilter.value != '(All)') {
            const Filter_DataType = myFilter.value;
            const Operand_DataType = myFilter.operator;
            const params = { ...this.query, Filter_DataType, Operand_DataType };
            this.query = params;
          }
        }
      });
    }
    this.onFilterChange();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
}
