import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersInFilterService } from '../../../services/customers-in-filter/customers-in-filter.service';
import { Customer } from '../../../services/customers/customers.model';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';

@Component({
  selector: 'app-customers-in-filter-list',
  templateUrl: './customers-in-filter-list.component.html',
  styleUrls: ['./customers-in-filter-list.component.scss'],
})
export class CustomersInFilterListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(

    private customersInFilterService: CustomersInFilterService,
    public activeModal: NgbActiveModal
  ) {
  }

  @Input() idOfPricingFilter: number | null = null;
  @Input() loading: boolean = true;
  @Input() customers: Customer[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Name',
      field: 'name',
      width: 300,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Number',
      field: 'number',
      width: 300,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'ERP ID',
      field: 'erpid',
      width: 300,
      orderIndex: 2,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  ngAfterViewInit() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('CustomersInFilterList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('CustomersInFilterList_Config', this.columnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });

    this.loadListData();
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('CustomersInFilterList_Config', this.columnsConfig);
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    const PricingFilterID = this.idOfPricingFilter;

    const params = { ...this.query, PricingFilterID };
    this.query = params;
    try {
      const response: any = await this.customersInFilterService.getList(this.query);
      this.customers = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.customers,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  onClick_Close(message) {
    this.activeModal.close(message);
  }
}

