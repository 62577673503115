import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { emptySyncWriterColumn, SyncWriterColumn } from '../../../services/sync-writer-columns/sync-writer-columns.model';
import { SyncWriterColumnsService } from '../../../services/sync-writer-columns/sync-writer-columns.service';

@Component({
  selector: 'app-sync-writer-column-single',
  templateUrl: './sync-writer-column-single.component.html',
  styleUrls: ['./sync-writer-column-single.component.scss'],
})
export class SyncWriterColumnSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncWriterColumn: number | null = null;
  @Input() idOfSyncWriter: number | null = null;
  @Input() syncWriterColumn: SyncWriterColumn = emptySyncWriterColumn;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  myResponse: string;
  syncWriterColumnForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;


  constructor(
    private syncWriterColumnsService: SyncWriterColumnsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfSyncWriterColumn) {
      this.loadData();
    }
    if (this.idOfSyncWriter) {
      this.syncWriterColumn.syncWriterID = this.idOfSyncWriter;
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncWriterColumnForm = new FormGroup({
      syncWriterID: new FormControl(''),
      sequenceNumber: new FormControl(null, Validators.required),
      columnName: new FormControl('', [Validators.maxLength(50), Validators.required ]),
    });
    this.syncWriterColumnForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  get f() {
    return this.syncWriterColumnForm.controls;
  }
  updateForm() {
    this.syncWriterColumnForm.patchValue({ ...this.syncWriterColumn });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async checkForExistingSequenceNumber() {
    var sequenceNumberExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const sequenceNumber = this.syncWriterColumnForm.controls['sequenceNumber'].value;
    const Filter_SyncWriterID = this.idOfSyncWriter;
    const params = { Filter_SyncWriterID, PageSize };
    const existingSyncWriterColumns: SyncWriterColumn[] = await (await this.syncWriterColumnsService.getList(params)).body;
    if (existingSyncWriterColumns.length > 0) {
      for (const key in existingSyncWriterColumns) {
        const currentSyncWriterColumn: SyncWriterColumn = existingSyncWriterColumns[key];
        if (currentSyncWriterColumn.sequenceNumber == sequenceNumber) {
          if (currentSyncWriterColumn.id != this.syncWriterColumn.id) {
            sequenceNumberExists = true;
          }
        }
      }
    }
    return sequenceNumberExists;
  }

  async saveRecord() {
    this.syncWriterColumnForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncWriterColumnForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncWriterColumn,
      ...this.syncWriterColumnForm.value,
    };
    // Check to see if Sequence Number already exists.
    if ((await this.checkForExistingSequenceNumber()) === true) {
      this.notificationHelper.showStatusOnDialog('Sequence Number already exists!', 'error', this.container);
    }
    else {
      if (this.syncWriterColumn.id) {
        try {
          const response: any = await this.syncWriterColumnsService.update(
            this.syncWriterColumn.id,
            data,
          );
          const status: any = response.status;
          if (status === 200) {
            this.notificationHelper.showStatus('Record updated successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      } else {
        try {

          const response: any = await this.syncWriterColumnsService.create(
            this.syncWriterColumnForm.value,
          );
          const status: any = response.status;
          if (status === 201) {
            this.notificationHelper.showStatus('Record saved successfully!', "success");
            this.isFormChanged = false;
          }
        } catch (e) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
      }
      this.onFormSaved.emit(false);
    }
  }


  async getSyncWriterColumnById() {
    if (this.idOfSyncWriterColumn
    ) {
      return await this.syncWriterColumnsService.getById(
        this.idOfSyncWriterColumn,
      );
    } else {
      return emptySyncWriterColumn;
    }
  }
  async loadData() {
    try {
      [this.syncWriterColumn] = await Promise.all([
        this.getSyncWriterColumnById(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

}
