<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Pricing_Tree.png" alt="Pricing Tree" style="padding-right: 5px;">
  Pricing Tree
</div>

<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <kendo-grid [kendoGridBinding]="gridView"
              [group]="defaultGroups"
              idField="id"
              [resizable]="true">

    <kendo-grid-column field="useInDynamicPricing" title="UseDynamically" width="70" [hidden]="true">
      <ng-template kendoGridGroupHeaderTemplate let-group="group">
        <span style="font-size: larger;">{{ group.value ? 'Dynamic Price Schedules' : 'Static Price Schedules' }}</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="dynamicPricingRank" title="Rank" width="70" [hidden]="true">
      <ng-template kendoGridGroupHeaderTemplate let-group="group">
        <span *ngIf="group.value != null" style="font-size: larger;">Group Rank: {{ group.value }}</span>
        <span *ngIf="group.value == null">Static Price Schedules are not ranked</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="isActive" title="Active" width="50" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
          <input type="checkbox" [checked]="dataItem.isActive" [disabled]="true" />
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="name" title="Name" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="['/price/price-schedule/', dataItem.id]">
          {{ dataItem.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="priceSourceOption.name" title="Source" width="120" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

    <kendo-grid-column field="pricingFilter.name" title="Filter" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.pricingFilter?.name ? 'Yes' : 'No' }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="priceBook.name" title="Price Book" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.priceBook?.name ? 'Yes' : 'No' }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="roundingRule.name" title="Round" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.roundingRule?.name ? 'Yes' : 'No' }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="erpid" title="ERP Linked" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
      <ng-template kendoGridCellTemplate let-dataItem>
          <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
            <input type="checkbox" [checked]="!dataItem.erpid.startsWith('RPM')" [disabled]="true" />
          </div>
      </ng-template>
    </kendo-grid-column>

    <div *kendoGridDetailTemplate="let dataItem">
      <app-pricing-tree-branch [priceSchedule]="dataItem"></app-pricing-tree-branch>
    </div>
  </kendo-grid>
</div>
<br />

<!--<kendo-grid-column field="isActive" title="Active" width="100" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
  <ng-template kendoGridCellTemplate let-dataItem>
    <kendo-switch [(ngModel)]="dataItem.isActive" style="transform: scale(0.67);"></kendo-switch>
    <kendo-switch [(ngModel)]="dataItem.isActive" style="transform: scale(0.67);" class="green-switch"></kendo-switch>
    <style>
      /ng-deep/ .green-switch .k-switch-label-on {
        background-color: green !important;
      }

      /ng-deep/ .green-switch .k-switch-handle {
        border-color: green !important;
      }
    </style>
  </ng-template>
</kendo-grid-column>-->
