import { Component, OnInit, Output, EventEmitter,  Input,  ViewContainerRef, ViewChild } from '@angular/core';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute, emptyCustomerAttribute } from '../../../services/customer-attributes/customer-attributes.model';
import { CustomerAttributeValuesService } from '../../../services/customer-attribute-values/customer-attribute-values.service';
import { CustomerAttributeValue, emptyCustomerAttributeValue } from '../../../services/customer-attribute-values/customer-attribute-values.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { NgbModal, NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { CustomerAttributeListComponent } from '../../customer-attributes/customer-attribute-list/customer-attribute-list.component';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  CustomerAttribute = 1,
}
@Component({
  selector: 'app-customer-attribute-value-single',
  templateUrl: './customer-attribute-value-single.component.html',
  styleUrls: ['./customer-attribute-value-single.component.scss'],
})
export class CustomerAttributeValueSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;
  
  filters = Filters;
  @Input() idOfCustomerAttributeValue: number | null = null;
  @Input() idOfCustomer: number | null = null;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  customerAttributeValue: CustomerAttributeValue = emptyCustomerAttributeValue;
  value: Date = new Date();
  customerAttributeValueForm: FormGroup;
  customerAttributes: CustomerAttribute[] = [];
  submitted = false;  
  modalReference: any;
  isFormChanged = false;
  
  public isAdmin: boolean;
  attributeDataType: number;
  attributeIsString: boolean = true;
  attributeIsDate: boolean = false;
  attributeIsCheckBox: boolean = false;
  attributeIsNumber: boolean = false;

  constructor(    
    private customerAttributeValuesService: CustomerAttributeValuesService,
    private customerAttributesService: CustomerAttributesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
    this.customerAttributeSearch = debounce(
      this.customerAttributeSearch,
      400,
      {},
    );
  }

  ngOnInit(): void {
    if (this.idOfCustomerAttributeValue) {
      this.loadData();
    }
    if (this.idOfCustomer) {
      this.customerAttributeValue.customerID = this.idOfCustomer;
    }
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.customerAttributeValueForm.disable();
    }
  }
  buildForm() {
    this.customerAttributeValueForm = new FormGroup({
      customerID: new FormControl(''),
      customerAttributeID: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
    });
    this.customerAttributeValueForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.customerAttributeValueForm.controls;
  }
  updateForm() {
    let value = null;
    if (this.customerAttributeValue?.value
      && this.customerAttributeValue?.customerAttribute?.attributeDataTypeID) {
      switch (this.customerAttributeValue.customerAttribute.attributeDataTypeID) {
        case 13: //Check Box
          value = this.customerAttributeValue.value
          break;
        case 14: // Number
          value = parseFloat(this.customerAttributeValue.value)
          break;
        case 16:
          value = new Date(this.customerAttributeValue.value)
          break;
        default:
          value = this.customerAttributeValue.value;
          break
      }
    }
    this.customerAttributeValueForm.patchValue({
      ...this.customerAttributeValue,
    });
    this.f.value.patchValue(value);
    this.isFormChanged = false;
  }
  clearPrefill() {
    setTimeout(() => {
      this.customerAttributes = [];
    });
  }

  async checkIfCustomerAttributeExists() {
    var nameExists: boolean = false;
    const Filter_CustomerID = this.idOfCustomer;
    const Filter_CustomerAttributeID = this.customerAttributeValue.customerAttribute.id;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_CustomerID, Filter_CustomerAttributeID, PageSize };
    const existingCustomerAttributeValues: CustomerAttributeValue[] = await (
      await this.customerAttributeValuesService.getList(params)
    ).body;
    if (existingCustomerAttributeValues.length > 0) {
      if (this.customerAttributeValue.id) {
        for (const key in existingCustomerAttributeValues) {
          const cav: CustomerAttributeValue =
            existingCustomerAttributeValues[key];
          if (cav.id != this.customerAttributeValue.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async validateDate() {
    // TODO: Validate Date data type
  }

  async validateNumber() {
    // TODO: Validate Number data type
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.customerAttributeValueForm.markAllAsTouched();
    this.submitted = true;
    if (this.customerAttributeValueForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.customerAttributeValue,
      ...this.customerAttributeValueForm.value,
    };
    // TODO: Check if CustomerAttribute datatype is date
    // TODO: Check if CustomerAttribute datatype is number
    // Check to see if Document Attribute Name already exists.
    if ((await this.checkIfCustomerAttributeExists()) === true) {
      this.notificationHelper.showStatusOnDialog('This Customer Attribute already exists for this Customer!', 'error', this.container);      
      return;
    }

    if (this.customerAttributeValue.id) {
      try {
        const response: any = await this.customerAttributeValuesService.update(
          this.customerAttributeValue.id,
          data,
        );     

        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");         
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.customerAttributeValuesService.create(
          this.customerAttributeValueForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }
  
  async getCustomerAttributeValueById() {
    if (this.idOfCustomerAttributeValue) {
      return await this.customerAttributeValuesService.getById(
        this.idOfCustomerAttributeValue,
      );
    } else {
      return emptyCustomerAttributeValue;
    }
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  async loadData() {    
    const PageSize = StorageService.PageSize() ?? 50;
    try {
      [
        this.customerAttributeValue,
        this.customerAttributes,
      ] = await Promise.all([
        this.getCustomerAttributeValueById(),
        (await this.customerAttributesService.getList(PageSize)).body,
      ]);     
      this.updateForm();
    } catch (e) {
    } finally {
    }
    if (this.customerAttributeValue?.customerAttribute?.attributeDataTypeID != null) {
      this.displayCorrectInput(this.customerAttributeValue.customerAttribute.attributeDataTypeID)
    }
  }

  async customerAttributeSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    const PageSize = StorageService.PageSize() ?? 50;
    return await this.getCustomerAttributes({ SearchTerm, PageSize });
  }

  async getCustomerAttributes(params) {

    try {
      const customerAttributes: CustomerAttribute[] = await (await this.customerAttributesService.getList(params)).body;
      this.customerAttributes = customerAttributes;
    } catch (e) {
      this.customerAttributes = [];
    }
    return this.customerAttributes;
  }
  onSelectBoxChanged(id: number, key: keyof CustomerAttributeValue, arr) {
    let item: CustomerAttribute = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    this.f.value.patchValue('');
    //this.customerAttributeValue.value = '';
    // @ts-ignore
    this.customerAttributeValue[key] = item;

    if (item != null && item.attributeDataType != null) {
      this.displayCorrectInput(item.attributeDataType.id)
    }
  }

  displayCorrectInput(attributeDataTypeID: number) {
    this.attributeDataType = attributeDataTypeID;
    switch (attributeDataTypeID) {
      case 13: //Check Box
        this.attributeIsString = false;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = true;
        this.attributeIsNumber = false;
        if (isNullOrEmptyString(this.customerAttributeValue.value)) {
          this.f.value.patchValue(false);
        }
        break;
      case 14:
        this.attributeIsString = false;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = true;

        break;
      case 15:
        this.attributeIsString = true;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = false;

        break;
      case 16:
        this.attributeIsString = false;
        this.attributeIsDate = true;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = false;

        break;
      default:
        this.attributeIsString = true;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = false;
        break;
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.CustomerAttribute:
        this.customerAttributes = await (
          await this.customerAttributesService.getList(params)
        ).body;
        break;
      default:
        break;
    }
  }

  async onLookup_CustomerAttribute() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, { });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry: CustomerAttribute) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes = [];
      this.customerAttributes.push(recordSelected);
      this.customerAttributeValueForm.patchValue({ ['customerAttributeID']: receivedEntry.id });
      this.customerAttributeValue.customerAttribute = receivedEntry;
      if (receivedEntry?.attributeDataTypeID != null) {
        this.displayCorrectInput(receivedEntry.attributeDataTypeID)
      }
      modalRef.close('test');
    });

  }
}
