<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/SyncWriter.png" alt="Sync Writer" style="padding-right: 5px;">
  Create / Update Sync Writer
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/sync-writers']" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Save.png" (click)="onClick_Save()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Save_Close.png" (click)="onClick_SaveClose()" title="Save & Close">SAVE & CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Save_New.png" (click)="onClick_SaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin" imageUrl="../../../../assets/images/Add.png" (click)="onClick_AddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">COPY</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
</kendo-buttongroup>
<form [formGroup]="syncWriterForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control"
                       formControlName="name"
                       placeholder="Enter Name"
                       maxlength="100" [clearButton]="true">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control"
                       formControlName="description"
                       placeholder="Enter Description"
                       maxlength="200" [clearButton]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="syncConnector" class="font-weight-bold" text="* Sync Connector"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="syncConnector" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="syncConnectorID" [notFoundText]="'Please start typing to search'"
                     [ngClass]="{'borderless': true, 'is-invalid': submitted && f.syncConnectorID.errors,'w-100': true }"
                     placeholder="Select a Sync Connector" [searchable]="true" (search)="search($event, filters.SyncConnector)"
                     (change)="onSelectBoxChanged($event,'syncConnectorID',syncConnectors)">
            <ng-option *ngFor="let item of syncConnectors" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton look="clear" (click)="onLookup_SyncConnector()" icon="search"></button>
        </span>
        <kendo-formerror>Sync Connector is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group" *ngIf="[144,145,147,148,149,150].includes(currentAuthType)">
      <kendo-formfield>
        <kendo-label for="query" class="font-weight-bold" text="* Query"></kendo-label>
        <kendo-textbox class="form-control" formControlName="query" placeholder="Enter Query"
                       [clearButton]="true" [required]="[144,145,147,148,149,150].includes(currentAuthType)">
        </kendo-textbox>
        <kendo-formerror>Query is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group" *ngIf="[146].includes(currentAuthType)">
      <kendo-formfield>
        <kendo-label for="storedProcName" class="font-weight-bold" text="* Stored Procedure Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="storedProcName" placeholder="Enter Stored Procedure Name"
                       maxlength="100" [clearButton]="true" [required]="[146].includes(currentAuthType)">
        </kendo-textbox>
        <kendo-formerror>Stored Procedure Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group" *ngIf="[151].includes(currentAuthType)">
      <kendo-formfield>
        <kendo-label for="filename" class="font-weight-bold" text="* Filename"></kendo-label>
        <kendo-textbox class="form-control" formControlName="filename" placeholder="Enter Report"
                       [clearButton]="true" [required]="[151].includes(currentAuthType)">
        </kendo-textbox>
        <kendo-formerror>Filename is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
</form>
<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-sync-writer-column-sublist #subgrid
                                  [syncWriterID]='idOfSyncWriter'>
  </app-sync-writer-column-sublist>
</div>
