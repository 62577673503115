import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { emptySyncSetEntity, SyncSetEntity } from '../../../services/sync-set-entities/sync-set-entities.model';
import { SyncSetEntitiesService } from '../../../services/sync-set-entities/sync-set-entities.service';
import { StorageService } from 'src/app/utils/StorageHelper';

@Component({
  selector: 'app-sync-set-entity-single',
  templateUrl: './sync-set-entity-single.component.html',
  styleUrls: ['./sync-set-entity-single.component.scss'],
})
export class SyncSetEntitySingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @Input() idOfSyncSetEntity: number | null = null;
  @Input() idOfSyncSet: number | null = null;
  @Input() syncSetEntity: SyncSetEntity = emptySyncSetEntity;
  @Output() onFormSaved = new EventEmitter<Boolean>();
  myResponse: string;
  syncSetEntityForm: FormGroup;
  submitted = false;
  modalReference: any;
  isFormChanged = false;

  constructor(
    private syncSetEntitiesService: SyncSetEntitiesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
  }

  ngOnInit(): void {
    if (this.idOfSyncSetEntity) {
      this.loadData();
    }
    if (this.idOfSyncSet) {
      this.syncSetEntity.syncSetID = this.idOfSyncSet;
    }
    this.buildForm();
    this.updateForm();
  }

  async buildForm() {
    this.syncSetEntityForm = new FormGroup({
      syncSetID: new FormControl(''),
      name: new FormControl('', [Validators.maxLength(50), Validators.required]),
      syncOrder: new FormControl('', Validators.required),
    });
    this.syncSetEntityForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.syncSetEntityForm.controls;
  }

  updateForm() {
    this.syncSetEntityForm.patchValue({ ...this.syncSetEntity });
    this.isFormChanged = false;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  onClick_Save() {
    this.saveRecord();
  }

  async checkForExistingName() {
    var entityNameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const entityName = this.syncSetEntityForm.controls['name'].value;
    const Filter_SyncSetID = this.idOfSyncSet;
    const params = { Filter_SyncSetID, PageSize };
    const existingSyncSetEntities: SyncSetEntity[] = await (await this.syncSetEntitiesService.getList(params)).body;
    if (existingSyncSetEntities.length > 0) {
      for (const key in existingSyncSetEntities) {
        const currentSyncSetEntity: SyncSetEntity = existingSyncSetEntities[key];
        if (currentSyncSetEntity.name == entityName) {
          if (currentSyncSetEntity.id != this.syncSetEntity.id) {
            entityNameExists = true;
          }
        }
      }
    }
    return entityNameExists;
  }

  async checkForExistingSyncOrder() {
    var syncOrderExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const syncOrderValue = this.syncSetEntityForm.controls['syncOrder'].value;
    const Filter_SyncSetID = this.idOfSyncSet;
    const params = { Filter_SyncSetID, PageSize };
    const existingSyncSetEntities: SyncSetEntity[] = await (await this.syncSetEntitiesService.getList(params)).body;
    if (existingSyncSetEntities.length > 0) {
      for (const key in existingSyncSetEntities) {
        const currentSyncSetEntity: SyncSetEntity = existingSyncSetEntities[key];
        if (currentSyncSetEntity.syncOrder == syncOrderValue) {
          if (currentSyncSetEntity.id != this.syncSetEntity.id) {
            syncOrderExists = true;
          }
        }
      }
    }
    return syncOrderExists;
  }

  async saveRecord() {
    this.syncSetEntityForm.markAllAsTouched();
    this.submitted = true;
    if (this.syncSetEntityForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.syncSetEntity,
      ...this.syncSetEntityForm.value,
    };
    if ((await this.checkForExistingSyncOrder()) === true) {
      this.notificationHelper.showStatusOnDialog('A record with this Sync Order already exists', 'error', this.container);
    }
    else {
      if ((await this.checkForExistingName()) === true) {
        this.notificationHelper.showStatusOnDialog('Name already exists for this Sync Set', 'error', this.container);
      }
      else {
        if (this.syncSetEntity.id) {
          try {
            const response: any = await this.syncSetEntitiesService.update(
              this.syncSetEntity.id,
              data,
            );
            const status: any = response.status;
            if (status === 200) {
              this.notificationHelper.showStatus('Record updated successfully!', "success");
              this.isFormChanged = false;
            }
          } catch (e) {
            this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
          }
        } else {
          try {

            const response: any = await this.syncSetEntitiesService.create(
              this.syncSetEntityForm.value,
            );
            const status: any = response.status;
            if (status === 201) {
              this.notificationHelper.showStatus('Record saved successfully!', "success");
              this.isFormChanged = false;
            }
          } catch (e) {
            this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
          }
        }
        this.onFormSaved.emit(false);
      }
    }
  }


  async getSyncSetEntityById() {
    if (this.idOfSyncSetEntity) {
      return await this.syncSetEntitiesService.getById(
        this.idOfSyncSetEntity,
      );
    } else {
      return emptySyncSetEntity;
    }
  }
  async loadData() {
    try {
      [this.syncSetEntity] = await Promise.all([
        this.getSyncSetEntityById(),
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
  }

}
