import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Customer } from '../customers/customers.model';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomersInFilterService {
  model = 'RPMCustomersInFilter';
  constructor(private httpClient: HttpClient) {}
  getList(params = {}): Promise<HttpResponse<Customer[]>> {
    
    // @ts-ignore
    // observe: 'response'
    return this.httpClient
      .get(this.model, { params, observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<Customer[]>>;
  } 
}
