export interface Item {
    id: number;
    erpid: string;
    number: string;
    name: string;
    description: string;
    uofMScheduleID: number;
    uofmSchedule: {
        name: string,
        id: number
    };
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyItem: Item = {
    id: null,
    erpid: null,
    number: '',
    name: '',
    description: '',
    uofMScheduleID: null,
    uofmSchedule: {
        name: null,
        id: null
    },
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
