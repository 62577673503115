import { Component, OnInit, Output, EventEmitter, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsService } from '../../../services/settings/settings.service';
import { Setting, emptySetting } from '../../../services/settings/settings.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom'; //mhr can't this and the referenced file be deleted?
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { stringify } from 'querystring';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { Currency, emptyCurrency } from 'src/app/services/currencies/currencies.model';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { CurrencyListComponent } from '../../currencies/currency-list/currency-list.component';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { UserListComponent } from '../../users/user-list/user-list.component';
import { TabStripComponent } from '@progress/kendo-angular-layout'
import { StorageService } from 'src/app/utils/StorageHelper';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup, emptyLookup } from 'src/app/services/lookups/lookups.model';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';

enum Filters {
  DefaultCurrency = 1,
}
@Component({
  selector: 'app-setting-single',
  templateUrl: './setting-single.component.html',
  styleUrls: ['./setting-single.component.scss'],
})
export class SettingSingleComponent implements OnInit {
  @ViewChild("AcumaticaPassword") AcumaticaPassword: TextBoxComponent;
  @ViewChild("BCClientSecret") BCClientSecret: TextBoxComponent;
  @ViewChild("GPPassword") GPPassword: TextBoxComponent;

  futureDevSwitch: boolean = false;
  filters = Filters;
  settings: Setting[] = [];
  loading = true;
  settingForm: FormGroup;
  acuSettingsForm: FormGroup;
  bcSettingsForm: FormGroup;
  gpSettingsForm: FormGroup;
  currencies: Currency[] = [];
  public erpNames: Lookup[] = [];
  public LoggingLevels: Lookup[] = [];
  defaultCurrency: Currency = emptyCurrency;
  submitted = false;
  paginationObject: any = { currencies: mockedData };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';
  useSingleCurrency: boolean = false;
  currencyLookupHasFocus = false;
  erpID: number;

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private currenciesService: CurrenciesService,
    private lookupService: LookupsService,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private router: Router,
  ) {    
    this.defaultCurrencySearch = debounce(this.defaultCurrencySearch, 400, {});
  }


  private emailValidators = [];

  ngOnInit(): void {
    this.buildForm();
    this.settingForm.get('UseSingleCurrency').valueChanges.subscribe(value => {
      if (value) {
        this.useSingleCurrency = true;
        this.settingForm.get('DefaultCurrency').setValidators(Validators.required);
      }
      else {
        this.useSingleCurrency = false;
        this.settingForm.get('DefaultCurrency').clearValidators();
      }
    });
    this.loadData();
    this.updateForm();
  }

  public ngAfterViewInit(): void {
    this.AcumaticaPassword.input.nativeElement.type = 'password';
    this.BCClientSecret.input.nativeElement.type = 'password';
    this.GPPassword.input.nativeElement.type = 'password';
  }

  async buildForm() {
    this.settingForm = new FormGroup({
      CompanyID: new FormControl({ value: localStorage.getItem('companyID'), disabled: false }),
      DatabaseVersionNumber: new FormControl(),
      UseSingleCurrency: new FormControl(false),
      DefaultCurrency: new FormControl(),
      ERPName: new FormControl(),
      LoggingLevel: new FormControl(),
      UseHighestPrice: new FormControl(true),
      UseLowestPrice: new FormControl(false)
    });

    this.settingForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });

    // ACU Form:
    this.acuSettingsForm = new FormGroup({
      AcumaticaUserName: new FormControl(),
      AcumaticaPassword: new FormControl(),
      AcumaticaBaseURL: new FormControl(),
      AcumaticaEndpointName: new FormControl(),
      AcumaticaEndpointVersion: new FormControl()
    });

    this.acuSettingsForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });

    // BC Form:
    this.bcSettingsForm = new FormGroup({
      BCClientID: new FormControl(),
      BCClientSecret: new FormControl(),
      BCTenantID: new FormControl(),
      BCBaseURL: new FormControl()   
    });

    this.bcSettingsForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });

    // GP Form:
    this.gpSettingsForm = new FormGroup({
      GPUserName: new FormControl(),
      GPPassword: new FormControl(),
      GPBaseURL: new FormControl()
    });

    this.gpSettingsForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });

  }
  get f() {
    return this.settingForm.controls;
  }

  changeERPTabs(event: any) {
    this.erpID = event;
  }

  updateForm() {
    this.settings.forEach(setting => {
      switch (setting.name) {
        case "DefaultCurrency":
          this.settingForm.patchValue({ DefaultCurrency: this.defaultCurrency.id });
          break;
        case "ERPName":
          let currentERP = this.erpNames.find(erpNameValue => erpNameValue.id == +setting.value);
          this.settingForm.patchValue({ ERPName: currentERP.id });
          this.erpID = currentERP.id;
          break;
        case "LoggingLevel":
          let currentLoggingLevel = this.LoggingLevels.find(LoggingLevelValue => LoggingLevelValue.id == +setting.value);
          this.settingForm.patchValue({ LoggingLevel: currentLoggingLevel.id });
          break;
        case "UseSingleCurrency":
          this.settingForm.patchValue({ UseSingleCurrency: setting.value == "true" });
          break;
        case "UseHighestPrice":
          this.settingForm.patchValue({ UseHighestPrice: setting.value == "true" });
          break;
        case "UseLowestPrice":
          this.settingForm.patchValue({ UseLowestPrice: setting.value == "true" });
          break;
        case "DBVersion":
          this.settingForm.patchValue({ DatabaseVersionNumber: setting.value });
          break;
        case "AcumaticaUserName":
          this.acuSettingsForm.patchValue({ AcumaticaUserName: setting.value });
          break;
        case "AcumaticaPassword":
          this.acuSettingsForm.patchValue({ AcumaticaPassword: setting.value });
          break;
        case "AcumaticaBaseURL":
          this.acuSettingsForm.patchValue({ AcumaticaBaseURL: setting.value });
          break;
        case "AcumaticaEndpointName":
          this.acuSettingsForm.patchValue({ AcumaticaEndpointName: setting.value });
          break;
        case "AcumaticaEndpointVersion":
          this.acuSettingsForm.patchValue({ AcumaticaEndpointVersion: setting.value });
          break;
        case "BCClientID":
          this.bcSettingsForm.patchValue({ BCClientID: setting.value });
          break;
        case "BCClientSecret":
          this.bcSettingsForm.patchValue({ BCClientSecret: setting.value });
          break;
        case "BCTenantID":
          this.bcSettingsForm.patchValue({ BCTenantID: setting.value });
          break;
        case "BCBaseURL":
          this.bcSettingsForm.patchValue({ BCBaseURL: setting.value });
          break;
        case "GPUserName":
          this.gpSettingsForm.patchValue({ GPUserName: setting.value });
          break;
        case "GPPassword":
          this.gpSettingsForm.patchValue({ GPPassword: setting.value });
          break;
        case "GPBaseURL":
          this.gpSettingsForm.patchValue({ GPBaseURL: setting.value });
          break;
        default:
          break;
      }
    });

    this.isFormChanged = false;
  }

  onClick_Save() {
    this.submit();
  }

  async submit() {
    this.settingForm.markAllAsTouched();
    this.submitted = true;
    if (this.settingForm.invalid) {
      return window.scrollTo(0, 0);
    }
    let errorLog: string = '';
    for (let setting in this.settingForm.controls) {
      var tempSetting: Setting = emptySetting;
      tempSetting.name = setting;
      tempSetting.id = 0;
      let currentSetting = this.settings.find(oldSetting => oldSetting.name == tempSetting.name)
      console.log('Setting', currentSetting)
      if (currentSetting) {
        tempSetting.id = currentSetting.id
      }
      let valueOfSetting = this.settingForm.value;
      switch (setting) {
        case "DefaultCurrency":
          tempSetting.value = valueOfSetting.DefaultCurrency ?? null;
          break;
        case "ERPName":
          tempSetting.value = valueOfSetting.ERPName ?? null;
          break;
        case "LoggingLevel":
          tempSetting.value = valueOfSetting.LoggingLevel ?? null;
          break;
        case "UseSingleCurrency":
          tempSetting.value = valueOfSetting.UseSingleCurrency ?? false;
          break;
        case "UseHighestPrice":
          tempSetting.value = valueOfSetting.UseHighestPrice ?? false;
          break;
        case "UseLowestPrice":
          tempSetting.value = valueOfSetting.UseLowestPrice ?? false;
          break;
        default:
          tempSetting.value = null;
          break;
      }

      if (tempSetting.value != null) {
        console.log('Temp Setting', tempSetting)

        tempSetting.companyID = StorageService.CompanyID();
        const data = { ...tempSetting, value: tempSetting.value, companyID: tempSetting.companyID };
        if (tempSetting.id) {
          try {
            const response: any = await this.settingsService.update(
              tempSetting.id,
              data,
            );
            const status: any = response.status;
            if (status === 200) {
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        } else {
          try {
            delete tempSetting.id;
            const response: any = await this.settingsService.create(
              tempSetting,
            );
            const status: any = response.status;
            if (status === 201) {
              tempSetting.id = response.body;
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        }
      }
    }

    for (let setting in this.acuSettingsForm.controls) {
      var tempSetting: Setting = emptySetting;
      tempSetting.name = setting;
      tempSetting.id = 0;
      let currentSetting = this.settings.find(oldSetting => oldSetting.name == tempSetting.name)
      console.log('Setting', currentSetting)
      if (currentSetting) {
        tempSetting.id = currentSetting.id
      }
      let valueOfSetting = this.acuSettingsForm.value;
      switch (setting) {       
        case "AcumaticaUserName":
          tempSetting.value = valueOfSetting.AcumaticaUserName;
          break;
        case "AcumaticaPassword":
          tempSetting.value = valueOfSetting.AcumaticaPassword;
          break;
        case "AcumaticaBaseURL":
          tempSetting.value = valueOfSetting.AcumaticaBaseURL;
          break;
        case "AcumaticaEndpointName":
          tempSetting.value = valueOfSetting.AcumaticaEndpointName;
          break;
        case "AcumaticaEndpointVersion":
          tempSetting.value = valueOfSetting.AcumaticaEndpointVersion;
          break;
        default:
          tempSetting.value = null;
          break;
      }

      if (tempSetting.value != null) {
        console.log('Temp Setting', tempSetting)

        tempSetting.companyID = StorageService.CompanyID();
        const data = { ...tempSetting, value: tempSetting.value, companyID: tempSetting.companyID };
        if (tempSetting.id) {
          try {
            const response: any = await this.settingsService.update(
              tempSetting.id,
              data,
            );
            const status: any = response.status;
            if (status === 200) {
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        } else {
          try {
            delete tempSetting.id;
            const response: any = await this.settingsService.create(
              tempSetting,
            );
            const status: any = response.status;
            if (status === 201) {
              tempSetting.id = response.body;
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        }
      }
    }

    
    for (let setting in this.bcSettingsForm.controls) {
      var tempSetting: Setting = emptySetting;
      tempSetting.name = setting;
      tempSetting.id = 0;
      let currentSetting = this.settings.find(oldSetting => oldSetting.name == tempSetting.name)
      console.log('Setting', currentSetting)
      if (currentSetting) {
        tempSetting.id = currentSetting.id
      }
      let valueOfSetting = this.bcSettingsForm.value;
      switch (setting) {
        case "BCClientID":
          tempSetting.value = valueOfSetting.BCClientID ?? null;
          break;
        case "BCClientSecret":
          tempSetting.value = valueOfSetting.BCClientSecret ?? null;
          break;
        case "BCTenantID":
          tempSetting.value = valueOfSetting.BCTenantID ?? null;
          break;
        case "BCBaseURL":
          tempSetting.value = valueOfSetting.BCBaseURL ?? false;
          break;
        default:
          tempSetting.value = null;
          break;
      }

      if (tempSetting.value != null) {
        console.log('Temp Setting', tempSetting)

        tempSetting.companyID = StorageService.CompanyID();
        const data = { ...tempSetting, value: tempSetting.value, companyID: tempSetting.companyID };
        if (tempSetting.id) {
          try {
            const response: any = await this.settingsService.update(
              tempSetting.id,
              data,
            );
            const status: any = response.status;
            if (status === 200) {
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        } else {
          try {
            delete tempSetting.id;
            const response: any = await this.settingsService.create(
              tempSetting,
            );
            const status: any = response.status;
            if (status === 201) {
              tempSetting.id = response.body;
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        }
      }
    }
      
    for (let setting in this.gpSettingsForm.controls) {
      var tempSetting: Setting = emptySetting;
      tempSetting.name = setting;
      tempSetting.id = 0;
      let currentSetting = this.settings.find(oldSetting => oldSetting.name == tempSetting.name)
      console.log('Setting', currentSetting)
      if (currentSetting) {
        tempSetting.id = currentSetting.id
      }
      let valueOfSetting = this.gpSettingsForm.value;
      switch (setting) {
        case "GPUserName":
          tempSetting.value = valueOfSetting.GPUserName;
          break;
        case "GPPassword":
          tempSetting.value = valueOfSetting.GPPassword;
          break;
        case "GPBaseURL":
          tempSetting.value = valueOfSetting.GPBaseURL;
          break;
        default:
          tempSetting.value = null;
          break;
      }

      if (tempSetting.value != null) {
        console.log('Temp Setting', tempSetting)

        tempSetting.companyID = StorageService.CompanyID();
        const data = { ...tempSetting, value: tempSetting.value, companyID: tempSetting.companyID };
        if (tempSetting.id) {
          try {
            const response: any = await this.settingsService.update(
              tempSetting.id,
              data,
            );
            const status: any = response.status;
            if (status === 200) {
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        } else {
          try {
            delete tempSetting.id;
            const response: any = await this.settingsService.create(
              tempSetting,
            );
            const status: any = response.status;
            if (status === 201) {
              tempSetting.id = response.body;
            }
          } catch (e) {
            errorLog = errorLog + e.error;
          }
        }
      }
    }

    if (errorLog != '') {
      this.snack.openSnackBar(errorLog, '', true, 'Error', 'alert-danger');
    }
    else {
      this.snack.openSnackBar(
        'Record saved successfully!',
        '',
        false,
        'Success',
        'alert-success',
      );
      await this.getSettings();

      let erpNameSetting: Setting = this.settings.find(erpSetting => erpSetting.name == 'ERPName')
      let erpNameSelected: string = this.erpNames.find(erp => erp.id == +erpNameSetting.value).name;
      StorageService.setERPName(erpNameSelected, false);

      let LoggingLevelSetting: Setting = this.settings.find(LoggingLevelSetting => LoggingLevelSetting.name == 'LoggingLevel')
      let LoggingLevelSelected: string = this.LoggingLevels.find(LoggingLevel => LoggingLevel.id == +LoggingLevelSetting.value).name;
      StorageService.setLoggingLevelName(LoggingLevelSelected, false); 
    }
    this.isFormChanged = false;
  }

  async getSettings() {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const params = { PageSize };
      const resp = await this.settingsService.getList(params);
      this.settings = resp.body;
    } catch (e) {
      this.settings = [];
    }
    return this.settings;
  }

  async loadData() {
    this.loading = true;
    try {

      // Wait for the ERPNames to be populated and then set the default to 'None'.
      this.erpNames = await Promise.resolve(await this.getERPNames());
      this.settingForm.patchValue({ ['ERPName']: this.erpNames[2].id });

      // TODO: Make these lookup values
      this.LoggingLevels = [
        { id: 0, optionSet: 'None0', name: 'None', sortOrder: 0},
        { id: 1, optionSet: 'Basic1', name: 'Basic', sortOrder: 1 },
        { id: 2, optionSet: 'Intermediate2', name: 'Intermediate', sortOrder: 2 },
        { id: 3, optionSet: 'Advanced3', name: 'Advanced', sortOrder: 3 }
      ];

      this.settings = await Promise.resolve(await this.getSettings());

      let defaultCurrencySetting: Setting =
        this.settings.find(currencySetting => currencySetting.name == 'DefaultCurrency')
      if (defaultCurrencySetting && defaultCurrencySetting.value) {
        this.defaultCurrency = await Promise.resolve(
          await this.currenciesService.getById(Number(defaultCurrencySetting.value)));
        this.currencies.push(this.defaultCurrency);
        this.settingForm.patchValue({ DefaultCurrency: this.defaultCurrency.id });
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }
    this.updateForm();
  }

  prepareData() {
    this.updateForm();
  }

  async defaultCurrencySearch() {
    return await this.getCurrencies();
  }

  async getCurrencies() {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const params = { PageSize };
      const resp = await this.currenciesService.getList(params);
      // @ts-ignore
      this.currencies = resp.body;
      this.paginationObject.uofMs = getPaginationHeader(
        // @ts-ignore
        resp.headers,
      );
    } catch (e) {
      this.currencies = [];
    }
    return this.currencies;
  }

  async getERPNames() {
    const Filter_OptionSet = 'ERPName';
    const params = { Filter_OptionSet };
    this.erpNames = await this.lookupService.getList(params);
    return this.erpNames;
  }

  onSelectBoxChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.DefaultCurrency:
        this.currencies = await (await this.currenciesService.getList(params)).body;
        break;
      default:
        break;
    }
  }

  onBlur(event) {
    if (event) {
      this.currencyLookupHasFocus = false;
    }
  }

  onFocus(event) {
    if (event) {
      this.currencyLookupHasFocus = true;
    }
  }

  async onLookup_defaultCurrency() {

    // If the user hits the enter key but is not on this button then ignore it.
    if (!this.currencyLookupHasFocus) {
      return;
    }

    const modalRef = this.modalService.open(CurrencyLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: "CurrencyModalClass",
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.gridClassName = "CurrencyDialogGrid";
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Currency = receivedEntry;
      this.currencies = [];
      this.currencies.push(recordSelected);
      this.settingForm.patchValue({ ['DefaultCurrency']: receivedEntry.id });
      modalRef.close('test');
    });
  }

  UseHighestPriceChanged() {
    if (this?.f?.UseHighestPrice) {
      if (this.f.UseHighestPrice.value == true) {
        this.settingForm.patchValue({ ['UseLowestPrice']: false });
        this.f.UseLowestPrice.updateValueAndValidity();
      }
    }
  }

  UseLowestPriceChanged() {
    if (this?.f?.UseLowestPrice) {
      if (this.f.UseLowestPrice.value == true) {
        this.settingForm.patchValue({ ['UseHighestPrice']: false });
        this.f.UseHighestPrice.updateValueAndValidity();
      }
    }
  }

  useSingleCurrencyChanged() {
    if (this?.f?.UseSingleCurrency) {
      if (this.f.UseSingleCurrency.value == true) {
        this.f.DefaultCurrency.setValidators(Validators.required);
        this.f.DefaultCurrency.updateValueAndValidity();
      }
      else {
        this.f.DefaultCurrency.clearValidators();
        this.f.DefaultCurrency.updateValueAndValidity();
      }
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }

  closeModal = () => {
    this.modalService.dismissAll();
  }

  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }

  async canDeactivate(nextURL: string) {
    console.log('leave', this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }
}
