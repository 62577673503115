export interface Catalog {
  id: number;
  name: string;
  description: string;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptyCatalog: Catalog = {
  id: null,
  name: '',
  description: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
