import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolToHuman',
})
export class BoolToHumanPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    // if (value === null) return 'Not Set';
    return value ? 'Yes' : 'No';
  }
}
