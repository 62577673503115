<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Item.png" alt="Item" style="padding-right: 5px;">
  Create / Update Item
</div>

<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;"
    imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/items']" routerLinkActive="router-link-active"
    title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">SAVE &
    CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin"
    imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="!isAdmin || disableDeleteBtn"
    imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)"
    title="Delete Record">DELETE</button>
</kendo-buttongroup>

<form [formGroup]="itemForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Item Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Name">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="number" class="font-weight-bold" text="* Number"></kendo-label>
        <kendo-textbox class="form-control" formControlName="number" placeholder="Enter Item Number">
        </kendo-textbox>
        <kendo-formerror>Item Number is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="uofMScheduleID" class="font-weight-bold" text="* Unit of Measure Schedule"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="uofMScheduleID" [multiple]="false" [selectOnTab]="true" class="noarrow"
            formControlName="uofMScheduleID" [ngClass]="{
            'is-invalid': submitted && f.uofMScheduleID.errors,
            'w-100': true
            }" [searchable]="true" placeholder="Select a Unit of Measure Schedule"
            [notFoundText]="'Please start typing to search'" (search)="search($event, filters.UofMSchedule)" (change)="
            onSelectBoxChanged(
              $event,
              'uofMScheduleID',
              uofMSchedules)">
            <ng-option *ngFor="let item of uofMSchedules" [value]="item.id">
              {{ item.name }}
            </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_uofMSchedule()" icon="search"></button>
        </span>
        <kendo-formerror>Unit of Measure Schedule is required</kendo-formerror>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="erpid" text="ERP ID"></kendo-label>
        <kendo-textbox class="form-control" formControlName="erpid">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="description" text="Description"></kendo-label>
        <kendo-textbox class="form-control" formControlName="description" placeholder="Enter Description">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <kendo-tabstrip [tabPosition]="'top'">
    <kendo-tabstrip-tab [title]="'Item Attributes'" [selected]="true">
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color:rgb(234, 234, 234)">
          <app-item-attribute-value-sublist [itemID]='idOfItem' mode='subgrid' #itemAttributeValuesSubgrid>
          </app-item-attribute-value-sublist>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab [title]="'Item Currencies'" >
      <ng-template kendoTabContent>
        <div class="card p-3" style="background-color:rgb(234, 234, 234)">
          <app-item-currency-sublist [itemID]='idOfItem' [defaultItem]='this.item' mode='subgrid' #itemCurrencySubgrid>
          </app-item-currency-sublist>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>

</form>
