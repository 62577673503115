import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html',
  styleUrls: ['./unsaved-changes-modal.component.scss'],
})
export class UnsavedChangesModalComponent implements OnInit {
  @Output() goNextPage: EventEmitter<any> = new EventEmitter();
  @Output() closeModal: EventEmitter<any> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
  goBackAndSave() {
    this.closeModal.emit();
  }

  goNext() {
    console.log('goNext');
    this.goNextPage.emit();
  }
}
