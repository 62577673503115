import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SyncQueuesService } from '../../../services/sync-queues/sync-queues.service';
import { SyncProcessingService } from '../../../services/sync-processing/sync-processing.service';
import { SyncQueue } from '../../../services/sync-queues/sync-queues.model';
import { debounce } from 'lodash';
import { Pagination, mockedData, getPaginationHeader } from 'src/app/utils/getPaginationHeader';
import { HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { GridDataResult, PageChangeEvent, GridComponent } from '@progress/kendo-angular-grid';
import { SortDescriptor, CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'sync-queue-list',
  templateUrl: './sync-queue-list.component.html',
  styleUrls: ['./sync-queue-list.component.scss'],
})
export class SyncQueueListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private syncQueuesService: SyncQueuesService,
    private syncProcessingService: SyncProcessingService,
    private router: Router,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private datePipe: DatePipe,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() syncQueues: SyncQueue[] = [];
  @Input() query = { PageSize: 25, SortTerm: 'name', PageNumber: 1, SearchTerm: '' };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input()
  mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public queueTypeOptions: Lookup[];
  public entityTypeOptions: Lookup[];
  public queueStatusOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Entry Time',
      field: 'entryTime',
      width: 200,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Sync Set',
      field: 'syncSet.name',
      width: 150,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Queue Type',
      field: 'queueType.name',
      width: 125,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Entity',
      field: 'entity.name',
      width: 125,
      orderIndex: 3,
      hidden: false,
    },
    {
      title: 'Record ID',
      field: 'recordID',
      width: 150,
      orderIndex: 4,
      hidden: false,
    },
    {
      title: 'Since Time',
      field: 'sinceTime',
      width: 100,
      orderIndex: 5,
      hidden: false,
    },
    {
      title: 'Status',
      field: 'status.name',
      width: 100,
      orderIndex: 6,
      hidden: false,
    },
    {
      title: 'Completed Time',
      field: 'completedTime',
      width: 100,
      orderIndex: 7,
      hidden: false,
    },
    {
      title: 'Error Message',
      field: 'errorMessage',
      width: 175,
      orderIndex: 8,
      hidden: false,
    },
    {
      title: 'Test Record Count',
      field: 'testRecordCount',
      width: 100,
      orderIndex: 9,
      hidden: false,
    }
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async getQueueTypeOptions() {
    const Filter_OptionSet = 'QueueType';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.queueTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "QueueType";
    defaultLookup.name = "(All)";
    this.queueTypeOptions.reverse();
    this.queueTypeOptions.push(defaultLookup);
    this.queueTypeOptions.reverse();
    return this.queueTypeOptions;
  }

  async getEntityTypeOptions() {
    const Filter_OptionSet = 'SyncEntityType';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.entityTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "SyncEntityType";
    defaultLookup.name = "(All)";
    this.entityTypeOptions.reverse();
    this.entityTypeOptions.push(defaultLookup);
    this.entityTypeOptions.reverse();
    return this.entityTypeOptions;
  }

  async getQueueStatusOptions() {
    const Filter_OptionSet = 'QueueStatus';
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { Filter_OptionSet, PageSize };
    this.queueStatusOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "QueueStatus";
    defaultLookup.name = "(All)";
    this.queueStatusOptions.reverse();
    this.queueStatusOptions.push(defaultLookup);
    this.queueStatusOptions.reverse();
    return this.queueStatusOptions;
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  async onClick_RunNow() {
    try {
      const response: any = await this.syncProcessingService.performSync(null);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Run successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
    this.loadListData();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async onClick_ResetQueues() {
    for (const value of this.mySelection) {
      try {
        const recordID = Number(value);
        const currentSyncQueue = await this.syncQueuesService.getById(recordID);
        currentSyncQueue.statusID = 93;
        currentSyncQueue.errorMessage = null;
        currentSyncQueue.completedTime = null;
        await this.syncQueuesService.update(recordID, currentSyncQueue);
      } catch (err) {
        this.notificationHelper.showStatus(`Error updating record with ID ${value}: ${err.error}`, 'error');
      }
    }
    this.mySelection = [];
    this.loadListData();
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.syncQueuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.router.navigate(['/price/sync-queues/' + this.clickedRowItem.id]);
      }
    }
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('SyncQueueList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('SyncQueueList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.hidden = columnConfig.hidden;
        column.orderIndex = columnConfig.orderIndex;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('SyncQueueList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
    this.getQueueTypeOptions();
    this.getQueueStatusOptions();
    this.getEntityTypeOptions();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('SyncQueueList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = { PageNumber: 1, PageSize: this.query.PageSize, SortTerm: 'name', SearchTerm: '' };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        let tempName: string | undefined;
        if (typeof myFilter.field === 'string') {
          tempName = myFilter.field.includes('.') ? myFilter.field.split('.')[0] : myFilter.field;
        }
        const filterField = 'Filter_' + tempName;
        const operandField = 'Operand_' + tempName;
        if (myFilter.field == 'status.name' || myFilter.field == 'queueType.name') {
          if (myFilter.value != '(All)') {
            const params = { ...this.query, [filterField]: myFilter.value, [operandField]: myFilter.operator };
            this.query = params;
          }
        }
        else {
          const params = { ...this.query, [filterField]: myFilter.value, [operandField]: myFilter.operator };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    let sortName = sort[0].field.includes('.') ? sort[0].field.split('.')[0] : sort[0].field;
    console.log(sortName);
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sortName
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sortName;
    } else {
      this.query.SortTerm = sortName;
    }
    this.loadListData();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.syncQueuesService.getList(this.query);
      this.syncQueues = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.syncQueues,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  edit(id: number) {
    this.router.navigate([`/price/sync-queue/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.syncQueuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }
  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }
  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

}
