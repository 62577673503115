<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../assets/images/Get_Price.png" alt="Get Price" style="padding-right: 5px;">
  Get Price
</div>
<form [formGroup]="getPriceForm">
  <div id="inputValues" class="card card-primary">
    <div class="card-header">Input Values</div>
    <div class="card-body">
      <div class="row">
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label for="priceScheduleID" text="Price Schedule"></kendo-label>
            <span class="k-textbox" style="width: 100%;">
              <ng-select id="priceScheduleID" class="borderless" [multiple]="false" [selectOnTab]="true"
                formControlName="priceScheduleID" [ngClass]="{
                'borderless': true,
                'is-invalid': submitted && f.priceScheduleID.errors,
                'w-100': true
              }" [searchable]="true" placeholder="Select a Price Schedule"
                [notFoundText]="'Please start typing to search'" (search)="search($event, filters.PriceSchedule)"
                (change)="onSelectBoxChanged($event, 'priceScheduleID', priceSchedules)">
                <ng-option *ngFor="let item of priceSchedules" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_priceSchedule()" icon="search"></button>
            </span>
          </kendo-formfield>
        </div>
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label class="font-weight-bold" for="effectiveDate" text="* Effective Date"></kendo-label>
            <kendo-datepicker id="effectiveDate" class="form-control" formControlName="effectiveDate"
              [(value)]="effectiveDate">
            </kendo-datepicker>
            <kendo-formerror>Effective Date is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label for="quantity" text="Quantity"></kendo-label>
            <kendo-numerictextbox class="form-control" formControlName="quantity" [min]="0" [autoCorrect]="true"
              [decimals]="5" [format]="'n5'" [max]="99999999999999" [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }">
            </kendo-numerictextbox>
          </kendo-formfield>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label for="unitPriceAmount" text="Value from ERP"></kendo-label>
            <kendo-numerictextbox class="form-control" formControlName="unitPriceAmount" [min]="0" [autoCorrect]="true"
              [decimals]="5" [format]="'n5'" [max]="99999999999999" [ngClass]="{ 'is-invalid': submitted && f.unitPriceAmount.errors }">
            </kendo-numerictextbox>
          </kendo-formfield>

        </div>
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label class="font-weight-bold" for="itemID" text="* Item"></kendo-label>
            <span class="k-textbox" style="width: 100%;">
              <ng-select class="borderless" id="itemID" [multiple]="false" [selectOnTab]="true" formControlName="itemID"
                [ngClass]="{
              'borderless': true, 'is-invalid': submitted && f.itemID.errors,
                'w-100': true
              }" [searchable]="true" placeholder="Select an Item" [notFoundText]="'Please start typing to search'"
                (search)="search($event, filters.Item)" (change)="onSelectBoxChanged($event, 'itemID', items)">
                <ng-option *ngFor="let item of items" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_item()" icon="search"></button>
            </span>
            <kendo-formerror>Item is required</kendo-formerror>
          </kendo-formfield>

        </div>
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label class="font-weight-bold" for="unitOfMeasureID" text="* Unit of Measure"></kendo-label>
            <span class="k-textbox" style="width: 100%;">
              <ng-select id="unitOfMeasureID" class="borderless" [multiple]="false" [selectOnTab]="true"
                formControlName="unitOfMeasureID" [ngClass]="{              
                'is-invalid': submitted && f.unitOfMeasureID.errors,
                'w-100': true
              }" [searchable]="true" placeholder="Select a Unit of Measure"
                [notFoundText]="'Please start typing to search'" (search)="search($event, filters.UnitOfMeasure)"
                (change)="onSelectBoxChanged($event, 'unitOfMeasureID', uofMs)">
                <ng-option *ngFor="let item of uofMs" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_unitOfMeasure()" icon="search"></button>
            </span>
            <kendo-formerror>Unit of Measure is required</kendo-formerror>
          </kendo-formfield>
        </div>
      </div>
      <div class="row">
        <div class="col form-group">
          <!-- <kendo-formfield>
            <kendo-label for="currencyID" class="font-weight-bold" text="* Currency"></kendo-label>
            <span class="k-textbox" style="width: 100%;">
              <kendo-combobox
                formControlName="currencyID"
                [data]="currencies"
                (filterChange)="search($event, filters.Currency)"
                [filterable]="true"
                (valueChange)="onSelectBoxChanged($event, 'currencyID', currencies)"               
                [disabled]="singleCurrency" 
                [valueField]="'id'" 
                [textField]="'name'"
                [valuePrimitive]="true"
                [allowCustom]="false"
                [clearButton]="false"
                [ngClass]="{
                  'borderless': true,
                  'w-100': true
                  }"
               >
              </kendo-combobox>
              <kendo-autocomplete [data]="currencies" formControlName="currencyID" [filterable]="true"
                (filterChange)="search($event, filters.Currency)"
                (valueChange)="onSelectBoxChanged($event, 'currencyID', currencies)" 
                placeholder="Start Typing to Begin Search..." 
                [disabled]="singleCurrency" 
                [ngClass]="{
                'is-invalid': submitted && f.currencyID.errors,
                  'w-100': true
                }" 
                valueField="name">
              </kendo-autocomplete>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_currency()" icon="search"
                [disabled]="singleCurrency"></button>
            </span>

          </kendo-formfield> -->
          <kendo-formfield *ngIf="singleCurrency">
            <kendo-label class="font-weight-bold" for="currencyID" text="* Currency"></kendo-label>
            <kendo-textbox            
              class="form-control"
              formControlName="currencyID"
              maxlength="100"
              disabled="true">
            </kendo-textbox>
          </kendo-formfield>
          <kendo-formfield *ngIf="!singleCurrency">
            <kendo-label class="font-weight-bold" for="currencyID" text="* Currency"></kendo-label>
            <span class="k-textbox" style="width: 100%;" >
              <ng-select class="borderless" id="currencyID" [multiple]="false" [selectOnTab]="true"
                formControlName="currencyID" [ngClass]="{
              'borderless': true,  
              'is-invalid': submitted && f.currencyID.errors,
                'w-100': true
              }" [searchable]="true" placeholder="Select a Currency" [notFoundText]="'Please start typing to search'"
                (search)="search($event, filters.Currency)"
                (change)="onSelectBoxChanged($event, 'currencyID', currencies)">
                <ng-option *ngFor="let item of currencies" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_currency()" icon="search"
                [disabled]="singleCurrency"></button>
            </span>
            <kendo-formerror>Currency is required</kendo-formerror>
          </kendo-formfield>
        </div>
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label for="customerID" text="Customer"></kendo-label>
            <span class="k-textbox" style="width: 100%;">
              <ng-select class="borderless" id="customerID" [multiple]="false" [selectOnTab]="true"
                formControlName="customerID" [ngClass]="{
              'borderless': true,  
              'is-invalid': submitted && f.customerID.errors,
                'w-100': true
              }" [searchable]="true" placeholder="Select a Customer" [notFoundText]="'Please start typing to search'"
                (search)="search($event, filters.Customer)"
                (change)="onSelectBoxChanged($event, 'customerID', customers)">
                <ng-option *ngFor="let item of customers" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_customer()" icon="search"></button>
            </span>
          </kendo-formfield>

        </div>
        <div class="col form-group">
          <kendo-formfield>
            <kendo-label for="locationID" text="Location"></kendo-label>
            <span class="k-textbox" style="width: 100%;">
              <ng-select id="locationID" class="borderless" [multiple]="false" [selectOnTab]="true"
                formControlName="locationID" placeholder="Select a Location" [ngClass]="{
              'borderless': true, 
              'is-invalid': submitted && f.locationID.errors,
                'w-100': true
              }" [searchable]="true" [notFoundText]="'Please start typing to search'"
                (search)="search($event, filters.Location)"
                (change)="onSelectBoxChanged($event, 'locationID', locations)">
                <ng-option *ngFor="let item of locations" [value]="item.id">
                  {{ item.name }}
                </ng-option>
              </ng-select>
              <kendo-textbox-separator></kendo-textbox-separator>
              <button kendoButton type="button" look="clear" (click)="onLookup_location()" icon="search"></button>
            </span>
          </kendo-formfield>
        </div>
      </div>
    </div>
  </div>
  <div id="outputValues" class="card card-primary">
    <div class="card-header">
      <div class="row">
        <div>Output Values</div>
        <div class="col flex-grow-1"></div>
        <div class="align-content-end" *ngIf="this.pricingDiagnosticRunID">
          <button type="button" (click)="this.show_PricingDiagnostic()" class="btn btn-link">
            View Diagnostics
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col form-group">
          <kendo-label for="returnedUnitPrice"> Returned Unit Price </kendo-label>
          <input readonly class="form-control" formControlName="returnedUnitPrice" id="returnedUnitPrice" />
        </div>
        <div class="col form-group">
          <kendo-label for="returnedPriceScheduleName">
            Returned Price Schedule Name
          </kendo-label>
          <input readonly class="form-control" formControlName="returnedPriceScheduleName"
            id="returnedPriceScheduleName" />
        </div>
        <div class="col form-group">
          <kendo-label for="getPriceStatus">Get Price Status</kendo-label>
          <kendo-label class="form-control text-success" *ngIf="submitted && priceWasFound == 1">Price Successfully
            Found</kendo-label>
          <kendo-label class="form-control text-danger" *ngIf="submitted && priceWasFound == 0">No Price Was Returned.
            View the Diagnostics</kendo-label>
        </div>
        <div class="col form-group">
          <kendo-label for="pricingRunTime"> Pricing Run Time </kendo-label>
          <input readonly class="form-control" formControlName="pricingRunTime" id="pricingRunTime" />
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">Price Adjustments</div>
    </div>
    <div class="card-body">
      <kendo-grid #grid="kendoGrid" [data]="gridView" [reorderable]="true" [pageable]="false" [filterable]="false"
        [resizable]="true" [autoSize]="true" [sortable]="false" kendoGridSelectBy="id">
        <ng-template kendoGridToolbarTemplate>
          <kendo-grid-column-chooser [allowHideAll]="false" [autoSync]="true"></kendo-grid-column-chooser>
        </ng-template>
        <kendo-grid-column field="PriceAdjustmentName" title="Name"></kendo-grid-column>
        <kendo-grid-column field="PriceAdjustmentAmount" title="Amount"></kendo-grid-column>
        <kendo-grid-column field="DebitGLAccountName" title="Debit GL Name"></kendo-grid-column>
        <kendo-grid-column field="DebitGLAccountNumber" title="Debit GL Number"></kendo-grid-column>
        <kendo-grid-column field="CreditGLAccountName" title="Credit GL Name"></kendo-grid-column>
        <kendo-grid-column field="CreditGLAccountNumber" title="Credit GL Number"></kendo-grid-column>
        <kendo-grid-column field="UserCanEdit" title="User Can Edit"></kendo-grid-column>
        <kendo-grid-column field="AdjustmentAffectsMargins" title="Affects Margins"></kendo-grid-column>
        <kendo-grid-column field="AdjustmentModifiesUnitPrice" title="Modifies Unit Price"></kendo-grid-column>
      </kendo-grid>
    </div>
  </div>
  <div class="row m-0 mt-4">
    <p class="k-block">
      <button kendoButton type="button" (click)="execute()" class="btn btn-primary mr-3">Execute</button>
      <button kendoButton type="button" (click)="clear()">Clear</button>
    </p>
    <!-- <button type="button" (click)="execute()" class="btn btn-primary mr-3">
      Execute
    </button> -->
  </div>
</form>
