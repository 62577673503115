export interface SyncSetEntity {
  id: number;
  syncSetID: number;
  syncSet: {
    name: string,
    id: number
  };
  name: string;
  syncOrder: number;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptySyncSetEntity: SyncSetEntity = {
  id: null,
  syncSetID: null,
  syncSet: {
    name: '',
    id: null
  },
  name: '',
  syncOrder: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};
