import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
//import { Setting } from './settings.model';
import { clearParamsFromNull } from '../params-utils/index';
import { observe } from '@progress/kendo-angular-grid/dist/es2015/utils';
//import { GridDataResult } from '@progress/kendo-angular-grid';
//import { map } from 'rxjs/operators';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ToolsService {
  model = 'RPMPrice';
  constructor(private httpClient: HttpClient) { }
  async getPrice(data): Promise<HttpResponse<string>> {
    const headerValues = new HttpHeaders({ 'Content-Type': 'application/*+json' });
    headerValues.append('Content-Type', 'application/*+json');

    return this.httpClient
      .post(this.model, data, { headers: headerValues, responseType: 'text', observe: 'response' })
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<HttpResponse<string>>;
  }
}
