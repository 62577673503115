<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">      
      Create / Update Customer Attribute Value
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="customerAttributeValueForm">
    <input hidden="true" class="form-control" formControlName="customerID"
           [ngClass]="{ 'is-invalid': submitted && f.customerID.errors }" />

    <kendo-formfield>
      <kendo-label for="customerAttributeID" class="font-weight-bold" text="* Customer Attribute"></kendo-label>
      <span class="k-textbox" style="width: 100%;">
        <ng-select id="customerAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true" appendTo="body" 
                   [notFoundText]="'Please start typing to search'" formControlName="customerAttributeID" [ngClass]="{
                'borderless': true,
                'is-invalid': submitted && f.customerAttributeID.errors,
                'w-100': true}" [searchable]="true" placeholder="Select a Customer Attribute"
                   (search)="search($event, filters.CustomerAttribute)" (change)="
              onSelectBoxChanged(
                  $event,
                  'customerAttribute',
                  customerAttributes)">
          <ng-option *ngFor="let item of customerAttributes" [value]="item.id">
            {{ item.name }}
          </ng-option>
        </ng-select>
        <kendo-textbox-separator></kendo-textbox-separator>
        <button kendoButton type="button" look="clear" (click)="onLookup_CustomerAttribute()"
                icon="search"></button>
      </span>
      <kendo-formerror>Customer Attribute is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield *ngIf="attributeIsString">
      <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
      <kendo-textbox class="form-control" formControlName="value" placeholder="Enter a Value">
      </kendo-textbox>
      <kendo-formerror>Value is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield *ngIf="attributeIsDate">
      <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
      <kendo-datepicker class="form-control" formControlName="value" placeholder="Enter a Value"
                        [(value)]="value">
      </kendo-datepicker>
      <kendo-formerror>Value is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield *ngIf="attributeIsNumber">
      <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
      <kendo-numerictextbox class="form-control" type="number" formControlName="value" [decimals]="5"
                            placeholder="Enter a Value" [format]="'n5'" [min]="-99999999999999"  [max]="99999999999999">
      </kendo-numerictextbox>
      <kendo-formerror>Value is required</kendo-formerror>
    </kendo-formfield>

    <kendo-formfield *ngIf="attributeIsCheckBox">
      <kendo-label for="value" class="font-weight-bold" text="* Value"></kendo-label>
      <input class="form-control" type="checkbox" formControlName="value" />
      <kendo-formerror>Value is required</kendo-formerror>
    </kendo-formfield>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton *ngIf="isAdmin" (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
