<kendo-grid *ngIf="gridViewChild && gridViewChild.length > 0"
            [kendoGridBinding]="gridViewChild"
            idField="id"
            [resizable]="true">

  <kendo-grid-column field="childPriceSchedule.name" title="Child Price Schedules" width="300" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a [routerLink]="['/price/price-schedule/', dataItem.childPriceSchedule.id]">
        {{ dataItem.childPriceSchedule.name }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="rank" title="Rank" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.priceSourceOption.name" title="Source" width="120" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.pricingFilter.name" title="Filter" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.childPriceSchedule.pricingFilter?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.priceBook.name" title="Price Book" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.childPriceSchedule.priceBook?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="childPriceSchedule.roundingRule.name" title="Round" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.childPriceSchedule.roundingRule?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<br />

<kendo-grid *ngIf="gridViewCalc && gridViewCalc.length > 0"
            [kendoGridBinding]="gridViewCalc"
            idField="id"
            [resizable]="true">

  <kendo-grid-column field="priceCalculation.name" title="Calculations" width="250" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a [routerLink]="['/price/price-calculation/', dataItem.priceCalculation.id]">
        {{ dataItem.priceCalculation.name }}
      </a>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="rank" title="Rank" width="70" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"> </kendo-grid-column>
  <kendo-grid-column field="priceCalculation.priceModeOption.name" title="Price Mode" width="120" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
  <kendo-grid-column field="priceCalculation.priceAmount" title="Amount" width="65" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>
  <kendo-grid-column field="priceCalculation.pricePercent" title="Percent" width="65" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }"></kendo-grid-column>

  <kendo-grid-column field="priceCalculation.pricingFilter.name" title="Filter" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.priceCalculation.pricingFilter?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceCalculation.pricingCondition.name" title="Condition" width="75" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.priceCalculation.pricingCondition?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="priceCalculation.roundingRule.name" title="Round" width="60" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em' }">
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.priceCalculation.roundingRule?.name ? 'Yes' : 'No' }}
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

