<kendo-dialog [minWidth]="250" (close)="onClick_Close('Cancel')" [width]="450">
  <kendo-dialog-titlebar>
    <div style="font-size: 18px; line-height: 1.3em;">
      Create / Update Sync Set Entity
    </div>
  </kendo-dialog-titlebar>
  <div style="margin-top:15px;" #container id="container"></div>

  <form [formGroup]="syncSetEntityForm">
    <input hidden="true" class="form-control" formControlName="syncSetID"
           [ngClass]="{ 'is-invalid': submitted && f.syncSetID.errors }" />
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
          <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Name" [clearButton]="true"
                         [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
          </kendo-textbox>
          <kendo-formerror>Name is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
    <div class="row">
      <div class="col form-group">
        <kendo-formfield>
          <kendo-label for="syncOrder" class="font-weight-bold" text="* Sync Order"></kendo-label>
          <kendo-numerictextbox class="form-control" formControlName="syncOrder" [min]="1" [autoCorrect]="true"
                                [decimals]="0" [format]="'n0'" [ngClass]="{ 'is-invalid': submitted && f.syncOrder.errors }"
                                [max]="999">
          </kendo-numerictextbox>
          <kendo-formerror>Sync Order is required</kendo-formerror>
        </kendo-formfield>
      </div>
    </div>
  </form>
  <kendo-dialog-actions>
    <button kendoButton (click)="onClick_Close('Cancel')"><span class="k-icon k-i-cancel"></span>Cancel</button>
    <button kendoButton (click)="onClick_Save()" primary="true" class="btn btn-primary"><span class="k-icon k-i-save"></span>Save</button>
  </kendo-dialog-actions>
</kendo-dialog>
