export interface User {
    id: string;
    userName: string;
    password: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean;
    isDisabled: boolean;
    invalidAttempts: number;
    pageSize:number;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
}

export const emptyUser: User = {
    id: '',    
    userName: '',
    password: '',
    firstName: '',
    lastName: '',
    isAdmin: false,
    isDisabled: false,
    invalidAttempts: null,
    pageSize: 25,
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
