import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { formatDate, HashLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SystemJobErrorsService } from '../../../services/system-job-errors/system-job-errors.service';
import { SystemJobError, emptySystemJobError } from '../../../services/system-job-errors/system-job-errors.model';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup } from 'src/app/services/lookups/lookups.model';
import { PricingFiltersService } from '../../../services/pricing-filters/pricing-filters.service';
import { PriceSchedulesService } from '../../../services/price-schedules/price-schedules.service';
import { CustomersService } from '../../../services/customers/customers.service';
import { ItemsService } from '../../../services/items/items.service';
import { LocationsService } from '../../../services/locations/locations.service';
import { PricingFilterLookupComponent } from '../../pricing-filters/pricing-filter-lookup/pricing-filter-lookup.component';
import { PriceScheduleLookupComponent } from '../../price-schedules/price-schedule-lookup/price-schedule-lookup.component';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { CurrenciesService } from '../../../services/currencies/currencies.service';
import { Currency } from 'src/app/services/currencies/currencies.model';
import { SystemJobLogsService } from '../../../services/system-job-logs/system-job-logs.service';
import { emptySystemJobLog, SystemJobLog } from 'src/app/services/system-job-logs/system-job-logs.model';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { SystemJobLogListComponent } from '../../system-job-logs/system-job-log-list/system-job-log-list.component';
import { CurrencyListComponent } from '../../currencies/currency-list/currency-list.component';
import { CurrencyLookupComponent } from '../../currencies/currency-lookup/currency-lookup.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { SettingsService } from '../../../services/settings/settings.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { Setting } from 'src/app/services/settings/settings.model';
import { Location as AngularLocation} from '@angular/common'; 
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { PricingFilter } from '../../../services/pricing-filters/pricing-filters.model';
import { PriceSchedule } from '../../../services/price-schedules/price-schedules.model';
import { CopyService } from '../../../services/copy/copy.service';
import { Customer } from '../../../services/customers/customers.model';
import { Item } from '../../../services/items/items.model';
import { Location } from '../../../services/locations/locations.model'; 
import { UtilitiesService } from '../../../services/utilities/utilities.service';


enum Filters {
  Name = 1,
  SytemJobLog,
  Currency,
  PriceSchedule,
  Customer,
  Item,
  Location
}
@Component({
  selector: 'app-system-job-error-single',
  templateUrl: './system-job-error-single.component.html',
  styleUrls: ['./system-job-error-single.component.scss'],
})
export class SystemJobErrorSingleComponent implements OnInit {

  filters = Filters;
  idOfSystemJobError: number | null = null;
  @Input() idOfSystemJobLog: number | null = null;
  @Input() createFromBook: boolean = false;
  SystemJobError: SystemJobError = emptySystemJobError;
  loading: boolean = true;
  SystemJobErrorForm: FormGroup;
  currencies: Currency[] = [];
  systemJobLogs: SystemJobLog[] = [];
  currencyID: number;
  singleCurrency: boolean;
  priceSchedules: PriceSchedule[] = [];
  customers: Customer[] = [];
  items: Item[] = [];
  locations: Location[] = [];
  effectiveDate: Date = new Date();
  public min: Date = new Date(2000, 0);

  submitted = false;
  date: { year: number; month: number };
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();

  isFormChanged = false;
  goBackForce = false;
  modalReference;
  nextURL = '';

  constructor(
    private route: ActivatedRoute,
    private SystemJobErrorsService: SystemJobErrorsService,
    private systemJobLogsService: SystemJobLogsService,
    private currenciesService: CurrenciesService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    public activeModal: NgbActiveModal,
    private sideNavService: SideNavService,
    private location : AngularLocation,
    private router: Router,
    private notificationHelper: NotificationHelper,
    private pricingFiltersService: PricingFiltersService,
    private priceSchedulesService: PriceSchedulesService,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private locationsService: LocationsService,
    private copyService: CopyService,
    private utilitiesService: UtilitiesService,
  ) {
    this.route.params.subscribe((params) => {
      if (params.id && params.id === 'new') {
        this.SystemJobError = emptySystemJobError;
        this.idOfSystemJobError = null;
        this.submitted = false;

        this.idOfSystemJobLog = Number(this.route.snapshot.paramMap.get('systemJobLogID'));
        return;
      }
      else{
        this.idOfSystemJobError = params.id;
      }
    });
  }

  ngOnInit(): void {
    this.buildForm();
    if (this.idOfSystemJobLog) {      
      this.SystemJobError.systemJobLogID = this.idOfSystemJobLog;
    }
    this.loadData();
  }

  buildForm() {
    this.SystemJobErrorForm = this.fb.group({
      errorMessage: new FormControl(''),
      systemJobLogID: new FormControl(''),
      errorDateTime: new FormControl(''),
      errorNumber: new FormControl(false),
      errorSeverity: new FormControl(''),
      errorState: new FormControl(''),
      entityName: new FormControl(),
      recordReferenceID: new FormControl(''),
      jsonSnippet: new FormControl(''),
    });
    this.SystemJobErrorForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  get f() {
    return this.SystemJobErrorForm.controls;
  }

  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  async onClickCopy() {
    try {
      const response: any = await this.copyService.performCopy(null, "RPMSystemJobError", this.SystemJobError.id);
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Copy successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  updateForm() {
    this.SystemJobErrorForm.patchValue({ ...this.SystemJobError });
    this.isFormChanged = false;
  }

  async deleteRecord(modal) {
    const ids = [];
    ids.push(this.SystemJobError.id);
    await this.SystemJobErrorsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        modal.close();
        this.router.navigate(['/price/system-job-errors']);
      }
      )
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
        modal.close();
      });
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  get disableButtons() {
    if (this.SystemJobError && this.SystemJobError.id) {
      return false;
    }
    else {
      return true;
    }
  }

  onClick_Back(): void {
    if (this.idOfSystemJobLog) {
      this.router.navigate(['/price/system-job-log/' + this.idOfSystemJobLog]);
    } else {
      this.location.back();
    }
  }


  async checkForExistingName() {
    var nameExists: boolean = false;
    const PageSize = StorageService.PageSize() ?? 50;
    const Filter_Name = this.SystemJobErrorForm.controls['name'].value;
    const Operand_Name = 'eq';
    const Filter_systemJobLogID = this.SystemJobError.systemJobLogID;
    const Operand_systemJobLogID = 'eq';

    const params = { Filter_Name, Operand_Name, Filter_systemJobLogID, Operand_systemJobLogID, PageSize };
    const existingSystemJobErrors: SystemJobError[] = await (
      await this.SystemJobErrorsService.getList(params)
    ).body;
    if (existingSystemJobErrors.length > 0) {
      if (this.SystemJobError.id) {
        for (const key in existingSystemJobErrors) {
          const currentSystemJobError: SystemJobError = existingSystemJobErrors[key];
          if (currentSystemJobError.id !== this.SystemJobError.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async loadData() {
    this.loading = true;
    try {
      //
      if (this.idOfSystemJobError) {
        // If this is an existing record we populate the values.
        this.SystemJobError = await Promise.resolve(
          await this.SystemJobErrorsService.getById(this.idOfSystemJobError));

        if (this.SystemJobError.systemJobLogID){
          const currentSystemJobLog: SystemJobLog = await Promise.resolve(
            await this.systemJobLogsService.getById(this.SystemJobError.systemJobLogID));
          this.systemJobLogs.push(currentSystemJobLog);  
          this.SystemJobError.systemJobLog = currentSystemJobLog;
        }

        if (this.SystemJobError.errorDateTime) {
          this.SystemJobError.errorDateTime = new Date(this.SystemJobError.errorDateTime)
        }

        this.updateForm();
      }
      else {
        if (this.idOfSystemJobLog) {
          const defaultSystemJobLog: SystemJobLog = await Promise.resolve(
            await this.systemJobLogsService.getById(this.idOfSystemJobLog));
          this.systemJobLogs.push(defaultSystemJobLog);
          this.SystemJobError.systemJobLog = defaultSystemJobLog;
          this.SystemJobError.systemJobLogID = defaultSystemJobLog.id;
          this.SystemJobErrorForm.patchValue({ ['systemJobLogID']: defaultSystemJobLog.id });
        }
      }
      this.isFormChanged = false;

    } catch (e) {
      console.log(e);
    } finally {
      this.loading = false;
    }
  }

  onSelectBoxChanged(id: number, key: keyof SystemJobError, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    // @ts-ignore
    this.SystemJobError[key] = item;
    if (key === 'systemJobLogID') {  
      this.idOfSystemJobLog = id;
      this.SystemJobError.systemJobLogID = id;  
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.SytemJobLog:
        this.systemJobLogs = [];
        this.systemJobLogs = await (await this.systemJobLogsService.getList(params))
          .body;
        break;
      case Filters.PriceSchedule:
        this.priceSchedules = await (
          await this.priceSchedulesService.getList(params)
        ).body;
        break;
      case Filters.Customer:
        this.customers = await (
          await this.customersService.getList(params)
        ).body;
        break;
      case Filters.Item:
        this.items = await (
          await this.itemsService.getList(params)
        ).body;
        break;
      case Filters.Location:
        this.locations = await (
          await this.locationsService.getList(params)
        ).body;
        break;
      case Filters.Currency:
        this.currencies = await (await this.currenciesService.getList(params))
          .body;
        break;
      default:
        break;
    }
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {    
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

}
