import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttribute, emptyItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributeValuesService } from '../../../services/item-attribute-values/item-attribute-values.service';
import { ItemAttributeValue, emptyItemAttributeValue } from '../../../services/item-attribute-values/item-attribute-values.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ItemAttributeListComponent } from '../../item-attributes/item-attribute-list/item-attribute-list.component';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { StorageService } from 'src/app/utils/StorageHelper';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';

enum Filters {
  ItemAttribute = 1,
}
@Component({
  selector: 'app-item-attribute-value-single',
  templateUrl: './item-attribute-value-single.component.html',
  styleUrls: ['./item-attribute-value-single.component.scss'],
})
export class ItemAttributeValueSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  filters = Filters;
  @Input() idOfItemAttributeValue: number | null = null;
  @Input() idOfItem: number | null = null;
  @Output() onFormSaved = new EventEmitter<Boolean>();

  itemAttributeValue: ItemAttributeValue = emptyItemAttributeValue;
  value: Date = new Date();
  itemAttributeValueForm: FormGroup;
  itemAttributes: ItemAttribute[] = [];
  submitted = false;
  paginationObject: any = { itemAttributes: mockedData };
  queryObject: any = {
    priceSchedule: {
      PageSize: 25,
      SortTerm: 'name',
      PageNumber: 1,
      SearchTerm: '',
      Filter_Name: '',
    },
  };

  modalReference: any;
  isFormChanged = false;
  public isAdmin: boolean;
  attributeDataType: number;
  attributeIsString: boolean = true;
  attributeIsDate: boolean = false;
  attributeIsCheckBox: boolean = false;
  attributeIsNumber: boolean = false;

  constructor(
    private itemAttributeValuesService: ItemAttributeValuesService,
    private itemAttributesService: ItemAttributesService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private notificationHelper: NotificationHelper
  ) {
    this.itemAttributeSearch = debounce(this.itemAttributeSearch, 400, {},);
  }

  ngOnInit(): void {
    if (this.idOfItemAttributeValue) {
      this.loadData();
    }
    if (this.idOfItem) {
      this.itemAttributeValue.itemID = this.idOfItem;
    }
    this.buildForm();
    this.updateForm();

    this.isAdmin = StorageService.IsAdmin();
    if (!this.isAdmin) {
      this.itemAttributeValueForm.disable();
    }
  }
  buildForm() {
    this.itemAttributeValueForm = new FormGroup({
      itemID: new FormControl(''),
      itemAttributeID: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required),
    });
    this.itemAttributeValueForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }
  get f() {
    return this.itemAttributeValueForm.controls;
  }
  updateForm() {
    let value = null;
    if (this.itemAttributeValue?.value
      && this.itemAttributeValue?.itemAttribute?.attributeDataTypeID) {
      switch (this.itemAttributeValue.itemAttribute.attributeDataTypeID) {
        case 13: //Check Box
          value = this.itemAttributeValue.value
          break;
        case 14: // Number
          value = parseFloat(this.itemAttributeValue.value)
          break;
        case 16:
          value = new Date(this.itemAttributeValue.value)
          break;
        default:
          value = this.itemAttributeValue.value;
          break
      }
    }
    this.itemAttributeValueForm.patchValue({
      ...this.itemAttributeValue,
    });
    this.f.value.patchValue(value);
    this.isFormChanged = false;
  }
  clearPrefill() {
    setTimeout(() => {
      this.itemAttributes = [];
    });
  }

  async checkIfItemAttributeExists() {
    var nameExists: boolean = false;
    // TODO: we need to clean this up so we are always using the same value for this.
    const PageSize = StorageService.PageSize() ?? 50;

    const Filter_ItemID = this.idOfItem ?? this.itemAttributeValue?.itemID ?? this.itemAttributeValue?.item?.id;
    const Filter_ItemAttributeID = this.itemAttributeValue.itemAttribute.id;
    const params = { Filter_ItemID, Filter_ItemAttributeID, PageSize };
    const existingItemAttributeValues: ItemAttributeValue[] = await (
      await this.itemAttributeValuesService.getList(params)
    ).body;
    if (existingItemAttributeValues.length > 0) {
      if (this.itemAttributeValue.id) {
        for (const key in existingItemAttributeValues) {
          const cav: ItemAttributeValue =
            existingItemAttributeValues[key];
          if (cav.id != this.itemAttributeValue.id) {
            nameExists = true;
          }
        }
      } else {
        nameExists = true;
      }
    }
    return nameExists;
  }

  async validateDate() {
    // TODO: Validate Date data type
  }

  async validateNumber() {
    // TODO: Validate Number data type
  }

  onClick_Save() {
    this.saveRecord();
  }

  async saveRecord() {
    this.itemAttributeValueForm.markAllAsTouched();
    this.submitted = true;
    if (this.itemAttributeValueForm.invalid) {
      return window.scrollTo(0, 0);
    }
    const data = {
      ...this.itemAttributeValue,
      ...this.itemAttributeValueForm.value,
    };
    // TODO: Check if ItemAttribute datatype is date
    // TODO: Check if ItemAttribute datatype is number
    // Check to see if Document Attribute Name already exists.
    if ((await this.checkIfItemAttributeExists()) === true) {
      this.notificationHelper.showStatusOnDialog('This Item Attribute already exists for this Item!', 'error', this.container);
      return;
    }

    if (this.itemAttributeValue.id) {
      try {
        const response: any = await this.itemAttributeValuesService.update(
          this.itemAttributeValue.id,
          data,
        );

        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatus('Record updated successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    } else {
      try {
        const response: any = await this.itemAttributeValuesService.create(
          this.itemAttributeValueForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.notificationHelper.showStatus('Record saved successfully!', "success");
        }
      } catch (e) {
        this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
      }
    }
    this.onFormSaved.emit(false);
  }
  async getItemAttributeValueById() {
    if (this.idOfItemAttributeValue) {
      return await this.itemAttributeValuesService.getById(
        this.idOfItemAttributeValue,
      );
    } else {
      return emptyItemAttributeValue;
    }
  }
  onClick_Close(message) {
    if (this.isFormChanged) {
      this.modalReference = this.modalService.open(UnsavedChangesModalComponent);
      this.modalReference.componentInstance.goNextPage.subscribe(this.goNextPage);
      this.modalReference.componentInstance.closeModal.subscribe(this.closeModal);
    } else {
      this.activeModal.close(message);
    }
  }

  closeModal = () => {
    this.modalReference.close();
  }

  goNextPage = () => {
    this.modalService.dismissAll();
  }

  async loadData() {
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { PageSize };

    try {
      [
        this.itemAttributeValue,
        this.itemAttributes,
      ] = await Promise.all([
        this.getItemAttributeValueById(),
        (await this.itemAttributesService.getList(params)).body,
      ]);
      this.updateForm();
    } catch (e) {
    } finally {
    }
    if (this.itemAttributeValue?.itemAttribute?.attributeDataTypeID != null) {
      this.displayCorrectInput(this.itemAttributeValue.itemAttribute.attributeDataTypeID)
    }
  }

  async itemAttributeSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    const PageSize = StorageService.PageSize() ?? 50;
    return await this.getItemAttributes({ SearchTerm, PageSize });
  }

  async getItemAttributes(params) {
    try {
      const itemAttributes: ItemAttribute[] = await (await this.itemAttributesService.getList(params)).body;
      this.itemAttributes = itemAttributes;
    } catch (e) {
      this.itemAttributes = [];
    }
    return this.itemAttributes;
  }

  onSelectBoxChanged(id: number, key: keyof ItemAttributeValue, arr) {
    let item: ItemAttribute = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }
    this.f.value.patchValue('');
    //this.itemAttributeValue.value = '';
    // @ts-ignore
    this.itemAttributeValue[key] = item;

    if (item != null && item.attributeDataType != null) {
      this.displayCorrectInput(item.attributeDataType.id)
    }
  }

  displayCorrectInput(attributeDataTypeID: number) {
    this.attributeDataType = attributeDataTypeID;
    switch (attributeDataTypeID) {
      case 13: //Check Box
        this.attributeIsString = false;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = true;
        this.attributeIsNumber = false;
        if (isNullOrEmptyString(this.itemAttributeValue.value)) {
          this.f.value.patchValue(false);
        }
        break;
      case 14:
        this.attributeIsString = false;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = true;

        break;
      case 15:
        this.attributeIsString = true;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = false;

        break;
      case 16:
        this.attributeIsString = false;
        this.attributeIsDate = true;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = false;

        break;
      default:
        this.attributeIsString = true;
        this.attributeIsDate = false;
        this.attributeIsCheckBox = false;
        this.attributeIsNumber = false;
        break;
    }
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.ItemAttribute:
        this.itemAttributes = await (
          await this.itemAttributesService.getList(params)
        ).body;
        break;
      default:
        break;
    }
  }

  async onLookup_ItemAttribute() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, { });
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry: ItemAttribute) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes = [];
      this.itemAttributes.push(recordSelected);
      this.itemAttributeValueForm.patchValue({ ['itemAttributeID']: receivedEntry.id });
      this.itemAttributeValue.itemAttribute = receivedEntry;
      if (receivedEntry?.attributeDataTypeID != null) {
        this.displayCorrectInput(receivedEntry.attributeDataTypeID)
      }
      modalRef.close('test');
    });

  }
}
