<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Book.png" alt="Price Book" style="padding-right: 5px;">
  Create / Update Price Book
</div>
<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" [routerLink]="['/price/price-books']" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save.png" (click)="onClickSave()" title="Save">SAVE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_Close.png" (click)="onClickSaveClose()" title="Save & Close">SAVE & CLOSE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Save_New.png" (click)="onClickSaveNew()" title="Save & New">SAVE & NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Add.png" (click)="onClickAddNew()" title="Add New">ADD NEW</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Copy.png" (click)="onClickCopy()" title="Copy">COPY</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
</kendo-buttongroup>

<form [formGroup]="priceBookForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" class="font-weight-bold" text="* Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" placeholder="Enter Price Book Name">
        </kendo-textbox>
        <kendo-formerror>Name is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col-6 form-group">
      <kendo-formfield>
        <kendo-label for="entityTypeID" class="font-weight-bold" text="* Optional Filtering Attribute"></kendo-label>
        <ng-select id="entityTypeID" [multiple]="false" [selectOnTab]="true" formControlName="entityTypeID"
                   [notFoundText]="'Please start typing to search'" title="Optionally filter each Price Sheet on this Book to an Attribute Value"
                   [ngClass]="{'is-invalid': submitted && f.entityTypeID.errors,'w-100': true}" placeholder="Select an Entity"
                   [searchable]="true" (change)="onSelectBoxChanged($event, 'entityTypeID', entityTypes)">
          <ng-option *ngFor="let item of entityTypes" [value]="item.id"> {{ item.name }} </ng-option>
          <kendo-formerror>Filtering Attribute Option is required</kendo-formerror>
        </ng-select>
      </kendo-formfield>
    </div>

    <div class="col-6 form-group">
      <kendo-formfield *ngIf="priceBookForm.get('entityTypeID')?.value === 75">
        <kendo-label for="customerAttributeID" class="font-weight-bold" text="* Customer Attribute"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="customerAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="customerAttributeID"
                     [ngClass]="{'is-invalid': submitted && f.customerAttributeID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.CustomerAttribute)" placeholder="Select a Customer Attribute"
                     (change)="onSelectBoxChanged($event,'customerAttributeID',customerAttributes)">
            <ng-option *ngFor="let item of customerAttributes" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_customerAttribute()" icon="search"></button>
        </span>
        <kendo-formerror>Customer Attribute is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="priceBookForm.get('entityTypeID')?.value === 76">
        <kendo-label for="itemAttributeID" class="font-weight-bold" text="* Item Attribute"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="itemAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="itemAttributeID"
                     [ngClass]="{'is-invalid': submitted && f.itemAttributeID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.ItemAttribute)" placeholder="Select an Item Attribute"
                     (change)="onSelectBoxChanged($event,'itemAttributeID',itemAttributes)">
            <ng-option *ngFor="let item of itemAttributes" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_itemAttribute()" icon="search"></button>
        </span>
        <kendo-formerror>Item Attribute is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield *ngIf="priceBookForm.get('entityTypeID')?.value === 70">
        <kendo-label for="documentAttributeID" class="font-weight-bold" text="* Document Attribute"></kendo-label>
        <span class="k-textbox" style="width: 100%;">
          <ng-select id="documentAttributeID" class="noarrow" [multiple]="false" [selectOnTab]="true"
                     formControlName="documentAttributeID"
                     [ngClass]="{'is-invalid': submitted && f.documentAttributeID.errors,'w-100': true}"
                     [searchable]="true" [notFoundText]="'Please start typing to search'"
                     (search)="search($event, filters.DocumentAttribute)" placeholder="Select a Document Attribute"
                     (change)="onSelectBoxChanged($event,'documentAttributeID',documentAttributes)">
            <ng-option *ngFor="let item of documentAttributes" [value]="item.id"> {{ item.name }} </ng-option>
          </ng-select>
          <kendo-textbox-separator></kendo-textbox-separator>
          <button kendoButton type="button" look="clear" (click)="onLookup_documentAttribute()" icon="search"></button>
        </span>
        <kendo-formerror>Document Attribute is required</kendo-formerror>
      </kendo-formfield>
    </div>
  </div>

</form>
<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-price-sheet-list #subgrid [priceBookID]='idOfPriceBook'
                        [pagination]="paginationObject" [loading]="loading"
                        [mode]="subgridmode">
  </app-price-sheet-list>
</div>

