import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RelatedRecordsService } from '../../../services/related-records/related-records.service';
import { RelatedRecord } from '../../../services/related-records/related-records.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { SideNavService } from '../../../services/side-nav/sidenav.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-related-record-list',
  templateUrl: './related-record-list.component.html',
  styleUrls: ['./related-record-list.component.scss'],
})
export class RelatedRecordListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private relatedRecordsService: RelatedRecordsService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private utilitiesService: UtilitiesService,
    private sideNavService: SideNavService,
    private lookupService: LookupsService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() relatedRecords: RelatedRecord[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'referenceByEntityName',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Output() authenticationChange = new EventEmitter();

  @Input() mode: 'view' | 'lookup' = 'view';
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: RelatedRecord[];
  public roundingDirectionOptions: Lookup[];
  public roundingToOptions: Lookup[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public clickedRowItem;
  public gridClassName: string = "DefaultGrid";
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Reference By Entity Name',
      field: 'referenceByEntityName',
      width: 200,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Reference By Record Name',
      field: 'referenceByRecordName',
      width: 200,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Reference To Entity Name',
      field: 'referenceToEntityName',
      width: 200,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Reference To Record Name',
      field: 'referenceToRecordName',
      width: 200,
      orderIndex: 3,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    this.loading = true;
    this.exportAll = false;
    grid.saveAsExcel();
    this.query.PageSize = StorageService.PageSize();
    this.loading = false;
  }

  async onClick_Rebuild() {
    this.loading = true;
    this.createRelatedRecords();
    this.loadListData();  
    this.loading = false;
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('RelatedRecordList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('RelatedRecordList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('RelatedRecordList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('RelatedRecordList_Config', this.columnsConfig);
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;

    this.loadListData();

    this.sideNavService.sideNavChangedEvent.subscribe(
      (event) => {
        let SideNavExpanded_Config: boolean = StorageService.get('SideNavExpanded_Config');
        if (SideNavExpanded_Config == true) {
          this.gridClassName = "DefaultGrid"
        }
        else {
          this.gridClassName = "FullScreenGrid"
        }
      },
    );
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }
   

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'referenceByEntityName',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'referenceByEntityName') {
          const Filter_ReferenceByEntityName = myFilter.value;
          const Operand_ReferenceByEntityName = myFilter.operator;
          const params = { ...this.query, Filter_ReferenceByEntityName, Operand_ReferenceByEntityName };
          this.query = params;
        }
        if (myFilter.field == 'referenceByRecordName') {
          const Filter_ReferenceByRecordName = myFilter.value;
          const Operand_ReferenceByRecordName = myFilter.operator;
          const params = { ...this.query, Filter_ReferenceByRecordName, Operand_ReferenceByRecordName };
          this.query = params;
        }
        if (myFilter.field == 'referenceToEntityName') {
          const Filter_ReferenceToEntityName = myFilter.value;
          const Operand_ReferenceToEntityName = myFilter.operator;
          const params = { ...this.query, Filter_ReferenceToEntityName, Operand_ReferenceToEntityName }
          this.query = params;
        }
        if (myFilter.field == 'referenceToRecordName') {
          const Filter_ReferenceToRecordName = myFilter.value;
          const Operand_ReferenceToRecordName = myFilter.operator;
          const params = { ...this.query, Filter_ReferenceToRecordName, Operand_ReferenceToRecordName };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.relatedRecordsService.getExportList(this.query);
  };

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      const response: any = await this.relatedRecordsService.getList(this.query);
      this.relatedRecords = response.body;
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.relatedRecords,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  async createRelatedRecords() {
    try {
      const response: any = await this.utilitiesService.callStoredProc('usp_RPMUtilityCreateRelatedRecords'); 
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Records created successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

}
