<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Sheet.png" alt="System Job Logs" style="padding-right: 5px;">
  System Job Logs
</div>
<kendo-grid #grid="kendoGrid" [data]="gridView" [pageSize]="query.PageSize" [skip]="skip"
            [reorderable]="true" [loading]="loading" [pageable]="true" (pageChange)="pageChange($event)" [filter]="filter"
            [filterable]="filterable" (filterChange)="gridFilterChange($event)" [sortable]="true" [sort]="sort" [resizable]="true"
            (sortChange)="sortChange($event)" [selectable]="{ mode: 'multiple' }" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" (cellClick)="onCellClick($event)" (dblclick)="onDblClick()"
            [class]="gridClassName">
  <ng-template kendoGridToolbarTemplate>
    <kendo-buttongroup>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Refresh.png" (click)="onRefresh()" title="Refresh">Refresh</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableDeleteBtn" imageUrl="../../../../assets/images/Delete.png" (click)="onClick_Delete()" title="Delete Selected">Delete</button>
      <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Excel_Export.png" (click)="onClick_Export(grid, exportToExcel)" title="Export to Excel">Export</button>
    </kendo-buttongroup>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/ResetGrid.png" (click)="onResetGridClick()" title="Reset Grid"></button>
    <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/FilterGrid.png" (click)="onFilterClick()" title="Filter"></button>
  </ng-template>

  <kendo-grid-column field="name" title="Name" [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter" operator="contains">
      </kendo-grid-string-filter-cell>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <a *ngIf="mode == 'view'" [routerLink]="['/price/system-job-log/', dataItem.id]">{{ dataItem.name }}</a>
      <span role="button" *ngIf="mode == 'lookup'">{{ dataItem.name }}</span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="beginDateTime" title="Begin Date Time"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="endDateTime" title="End Date Time"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-date-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="minutesToComplete" title="Minutes To Complete"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="eq">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="status" title="Status"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-column *ngIf="showQueueColumn" field="syncQueueID" title="Queue"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div *ngIf="dataItem.syncQueueID">
        <a [routerLink]="['/price/sync-queues/', dataItem.syncQueueID]"> (link) </a>
        <span role="button"
              *ngIf="mode == 'lookup'"
              (dblclick)="onSelectSingleItem(dataItem)">
          {{ dataItem.syncQueueID }}
        </span>
      </div>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column field="comment" title="Comment"
                     [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
  </kendo-grid-column>

  <kendo-grid-excel #exportToExcel fileName="SystemJobLogs.xlsx">
    <kendo-excelexport-column field="id" title="ID" *ngIf="exportAll && optRowID"></kendo-excelexport-column>
    <kendo-excelexport-column field="name" title="Name" [headerCellOptions]="{bold:'true'}"></kendo-excelexport-column>
    <kendo-excelexport-column field="beginDateTime" title="BeginDateTime"></kendo-excelexport-column>
    <kendo-excelexport-column field="endDateTime" title="EndDateTime"></kendo-excelexport-column>
    <kendo-excelexport-column field="minutesToComplete" title="MinutesToComplete"></kendo-excelexport-column>
    <kendo-excelexport-column field="status" title="Status"></kendo-excelexport-column>
    <kendo-excelexport-column field="comment" title="Comment"></kendo-excelexport-column>
  </kendo-grid-excel>
</kendo-grid>
