import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilterValue, emptyPricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';
import { PricingFilterValueCollectionsService } from 'src/app/services/pricing-filter-value-collections/pricing-filter-value-collections.service';
import { PricingFilterValueCollection, emptyPricingFilterValueCollection } from 'src/app/services/pricing-filter-value-collections/pricing-filter-value-collections.model';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import { getPaginationHeader, mockedData, Pagination } from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { Lookup, emptyLookup } from 'src/app/services/lookups/lookups.model';
import { Customer, emptyCustomer } from 'src/app/services/customers/customers.model';
import { CustomerListComponent } from '../../customers/customer-list/customer-list.component';
import { CustomerLookupComponent } from '../../customers/customer-lookup/customer-lookup.component';
import { CustomersService } from '../../../services/customers/customers.service';
import { CustomerAttributesService } from '../../../services/customer-attributes/customer-attributes.service';
import { CustomerAttribute, emptyCustomerAttribute } from '../../../services/customer-attributes/customer-attributes.model';
import { CustomerAttributeListComponent } from '../../customer-attributes/customer-attribute-list/customer-attribute-list.component';
import { CustomerAttributeLookupComponent } from '../../customer-attributes/customer-attribute-lookup/customer-attribute-lookup.component';
import { DocumentAttributesService } from '../../../services/document-attributes/document-attributes.service';
import { DocumentAttribute, emptyDocumentAttribute } from '../../../services/document-attributes/document-attributes.model';
import { DocumentAttributeListComponent } from '../../document-attributes/document-attribute-list/document-attribute-list.component';
import { DocumentAttributeLookupComponent } from '../../document-attributes/document-attribute-lookup/document-attribute-lookup.component';
import { emptyItem, Item } from 'src/app/services/items/items.model';
import { ItemListComponent } from '../../items/item-list/item-list.component';
import { ItemLookupComponent } from '../../items/item-lookup/item-lookup.component';
import { ItemsService } from '../../../services/items/items.service';
import { ItemAttributesService } from '../../../services/item-attributes/item-attributes.service';
import { ItemAttribute, emptyItemAttribute } from '../../../services/item-attributes/item-attributes.model';
import { ItemAttributeListComponent } from '../../item-attributes/item-attribute-list/item-attribute-list.component';
import { ItemAttributeLookupComponent } from '../../item-attributes/item-attribute-lookup/item-attribute-lookup.component';
import { emptyLocation, Location } from 'src/app/services/locations/locations.model';
import { LocationListComponent } from '../../locations/location-list/location-list.component';
import { LocationLookupComponent } from '../../locations/location-lookup/location-lookup.component';
import { LocationsService } from '../../../services/locations/locations.service';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UnsavedChangesModalComponent } from 'src/app/shared/unsaved-changes-modal/unsaved-changes-modal.component';
import { GridDataResult, GridComponent, ExcelComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { HttpErrorResponse } from '@angular/common/http';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service'
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { StorageService } from '../../../../app/utils/StorageHelper';
import { lookup } from 'dns';
import { promise } from 'selenium-webdriver';
import { stringify } from 'querystring';
import { NotificationHelper } from '../../../utils/NotificationHelper';

enum Filters {
  Customer = 1,
  CustomerAttribute = 2,
  DocumentAttribute = 3,
  Item = 4,
  ItemAttribute = 5,
  Location = 6,
  Operand = 8,
  CollectionCustomer = 9,
  CollectionItem = 10,
  CollectionLocation = 11,
  CollectionUofM = 12
}
@Component({
  selector: 'app-pricing-filter-value-single',
  templateUrl: './pricing-filter-value-single.component.html',
  styleUrls: ['./pricing-filter-value-single.component.scss'],
})
export class PricingFilterValueSingleComponent implements OnInit {
  @ViewChild("container", { read: ViewContainerRef })
  public container: ViewContainerRef;

  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  filters = Filters;
  idOfPricingFilterValue: number | null = null;
  @Output() onFormChanged = new EventEmitter<Boolean>();
  @Output() onFormSaved = new EventEmitter<Boolean>();
  @Output() onFormCancelled = new EventEmitter<Boolean>();
  @Output() filterChange = new EventEmitter();
  @Input() idOfPricingFilter: number | null = null;
  @Input() pricingFilterValueCollections: PricingFilterValueCollection[] = [];
  @Input() pagination: Pagination = mockedData;
  @Input() query = {
    PageSize: 25,
    SortTerm: 'value',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';

  pricingFilterValue: PricingFilterValue = emptyPricingFilterValue;
  pricingFilterValueCollection: PricingFilterValueCollection = emptyPricingFilterValueCollection;
  loading: boolean = true;
  limits = [25, 50, 75, 100, 250];
  idForRemove: number | null = null;
  selectedIds: { [key: number]: boolean } = {};
  pricingFilterValueForm: FormGroup;
  pricingFilterValueCollectionForm: FormGroup;
  entityTypes: Lookup[] = [];
  operands: Lookup[] = [];
  value: any = null;
  dateValue: Date = new Date();
  pricingFilterValueCollectionValue: any = null;
  pricingFilterValueCollectionID: number | null = null;
  collectionCustomers: Customer[] = [];
  customers: Customer[] = [];
  customerAttributes: CustomerAttribute[] = [];
  selectedCustomerAttribute: CustomerAttribute = emptyCustomerAttribute;
  documentAttributes: DocumentAttribute[] = [];
  selectedDocumentAttribute: DocumentAttribute = emptyDocumentAttribute;
  items: Item[] = [];
  collectionItems: Item[] = [];
  itemAttributes: ItemAttribute[] = [];
  locations: Location[] = [];
  collectionLocations: Location[] = [];
  selectedItemAttribute: ItemAttribute = emptyItemAttribute;
  submitted = false;
  showCollectionGrid: boolean = false;
  showCustomerLookup: boolean = false;
  showCustomerAttributeLookup: boolean = false;
  showItemLookup: boolean = false;
  showItemAttributeLookup: boolean = false;
  showLocationLookup: boolean = false;
  showCollectionCustomerLookup: boolean = false;
  showCollectionDatePicker: boolean = false;
  showCollectionItemLookup: boolean = false;
  showCollectionLocationLookup: boolean = false;
  showCollectionUofMLookup: boolean = false;
  isFormChanged = false;
  goBackForce = false;
  modalReference;
  attributeIsString: boolean = true;
  attributeIsDate: boolean = false;
  attributeIsCheckBox: boolean = false;
  attributeIsNumber: boolean = false;
  operandHasValue: boolean = false;
  nextURL = '';
  paginationObject: any = {
    customerAttributes: mockedData,
    documentAttributes: mockedData,
    itemAttributes: mockedData
  };
  public gridView: GridDataResult;
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Value',
      dir: 'asc',
    },
  ];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public filter: CompositeFilterDescriptor;
  public skip = 0;
  public mySelection: string[] = [];
  public clickedRowItem;


  constructor(
    private route: ActivatedRoute,
    private pricingFilterValuesService: PricingFilterValuesService,
    private pricingFilterValueCollectionsService: PricingFilterValueCollectionsService,
    private customerAttributesService: CustomerAttributesService,
    private documentAttributesService: DocumentAttributesService,
    private itemAttributesService: ItemAttributesService,
    private lookupService: LookupsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public fb: FormBuilder,
    private snack: MySnackBarService,
    private router: Router,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private locationsService: LocationsService,
    private importsService: ImportsService,
    private notificationHelper: NotificationHelper,
    private renderer: Renderer2,
    private elementRef: ElementRef

  ) {
    this.route.params.subscribe(params => {
      if (params.id && params.id === 'new') { return; }
      this.idOfPricingFilterValue = params.id;
      this.loadData();
    });
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.customerAttributeSearch = debounce(this.customerAttributeSearch, 400, {});
    this.documentAttributeSearch = debounce(this.documentAttributeSearch, 400, {});
    this.itemAttributeSearch = debounce(this.itemAttributeSearch, 400, {});
  }

  ngOnInit(): void {
    if (this.idOfPricingFilterValue) {
      this.loadData();
      this.loadListData();
    }
    if (this.idOfPricingFilter) {
      // TODO: Figure out why this value is persisting between forms.
      this.pricingFilterValue.value = '';
      this.pricingFilterValue.pricingFilterID = this.idOfPricingFilter;
    }
    this.buildForm();
    this.updateForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pricingFilterID && !changes.pricingFilterID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAddPricingFilterValue').setAttribute('title', 'Add New');
    }
  }

  buildForm() {
    this.pricingFilterValueForm = this.fb.group({
      pricingFilterID: new FormControl('', Validators.required),
      entityID: new FormControl('', Validators.required),
      customerAttributeID: new FormControl(''),
      documentAttributeID: new FormControl(''),
      itemAttributeID: new FormControl(''),
      operandID: new FormControl('', Validators.required),
      customerID: new FormControl(''),
      itemID: new FormControl(''),
      locationID: new FormControl(''),
      value: new FormControl(''),
      dateValue: new FormControl('')
    });
    //this.getOperandOptions();
    this.getEntityOptions();
    this.pricingFilterValueForm.valueChanges.subscribe((status) => {
      this.isFormChanged = true;
    });
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMPricingFilterValueCollection');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }

  onFileChangeOLD(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(inputXML, 'RPMPricingFilterValueCollection');
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  get f() {
    return this.pricingFilterValueForm.controls;
  }

  updateForm() {
    this.pricingFilterValueForm.patchValue({ ...this.pricingFilterValue });
    this.isFormChanged = false;
  }

  clearPrefill() {
    setTimeout(() => {
      this.itemAttributes = [];
    });
  }

  onSave(action) {
    this.submit(action);
  }

  async submit(action) {
    this.submitted = true;

    if (this.pricingFilterValueForm.invalid) {
      return window.scrollTo(0, 0);
    }

    if (this.pricingFilterValue.id) {
      try {
        const data = {
          ...this.pricingFilterValue,
          ...this.pricingFilterValueForm.value
        };

        // If they are updating a record and the operand isn't One of or NOT One of we need to delete the old collection values.
        if (this.f.operandID.value != 22 && this.pricingFilterValue.operandID != 23) {
          this.DeleteCollectionRecords();
        }

        const response: any = await this.pricingFilterValuesService.update(
          this.pricingFilterValue.id,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.notificationHelper.showStatusOnDialog('Record updated successfully!', "success", this.container);
        }
        //this.onFormSaved.emit(false);
      } catch (e) {
        if (e && e.error) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
        else if (e && e.message) {
          this.notificationHelper.showStatusOnDialog(e.message, "error", this.container);
        }
        else {
          this.notificationHelper.showStatusOnDialog("An error has occured.", "error", this.container);
        }
      }
    } else {
      try {
        console.log('Pricing Filter Value Create')
        const response: any = await this.pricingFilterValuesService.create(
          this.pricingFilterValueForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.pricingFilterValue = response.body;
          this.idOfPricingFilterValue = this.pricingFilterValue.id;
          //document.getElementById('btnAddPricingFilterValue').setAttribute('title', 'Add New');
          this.notificationHelper.showStatusOnDialog('Record updated successfully!', "success", this.container);
        }
        // If they are saving a new record and the operand is One of or NOT One of we will leave the form open.
        //if (this.pricingFilterValue.operandID != 22 && this.pricingFilterValue.operandID != 23) {
        //  this.onFormSaved.emit(false);
        //}
      } catch (e) {
        if (e && e.error) {
          this.notificationHelper.showStatusOnDialog(e.error, "error", this.container);
        }
        else if (e && e.message) {
          this.notificationHelper.showStatusOnDialog(e.message, "error", this.container);
        }
        else {
          this.notificationHelper.showStatusOnDialog("An error has occured.", "error", this.container);
        }
      }
    }
    if (action == 'Close') {
      this.onFormSaved.emit(false);
    }
  }

  async DeleteCollectionRecords() {
    const ids = [];

    const response: any = await this.pricingFilterValueCollectionsService.getList({
      Filter_PricingFilterValueID: this.idOfPricingFilterValue, ...this.query
    });
    this.pricingFilterValueCollections = response.body;

    for (var i = 0; i < this.pricingFilterValueCollections.length; i++) {
      ids.push(this.pricingFilterValueCollections[i].id);
    }

    await this.pricingFilterValueCollectionsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        // Since these are child records we are not showing any message to the user for them.
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onFilterClick() {
    this.filterable = !this.filterable;
  };

  async onLookup_Customer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.customers = [];
      this.customers.push(recordSelected);
      this.pricingFilterValueForm.patchValue({ ['customerID']: receivedEntry.name });
      this.pricingFilterValueForm.patchValue({ ['value']: receivedEntry.id });
      this.pricingFilterValue.value = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_CollectionCustomer() {
    const modalRef = this.modalService.open(CustomerLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Customer = receivedEntry;
      this.collectionCustomers = [];
      this.collectionCustomers.push(recordSelected);
      this.pricingFilterValueCollectionForm.patchValue({ ['collectionCustomerID']: receivedEntry.name });
      this.pricingFilterValueCollectionForm.patchValue({ ['value']: receivedEntry.id });
      this.pricingFilterValueCollection.value = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_Item() {
    const modalRef = this.modalService.open(ItemLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.items = [];
      this.items.push(recordSelected);
      this.pricingFilterValueForm.patchValue({ ['itemID']: receivedEntry.name });
      this.pricingFilterValueForm.patchValue({ ['value']: receivedEntry.id });
      this.pricingFilterValue.value = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_CollectionItem() {
    const modalRef = this.modalService.open(ItemLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Item = receivedEntry;
      this.collectionItems = [];
      this.collectionItems.push(recordSelected);
      this.pricingFilterValueCollectionForm.patchValue({ ['collectionItemID']: receivedEntry.name });
      this.pricingFilterValueCollectionForm.patchValue({ ['value']: receivedEntry.id });
      this.pricingFilterValueCollection.value = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_Location() {
    const modalRef = this.modalService.open(LocationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.locations = [];
      this.locations.push(recordSelected);
      this.pricingFilterValueForm.patchValue({ ['locationID']: receivedEntry.name });
      this.pricingFilterValueForm.patchValue({ ['value']: receivedEntry.id });
      this.pricingFilterValue.value = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async onLookup_CollectionLocation() {
    const modalRef = this.modalService.open(LocationLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: Location = receivedEntry;
      this.collectionLocations = [];
      this.collectionLocations.push(recordSelected);
      this.pricingFilterValueCollectionForm.patchValue({ ['collectionLocationID']: receivedEntry.name });
      this.pricingFilterValueCollectionForm.patchValue({ ['value']: receivedEntry.id });
      this.pricingFilterValueCollection.value = receivedEntry.id;
      modalRef.close('test');
    });
  }

  async getEntityOptions() {
    const Filter_OptionSet = 'EntityType';
    const params = { Filter_OptionSet };
    const allEntityTypes: Lookup[] = await this.lookupService.getList(params);

    if (allEntityTypes.length > 0) {
      for (const key in allEntityTypes) {
        const currentLookup: Lookup = allEntityTypes[key];
        if (currentLookup.name != "Price Book" && currentLookup.name != "Attribute Map" && currentLookup.name != "None"
          && currentLookup.name !== "Catalog" && currentLookup.name !== "Catalog Sheet" && currentLookup.name !== "Data Sync Map")
        {
          this.entityTypes.push(currentLookup);
        }
      }
    }
    return this.entityTypes;
  }

  async getOperandOptions() {
    const Filter_OptionSet = 'Operand';
    const params = { Filter_OptionSet };
    this.operands = await this.lookupService.getList(params);
    return this.operands;
  }

  async getPricingFilterValueById() {
    if (this.idOfPricingFilterValue) {
      return await this.pricingFilterValuesService.getById(this.idOfPricingFilterValue);
    } else {
      return emptyPricingFilterValue;
    }
  }

  async getCustomerById(id) {
    let customer: Customer;
    try {
      customer = await this.customersService.getById(id);
    }
    catch {
      customer = emptyCustomer;
    }
    return customer;
  }

  async getItemById(id) {
    let item: Item;
    try {
      item = await this.itemsService.getById(id);
    }
    catch {
      item = emptyItem;
    }
    return item;
  }

  async getLocationById(id) {
    let location: Location;
    try {
      location = await this.locationsService.getById(id);
    }
    catch {
      location = emptyLocation;
    }
    return location;
  }

  async getCustomerAttributeById(id) {
    let customerAttribute: CustomerAttribute;
    try {
      customerAttribute = await this.customerAttributesService.getById(id);
    }
    catch {
      customerAttribute = emptyCustomerAttribute;
    }
    return customerAttribute;
  }

  async getDocumentAttributeById(id) {
    let documentAttribute: DocumentAttribute;
    try {
      documentAttribute = await this.documentAttributesService.getById(id);
    }
    catch {
      documentAttribute = emptyDocumentAttribute;
    }
    return documentAttribute;
  }

  async getItemAttributeById(id) {
    let itemAttribute: ItemAttribute;
    try {
      itemAttribute = await this.itemAttributesService.getById(id);
    }
    catch {
      itemAttribute = emptyItemAttribute;
    }
    return itemAttribute;
  }

  async getOperandById(id) {
    return await this.lookupService.getById(id);
  }

  async loadData() {
    this.loading = true;
    try {
      if (this.idOfPricingFilterValue) {
        this.pricingFilterValue = await Promise.resolve(this.getPricingFilterValueById());

        if (this.pricingFilterValue.entityID == 68) {
          const currentCustomer: Customer = await Promise.resolve(this.getCustomerById(this.pricingFilterValue.value));
          this.pricingFilterValueForm.patchValue({ ['value']: currentCustomer.id });
          this.pricingFilterValueForm.patchValue({ ['customerID']: currentCustomer.name });
          this.displayCorrectOperands(15);
        }
        if (this.pricingFilterValue.entityID == 69) {
          this.displayCorrectInput(16);
          this.displayCorrectOperands(16);
        }
        if (this.pricingFilterValue.entityID == 70) {
          if (this.pricingFilterValue.documentAttributeID) {
            const currentDocumentAttribute: DocumentAttribute = await Promise.resolve(this.getDocumentAttributeById(this.pricingFilterValue.documentAttributeID));
            this.documentAttributes.push(currentDocumentAttribute);
            this.displayCorrectOperands(currentDocumentAttribute.attributeDataTypeID);
          }
        }
        if (this.pricingFilterValue.entityID == 71) {
          const currentItem: Item = await Promise.resolve(this.getItemById(this.pricingFilterValue.value));
          this.pricingFilterValueForm.patchValue({ ['value']: currentItem.id });
          this.pricingFilterValueForm.patchValue({ ['itemID']: currentItem.name });
          this.displayCorrectOperands(15);
        }
        if (this.pricingFilterValue.entityID == 72) {
          const currentLocation: Location = await Promise.resolve(this.getLocationById(this.pricingFilterValue.value));
          this.pricingFilterValueForm.patchValue({ ['value']: currentLocation.id });
          this.pricingFilterValueForm.patchValue({ ['locationID']: currentLocation.name });
          this.displayCorrectOperands(15);
        }
        if (this.pricingFilterValue.entityID == 73) {
          this.displayCorrectOperands(15);
        }
        if (this.pricingFilterValue.entityID == 75) {
          const currentCustomerAttribute: CustomerAttribute = await Promise.resolve(this.getCustomerAttributeById(this.pricingFilterValue.customerAttributeID));
          this.customerAttributes.push(currentCustomerAttribute);
          this.displayCorrectOperands(currentCustomerAttribute.attributeDataTypeID);
        }
        if (this.pricingFilterValue.entityID == 76) {
          const currentItemAttribute: ItemAttribute = await Promise.resolve(this.getItemAttributeById(this.pricingFilterValue.itemAttributeID));
          this.itemAttributes.push(currentItemAttribute);
          this.displayCorrectOperands(currentItemAttribute.attributeDataTypeID);
        }

        this.updateForm();
      }
    } catch (e) {
    } finally {
      this.loading = false;
    }

    if (this?.pricingFilterValue?.operandID != null) {
      this.operandHasValue = true;
      this.operandChange(this.pricingFilterValue.operandID);
    }
    if (this?.pricingFilterValue?.customerAttribute?.attributeDataTypeID != null) {
      this.displayCorrectInput(this.pricingFilterValue.customerAttribute.attributeDataTypeID)
    }
    if (this?.pricingFilterValue?.documentAttribute?.attributeDataTypeID != null) {
      this.displayCorrectInput(this.pricingFilterValue.documentAttribute.attributeDataTypeID)
    }
    if (this?.pricingFilterValue?.itemAttribute?.attributeDataTypeID != null) {
      this.displayCorrectInput(this.pricingFilterValue.itemAttribute.attributeDataTypeID)
    }
  }
  prepareData() {
    [
      //@ts-ignore
      this.customerAttributes,
      //@ts-ignore
      this.documentAttributes,
      //@ts-ignore
      this.itemAttributes,
      //@ts-ignore
      this.operandOptions,
    ] = prepareData(
      this.pricingFilterValue,
      this.customerAttributes,
      this.documentAttributes,
      this.itemAttributes,
      this.operands
    );
    this.updateForm();
  }

  async customerAttributeSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getCustomerAttributes({ SearchTerm });
  }
  async documentAttributeSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getDocumentAttributes({ SearchTerm });
  }
  async itemAttributeSearch(event) {
    const SearchTerm = event.term; // or Filter_Name
    return await this.getItemAttributes({ SearchTerm });
  }

  async search($event, type) {
    const SearchTerm = $event.term;
    const PageSize = StorageService.PageSize() ?? 50;
    const params = { SearchTerm, PageSize };
    switch (type) {
      case Filters.Customer:
        this.customers = await (
          await this.customersService.getList(params)).body;
        break;
      case Filters.CollectionCustomer:
        this.collectionCustomers = await (
          await this.customersService.getList(params)).body;
        break;
      case Filters.Item:
        this.items = await (
          await this.itemsService.getList(params)).body;
        break;
      case Filters.CollectionItem:
        this.collectionItems = await (
          await this.itemsService.getList(params)).body;
        break;
      case Filters.Location:
        this.locations = await (
          await this.locationsService.getList(params)).body;
        break;
      case Filters.CollectionLocation:
        this.collectionLocations = await (
          await this.locationsService.getList(params)).body;
        break;
      case Filters.CustomerAttribute:
        this.customerAttributes = await (
          await this.customerAttributesService.getList(params)).body;
        break;
      case Filters.DocumentAttribute:
        this.documentAttributes = await (
          await this.documentAttributesService.getList(params)).body;
        break;
      case Filters.ItemAttribute:
        this.itemAttributes = await (
          await this.itemAttributesService.getList(params)).body;
        break;
      case Filters.Operand:
        {
          const Filter_OptionSet = 'Operand';
          const params = { SearchTerm, PageSize, Filter_OptionSet };
          this.operands = await this.lookupService.getList(params);
        }
        break;
      default:
        break;
    }
  }

  async getCustomerAttributes(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.customerAttributesService.getList({
        ...params, PageSize
      });
      this.customerAttributes = resp.body;
      this.paginationObject.customerAttributes = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.customerAttributes = [];
    }
    return this.customerAttributes;
  }

  async getDocumentAttributes(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.documentAttributesService.getList({
        ...params, PageSize
      });
      this.documentAttributes = resp.body;
      this.paginationObject.documentAttributes = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.documentAttributes = [];
    }
    return this.documentAttributes;
  }

  async getItemAttributes(params) {
    try {
      const PageSize = StorageService.PageSize() ?? 50;
      const resp = await this.itemAttributesService.getList({
        ...params, PageSize
      });
      this.itemAttributes = resp.body;
      this.paginationObject.itemAttributes = getPaginationHeader(
        resp.headers,
      );
    } catch (e) {
      this.itemAttributes = [];
    }
    return this.itemAttributes;
  }

  onCustomerChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      this.pricingFilterValueForm.patchValue({ ['customerID']: item.name });
      this.pricingFilterValueForm.patchValue({ ['value']: item.id });
      this.pricingFilterValue.value = item.id;
    }
  }

  onCollectionCustomerChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      this.pricingFilterValueCollectionForm.patchValue({ ['collectionCustomerID']: item.name });
      this.pricingFilterValueCollectionForm.patchValue({ ['value']: item.id });
      this.pricingFilterValueCollection.value = item.id;
    }
  }

  onItemChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      this.pricingFilterValueForm.patchValue({ ['itemID']: item.name });
      this.pricingFilterValueForm.patchValue({ ['value']: item.id });
      this.pricingFilterValue.value = item.id;
    }
  }

  onCollectionItemChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      this.pricingFilterValueCollectionForm.patchValue({ ['collectionItemID']: item.name });
      this.pricingFilterValueCollectionForm.patchValue({ ['value']: item.id });
      this.pricingFilterValueCollection.value = item.id;
    }
  }

  onLocationChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      this.pricingFilterValueForm.patchValue({ ['locationID']: item.name });
      this.pricingFilterValueForm.patchValue({ ['value']: item.id });
      this.pricingFilterValue.value = item.id;
    }
  }

  onCollectionLocationChanged(id: number, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
      this.pricingFilterValueCollectionForm.patchValue({ ['collectionLocationID']: item.name });
      this.pricingFilterValueCollectionForm.patchValue({ ['value']: item.id });
      this.pricingFilterValueCollection.value = item.id;
    }
  }

  onSelectBoxChanged(id: number, key: keyof PricingFilterValue, arr) {
    let item = null;
    if (id != null) {
      item = arr.find((e) => e.id === id);
    }

    this.showCustomerLookup = false;
    this.showItemLookup = false;
    this.showLocationLookup = false;

    // @ts-ignore
    if (key == "entityID") {
      this.operands.length = 0;
      this.pricingFilterValueForm.patchValue({ ['operandID']: null });
      this.pricingFilterValue.operandID = null;
      this.pricingFilterValueForm.patchValue({ ['customerAttributeID']: null });
      this.pricingFilterValue.customerAttributeID = null;
      this.showCustomerAttributeLookup = false;
      this.pricingFilterValueForm.patchValue({ ['documentAttributeID']: null });
      this.pricingFilterValue.documentAttributeID = null;
      this.pricingFilterValueForm.patchValue({ ['itemAttributeID']: null });
      this.pricingFilterValue.itemAttributeID = null;
      this.showItemAttributeLookup = false;

      this.operandHasValue = false;
      this.f.documentAttributeID.clearValidators();
      this.f.documentAttributeID.updateValueAndValidity();

      switch (item.id) {
        case 68: { // Customer      
          this.displayCorrectOperands(15);
          this.showCustomerLookup = true;
          break;
        }
        case 69: { // Date          
          this.displayCorrectOperands(16);
          break;
        }
        case 70: { // Document Attribute
          this.f.documentAttributeID.setValidators(Validators.required);
          this.f.documentAttributeID.updateValueAndValidity();
          break;
        }
        case 71: { // Item
          this.displayCorrectOperands(15);
          this.showItemLookup = true;
          break;
        }
        case 72: { // Location         
          this.displayCorrectOperands(15);
          this.showLocationLookup = true;
          break;
        }
        case 73: { // UofM
          this.displayCorrectOperands(15);
          break;
        }
        case 75: { // Customer Attribute
          this.f.customerAttributeID.setValidators(Validators.required);
          this.f.customerAttributeID.updateValueAndValidity();
          break;
        }
        case 76: { // Item Attribute
          this.f.itemAttributeID.setValidators(Validators.required);
          this.f.itemAttributeID.updateValueAndValidity();
          break;
        }
      }
    }
    else if (key == "operand") {
      if (id != undefined) {
        this.operandHasValue = true;
      }
      else {
        this.operandHasValue = false;
      }
      this.operandChange(id);
    }
    else if (key == "customerAttributeID" || key == "documentAttributeID" || key == "itemAttributeID") {
      this.pricingFilterValueForm.patchValue({ ['value']: null });
      this.displayCorrectOperands(item.attributeDataTypeID);
    }
  }

  operandChange(operandID) {

    this.showCustomerLookup = false;
    this.showItemLookup = false;
    this.showLocationLookup = false;
    this.showCollectionCustomerLookup = false;
    this.showCollectionDatePicker = false;
    this.showCollectionItemLookup = false;
    this.showCollectionLocationLookup = false;
    this.showCollectionUofMLookup = false;

    this.f.customerID.clearValidators();
    this.f.customerID.updateValueAndValidity();
    this.f.itemID.clearValidators();
    this.f.itemID.updateValueAndValidity();
    this.f.locationID.clearValidators();
    this.f.locationID.updateValueAndValidity();
    this.f.value.clearValidators();
    this.f.value.updateValueAndValidity();

    switch (operandID) {
      case 18:
      case 19: { // Contains, Does NOT Contain
        this.attributeIsCheckBox = false;
        this.attributeIsString = true;
        this.attributeIsDate = false;
        this.f.value.setValidators(Validators.required);
        this.f.value.updateValueAndValidity();
      }
      case 20:
      case 21:
      case 24:
      case 25:
      case 26:
      case 27: { // ==, !=, >, >=, <, <=
        if (this.f.entityID.value == 68 && !this.f.customerAttributeID.value) {
          this.showCustomerLookup = true;
          this.f.customerID.setValidators(Validators.required);
          this.f.customerID.updateValueAndValidity();
        }
        if (this.f.entityID.value == 69) {
          this.attributeIsCheckBox = false;
          this.attributeIsString = false;
          this.attributeIsDate = true;
          this.f.value.setValidators(Validators.required);
          this.f.value.updateValueAndValidity();
        }
        if (this.f.entityID.value == 71 && !this.f.itemAttributeID.value) {
          this.showItemLookup = true;
          this.f.itemID.setValidators(Validators.required);
          this.f.itemID.updateValueAndValidity();
        }
        if (this.f.entityID.value == 72) {
          this.showLocationLookup = true;
          this.f.locationID.setValidators(Validators.required);
          this.f.locationID.updateValueAndValidity();
        }
        if (this.f.entityID.value == 73) { //UofM
          this.attributeIsCheckBox = false;
          this.attributeIsString = true;
          this.attributeIsDate = false;
        }
        this.showCollectionGrid = false;
        break;
      }
      case 22:
      case 23: { // One of, NOT One of
        // Clear out individual values.       
        this.f.customerID.patchValue(null);
        this.f.customerID.updateValueAndValidity();
        this.f.itemID.patchValue(null);
        this.f.itemID.updateValueAndValidity();
        this.f.locationID.patchValue(null);
        this.f.locationID.updateValueAndValidity();
        this.f.value.patchValue(null);
        this.f.value.updateValueAndValidity();

        if (this.f.entityID.value == 68 && !this.f.customerAttributeID.value) {
          this.showCollectionCustomerLookup = true;
        }
        if (this.f.entityID.value == 69) {
          this.attributeIsCheckBox = false;
          this.attributeIsString = false;
          this.attributeIsDate = true;
        }
        if (this.f.entityID.value == 71 && !this.f.itemAttributeID.value) {
          this.showCollectionItemLookup = true;
        }
        if (this.f.entityID.value == 72) {
          this.showCollectionLocationLookup = true;
        }
        if (this.f.entityID.value == 73) {
          this.showCollectionUofMLookup = true;
        }
        this.showCollectionGrid = true;
        break;
      }
      case 28:
      case 29:
      case 30: {  // On or Before, On, On or After
        this.attributeIsCheckBox = false;
        this.attributeIsString = false;
        this.attributeIsDate = true;
        this.f.value.setValidators(Validators.required);
        this.f.value.updateValueAndValidity();
      }
    }
  }

  async onLookup_CustomerAttribute() {
    const modalRef = this.modalService.open(CustomerAttributeLookupComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'lookup';
    modalRef.componentInstance.gridClassName = "CustomerAttributeDialogGrid";
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: CustomerAttribute = receivedEntry;
      this.customerAttributes = [];
      this.customerAttributes.push(recordSelected);
      this.pricingFilterValueForm.patchValue({ ['customerAttributeID']: receivedEntry.id });
      this.pricingFilterValueForm.patchValue({ ['value']: null });
      this.pricingFilterValue.customerAttribute = receivedEntry;
      this.selectedCustomerAttribute = receivedEntry;
      modalRef.close('test');
      if (receivedEntry?.attributeDataTypeID != null) {
        this.displayCorrectOperands(receivedEntry.attributeDataTypeID)
        this.displayCorrectInput(receivedEntry.attributeDataTypeID)
      }
    });
  }

  async onLookup_DocumentAttribute() {
    const modalRef = this.modalService.open(DocumentAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: DocumentAttribute = receivedEntry;
      this.documentAttributes = [];
      this.documentAttributes.push(recordSelected);
      this.pricingFilterValueForm.patchValue({ ['documentAttributeID']: receivedEntry.id });
      this.pricingFilterValueForm.patchValue({ ['value']: null });
      this.pricingFilterValue.documentAttribute = receivedEntry;
      this.selectedDocumentAttribute = receivedEntry;
      modalRef.close('test');
      if (receivedEntry?.attributeDataTypeID != null) {
        this.displayCorrectOperands(receivedEntry.attributeDataTypeID)
        this.displayCorrectInput(receivedEntry.attributeDataTypeID)
      }
    });
  }

  async onLookup_ItemAttribute() {
    const modalRef = this.modalService.open(ItemAttributeLookupComponent, {});
    modalRef.componentInstance.onSelectSingle.subscribe((receivedEntry) => {
      const recordSelected: ItemAttribute = receivedEntry;
      this.itemAttributes = [];
      this.itemAttributes.push(recordSelected);
      this.pricingFilterValueForm.patchValue({ ['itemAttributeID']: receivedEntry.id });
      this.pricingFilterValueForm.patchValue({ ['value']: null });
      this.pricingFilterValue.itemAttribute = receivedEntry;
      this.selectedItemAttribute = receivedEntry;
      modalRef.close('test');
      if (receivedEntry?.attributeDataTypeID != null) {
        this.displayCorrectOperands(receivedEntry.attributeDataTypeID)
        this.displayCorrectInput(receivedEntry.attributeDataTypeID)
      }
    });
  }

  formChanged($event: boolean) {
    this.isFormChanged = $event;
  }
  closeModal = () => {
    this.modalService.dismissAll();
  }
  goNextPage = () => {
    this.closeModal();
    this.goBackForce = true;
    this.router.navigate([this.nextURL]);
  }
  async canDeactivate(nextURL: string) {
    console.log(this.isFormChanged);
    this.nextURL = nextURL;
    if (this.isFormChanged && !this.goBackForce) {
      const modalRef = this.modalService.open(UnsavedChangesModalComponent);
      modalRef.componentInstance.goNextPage.subscribe(this.goNextPage);
      modalRef.componentInstance.closeModal.subscribe(this.closeModal);
      return false;
    } else {
      return true;
    }
  }

  onClose(message) {
    // TODO: Figure out prompt for save.
    this.activeModal.close(message);
  }

  async addPricingFilterValueCollection(modal) {
    this.submitted = true;

    // Validate Value Lookup.    
    if (this.pricingFilterValueCollectionForm.controls.value.hasError('required')) {
      delete this.pricingFilterValueCollectionForm.controls.value.errors['required'];
      this.pricingFilterValueCollectionForm.controls.value.updateValueAndValidity();
    }
    if (!this.pricingFilterValueCollectionForm.controls.value.value) {
      this.pricingFilterValueCollectionForm.controls.value.setErrors({ required: true });
    }

    // Validate Customer Lookup.    
    if (this.pricingFilterValueCollectionForm.controls.collectionCustomerID.hasError('required')) {
      delete this.pricingFilterValueCollectionForm.controls.collectionCustomerID.errors['required'];
      this.pricingFilterValueCollectionForm.controls.collectionCustomerID.updateValueAndValidity();
    }
    if (this.showCollectionCustomerLookup && !this.pricingFilterValueCollectionForm.controls.collectionCustomerID.value) {
      this.pricingFilterValueCollectionForm.controls.collectionCustomerID.setErrors({ required: true });
    }

    // Validate Item Lookup.    
    if (this.pricingFilterValueCollectionForm.controls.collectionItemID.hasError('required')) {
      delete this.pricingFilterValueCollectionForm.controls.collectionItemID.errors['required'];
      this.pricingFilterValueCollectionForm.controls.collectionItemID.updateValueAndValidity();
    }
    if (this.showCollectionItemLookup && !this.pricingFilterValueCollectionForm.controls.collectionItemID.value) {
      this.pricingFilterValueCollectionForm.controls.collectionItemID.setErrors({ required: true });
    }

    // Validate Location Lookup.    
    if (this.pricingFilterValueCollectionForm.controls.collectionLocationID.hasError('required')) {
      delete this.pricingFilterValueCollectionForm.controls.collectionLocationID.errors['required'];
      this.pricingFilterValueCollectionForm.controls.collectionLocationID.updateValueAndValidity();
    }
    if (this.showCollectionLocationLookup && !this.pricingFilterValueCollectionForm.controls.collectionLocationID.value) {
      this.pricingFilterValueCollectionForm.controls.collectionLocationID.setErrors({ required: true });
    }

    if (this.pricingFilterValueCollectionForm.invalid) {
      return window.scrollTo(0, 0);
    }

    if (this.pricingFilterValueCollectionID) {
      const data = {
        ...this.pricingFilterValueCollection,
        ...this.pricingFilterValueCollectionForm.value
      };
      try {
        const response: any = await this.pricingFilterValueCollectionsService.update(
          this.pricingFilterValueCollectionID,
          data,
        );
        const status: any = response.status;
        if (status === 200) {
          this.snack.openSnackBar(
            'Record updated successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    } else {
      try {
        const response: any = await this.pricingFilterValueCollectionsService.create(
          this.pricingFilterValueCollectionForm.value,
        );
        const status: any = response.status;
        if (status === 201) {
          this.snack.openSnackBar(
            'Record saved successfully!',
            '',
            false,
            'Success',
            'alert-success',
          );
        }
      } catch (e) {
        this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
      }
    }
    modal.close();
    this.loadListData();
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;

    // TODO: for some reason we cannot get at this button need to figure out how to update it like the others.
    //document.getElementById('btnAddPricingFilterValue').setAttribute('title', 'Add New');

    try {
      // get the entityid from the pricing filter
      // 68-cust, 71 -item, 72-location,73-uofm      
      const response: any = await this.pricingFilterValueCollectionsService.getList({
        Filter_PricingFilterValueID: this.idOfPricingFilterValue, ...this.query
      });
      this.pricingFilterValueCollections = response.body;

      let currentPricingFilterValue = (await this.getPricingFilterValueById());

      for (var i = 0; i < this.pricingFilterValueCollections.length; i++) {
        this.pricingFilterValueCollections[i].displayValue = this.pricingFilterValueCollections[i].value;

        if (currentPricingFilterValue.entityID == 68 && !currentPricingFilterValue.customerAttributeID) // Customer
        {
          this.pricingFilterValueCollections[i].displayValue = (await this.getCustomerById(Number(this.pricingFilterValueCollections[i].value))).name;
        }
        if (currentPricingFilterValue.entityID == 71 && !currentPricingFilterValue.itemAttributeID) // Item
        {
          this.pricingFilterValueCollections[i].displayValue = (await this.getItemById(Number(this.pricingFilterValueCollections[i].value))).name;
        }
        if (currentPricingFilterValue.entityID == 72) // Location
        {
          this.pricingFilterValueCollections[i].displayValue = (await this.getLocationById(Number(this.pricingFilterValueCollections[i].value))).name;
        }
      }
      this.pagination = getPaginationHeader(response.headers);
      this.gridView = {
        data: this.pricingFilterValueCollections,
        total: this.pagination.TotalCount,
      };
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  async onEdit(content) {
    let id = this.clickedRowItem;
    let value: any;
    let collectionCustomerIDValue: string = '';
    let collectionItemIDValue: string = '';
    let collectionLocationIDValue: string = '';
    let collectionUofMIDValue: string = '';

    if (this.attributeIsDate) {
      value = id?.value == null ? new Date() : new Date(id.value);
    }
    else if (this.attributeIsNumber) {
      value = id?.value == null ? null : Number(id.value);
    }
    else {
      //retrieve the pricingfiltervalue
      let currentPricingFilterValue = (await this.getPricingFilterValueById());
      if (currentPricingFilterValue.entityID == 68 && !currentPricingFilterValue.customerAttributeID) // Customer
      {
        collectionCustomerIDValue = (await this.getCustomerById(Number(id.value))).name;
      }
      if (currentPricingFilterValue.entityID == 71 && !currentPricingFilterValue.itemAttributeID) // Item
      {
        collectionItemIDValue = (await this.getItemById(Number(id.value))).name;
      }
      if (currentPricingFilterValue.entityID == 72) // Location
      {
        collectionLocationIDValue = (await this.getLocationById(Number(id.value))).name;
      }
      value = id?.value;
    }
    this.pricingFilterValueCollectionForm = this.fb.group({
      pricingFilterValueID: new FormControl(this.idOfPricingFilterValue, Validators.required),
      value: new FormControl(value),
      collectionCustomerID: new FormControl(collectionCustomerIDValue),
      collectionItemID: new FormControl(collectionItemIDValue),
      collectionLocationID: new FormControl(collectionLocationIDValue),
      collectionUofMID: new FormControl(collectionUofMIDValue),
      dateValue: new FormControl('')
    });

    this.pricingFilterValueCollectionID = id?.id;
    if (this.pricingFilterValueCollectionID) {
      [this.pricingFilterValueCollection] =
        await Promise.all([this.pricingFilterValueCollectionsService.getById(this.pricingFilterValueCollectionID)])
        ;
    }

    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async onCreate(content) {
    this.pricingFilterValueCollectionID = null;
    this.pricingFilterValueCollectionForm = this.fb.group({
      pricingFilterValueID: new FormControl(this.idOfPricingFilterValue, Validators.required),
      value: new FormControl(''),
      collectionCustomerID: new FormControl(''),
      collectionItemID: new FormControl(''),
      collectionLocationID: new FormControl(''),
      collectionUofMID: new FormControl(''),
      dateValue: new FormControl('')
    });

    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.pricingFilterValueCollectionsService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  public setValueFromDate(dateValue) {
    this.pricingFilterValueForm.patchValue({ ['value']: dateValue });
    this.value = new Date(dateValue);
    this.pricingFilterValue.value = this.value;
  }

  public setValueFromDateCollection(dateValue) {
    this.pricingFilterValueCollectionForm.patchValue({
      ['value']: dateValue,
    });
  }

  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.pricingFilterValueCollectionsService.getExportList({
      Filter_PricingFilterValueID: this.idOfPricingFilterValue, ...this.query
    });
  }
  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }
  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'dateAttribute.name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'value') {
          const Filter_Value = myFilter.value;
          const params = { ...this.query, Filter_Value };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  displayCorrectOperands(attributeDataTypeID: number) {
    this.operands.length = 0;
    this.pricingFilterValueForm.patchValue({ ['operandID']: null });

    switch (attributeDataTypeID) {
      case 13: // Check Box
        {
          this.operands.push({ id: 20, name: "Equals", optionSet: "Operand", sortOrder: 0 });
          break;
        }
      case 14: // Number
        {
          this.operands.push({ id: 18, name: "Contains", optionSet: "Operand", sortOrder: 0 });
          this.operands.push({ id: 19, name: "Does NOT Contain", optionSet: "Operand", sortOrder: 1 });
          this.operands.push({ id: 20, name: "Equals", optionSet: "Operand", sortOrder: 2 });
          this.operands.push({ id: 21, name: "Does NOT Equal", optionSet: "Operand", sortOrder: 3 });
          this.operands.push({ id: 22, name: "One Of", optionSet: "Operand", sortOrder: 4 });
          this.operands.push({ id: 23, name: "NOT One Of", optionSet: "Operand", sortOrder: 5 });
          this.operands.push({ id: 24, name: "Greater Than", optionSet: "Operand", sortOrder: 6 });
          this.operands.push({ id: 25, name: "Greater Than or Equal", optionSet: "Operand", sortOrder: 7 });
          this.operands.push({ id: 26, name: "Less Than", optionSet: "Operand", sortOrder: 8 });
          this.operands.push({ id: 27, name: "Less Than or Equal", optionSet: "Operand", sortOrder: 9 });
          break;
        }
      case 15: // Lookup
        {
          this.operands.push({ id: 20, name: "Equals", optionSet: "Operand", sortOrder: 0 });
          this.operands.push({ id: 21, name: "Does NOT Equal", optionSet: "Operand", sortOrder: 1 });
          this.operands.push({ id: 22, name: "One Of", optionSet: "Operand", sortOrder: 2 });
          this.operands.push({ id: 23, name: "NOT One Of", optionSet: "Operand", sortOrder: 3 });
          break;
        }
      case 16: // Date
        {
          this.operands.push({ id: 28, name: "On or Before", optionSet: "Operand", sortOrder: 0 });
          this.operands.push({ id: 29, name: "On", optionSet: "Operand", sortOrder: 1 });
          this.operands.push({ id: 30, name: "On or After", optionSet: "Operand", sortOrder: 2 });
          this.operands.push({ id: 22, name: "One Of", optionSet: "Operand", sortOrder: 3 });
          this.operands.push({ id: 23, name: "NOT One Of", optionSet: "Operand", sortOrder: 4 });
          break;
        }
      case 17: // String
        {
          this.operands.push({ id: 18, name: "Contains", optionSet: "Operand", sortOrder: 0 });
          this.operands.push({ id: 19, name: "Does NOT Contain", optionSet: "Operand", sortOrder: 1 });
          this.operands.push({ id: 20, name: "Equals", optionSet: "Operand", sortOrder: 2 });
          this.operands.push({ id: 21, name: "Does NOT Equal", optionSet: "Operand", sortOrder: 3 });
          this.operands.push({ id: 22, name: "One Of", optionSet: "Operand", sortOrder: 4 });
          this.operands.push({ id: 23, name: "NOT One Of", optionSet: "Operand", sortOrder: 5 });
          this.operands.push({ id: 24, name: "Greater Than", optionSet: "Operand", sortOrder: 6 });
          this.operands.push({ id: 25, name: "Greater Than or Equal", optionSet: "Operand", sortOrder: 7 });
          this.operands.push({ id: 26, name: "Less Than", optionSet: "Operand", sortOrder: 8 });
          this.operands.push({ id: 27, name: "Less Than or Equal", optionSet: "Operand", sortOrder: 9 });
          break;
        }
    }
  }

  displayCorrectInput(attributeDataTypeID: number) {
    this.showCustomerLookup = false;
    this.showItemLookup = false;
    this.showLocationLookup = false;
    this.attributeIsString = false;
    this.attributeIsDate = false;
    this.attributeIsCheckBox = false;
    this.attributeIsNumber = false;

    switch (attributeDataTypeID) {
      case 13: //Check Box
        this.operands.length = 0;
        this.operands.push({ id: 20, name: "Equals", optionSet: "Operand", sortOrder: 0 });
        this.attributeIsCheckBox = true;
        if (isNullOrEmptyString(this.pricingFilterValue.value)) {
          this.f.value.patchValue(false);
        }
        break;
      case 14: // Number
        this.attributeIsNumber = true;
        break;
      case 15: // Lookup
        this.attributeIsString = true;
        break;
      case 16: // Date
        this.attributeIsDate = true;
        break;
      default: // String
        this.attributeIsString = true;
        break;
    }
  }
}
