export interface GLAccount {
    id: number;
    name: string;
    number: string;
    erpid: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
  }

export const emptyGLAccount: GLAccount = {
    id: null,
    name: '',
    number: '',
    erpid: '',
    createdOn: null,
    createdBy: '',
    modifiedOn: null,
    modifiedBy: ''
};
