import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PricingDiagnosticsService } from '../../../services/pricing-diagnostics/pricing-diagnostics.service';
import {
  PricingDiagnostic,
  emptyPricingDiagnostic,
} from '../../../services/pricing-diagnostics/pricing-diagnostics.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { debounce, uniqBy } from 'lodash';
import {
  getPaginationHeader,
  mockedData,
} from 'src/app/utils/getPaginationHeader';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { prepareData } from './utils/prepareDataForFrom';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';

enum Filters {
  AttributeDataType = 1,
}
@Component({
  selector: 'app-pricing-diagnostic-single',
  templateUrl: './pricing-diagnostic-single.component.html',
  styleUrls: ['./pricing-diagnostic-single.component.scss'],
})
export class PricingDiagnosticSingleComponent implements OnInit {
  filters = Filters;
  idOfPricingDiagnostic: number | null = null;
  pricingDiagnostic: PricingDiagnostic = emptyPricingDiagnostic;
  loading: boolean = true;
  pricingDiagnosticForm: FormGroup;
  submitted = false;
  @Output()
  onFormChanged = new EventEmitter<Boolean>();
  modalReference: any;

  constructor(
    private route: ActivatedRoute,
    private pricingDiagnosticsService: PricingDiagnosticsService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private snack: MySnackBarService,
  ) {
    // this.route.params.subscribe((params) => {
    //   this.idOfPricingDiagnostic = params.id;
    //   this.loadData();
    // });
    // this.buildForm();
  }

  ngOnInit(): void {
    this.loadData();
    this.buildForm();

  }
  buildForm() {
    this.pricingDiagnosticForm = new FormGroup({
      id: new FormControl(''),
      runDateTime: new FormControl(''),
      resultData: new FormControl(''),
    });    
  }
  get f() {
    return this.pricingDiagnosticForm.controls;
  }
  updateForm() {
    this.pricingDiagnosticForm.patchValue({ ...this.pricingDiagnostic });
    this.pricingDiagnosticForm.valueChanges.subscribe((status) => {
      this.onFormChanged.emit(true);
    });
  }
 
  onClick_Close(message) {
    // TODO: Figure out prompt for save.
    this.activeModal.close(message);
  }

  async getPricingDiagnosticById() {
    if (this.idOfPricingDiagnostic) {
      return await this.pricingDiagnosticsService.getById(
        this.idOfPricingDiagnostic,
      );
    } else {
      return emptyPricingDiagnostic;
    }
  }
  async loadData() {
    this.loading = true;
    try {
      [this.pricingDiagnostic] = await Promise.all([
        this.getPricingDiagnosticById()
      ]);
      this.prepareData();
    } catch (e) {
    } finally {
      this.loading = false;
    }
  }
  prepareData() {
    // [
    //   // @ts-ignore
    //   this.attributeDataTypes,
    // ] = prepareData(this.documentAttribute, this.attributeDataTypes);
    this.updateForm();
  }
}
