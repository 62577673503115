import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { UserSingleComponent } from '../user-single/user-single.component';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { CompanyUser } from 'src/app/services/companies-users/companies-users.model';
import { CompaniesUsersService } from 'src/app/services/companies-users/companies-users.service';
import { User } from 'src/app/services/users/users.model';
import { UsersService } from 'src/app/services/users/users.service';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  @ViewChild('grid') myGrid: GridComponent;

  constructor(
    private adminCompaniesUsersService: CompaniesUsersService,
    private relatedCompaniesUsersService: CompaniesUsersService,
    private usersService: UsersService,
    private router: Router,
    private modalService: NgbModal,

    private snack: MySnackBarService,
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
  }

  @Input() loading: boolean = true;
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';
  public clickedRowItem;
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;
  users: User[] = [];

  paginationObject: any = {
    users: mockedData,
  };

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: User[];
  private companiesUsers: CompanyUser[];
  public isAdmin: boolean;
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'User Name',
      field: 'userName',
      width: 300,
      orderIndex: 0,
      hidden: false
    },
    {
      title: 'First Name',
      field: 'firstName',
      width: 300,
      orderIndex: 1,
      hidden: false
    },
    {
      title: 'Last Name',
      field: 'lastName',
      width: 300,
      orderIndex: 2,
      hidden: false
    },
    {
      title: 'Is Admin',
      field: 'isAdmin',
      width: 300,
      orderIndex: 3,
      hidden: false
    },
    {
      title: 'Is Disabled',
      field: 'isDisabled',
      width: 300,
      orderIndex: 4,
      hidden: false
    }
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  ngAfterViewInit() {
    this.isAdmin = StorageService.IsAdmin();
    this.loading = true;
    this.ConfigureGrid();
    this.loading = false;
    this.loadListData();
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('UserList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('UserList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('UserList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('UserList_Config', this.columnsConfig);
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.onEdit(this.clickedRowItem);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;
        if (myFilter.field == 'userName') {
          const Filter_UserName = myFilter.value;
          const Operand_UserName = myFilter.operator;
          const params = { ...this.query, Filter_UserName, Operand_UserName };
          this.query = params;
        }
        if (myFilter.field == 'firstName') {
          const Filter_FirstName = myFilter.value;
          const Operand_FirstName = myFilter.operator;
          const params = { ...this.query, Filter_FirstName, Operand_FirstName };
          this.query = params;
        }
        if (myFilter.field == 'lastName') {
          const Filter_LastName = myFilter.value;
          const Operand_LastName = myFilter.operator;
          const params = { ...this.query, Filter_LastName, Operand_LastName };
          this.query = params;
        }
        if (myFilter.field == 'isAdmin') {
          const Filter_IsAdmin = myFilter.value;
          const Operand_IsAdmin = myFilter.operator;
          const params = { ...this.query, Filter_IsAdmin, Operand_IsAdmin };
          this.query = params;
        }
        if (myFilter.field == 'invalidAttempts') {
          const Filter_InvalidAttempts = myFilter.value;
          const Operand_InvalidAttempts = myFilter.operator;
          const params = {
            ...this.query,
            Filter_InvalidAttempts,
            Operand_InvalidAttempts,
          };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }

  public async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize();
    try {
      this.users = [];
      // Get the Companies that the logged in user is associated with.
      const Filter_UserID = StorageService.UserID();
      const adminCompanyUsers: CompanyUser[] = await (await Promise.resolve(this.adminCompaniesUsersService.getList({ Filter_UserID, ...this.query }))).body;

      // Foreach through those companies and retrieve users associated with that company
      for (const adminCompanyUser of adminCompanyUsers) {
        const Filter_CompanyID = adminCompanyUser.companyID;
        const relatedCompanyUsers: CompanyUser[] = await (await Promise.resolve(this.relatedCompaniesUsersService.getList({ Filter_CompanyID, ...this.query }))).body;
        for (const relatedCompanyUser of relatedCompanyUsers) {
          if (!this.users.find(e => e.userName == relatedCompanyUser.user.userName)) {
            this.users.push(relatedCompanyUser.user);
          }
        }
      }

      // Sort them by username
      this.users.sort((a, b) => (a.userName > b.userName) ? 1 : -1)

      this.gridView = {
        data: this.users,
        total: this.users.length
      };
    } catch (e) {

    } finally {
      this.loading = false;
    }
  }

  get disableEditBtn() {
    const activeIds = removeUnSelectedIds(this.mySelection);
    return Object.keys(activeIds).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }
  onCreate() {
    const modalRef = this.modalService.open(UserSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'dialog';
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  onEdit(user) {
    const modalRef = this.modalService.open(UserSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.mode = 'dialog';
    modalRef.componentInstance.idOfUser = user.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }
}
