import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SyncReaderPreview } from './sync-reader-preview.model';
import { timeout } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SyncReaderPreviewService {
  model = 'RPMSyncReaderPreview';
  constructor(private httpClient: HttpClient) { } 
  getById(id: number): Promise<SyncReaderPreview> {
    return this.httpClient.get(`${this.model}/${id}`)
      .pipe(timeout(environment.requestTimeout))
      .toPromise() as Promise<SyncReaderPreview>;
  }  
}
