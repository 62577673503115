export interface SystemJob {
  id: number;
  name: string;
  jobTypeID: number;
  jobType: {
    id: number;
    name: string;
  };
  isRunning: boolean;
  timeUnitID: number;
  timeUnit: {
    id: number;
    name: string;
  };
  startOn: Date;
  minutelyID: number;
  minutely: {
    id: number;
    name: string;
  };
  hourlyID: number;
  hourly: {
    id: number;
    name: string;
  };
  nextRun: Date;
  createdOn: Date;
  createdBy: string;
  modifiedOn: Date;
  modifiedBy: string;
}

export const emptySystemJob: SystemJob = {
  id: null,
  name: '',
  jobTypeID: null,
  jobType: {
    id: null,
    name: ''
  },
  isRunning: false,
  timeUnitID: null,
  timeUnit: {
    id: null,
    name: ''
  },
  startOn: null,
  minutelyID: null,
  minutely: {
    id: null,
    name: ''
  },
  hourlyID: null,
  hourly: {
    id: null,
    name: ''
  },
  nextRun: null,
  createdOn: null,
  createdBy: '',
  modifiedOn: null,
  modifiedBy: ''
};

