<ng-template #areYouSure let-modal>
  <div class="modal-header">Alert</div>
  <div class="modal-body">
    <p>Are you sure you want to delete this record?</p>
  </div>
  <div class="modal-footer">
    <button type="button"
            class="btn btn-outline-primary"
            (click)="deleteRecord(modal)">
      Yes
    </button>
    <button type="button"
            class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">
      No
    </button>
  </div>
</ng-template>

<div class="flex-fill pl-2 pr-2 pb-3" style="font-size: large;">
  <img src="../../../../assets/images/Price_Sheet.png" alt="System Job Log" style="padding-right: 5px;">
  System Job Log
</div>

<kendo-buttongroup>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" imageUrl="../../../../assets/images/Back.png" (click)="onClickBack()" routerLinkActive="router-link-active" title="Go Back to List">BACK</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Delete.png" (click)="showRemovePopUp(areYouSure)" title="Delete Record">DELETE</button>
  <button kendoButton type="button" style="border: none; padding-left: 10px; padding-right: 10px;" [disabled]="disableButtons" imageUrl="../../../../assets/images/Cancel.png" (click)="confirmCancel()" title="Cancel">CANCEL</button>
</kendo-buttongroup>

<form [formGroup]="SystemJobLogForm">
  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="name" text="Name"></kendo-label>
        <kendo-textbox class="form-control" formControlName="name" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="beginDateTime" text="Begin Date Time"></kendo-label>
        <kendo-textbox class="form-control" formControlName="beginDateTime" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="endDateTime" text="End Date Time"></kendo-label>
        <kendo-textbox class="form-control" formControlName="endDateTime" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="minutesToComplete" text="Minutes To Complete"></kendo-label>
        <kendo-textbox class="form-control" formControlName="minutesToComplete" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="status" text="Status"></kendo-label>
        <kendo-textbox class="form-control" formControlName="status" [readonly]="true">
        </kendo-textbox>
      </kendo-formfield>
    </div>
  </div>

  <div class="row">
    <div class="col form-group">
      <kendo-formfield>
        <kendo-label for="comment" text="Comment"></kendo-label>
        <kendo-textarea class="form-control" width="100%" formControlName="comment" [readonly]="true" rows="4" style="width: 100%; overflow-y: auto;">
        </kendo-textarea>
      </kendo-formfield>
    </div>
  </div>

</form>

<div class="card p-3" style="background-color:rgb(234, 234, 234)">
  <app-system-job-error-list #subgrid [systemJobLogID]='idOfSystemJobLog'
                             [pagination]="paginationObject" [loading]="loading"
                             [mode]="subgridmode">
  </app-system-job-error-list>
</div>
