import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  QueryList,
  ViewChild,
  ElementRef,
  AfterViewInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomersService } from '../../../services/customers/customers.service';
import { Customer, emptyCustomer } from '../../../services/customers/customers.model';
import { ItemsService } from '../../../services/items/items.service';
import { Item, emptyItem } from '../../../services/items/items.model';
import { LocationsService } from '../../../services/locations/locations.service';
import { Location, emptyLocation } from '../../../services/locations/locations.model';
import { PricingFilterValuesService } from '../../../services/pricing-filter-values/pricing-filter-values.service';
import { PricingFilterValue } from '../../../services/pricing-filter-values/pricing-filter-values.model';
import { debounce } from 'lodash';
import {
  Pagination,
  mockedData,
  getPaginationHeader,
} from 'src/app/utils/getPaginationHeader';
import { removeUnSelectedIds } from 'src/app/utils/RemoveUnSelectedIds';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { MySnackBarService } from '../../../shared/snackbar/my-snackbar.service';
import { PricingFilterValueSingleComponent } from '../pricing-filter-value-single/pricing-filter-value-single.component';
import {
  GridDataResult,
  DataStateChangeEvent,
  PageChangeEvent,
  ExcelModule,
  GridComponent,
  ExcelComponent,
  FooterComponent,
  RowArgs,
} from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  orderBy,
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { ColumnSettings } from '../../../../app/utils/column-settings.interface';
import { StorageService } from '../../../../app/utils/StorageHelper';
import * as XLSX from 'xlsx';
import { ImportsService } from '../../../services/imports/imports.service';
import { emptyLookup, Lookup } from 'src/app/services/lookups/lookups.model';
import { LookupsService } from '../../../services/lookups/lookups.service';
import { NotificationHelper } from '../../../utils/NotificationHelper';
import { ExportConfirmationComponent } from 'src/app/shared/export-confirmation/export-confirmation.component';
import { DeleteConfirmationComponent } from '../../../shared/delete-confirmation/delete-confirmation.component';

@Component({
  selector: 'app-pricing-filter-value-list',
  templateUrl: './pricing-filter-value-list.component.html',
  styleUrls: ['./pricing-filter-value-list.component.scss'],
})
export class PricingFilterValueListComponent implements AfterViewInit, OnInit {
  @ViewChild('grid') myGrid: GridComponent;
  @ViewChild('hiddenfileinput') fileUploader: ElementRef;

  constructor(
    private pricingFilterValuesService: PricingFilterValuesService,
    private router: Router,
    private modalService: NgbModal,
    private snack: MySnackBarService,
    private importsService: ImportsService,
    private lookupService: LookupsService,
    private customersService: CustomersService,
    private itemsService: ItemsService,
    private locationsService: LocationsService,
    private notificationHelper: NotificationHelper
  ) {
    this.onFilterChange = debounce(this.onFilterChange, 300, { leading: true });
    this.allData = this.allData.bind(this);
  }

  @Input() loading: boolean = true;
  @Input() pricingFilterID: number;
  @Input() pricingFilterValues: PricingFilterValue[] = [];
  @Input() query = {
    PageSize: 25,
    SortTerm: 'name',
    PageNumber: 1,
    SearchTerm: '',
  };
  @Input() pagination: Pagination = mockedData;
  @Input() mode: 'view' | 'lookup' | 'subgrid' = 'view';

  @Output() filterChange = new EventEmitter();
  @Output() onSelectSingle = new EventEmitter();
  limits = [25, 50, 75, 100, 250];
  selectedIds: { [key: number]: boolean } = {};
  idForRemove: number | null = null;

  public mySelection: string[] = [];
  public gridView: GridDataResult;
  public pageSize = 25;
  public skip = 0;
  public filter: CompositeFilterDescriptor;
  private data: PricingFilterValue[];
  public exportAll: boolean;
  public optDescColumns: boolean;
  public optRowID: boolean;
  public globalRecordCount: number = 1;
  public clickedRowItem;
  public entityTypeOptions: Lookup[];
  public pricingFilterValueOperandOptions: Lookup[];
  public filterable: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'Name',
      dir: 'asc',
    },
  ];
  defaultColumnsConfig: ColumnSettings[] = [
    {
      title: 'Entity',
      field: 'entity.name',
      width: 300,
      orderIndex: 0,
      hidden: false,
    },
    {
      title: 'Attribute',
      field: 'attribute.name',
      width: 300,
      orderIndex: 1,
      hidden: false,
    },
    {
      title: 'Operand',
      field: 'operand.name',
      width: 300,
      orderIndex: 2,
      hidden: false,
    },
    {
      title: 'Value',
      field: 'value',
      width: 300,
      orderIndex: 3,
      hidden: false,
    },
  ];
  columnsConfig: ColumnSettings[];

  ngOnInit(): void {
    this.loading = true;
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.saveGrid();
  }

  async onClick_Delete() {
    const modalRef = this.modalService.open(DeleteConfirmationComponent);
    modalRef.componentInstance.confirmDelete.subscribe(() => {
      modalRef.close();
      this.DeleteRecords();
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
    });
  }

  async DeleteRecords() {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });

    await this.pricingFilterValuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.notificationHelper.showStatus('Record(s) deleted successfully!', 'success');
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.notificationHelper.showStatus(err.error, "error");
      });

    this.loadListData();
  }

  async onClick_Export(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();
    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total;
    modalRef.componentInstance.siblingRecordCount = this.globalRecordCount;
    modalRef.componentInstance.confirmExport.subscribe((options) => {
      modalRef.close();
      this.loading = true;
      this.exportAll = options.optTechnicalStyle;
      this.optDescColumns = options.optDescColumns;
      this.optRowID = options.optRowID;
      if (options.optTechnicalStyle) {
        if (options.optSiblingRecords) {
          excelComponent.fetchData = this.allGlobalSiblingData;
        }
        else {
          excelComponent.fetchData = this.allData;
        }
      }
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });
  }

  public allGlobalSiblingData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.pricingFilterValuesService.getExportList({
      ...this.query
    });
  }

  async getGlobalSiblingDataCount() {
    this.loading = true;
    try {
      const response: any = await this.pricingFilterValuesService.getList({ PageSize: 1, PageNumber: 1 });
      this.loading = false;
      const pagination = getPaginationHeader(response.headers);
      this.globalRecordCount = pagination.TotalCount;
    } catch (error) {
      this.loading = false;
      console.error("Error fetching total count: ", error);
    }
  }

  async onClick_ExportOLD(grid: GridComponent, excelComponent: ExcelComponent) {
    this.saveGrid();

    const modalRef = this.modalService.open(ExportConfirmationComponent);
    modalRef.componentInstance.gridRecordCount = this.gridView?.total; 
    modalRef.componentInstance.confirmReImport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = true;
      excelComponent.fetchData = this.allData;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.confirmExport.subscribe(() => {
      modalRef.close();
      this.loading = true;
      this.exportAll = false;
      grid.saveAsExcel();
      this.query.PageSize = StorageService.PageSize();
      this.loading = false;
    });
    modalRef.componentInstance.closeModal.subscribe(() => {
      modalRef.close();
      this.loading = false;
    });

  }

  async getEntityTypeOptions() {
    const Filter_OptionSet = 'EntityType';
    const params = { Filter_OptionSet };
    this.entityTypeOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "EntityType";
    defaultLookup.name = "(All)";
    this.entityTypeOptions.reverse();
    this.entityTypeOptions.push(defaultLookup);
    this.entityTypeOptions.reverse();
    this.entityTypeOptions = this.entityTypeOptions.filter(
      x => x.name !== "Catalog" && x.name !== "Catalog Sheet" && x.name !== "Data Sync Map");
    return this.entityTypeOptions;
  }

  async getPricingFilterValueOperandOptions() {
    const Filter_OptionSet = 'Operand';
    const params = { Filter_OptionSet };
    this.pricingFilterValueOperandOptions = await this.lookupService.getList(params);
    const defaultLookup: Lookup = emptyLookup;
    defaultLookup.id = 999;
    defaultLookup.optionSet = "Operand";
    defaultLookup.name = "(All)";
    this.pricingFilterValueOperandOptions.reverse();
    this.pricingFilterValueOperandOptions.push(defaultLookup);
    this.pricingFilterValueOperandOptions.reverse();
    return this.pricingFilterValueOperandOptions;
  }

  onFilterClick() {
    this.filterable = !this.filterable;
  }

  onResetGridClick() {
    StorageService.removeColumnSettings('PricingFilterValueList_Config');
    this.columnsConfig = this.defaultColumnsConfig.map(obj => ({ ...obj }));
    this.ConfigureGrid();
  }

  ConfigureGrid() {
    // Try and pull our grid configuration from the storage.
    let gridConfig: ColumnSettings[] = StorageService.getColumnSettings('PricingFilterValueList_Config');

    // If it has not been persisted yet, then persist the default configuration
    if (!gridConfig) {
      StorageService.setColumnSettings('PricingFilterValueList_Config', this.defaultColumnsConfig);
    } else {
      // Use the updated configuration for the user.
      this.columnsConfig = gridConfig;
    }

    // restore columns to saved configuration
    this.myGrid.columns.forEach((column) => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        column.orderIndex = columnConfig.orderIndex;
        column.hidden = columnConfig.hidden;
        column.width = columnConfig.width;
      }
    });
  }

  saveGrid(): void {
    // save column configuration
    this.myGrid.columns.forEach(column => {
      const columnConfig = this.columnsConfig.find(cc => cc.title === column.title);

      if (columnConfig) {
        columnConfig.hidden = column.hidden;
        columnConfig.orderIndex = column.orderIndex;
        columnConfig.width = column.width;
      }
    });

    // sort the array, this is necessary for the excel export
    this.columnsConfig = this.columnsConfig.sort((cc1, cc2) => {
      if (cc1.orderIndex > cc2.orderIndex) {
        return 1;
      }

      if (cc1.orderIndex < cc2.orderIndex) {
        return -1;
      }

      return 0;
    });

    StorageService.setColumnSettings('PricingFilterValueList_Config', this.columnsConfig);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pricingFilterID && !changes.pricingFilterID.firstChange) {
      // We now have an Parent ID so we can change the tooltip.
      document.getElementById('btnAdd').setAttribute('title', 'Add New');
    }
  }

  ngAfterViewInit() {
    this.loading = true;
    this.ConfigureGrid();    
    this.loading = false;
    this.loadListData();
    this.getEntityTypeOptions();
    this.getPricingFilterValueOperandOptions();
  }

  async getCustomerById(customerID: number) {
    let customer: Customer;
    try {
      customer = await this.customersService.getById(customerID);
    }
    catch {
      customer = emptyCustomer;
    }
    return customer;
  }
  async getItemById(itemID: number) {
    let item: Item;
    try {
      item = await this.itemsService.getById(itemID);
    }
    catch {
      item = emptyItem;
    }
    return item;
  }
  async getLocationById(locationID: number) {
    let location: Location;
    try {
      location = await this.locationsService.getById(locationID);
    }
    catch {
      location = emptyLocation;
    }
    return location;
  }

  async loadListData() {
    this.loading = true;
    this.query.PageSize = StorageService.PageSize() ?? 50;
    try {
      if (this.pricingFilterID) {
        document.getElementById('btnAdd').setAttribute('title', 'Add New');
        const response: any = await this.pricingFilterValuesService.getList({
          Filter_PricingFilterID: this.pricingFilterID,
          ...this.query
        });
        this.pricingFilterValues = response.body;

        for (var i = 0; i < this.pricingFilterValues.length; i++) {
          //the substitution of ID for Number is now done in the API.  Much of this code needs cleanup.
          if (this.pricingFilterValues[i].entityID == 68) // Customer
          {
            if (!this.pricingFilterValues[i].customerAttribute) {
              if (this.pricingFilterValues[i].operandID == 22 || this.pricingFilterValues[i].operandID == 23) {
                if (this.pricingFilterValues[i].value != "{}" && this.pricingFilterValues[i].value != null) {
                  let orig: string = this.pricingFilterValues[i].value;
                  let formatted: string = orig.replace('{', '').replace('}', '');
                  let final_array = formatted.split('~');
                  let displayValue = '{';
                  for (var j = 0; j < final_array.length; j++) {
                    displayValue = displayValue + (await this.getCustomerById(Number(final_array[j]))).number + ', ';
                  }
                  displayValue = displayValue.substring(0, displayValue.length - 2) + '}';
                  this.pricingFilterValues[i].displayValue = displayValue;
                  this.pricingFilterValues[i].displayValue = orig; //mhr
                }
                else {
                  this.pricingFilterValues[i].displayValue = "{}";
                }
              }
              else {
                this.pricingFilterValues[i].displayValue = (await this.getCustomerById(Number(this.pricingFilterValues[i].value))).number;
                this.pricingFilterValues[i].displayValue = this.pricingFilterValues[i].value;
              }
              this.pricingFilterValues[i].value = this.pricingFilterValues[i].displayValue;
            }
          }
          if (this.pricingFilterValues[i].entityID == 71) // Item
          {
            if (!this.pricingFilterValues[i].itemAttribute) {
              if (this.pricingFilterValues[i].operandID == 22 || this.pricingFilterValues[i].operandID == 23) {
                if (this.pricingFilterValues[i].value != "{}" && this.pricingFilterValues[i].value != null) {
                  let orig: string = this.pricingFilterValues[i].value;
                  let formatted: string = orig.replace('{', '').replace('}', '');
                  let final_array = formatted.split('~');
                  let displayValue = '{';
                  for (var j = 0; j < final_array.length; j++) {
                    displayValue = displayValue + (await this.getItemById(Number(final_array[j]))).number + ', ';
                  }
                  displayValue = displayValue.substring(0, displayValue.length - 2) + '}';
                  this.pricingFilterValues[i].displayValue = displayValue;
                  this.pricingFilterValues[i].displayValue = orig; //mhr
                }
                else {
                  this.pricingFilterValues[i].displayValue = "{}";
                }
              }
              else {
                this.pricingFilterValues[i].displayValue = (await this.getItemById(Number(this.pricingFilterValues[i].value))).number;
                this.pricingFilterValues[i].displayValue = this.pricingFilterValues[i].value;
              }
              this.pricingFilterValues[i].value = this.pricingFilterValues[i].displayValue;
            }
          }
          if (this.pricingFilterValues[i].entityID == 72) // Location
          {
            if (this.pricingFilterValues[i].operandID == 22 || this.pricingFilterValues[i].operandID == 23) {
              if (this.pricingFilterValues[i].value != "{}" && this.pricingFilterValues[i].value != null) {
                let orig: string = this.pricingFilterValues[i].value;
                let formatted: string = orig.replace('{', '').replace('}', '');
                let final_array = formatted.split('~');
                let displayValue = '{';
                for (var j = 0; j < final_array.length; j++) {
                  displayValue = displayValue + (await this.getLocationById(Number(final_array[j]))).name + ', ';
                }
                displayValue = displayValue.substring(0, displayValue.length - 2) + '}';
                this.pricingFilterValues[i].displayValue = displayValue;
                this.pricingFilterValues[i].displayValue = orig; //mhr
              }
              else {
                this.pricingFilterValues[i].displayValue = "{}";
              }
            }
            else {
              this.pricingFilterValues[i].displayValue = (await this.getLocationById(Number(this.pricingFilterValues[i].value))).name;
              this.pricingFilterValues[i].displayValue = this.pricingFilterValues[i].value;
            }
            this.pricingFilterValues[i].value = this.pricingFilterValues[i].displayValue;
          }
          if (this.pricingFilterValues[i].entityID == 73) // UofM
          {
            if (this.pricingFilterValues[i].operandID == 22 || this.pricingFilterValues[i].operandID == 23) {
              if (this.pricingFilterValues[i].value != "{}") {
                let orig: string = this.pricingFilterValues[i].value;
                let formatted: string = orig.replace('{', '').replace('}', '');
                let final_array = formatted.split('~');
                let displayValue = '{';
                for (var j = 0; j < final_array.length; j++) {
                  displayValue = displayValue + final_array[j] + ', ';
                }
                displayValue = displayValue.substring(0, displayValue.length - 2) + '}';
                this.pricingFilterValues[i].displayValue = displayValue;
              }
              else {
                this.pricingFilterValues[i].displayValue = "{}";
              }
            }
            else {
              this.pricingFilterValues[i].displayValue = this.pricingFilterValues[i].value;
            }
          }

          if (this.pricingFilterValues[i].entityID == 75) // Customer Attribute
          {
            if (this.pricingFilterValues[i].operandID == 22 || this.pricingFilterValues[i].operandID == 23) {
              if (this.pricingFilterValues[i].value != "{}") {
                let orig: string = this.pricingFilterValues[i].value;
                let formatted: string = orig.replace('{', '').replace('}', '');
                let final_array = formatted.split('~');
                let displayValue = '{';
                for (var j = 0; j < final_array.length; j++) {
                  displayValue = displayValue + final_array[j] + ', ';
                }
                displayValue = displayValue.substring(0, displayValue.length - 2) + '}';
                this.pricingFilterValues[i].displayValue = displayValue;
              }
              else {
                this.pricingFilterValues[i].displayValue = "{}";
              }
            }
            else {
              this.pricingFilterValues[i].displayValue = this.pricingFilterValues[i].value;
            }
          }
          if (this.pricingFilterValues[i].entityID == 76) // Item Attribute
          {
            if (this.pricingFilterValues[i].operandID == 22 || this.pricingFilterValues[i].operandID == 23) {
              if (this.pricingFilterValues[i].value != "{}") {
                let orig: string = this.pricingFilterValues[i].value;
                let formatted: string = orig.replace('{', '').replace('}', '');
                let final_array = formatted.split('~');
                let displayValue = '{'; 
                for (var j = 0; j < final_array.length; j++) {
                  displayValue = displayValue + final_array[j] + ', ';
                }
                displayValue = displayValue.substring(0, displayValue.length - 2) + '}';
                this.pricingFilterValues[i].displayValue = displayValue;
              }
              else {
                this.pricingFilterValues[i].displayValue = "{}";
              }
            }
            else {
              this.pricingFilterValues[i].displayValue = this.pricingFilterValues[i].value;
            }
          }
        }

        this.pagination = getPaginationHeader(response.headers);
        this.gridView = {
          data: this.pricingFilterValues,
          total: this.pagination.TotalCount,
        };
      }
    } catch (e) {
      // TODO: This throws an error on load of new record because PriceFilterID is not set yet.
      //this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    } finally {
      this.loading = false;
    }
    this.getGlobalSiblingDataCount();
  }

  onFilterChange() {
    this.filterChange.emit(this.query);
    this.loadListData();
  }

  async onFileChange(evt: any) {
    await this.importsService.onFileChange(evt, 'RPMPricingFilterValue');
    this.fileUploader.nativeElement.value = null;
    this.loadListData();
  }

  onFileChangeOLD(evt: any) {
    const target: DataTransfer = <DataTransfer>evt.target;

    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();

    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      //ws.forEach(function(y) {
      //var worksheet = wb.Sheets[y];
      var headers = {};
      var data = [];
      for (const z in ws) {
        if (z[0] === '!') continue;
        //parse out the column, row, and value
        var col = z.substring(0, 1);
        var row = parseInt(z.substring(1));
        var value = ws[z].v;

        //store header names
        if (row == 1) {
          headers[col] = String(value);
          continue;
        }

        if (!data[row]) data[row] = {};
        data[row][headers[col]] = String(value).trim();
      }
      //drop those first two rows which are empty
      data.shift();
      data.shift();
      console.log(data);
      //});
      var myJSONString = JSON.stringify(data);
      var myEscapedJSONString = myJSONString
        .replace(/[\\]/g, '\\\\')
        .replace(/[\']/g, "\\'")
        .replace(/[\"]/g, '\\"')
        .replace(/[\/]/g, '\\/')
        .replace(/[\b]/g, '\\b')
        .replace(/[\f]/g, '\\f')
        .replace(/[\n]/g, '\\n')
        .replace(/[\r]/g, '\\r')
        .replace(/[\t]/g, '\\t');

      const inputXML: string = "'" + myEscapedJSONString + "'";
      this.performImport(inputXML);
    };

    reader.readAsBinaryString(target.files[0]);

    this.fileUploader.nativeElement.value = null;
  }

  async performImport(inputXML) {
    try {
      const response: any = await this.importsService.performImport(
        inputXML,
        'RPMPricingFilterValue',
      );
      const status: any = response.status;
      if (status === 200) {
        this.snack.openSnackBar(
          'Completed Import successfully!' + response.body,
          '',
          false,
          'Success',
          'alert-success',
        );
        this.loadListData();
      }
    } catch (e) {
      this.snack.openSnackBar(e.error, '', true, 'Error', 'alert-danger');
    }
  }

  onRefresh() {
    this.loadListData();
  }

  onCellClick(e) {
    this.clickedRowItem = e.dataItem;
  }

  onDblClick() {
    if (this.clickedRowItem) {
      if (this.mode === 'lookup') {
        this.onSelectSingle.emit(this.clickedRowItem);
      }
      else {
        this.onEdit(this.clickedRowItem);
      }
    }
  }

  public gridFilterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    this.query = {
      PageNumber: 1,
      PageSize: this.query.PageSize,
      SortTerm: 'attribute.name',
      SearchTerm: '',
    };
    if (filter.filters.length > 0) {
      filter.filters.forEach((value) => {
        const myFilter: FilterDescriptor = value as FilterDescriptor;       
        if (myFilter.field == 'entity.name') {
          if (myFilter.value != '(All)') {
            const Filter_EntityName = myFilter.value;
            const Operand_EntityName = myFilter.operator;
            const params = { ...this.query, Filter_EntityName, Operand_EntityName };
            this.query = params;
          }
        }
        if (myFilter.field == 'attribute.name') {
          const Filter_AttributeName = myFilter.value;
          const Operand_AttributeName = myFilter.operator;
          const params = {
            ...this.query,
            Filter_AttributeName,
            Operand_AttributeName,
          };
          this.query = params;
        }

        if (myFilter.field == 'operand.name') {
          if (myFilter.value != '(All)') {
            const Filter_Operand = myFilter.value;
            const Operand_Operand = myFilter.operator;
            const params = { ...this.query, Filter_Operand, Operand_Operand };
            this.query = params;
          }
        }
       if (myFilter.field == 'value') {
          const Filter_Value = myFilter.value;
          const Operand_Value = myFilter.operator;
          const params = { ...this.query, Filter_Value, Operand_Value };
          this.query = params;
        }
      });
    }
    this.onFilterChange();
  }
  public allData = (): Promise<any> => {
    this.query.PageSize = 200000;
    this.query.PageNumber = 1;
    this.loading = true;
    return this.pricingFilterValuesService.getExportList({
      Filter_PricingFilterID: this.pricingFilterID,
      ...this.query,
    });
  };

  edit(id: number) {
    this.router.navigate([`/price/pricing-filter-value/${id}`]);
  }

  async showRemovePopUp(content) {
    const result = await this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    }).result;
  }

  async remove(modal) {
    const ids = [];
    this.mySelection.forEach((value) => {
      ids.push(Number(value));
    });
    await this.pricingFilterValuesService
      .removeByIds(ids)
      .then((showSuccess: VoidFunction) => {
        this.snack.openSnackBar(
          'Record(s) deleted successfully!',
          '',
          false,
          'Success',
          'alert-success',
        );
        this.mySelection = [];
      })
      .catch((err: HttpErrorResponse) => {
        this.snack.openSnackBar(err.error, '', true, 'Error', 'alert-danger');
      });

    modal.close();
    this.loadListData();
  }

  get disableDeleteBtn() {
    return !Object.keys(this.mySelection).length;
  }

  get disableEditBtn() {
    return Object.keys(this.mySelection).length !== 1;
  }

  onSelectSingleItem(item) {
    this.onSelectSingle.emit(item);
  }
  public pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.query.PageNumber = event.skip / event.take + 1;
    this.loadListData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    if (sort[0].dir == 'asc') {
      this.query.SortTerm = sort[0].field;
    } else if (sort[0].dir == 'desc') {
      this.query.SortTerm = '-' + sort[0].field;
    } else {
      this.query.SortTerm = sort[0].field;
    }
    this.loadListData();
  }

  onCreate() {
    const modalRef = this.modalService.open(PricingFilterValueSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',      
      windowClass: 'PricingFilterValueModalClass',
      centered: true,
      backdrop: 'static',      
    });
    modalRef.componentInstance.idOfPricingFilter = this.pricingFilterID;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.loadListData();
      modalRef.close();
    });
  }

  onEdit(pricingFilterValueID) {
    const modalRef = this.modalService.open(PricingFilterValueSingleComponent, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'PricingFilterValueModalClass',
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.idOfPricingFilterValue = pricingFilterValueID.id;
    modalRef.componentInstance.onFormSaved.subscribe(() => {
      this.filterChange.emit(this.query);
      this.loadListData();
      modalRef.close();
    });
  }
}
